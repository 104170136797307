// import { DIMENSIONS } from './constants';

const getWinningDimensions = data => {
  // console.log({ data });
  // console.log(JSON.stringify(data, null, 2));
  const { TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4 } = data;
  // get current DIMENSIONS index of top dims
  // const firstIndex = DIMENSIONS.indexOf(TOP_DIM_1);
  // const secondIndex = DIMENSIONS.indexOf(TOP_DIM_2);
  // console.log({ firstIndex, secondIndex, TOP_DIM_1, TOP_DIM_2 });
  return {
    first: TOP_DIM_1,
    second: TOP_DIM_2,
    third: TOP_DIM_3,
    fourth: TOP_DIM_4
  };
};

export const getFirstNWinners = (data, n) => {
  const currData = [...data];
  const sorted = currData.sort((a, b) => b.sum - a.sum);
  const sums = sorted.map(s => s.sum);
  // console.log({ sums });
  const thirdIsEqual = sums[1] === sums[2];
  const fourthIsEqual = sums[1] === sums[3];
  // console.log({ thirdIsEqual, fourthIsEqual });
  let sliceN = n;
  if (thirdIsEqual) {
    sliceN = n + 1;
  }
  if (fourthIsEqual) {
    sliceN = n + 2;
  }
  const slicedSorted = sorted.slice(0, sliceN);
  return slicedSorted;
};

export const getDimensionItems = (items, dimension) => {
  const dimensionItems = items.find(group => group.dimension === dimension);
  if (!dimensionItems) {
    return {};
  }
  const dimensionGroup = dimensionItems.items;
  const allItems = dimensionGroup.reduce((acc, current, index) => {
    const options = current.options;
    const parsedOptions = options.map(option => ({
      ...option,
      id: option.code,
      value: 0
    }));
    const allOptions = [...acc, parsedOptions];
    return allOptions;
  }, []);
  // –– RANDOMIZE LIST ITEMS ––
  // get array of indexes
  const indexes = allItems.map((it, index) => index);
  // copy to let var
  let indexesLet = [...indexes];
  // declare shuffle func
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  // shuffle (mutate) let var
  shuffle(indexesLet);
  // get a clone from mutated pattern
  const randomPattern = [...indexesLet];
  // console.log({ randomPattern });
  // FINALLY, use pattern on list items
  const itemsRandomized = randomPattern.map(n => allItems[n]);
  // –––– FINISH RANDOM ––––
  return { allItems: allItems, randomItems: itemsRandomized };
};

export default getWinningDimensions;
