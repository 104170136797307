import React from 'react';
import ChatWrapper from './chat.style';
import NabuPic from './img/nabu-full.png';

class NabuIntro extends React.Component {
  render() {
    const { name, email } = this.props;
    return (
      <ChatWrapper>
        <div className='nabu-chat-person'>
          <img className='nabu-pic' src={NabuPic} alt='nabu-pic' />
        </div>
        <div className='nabu-chat-wrapper'>
          <div className='finish_main'>
            <h3>¡Muchas Gracias!</h3>
            <p>
              La información entregada ha sido registrada de forma exitosa.
              <br />
              Los datos ya están siendo procesados y trabajamos para que
              prontamente puedas tener tus resultados.
              <br />
              <br />
              Si tienes alguna pregunta o deseas ponerte en contacto con
              nosotros, favor contactar a {name} al email{' '}
              <a href={`mailto:${email}`}>{email}</a>.
            </p>
          </div>
        </div>
      </ChatWrapper>
    );
  }
}

export default NabuIntro;
