import styled from 'styled-components';

const ProgressWrapper = styled.div`
  .progress {
    position: fixed;
    top: 0;
    width: 100%;
    border-radius: 0;
    height: 5px;
    span {
      opacity: 0;
      width: 0;
    }
    .progress-bar {
      margin: 0px;
      background-color: ${({ theme }) => theme.colors.brandColor} !important;
    }
  }
`;

export default ProgressWrapper;
