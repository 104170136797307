const LearningAgility = {
  locale: 'es',
  title: 'Learning Agility',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_02',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
<legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          <h3>Aprendizaje</h3>
        </span>
      </span>
    </div>
    <br>
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        A continuación, encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. <u>Pensando en los últimos 6 meses</u>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.
        <br>
        <br>
        Por ejemplo, imagine que durante los últimos 6 meses Ud. ha intentado seguir una dieta baja en calorías. Si ha sido capaz de seguir la dieta casi todos los días o todos los días, Ud. debiera marcar un valor cercano a 7 (Muy frecuentemente). Si sólo algunos días ha seguido la dieta debiese marcar una opción entorno a un valor de 4 (Ocasionalmente). Por último, si en verdad no ha sido capaz de seguir la dieta Ud. debiese marcar un valor cercano a 1 (Nada).
        <br>
        <br>
        Puede marcar la opción “No aplica” si por alguna <u>condición muy excepcional</u> es imposible que se dé el comportamiento descrito en su trabajo. Sin embargo, le pedimos que en lo posible <u>evite</u> usar esta opción. Por ejemplo, se entiende que algunas personas pueden no tener un jefe/a, pero seguramente tiene algún superior a quien reportar o pedir feedback sobre su desempeño.
        <br>
        <br>
        Le recordamos que es de vital importancia que responda las siguientes preguntas de manera honesta y sin pensarlo demasiado. El objetivo de este test es ayudarle a pensar sobre sí mismo/a y descubrir sus fortalezas y oportunidades de desarrollo.
        <br>
        <br>
        <br>
        Presione el botón para continuar
      </span>
    </span>
  </div>
</legend>
`
        }
      ]
    },
    {
      name: 'LEARN_AGILITY_01',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
<div>
  <legend>
    <span style="font-size:16px;">
      <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
    </span>
  </legend>
  <div class="title_img_table table_title">
    <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
  </div>
</div>
`
        },
        {
          type: 'matrix',
          name: 'L_AGILITY_01',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'BF_01',
              text: 'Pedir a mis pares que me den feedback sobre mi desempeño'
            },
            {
              value: 'BF_02',
              text:
                'Pedir a mi jefe/a que me entregue feedback sobre mi desempeño'
            },
            {
              value: 'BF_03',
              text:
                'Conversar con mi jefe/a sobre qué necesito mejorar para avanzar dentro de la organización'
            },
            {
              value: 'BF_04',
              text:
                'Pedir directamente a otros su opinión sobre cómo podría mejorar mi desempeño'
            },
            {
              value: 'BI_01',
              text:
                'Buscar información nueva sobre temas relacionados con mi trabajo o mi área'
            },
            {
              value: 'BI_02',
              text:
                'Actualizar mis conocimientos y experticia a través de capacitaciones o educación formal'
            },
            {
              value: 'BI_03',
              text:
                'Para estar informado, leer revistas especializadas, artículos en el diario, libros y otras fuentes'
            },
            {
              value: 'BI_04',
              text:
                'Buscar datos para mejorar mi conocimiento, ver cómo voy y analizar los próximos pasos'
            }
          ]
        }
      ]
    },

    {
      name: 'LEARN_AGILITY_02',
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
<div>
  <legend>
    <span style="font-size:16px;">
      <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
    </span>
  </legend>
  <div class="title_img_table table_title">
    <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
  </div>
</div>
`
        },
        {
          type: 'matrix',
          name: 'L_AGILITY_02',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'TRD_01',
              text: 'Asumir nuevos roles o tareas que sean desafiantes'
            },
            {
              value: 'TRD_02',
              text:
                'Involucrarse en tareas complejas en donde no se sabe de antemano cuál es la mejor manera de actuar'
            },
            {
              value: 'TRD_03',
              text:
                'Aceptar con gusto trabajos que implican riesgo, incluso si los resultados son inciertos'
            },
            {
              value: 'TRD_04',
              text:
                'Ofrecerse de forma voluntaria para realizar tareas o proyectos que involucren la posibilidad de fallar'
            },
            {
              value: 'TRI_01',
              text:
                '"Poner sobre la mesa" los problemas o temas difíciles con los demás'
            },
            {
              value: 'TRI_02',
              text: 'Pedir ayuda a los demás cuando lo necesito'
            },
            {
              value: 'TRI_03',
              text: 'Conversar mis errores con otros'
            },
            {
              value: 'TRI_04',
              text:
                'Desafiar las ideas y opiniones de otros, incluso cuando son compartidas por muchas personas'
            }
          ]
        }
      ]
    },

    {
      name: 'LEARN_AGILITY_03',
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
<div>
  <legend>
    <span style="font-size:16px;">
      <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
    </span>
  </legend>
  <div class="title_img_table table_title">
    <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
  </div>
</div>
`
        },
        {
          type: 'matrix',
          name: 'L_AGILITY_03',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'COL_01',
              text:
                'Buscar formas de potenciar las habilidades, conocimientos y talentos únicos de los demás'
            },
            {
              value: 'COL_02',
              text:
                'Trabajar con personas de distintos ‘mundos’ o disciplinas para compartir puntos de vista'
            },
            {
              value: 'COL_03',
              text:
                'Colaborar con personas que se encuentran en otras áreas de la organización donde trabajo'
            },
            {
              value: 'COL_04',
              text:
                'Preguntar a varias partes implicadas (stakeholders) su punto de vista'
            },
            {
              value: 'EXP_01',
              text:
                'Evaluar nuevas técnicas o formas diferentes de resolver problemas'
            },
            {
              value: 'EXP_02',
              text:
                'Experimentar con ideas que no han sido probadas poniéndolas a prueba'
            },
            {
              value: 'EXP_03',
              text:
                'Probar diferentes enfoques para ver cuál de ellos genera los mejores resultados'
            },
            {
              value: 'EXP_04',
              text:
                'Lanzarse a la acción y aprender por medio de ensayo y error'
            }
          ]
        }
      ]
    },

    {
      name: 'LEARN_AGILITY_04',
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
<div>
  <legend>
    <span style="font-size:16px;">
      <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
    </span>
  </legend>
  <div class="title_img_table table_title">
    <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
  </div>
</div>
`
        },
        {
          type: 'matrix',
          name: 'L_AGILITY_04',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'REF_01',
              text:
                'Detenerse a reflexionar sobre los procesos y proyectos laborales'
            },
            {
              value: 'REF_02',
              text:
                'Tomarse el tiempo para reflexionar sobre cómo ser más efectivo/a'
            },
            {
              value: 'REF_03',
              text:
                'Considerar las razones y las consecuencias de mis acciones o de eventos recientes'
            },
            {
              value: 'REF_04',
              text:
                'Evaluar en forma crítica, con los demás, los eventos relacionados con el trabajo, con el fin de entender qué sucedió'
            },
            {
              value: 'REF_05_new',
              text:
                'Entender bien por qué hago las cosas y cuáles son las consecuencias de mis acciones'
            },
            {
              value: 'COL_05_new',
              text:
                'Preguntar a los distintos actores involucrados para construir soluciones colaborativas'
            },
            {
              value: 'TRI_05_new',
              text:
                'Preguntar a mis pares cuando hay algo que no sé cómo resolver'
            },
            {
              value: 'TRI_06_new',
              text:
                'Discutir con mis pares acerca de los problemas que pueden estar ocurriendo'
            },
            {
              value: 'TRI_07_new',
              text:
                'Tener conversaciones difíciles que otras personas prefieren evitar'
            }
          ]
        }
      ]
    },

    {
      name: 'LEARN_ABILITY_01',
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
<div>
  <legend>
    <span style="font-size:16px;">
      <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
    </span>
  </legend>
  <div class="title_img_table table_title">
    <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
  </div>
</div>
`
        },
        {
          type: 'matrix',
          name: 'LEARN_ABILITY_01',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'FLEX_01',
              text: 'Considerar diferentes opciones antes de actuar'
            },
            {
              value: 'FLEX_02',
              text:
                'Alternar entre diferentes tareas y trabajos según sea necesario'
            },
            {
              value: 'FLEX_03',
              text: 'Encontrar temas comunes entre puntos de vista opuestos'
            },
            {
              value: 'FLEX_04',
              text:
                'Integrar ideas o perspectivas que aparentemente son contrarias'
            },
            {
              value: 'FLEX_05',
              text: 'Proponer soluciones que otros consideran como innovadoras'
            },
            {
              value: 'VEL_01',
              text: 'Desarrollar rápidamente soluciones a los problemas'
            },
            {
              value: 'VEL_02',
              text:
                '"Ponerse al día" rápidamente al sumarse a un nuevo proyecto'
            },
            {
              value: 'VEL_03',
              text:
                'Adquirir en forma rápida y fácil nuevas habilidades y conocimientos'
            },
            {
              value: 'VEL_04',
              text: 'Reaccionar bien ante problemas inesperados'
            },
            {
              value: 'VEL_05',
              text: 'Comprender nuevas ideas o conceptos con rapidez'
            }
          ]
        }
      ]
    },

    {
      name: 'LEARN_ABILITY_02',
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
<div>
  <legend>
    <span style="font-size:16px;">
      <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
    </span>
  </legend>
  <div class="title_img_table table_title">
    <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
  </div>
</div>
`
        },
        {
          type: 'matrix',
          name: 'LEARN_ABILITY_02',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'VEL_06_new',
              text:
                'Al involucrarse en un nuevo proyecto, aprender rápido todo lo que tengo que aprender'
            },
            {
              value: 'FLEX_06_new',
              text: 'Integrar diferentes perspectivas u opiniones'
            },
            {
              value: 'FLEX_07_new',
              text:
                'Cambiar la manera en que se está enfocando un problema para encontrar soluciones'
            },
            {
              value: 'VEL_07_new',
              text: 'Responder rápidamente a situaciones imprevistas'
            }
          ]
        }
      ]
    }
  ]
};

export default LearningAgility;
