import React from 'react';
import HomeComponent from '../../Components/Index';
import { StageConsumer } from '../../Context';

const IndexPage = props => {
  return (
    <StageConsumer>
      {({ state, updateContext, nextStage }) => (
        <HomeComponent
          {...props}
          {...state}
          updateContext={updateContext}
          toNextStage={nextStage}
        />
      )}
    </StageConsumer>
  );
};

export default IndexPage;
