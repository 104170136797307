import React from 'react';
import SortableList from './SortableList';
import postData from '../../API';

class SortableListPage extends React.Component {
  onComplete = async data => {
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    return <SortableList onComplete={this.onComplete} />;
  }
}

export default SortableListPage;
