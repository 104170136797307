import React from 'react';
import SortableList from './SortablePolarList';

const ITEMS = [
  {
    id: 'EXP_01',
    statement: 'Ensalada de tomate'
  },
  {
    id: 'EXP_02',
    statement: 'Pizza con piña'
  },
  {
    id: 'EXP_03',
    statement: 'Pescado con papas fritas'
  },
  {
    id: 'EXP_04',
    statement: 'Helado de piña'
  }
];

class SortablePolarListComponent extends React.Component {
  onComplete = async data => {
    // console.log({ data });
    return this.props.triggerNextStep({ value: data });
  };

  render() {
    // console.log('on custom component');
    // console.log({ props: this.props });
    const { steps } = this.props;
    // if already has value
    const { ranking_02 } = steps;
    if (ranking_02 && ranking_02.value) {
      const reuseData = ranking_02.value.reduce((acc, current, index) => {
        const entries = Object.entries(current);
        const items = entries.map(entry => entry[1]);
        return [...acc, ...items];
      }, []);
      return (
        <SortableList
          {...this.props}
          onComplete={this.onComplete}
          exampleItems={reuseData}
        />
      );
    }
    return (
      <SortableList
        {...this.props}
        onComplete={this.onComplete}
        exampleItems={ITEMS}
      />
    );
  }
}

export default SortablePolarListComponent;
