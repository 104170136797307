const baseInfo = {
  locale: 'es',
  title: 'La enfermedad y su impacto en la familia.',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'PARTNER_PAGE_01',
      title: 'Impacto sobre la pareja',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-size:16px;">
    [SÓLO EN EL CASO QUE EL RESPONDIENTE SEA UNO DE LOS MIEMBROS DE LA PAREJA PARENTAL]
    </span>
    <br />
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'radiogroup',
          name: 'PARTNER_01',
          title:
            'Al momento del diagnóstico de {CHILD_NAME}, ¿Se encontraba usted en pareja?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'PARTNER_02',
          title: '¿Es su pareja madre/padre de {CHILD_NAME}?',
          isRequired: true,
          visibleIf: '{PARTNER_01} = "01"',
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            }
          ]
        }
      ]
    },
    {
      name: 'PARTNER_PAGE_02',
      title: 'Impacto en la pareja',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          visibleIf: '{PARTNER_01} = "01"',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
                Pensando en los posibles efectos negativos de la enfermedad en la relación pareja:
                <br />
                <strong>En una escala de 0 a 3</strong>, donde 0 significa que no se vio afectado, 1 significa levemente afectado, 2 significa moderadamente afectado y 3 significa gravemente afectado,
                <strong>¿Cuánto se vieron afectados los siguientes aspectos de su relación de pareja?</strong>
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'PARTNER_AFFECTED',
          visibleIf: '{PARTNER_01} = "01"',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '0',
              text: 'No se vio afectado'
            },
            {
              value: '1',
              text: 'Levemente afectado'
            },
            {
              value: '2',
              text: 'Moderadamente afectado'
            },
            {
              value: '3',
              text: 'Gravemente afectado'
            },
            {
              value: '4',
              text: 'No Responde'
            }
          ],
          rows: [
            {
              value: 'PAFF_01',
              text: 'Vida Social en pareja'
            },
            {
              value: 'PAFF_02',
              text: 'Intimidad de la pareja'
            },
            {
              value: 'PAFF_03',
              text: 'Comunicación / Diálogo'
            },
            {
              value: 'PAFF_04',
              text: 'Cercanía emocional'
            }
          ]
        }
      ]
    },
    {
      name: 'PARTNER_PAGE_03',
      title: 'Impacto en la pareja',
      visibleIf: '{PARTNER_01} = "01"',
      elements: [
        {
          type: 'radiogroup',
          name: 'PARTNER_03',
          title:
            'Después del diagnóstico o tratamiento de {CHILD_NAME} ¿Terminó o se separó de su pareja?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
                Click en el botón para continuar
              </span>
            </legend>
          </div>
          `
        }
      ]
    },
    {
      name: 'PARTNER_PAGE_04',
      title: 'Impacto en la pareja',
      visibleIf: '{PARTNER_01} = "02"',
      elements: [
        {
          type: 'radiogroup',
          name: 'PARTNER_04',
          title:
            'Durante el tratamiento de la enfermedad de su hijo/a. ¿Mantuvo contacto con el padre/la madre del/la menor?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'PARTNER_05',
          title:
            'Respecto de la calidad de la relación con el padre/la madre del/la menor, usted diría qué:',
          isRequired: true,
          visibleIf: '{PARTNER_04} = "01"',
          choices: [
            {
              value: '01',
              text: 'La relación se mantuvo igual que antes del diagnóstico'
            },
            {
              value: '02',
              text: 'Tuvieron muchos problemas después del diagnóstico'
            },
            {
              value: '03',
              text: 'La relación mejoró después del diagnóstico'
            },
            {
              value: '04',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
                Click en el botón para continuar
              </span>
            </legend>
          </div>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
