const rateValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

const LearningAgility = {
  locale: 'es',
  title: 'Cultura de Pensamiento / Modelo VESS',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'C_CHANGE_PAGE_01',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
              Por favor evalúe las siguientes afirmaciones pensando en la cultura de pensamiento o metodología VESS como una estrategia de cambio adoptada por el colegio al cual usted pertenece. Frente a cada una de ellas evalúe su grado de acuerdo o desacuerdo <strong>usando una escala de 1 (Muy en desacuerdo) a 5 (Muy de acuerdo).</strong>
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'C_CHANGE_01',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: rateValues,
          rows: [
            {
              value: 'CHANGE_01',
              text:
                'Creo en el valor del VESS para producir aprendizajes significativos en mis estudiantes'
            },
            {
              value: 'CHANGE_02',
              text:
                'El VESS contribuye a los buenos resultados en pruebas como el SIMCE y la PSU '
            },
            {
              value: 'CHANGE_03',
              text: 'Tengo la preparación adecuada para implementar el VESS'
            },
            {
              value: 'CHANGE_04',
              text:
                'Tengo tiempo suficiente para planificar las actividades asociadas a la metodología VESS'
            },
            {
              value: 'CHANGE_05',
              text:
                'Cuento con los recursos materiales adecuados para implementar la metodología VESS'
            },
            {
              value: 'CHANGE_06',
              text:
                'Creo que aprender la metodología VESS aporta a mi desarrollo profesional y empleabilidad'
            }
          ]
        }
      ]
    },
    {
      name: 'C_CHANGE_PAGE_02',
      elements: [
        {
          type: 'comment',
          name: 'CHANGE_COMMENT',
          title: '¿Qué sugeriría Ud. para mejorar la implementación del VESS?'
        }
      ]
    }
  ]
};

export default LearningAgility;
