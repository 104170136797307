import React, { useState, useEffect, useRef } from 'react';
import Inputmask from 'inputmask';
import EquisJuniorLogo from '../img/equis_junior_logo.png';
import EquisOneLogo from '../img/equis_1_logo.png';

import {
  Form,
  FormInput,
  SchoolInfoWrapper,
  Row,
  Col,
  Footer,
  ErrorMessage
} from './login.styles';
import { useDataContext } from '../../../../Context/campaign-context';

const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

const LoginBox = props => {
  const { SKU } = props;
  const [error, setError] = useState('');
  const [rut, setRut] = useState('');
  const { campaign, setUserData, loadingUser, logInUser } = useDataContext();

  const inputEl = useRef(null);

  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (inputEl.current) {
      Inputmask({
        mask: '99.999.999-9',
        numericInput: true,
        jitMasking: true
      }).mask(inputEl);
    }
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    // console.log('submitting', rut);
    const cleanRut = rut.replace(/_/g, ''); // clean "_" chars from rut.
    // console.log({ rut, cleanRut });
    // LOGIN LOGIC!!!
    // console.log('now login on campaign with this rut');
    let currentUser = {};
    // console.log({ campaign });
    const isClosed = campaign.type === 'closed';
    const isOpened = campaign.type === 'opened';
    const isMixed = campaign.type === 'mixed';

    // check if closed and no participant to return early
    if (isClosed) {
      // console.log('is closed, has to logIn');
      const participant = await logInUser({ field: 'rut', value: cleanRut });
      if (!participant) {
        // return early
        return setError(`No se encontró participante con RUT: ${cleanRut}`);
      }
      currentUser = { ...participant, rut: cleanRut }; // login logic has user object with responseID ??
    }

    if (isOpened) {
      // set local user only with rut and continue
      currentUser = { rut: cleanRut }; // login logic has user object with responseID ??
    }

    if (isMixed) {
      // console.log('is mixed, has to logIn and continue regardless');
      const participant = await logInUser({ field: 'rut', value: cleanRut });
      if (!participant) {
        currentUser = { rut: cleanRut };
      }
      if (participant) {
        currentUser = { ...participant, rut: cleanRut }; // login logic has user object with responseID ??
      }
    }

    const CURRENT_CODE = `currentCode_${campaign.id}`;
    const LOCAL_RESPONSES = `localUserResponses_${campaign.id}`;
    const localList = localStorage.getItem(LOCAL_RESPONSES);
    // if responses list, check if current rut has local responseID to continue
    if (localList) {
      const campaignResponseLists = JSON.parse(localList);
      const currUserResponse = campaignResponseLists[cleanRut];
      if (currUserResponse) {
        currentUser = { ...currentUser, responseID: currUserResponse };
      }
    }
    // set responseID from url to continue prev incomplete data
    const urlResponseID = getQuery('rid');
    if (urlResponseID) {
      currentUser = { ...currentUser, responseID: urlResponseID };
    }
    // console.log({ currentUser });
    localStorage.setItem(CURRENT_CODE, cleanRut);
    localStorage.setItem(
      `localUserData_${campaign.id}`,
      JSON.stringify(currentUser)
    );

    await setUserData(currentUser);
  };

  return (
    <Row>
      {SKU === 'EQUIS_ONE' && <EquisOneIntro />}
      {SKU === 'EQUIS_ONE_PILOTO' && <EquisOneIntro />}
      {SKU === 'EQUIS_ONE_SORTABLE_ITEMS' && <EquisOneIntro />}
      {SKU === 'EQUIS_ONE_DIMENSION_ITEMS' && <EquisOneIntro />}
      {SKU === 'EQUIS_JUNIOR' && <EquisJuniorIntro />}
      {SKU === 'EQUIS_JUNIOR_PILOTO' && <EquisJuniorIntro />}
      <Col>
        {campaign.status === 'published' && (
          <Form onSubmit={onSubmit}>
            <FormInput>
              <SchoolInfoWrapper>
                <div>{campaign.organization || campaign.title}</div>
              </SchoolInfoWrapper>
            </FormInput>
            <FormInput>
              <label htmlFor='rutid'>Ingresa tu Rut</label>
              <input
                ref={inputEl}
                name='rutid'
                value={rut}
                onChange={e => setRut(e.target.value)}
              />
              <p>
                <strong>Importante: </strong>
                Ingresa tu RUT <u>sin puntos ni guión</u>. <br />
                En caso que termine en <em>K</em> reemplázalo por un <em>0</em>.
              </p>
            </FormInput>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Footer>
              <button type='submit' disabled={loadingUser}>
                {loadingUser ? 'Ingresando...' : 'Ingresar'}
              </button>
            </Footer>
          </Form>
        )}
        {campaign.status === 'unpublished' && (
          <Form onSubmit={() => {}}>
            <FormInput>
              <SchoolInfoWrapper>
                <div>{campaign.organization || campaign.title}</div>
              </SchoolInfoWrapper>
            </FormInput>
            <FormInput>
              <p>Campaña no disponible</p>
            </FormInput>
          </Form>
        )}
        {campaign.status === 'paused' && (
          <Form onSubmit={() => {}}>
            <FormInput>
              <SchoolInfoWrapper>
                <div>{campaign.organization || campaign.title}</div>
              </SchoolInfoWrapper>
            </FormInput>
            <FormInput>
              <p>Campaña pausada</p>
            </FormInput>
          </Form>
        )}
      </Col>
      <div>
        <div className='bl' />
        <div className='tr' />
      </div>
    </Row>
  );
};
export default LoginBox;

const EquisOneIntro = () => (
  <Col>
    <div className='logo-wrapper'>
      <img src={EquisOneLogo} alt='equis1 logo' />
    </div>
    <br />
    <div className='equis-info'>
      <p>
        Bienvenido a <strong>Equis One</strong>. Ingresa tu rut a continuación.
      </p>
      <p>
        Esta información solo se utilizará para guardar los avances de esta
        encuesta en este dispositivo o computadora, y es requisito para poder
        registrar cada una de tus respuestas.
      </p>
      <p>
        En caso de necesitarlo, puedes retomar tus avances volviendo a
        introducir tu RUT en esta encuesta.
      </p>
      <p>
        Si tienes alguna pregunta o deseas ponerte en contacto con nosotros,
        puedes contactar a UAI METRICS al email{' '}
        <a href={`mailto:uaimetrics@uai.cl`}>uaimetrics@uai.cl</a>.
      </p>
    </div>
  </Col>
);

const EquisJuniorIntro = () => (
  <Col>
    <div className='logo-wrapper'>
      <img src={EquisJuniorLogo} alt='equis junior logo' />
    </div>
    <br />
    <div className='equis-info'>
      <p>
        Bienvenido a <strong>Equis Junior</strong>. Ingresa tu rut a
        continuación.
      </p>
      <p>
        Esta información solo se utilizará para guardar los avances de esta
        encuesta en este dispositivo o computadora, y es requisito para poder
        registrar cada una de tus respuestas.
      </p>
      <p>
        En caso de necesitarlo, puedes retomar tus avances volviendo a
        introducir tu RUT en esta encuesta.
      </p>
      <p>
        Si tienes alguna pregunta o deseas ponerte en contacto con nosotros,
        puedes contactar a UAI METRICS al email{' '}
        <a href={`mailto:uaimetrics@uai.cl`}>uaimetrics@uai.cl</a>.
      </p>
    </div>
  </Col>
);
