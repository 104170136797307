const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  }
];

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'IG_00_PAGE',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01_IG',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <h3>Instrucciones</h3>
    </span>
    <br />
    Para finalizar, a continuación encontrarás una lista de afirmaciones relacionadas con el tomar decisiones en general.
    <br />
    Por favor indica en qué medida estas afirmaciones te representan usando una escala de <strong>1</strong> (No me representa para nada) a <strong>7</strong> (Me representa muy bien)
    <br />
  </span>
</div>
`
        }
      ]
    },
    {
      name: 'IG_01_PAGE',
      title: '',
      elements: [
        {
          type: 'matrix',
          name: 'IG_MATRIX',
          title: `Por favor indica en qué medida estas afirmaciones te representan usando una escala de **1** (No me representa para nada) a **7** (Me representa muy bien)`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: ratingValues,
          rows: [
            {
              value: 'IG_01',
              text: 'Encuentro fácil tomar decisiones'
            },
            {
              value: 'IG_02',
              text: 'Me caracterizaría como una persona indecisa'
            },
            {
              value: 'IG_03',
              text: 'No sé como tomar decisiones'
            },
            {
              value: 'IG_04',
              text: 'No dudo demasiado cuando tengo que tomar una decisión'
            },
            {
              value: 'IG_05',
              text:
                'Cuando tomo una decisión, generalmente me siento muy inseguro'
            },
            {
              value: 'IG_06',
              text: 'Puedo tomar decisiones rápidamente'
            },
            {
              value: 'IG_07',
              text: 'Normalmente no pospongo tener que tomar una decisión'
            },
            {
              value: 'IG_08',
              text: 'Trato de evitar tomar decisiones'
            },
            {
              value: 'IG_09',
              text: 'Intento dejar la toma de decisiones a otras personas'
            },
            {
              value: 'IG_10',
              text: 'Normalmente reconsidero las decisiones que tomo'
            },
            {
              value: 'IG_11',
              text:
                'Después de tomar una decisión, no puedo dejar de pensar en ello'
            },
            {
              value: 'IG_12',
              text:
                'Normalmente después de que tomo una decisión, creo que me he equivocado'
            },
            {
              value: 'IG_13',
              text: 'Es difícil para mi tomar una decisión'
            },
            {
              value: 'IG_14',
              text: 'Sé que pasos debo realizar para tomar una decisión'
            },
            {
              value: 'IG_15',
              text: 'Cuando tomo una decisión, normalmente me siento seguro'
            },
            {
              value: 'IG_16',
              text:
                'Me toma mucho tiempo analizar los pros y contras cuando tengo que tomar una decisión'
            },
            {
              value: 'IG_17',
              text: 'Generalmente me demoro en tomar una decisión'
            },
            {
              value: 'IG_18',
              text: 'No evito situaciones en que se deben tomar decisiones'
            },
            {
              value: 'IG_19',
              text:
                'Prefiero tomar las decisiones por mi mismo que dejárselo a otras personas'
            },
            {
              value: 'IG_20',
              text: 'Una vez que tomo una decisión, me apego a ella'
            },
            {
              value: 'IG_21',
              text:
                'Una vez que tomo una decisión, dejo de preocuparme por el asunto'
            },
            {
              value: 'IG_22',
              text:
                'Después de tomar una decisión, no me arrepiento de lo decidido'
            }
          ]
        }
      ]
    }
  ]
};

export default testInfo;
