import axios from 'axios';
// import { USER_ID } from '../config';
import getEquisOneResults from './getResults';

const headers = {
  'x-uaimetrics-api-ver': 'v1'
};

const getParsedResult = data => {
  // console.log('parsing data');
  const resultData = getEquisOneResults(data);
  return resultData;
  // return data;
};

const getResponse = async responseID => {
  const POST_API = `/api/response`;
  const body = {
    params: {
      id: responseID
    }
  };
  // console.log('prev getting');
  const { data } = await axios.get(POST_API, body);
  // console.log({ data });
  if (typeof data === 'string') {
    // if data returned as string
    try {
      const parsedResponse = JSON.parse(data);
      return parsedResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
  return data;
};

const createNewResponse = async (values, opts = {}) => {
  const POST_API = '/api/response/new';
  // const POST_API = `/api/new-response`;
  const body = { ...values };
  const options = { ...opts, headers };
  // console.log('prev creating');
  const { data } = await axios.post(`${POST_API}`, body, options);
  // console.log(data);
  if (typeof data === 'string') {
    // console.log('data is string');
    // if data returned as string
    try {
      const parsedResponse = JSON.parse(data);
      // console.log({ parsedResponse });
      return parsedResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
  // console.log('data is not string');
  return data;
};

const updateResponse = async responseInput => {
  const POST_API = `/api/response/update`;
  const body = {
    ...responseInput
  };
  // console.log('prev updating');
  const { data } = await axios.post(POST_API, body);
  // console.log({ data });
  if (typeof data === 'string') {
    // if data returned as string
    try {
      const parsedResponse = JSON.parse(data);
      return parsedResponse.data;
    } catch (err) {
      console.log(err);
    }
  }
  return data;
};

const getPrevData = async ({ responseID }) => {
  // console.log('getting prev data for: ', responseID);
  if (!responseID) {
    return null;
  }
  const prevRecord = await getResponse(responseID);
  // console.log({ prevRecord });
  // if user has responses
  if (prevRecord) {
    // console.log({ parsedResponses });
    const incompleteResponse = {
      ...prevRecord,
      data: JSON.parse(prevRecord.data)
    };
    // console.log({ incompleteResponse });
    // if user has incomplete SKU response
    if (incompleteResponse) {
      return Promise.resolve(incompleteResponse);
    }
  }
  return null;
};

// const { campaignID, data, userID, username } = body;

const postData = async data => {
  const { values, campaignID, responseID, CODE, finished } = data;
  // console.log('check prev response with id: ', responseID);
  const prevData = await getPrevData({ responseID });
  const date = new Date();
  let resultData = {};
  // console.log({ prevData });
  if (prevData) {
    // console.log('has prev data!');
    // update prev record
    let newData = { ...prevData.data, ...values };
    // if finished set new resultData
    if (Boolean(finished)) {
      resultData = getParsedResult(newData);
      newData = { ...prevData.data, ...values, ...resultData };
    }
    const updateObject = {
      id: prevData.id,
      submitted: Boolean(finished),
      data: JSON.stringify(newData),
      updatedAt: date.toISOString()
    };
    // console.log({ updateObject });
    const updatedResponse = await updateResponse(updateObject);
    // console.log({ updatedResponse });
    return updatedResponse;
  }
  // console.log('create new response');

  if (!campaignID) {
    // an error ocurred
    return null;
  }
  // if not prev records, create new response record
  let responseData = { ...values };
  // if finished set new resultData
  if (Boolean(finished)) {
    resultData = getParsedResult(values);
    responseData = { ...values, ...resultData };
  }

  const responseObject = {
    username: CODE,
    campaignID,
    submitted: Boolean(finished),
    data: responseData
  };
  // console.log({ data, responseObject });
  const newResponse = await createNewResponse(responseObject);
  // console.log({ newResponse });
  return newResponse;
  // return Promise.resolve('200');
};

export default postData;
