const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

// const questionFooter = {
//   type: 'html',
//   name: 'HTML_QUESTION_FOOTER',
//   html: `
//   <div class="qstn-likert-label pb-5">
//     <div>No me representa para nada</div>
//     <div></div>
//     <div>Me representa muy bien</div>
//   </div>
//   `
// };
// const questionHeader = {
//   type: 'html',
//   name: 'HTML_QUESTION_Header',
//   html: `
//   <div class="QuestionText BorderColor">
//     <span style="font-size:16px;">
//       Por favor indica en qué medida estas afirmaciones te representan utilizando la siguiente escala:
//     </span>
//   </div>
//   `
// };

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FEEDBACK_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01_DIM',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <h3>Instrucciones</h3>
    </span>
    <br />
    A continuación, le presentaremos una serie de preguntas en relación a su experiencia con el test, las cuales debes responder utilizando la escala proporcionada en cada caso.
    <br />
    <br />
    Tal como en las etapas anteriores, no existen preguntas correctas o incorrectas, por lo que te pedimos responder de la forma más honesta posible.
    <br />
  </span>
</div>
`
        }
      ]
    }
  ]
};

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FUTURE_CHOICE',
      title: '',
      elements: [
        {
          type: 'rating',
          name: 'FUTURE_CHOICE',
          title:
            'En una escala de 1 a 5 ¿cuánta **claridad** tienes sobre la carrera profesional o trabajo que te gustaría desempeñar en el futuro?',
          isRequired: true,
          rateValues: ratingValues
        },
        {
          type: 'html',
          name: 'HTML_QUESTION_FOOTER',
          html: `
          <div class="qstn-likert-label pb-5">
            <div>Para nada claro</div>
            <div></div>
            <div>Muy claro</div>
          </div>
          `
        }
      ]
    },
    {
      name: 'FEEDBACK_PAGE_01_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01_DIM',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <h3>Instrucciones</h3>
    </span>
    <br />
    A continuación, te realizaremos algunas preguntas acerca de tu opinión del test que acabas de responder:
    <br />
    <br />
  </span>
</div>
`
        }
      ]
    },
    {
      name: 'FEED_01',
      title: '',
      elements: [
        {
          type: 'rating',
          name: 'FEED_01',
          title:
            'En una escala de 1 a 5 ¿Cuánto **te gustó** responder este test?',
          isRequired: true,
          rateValues: ratingValues
        },
        {
          type: 'html',
          name: 'HTML_QUESTION_FOOTER',
          html: `
          <div class="qstn-likert-label pb-5">
            <div>No me gusto nada</div>
            <div>Neutro</div>
            <div>Me gusto mucho</div>
          </div>
          `
        }
      ]
    },
    {
      name: 'FEED_02',
      title: '',
      elements: [
        {
          type: 'rating',
          name: 'FEED_02',
          title:
            'En una escala de 1 a 5 ¿Cuán **entretenido** fue para ti responder este test?',
          isRequired: true,
          rateValues: ratingValues
        },
        {
          type: 'html',
          name: 'HTML_QUESTION_FOOTER',
          html: `
          <div class="qstn-likert-label pb-5">
            <div>Nada entretenido</div>
            <div>Neutro</div>
            <div>Muy entretenido</div>
          </div>
          `
        }
      ]
    },
    {
      name: 'FEED_03',
      title: '',
      elements: [
        {
          type: 'rating',
          name: 'FEED_03',
          title:
            'En una escala de 1 a 5 ¿cuán fácil fue para ti entender las preguntas del test?',
          isRequired: true,
          rateValues: ratingValues
        },
        {
          type: 'html',
          name: 'HTML_QUESTION_FOOTER',
          html: `
          <div class="qstn-likert-label pb-5">
            <div>Para nada fácil</div>
            <div>Neutro</div>
            <div>Muy fácil</div>
          </div>
          `
        }
      ]
    },
    {
      name: 'FEED_04',
      title: '',
      elements: [
        {
          type: 'rating',
          name: 'FEED_04',
          title:
            'Las preguntas de este test me hicieron pensar sobre lo que me gustaría hacer en el futuro',
          isRequired: true,
          rateValues: ratingValues
        },
        {
          type: 'html',
          name: 'HTML_QUESTION_FOOTER',
          html: `
          <div class="qstn-likert-label pb-5">
            <div>Muy en desacuerdo</div>
            <div>Neutro</div>
            <div>Muy de acuerdo</div>
          </div>
          `
        }
      ]
    },
    {
      name: 'FEED_05',
      title: '',
      elements: [
        {
          type: 'radiogroup',
          name: 'FEED_05',
          title: '¿Recomendarías a otras personas responder este test?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Si'
            },
            {
              value: '2',
              text: 'No'
            }
          ]
        },
        {
          type: 'comment',
          title: '¿Por qué?',
          name: 'FEED_05_OTHER',
          isRequired: true,
          visibleIf: '{FEED_05} = "2"'
        }
      ]
    }
  ]
};

export default testInfo;
