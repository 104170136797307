import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import DemographicsSurvey from './demographics';
import DemographicsWrapper from './demographics.styles';

function checkboxMinSelected(params) {
  const [responses, n] = params;
  const minSelected = responses.length >= n;
  if (minSelected) {
    return true;
  }
  return false;
}

class Demographics extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { onComplete } = this.props;

    const { data } = survey;
    const { NAME_BASE } = data;
    const parsed = {
      ...data,
      ...NAME_BASE
    };

    delete parsed.NAME_BASE;

    await onComplete(parsed);
  };

  onValidate = async survey => {
    const { data } = survey;
    // if not consent, submit early
    if (data.CONSENT === '2') {
      const { onComplete } = this.props;
      const parsed = {
        ...data
      };
      await onComplete(parsed);
    }
  };

  render() {
    const DemographicsModel = new Survey.Model(DemographicsSurvey);
    Survey.FunctionFactory.Instance.register(
      'checkboxMinSelected',
      checkboxMinSelected,
      false // last param indicates if async callback or not
    );
    return (
      <DemographicsWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={DemographicsModel}
          css={CssNames}
          onComplete={this.onComplete}
          onValidatedErrorsOnCurrentPage={this.onValidate}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </DemographicsWrapper>
    );
  }
}

export default Demographics;
