import React from 'react';
import Survey, { CssNames } from '../../Survey';
import FinishedSurvey from './finishedSurvey';
import FinishedWrapper from './finished.styles';

class FinishedMessage extends React.Component {
  render() {
    const FinishedModel = new Survey.Model(FinishedSurvey);
    return (
      <FinishedWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={FinishedModel}
          css={CssNames}
          // onComplete={this.onComplete}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </FinishedWrapper>
    );
  }
}

export default FinishedMessage;
