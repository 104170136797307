const baseInfo = {
  locale: 'es',
  title: 'La enfermedad y su impacto en la familia',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'CARE_PAGE_01',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'expression',
          name: 'question2',
          title:
            'Independiente de si viven o no en el mismo hogar, identifique 3 personas que cumplen/cumplieron un rol fundamental de apoyo a {CHILD_NAME}',
          commentText: 'Other (describe)'
        },
        {
          type: 'radiogroup',
          name: 'CARE_ROLE_01',
          title:
            'Por favor, identifique a la 1ra persona dentro de las siguientes opciones:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Hermano'
            },
            {
              value: '04',
              text: 'Hermana'
            },
            {
              value: '05',
              text: 'Tío'
            },
            {
              value: '06',
              text: 'Tía'
            },
            {
              value: '07',
              text: 'Abuelo'
            },
            {
              value: '08',
              text: 'Abuela'
            },
            {
              value: '09',
              text: 'Otro'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique',
          name: 'CARE_ROLE_01_OTHER',
          isRequired: true,
          visibleIf: '{CARE_ROLE_01} = "09"'
        },
        {
          type: 'radiogroup',
          name: 'CARE_ROLE_02',
          title:
            'Por favor, identifique a la 2da persona dentro de las siguientes opciones:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Hermano'
            },
            {
              value: '04',
              text: 'Hermana'
            },
            {
              value: '05',
              text: 'Tío'
            },
            {
              value: '06',
              text: 'Tía'
            },
            {
              value: '07',
              text: 'Abuelo'
            },
            {
              value: '08',
              text: 'Abuela'
            },
            {
              value: '09',
              text: 'Otro'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique',
          name: 'CARE_ROLE_02_OTHER',
          isRequired: true,
          visibleIf: '{CARE_ROLE_02} = "09"'
        },

        {
          type: 'radiogroup',
          name: 'CARE_ROLE_03',
          title:
            'Por favor, identifique a la 3ra persona dentro de las siguientes opciones:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Hermano'
            },
            {
              value: '04',
              text: 'Hermana'
            },
            {
              value: '05',
              text: 'Tío'
            },
            {
              value: '06',
              text: 'Tía'
            },
            {
              value: '07',
              text: 'Abuelo'
            },
            {
              value: '08',
              text: 'Abuela'
            },
            {
              value: '09',
              text: 'Otro'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique',
          name: 'CARE_ROLE_03_OTHER',
          isRequired: true,
          visibleIf: '{CARE_ROLE_03} = "09"'
        },
        {
          type: 'text',
          name: 'CARE_FIRST',
          title:
            'Entre las 3 personas identificadas anteriormente ¿Quién diría Ud. que es el cuidador principal de {CHILD_NAME}? Por cuidador principal nos referimos a la persona que MÁS tiempo dedica a los cuidados de {CHILD_NAME} relacionados con su enfermedad y tratamiento',
          isRequired: true
        }
      ]
    },
    {
      name: 'CARE_PAGE_02',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'checkbox',
          name: 'CARE_01',
          title:
            '¿Cuál de las siguientes actividades ha realizado {CARE_ROLE_01} {CARE_ROLE_01_OTHER}, en relación al diagnóstico y tratamiento de {CHILD_NAME}? Dígame Sí o No. [MARCAR TODAS LAS QUE CORRESPONDAN]:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Acompañar al niño a controles'
            },
            {
              value: '02',
              text: 'Acompañar a los tratamientos ambulatorios'
            },
            {
              value: '03',
              text: 'Búsqueda de apoyo financiero o previsional'
            },
            {
              value: '04',
              text: 'Acompañamiento al niño en la hospitalización'
            },
            {
              value: '05',
              text:
                'Actividades relacionadas al acondicionamiento de la vivienda'
            },
            {
              value: '06',
              text: 'Actividades relacionadas a la rehabilitación'
            },
            {
              value: '07',
              text: 'Actividades de apoyo educativo'
            },
            {
              value: '08',
              text: 'Otra'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otra, especifique',
          name: 'CARE_01_OTHER',
          isRequired: true,
          visibleIf: '{CARE_01} contains "08"'
        }
      ]
    },
    {
      name: 'CARE_PAGE_03',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'checkbox',
          name: 'CARE_02',
          title:
            '¿Cuál de las siguientes actividades ha realizado {CARE_ROLE_02} {CARE_ROLE_02_OTHER}, en relación al diagnóstico y tratamiento de {CHILD_NAME}? Dígame Sí o No. [MARCAR TODAS LAS QUE CORRESPONDAN]:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Acompañar al niño a controles'
            },
            {
              value: '02',
              text: 'Acompañar a los tratamientos ambulatorios'
            },
            {
              value: '03',
              text: 'Búsqueda de apoyo financiero o previsional'
            },
            {
              value: '04',
              text: 'Acompañamiento al niño en la hospitalización'
            },
            {
              value: '05',
              text:
                'Actividades relacionadas al acondicionamiento de la vivienda'
            },
            {
              value: '06',
              text: 'Actividades relacionadas a la rehabilitación'
            },
            {
              value: '07',
              text: 'Actividades de apoyo educativo'
            },
            {
              value: '08',
              text: 'Otra'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otra, especifique',
          name: 'CARE_02_OTHER',
          isRequired: true,
          visibleIf: '{CARE_02} contains "08"'
        }
      ]
    },
    {
      name: 'CARE_PAGE_04',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'checkbox',
          name: 'CARE_03',
          title:
            '¿Cuál de las siguientes actividades ha realizado {CARE_ROLE_03} {CARE_ROLE_03_OTHER}, en relación al diagnóstico y tratamiento de {CHILD_NAME}? Dígame Sí o No. [MARCAR TODAS LAS QUE CORRESPONDAN]:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Acompañar al niño a controles'
            },
            {
              value: '02',
              text: 'Acompañar a los tratamientos ambulatorios'
            },
            {
              value: '03',
              text: 'Búsqueda de apoyo financiero o previsional'
            },
            {
              value: '04',
              text: 'Acompañamiento al niño en la hospitalización'
            },
            {
              value: '05',
              text:
                'Actividades relacionadas al acondicionamiento de la vivienda'
            },
            {
              value: '06',
              text: 'Actividades relacionadas a la rehabilitación'
            },
            {
              value: '07',
              text: 'Actividades de apoyo educativo'
            },
            {
              value: '08',
              text: 'Otra'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otra, especifique',
          name: 'CARE_03_OTHER',
          isRequired: true,
          visibleIf: '{CARE_03} contains "08"'
        }
      ]
    },
    {
      name: 'OCCUPATION_PAGE_01',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'radiogroup',
          name: 'OCCUPATION_01',
          title:
            '¿A qué se dedica actualmente {CARE_ROLE_01} {CARE_ROLE_01_OTHER}',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Dueña/o de casa'
            },
            {
              value: '02',
              text: 'Desempleado/a'
            },
            {
              value: '03',
              text: 'Empleado tiempo completo'
            },
            {
              value: '04',
              text: 'Empleado a tiempo parcial'
            },
            {
              value: '05',
              text: 'Trabajador independiente o propietario de empresa pequeña'
            },
            {
              value: '06',
              text: 'Propietario de empresa mediana o grande'
            },
            {
              value: '07',
              text: 'Pensionado/a, jubilado/a o retirado/a'
            },
            {
              value: '08',
              text: 'Estudiante'
            },
            {
              value: '09',
              text: 'Otro'
            },
            {
              value: '10',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique',
          name: 'OCCUPATION_01_OTHER',
          isRequired: true,
          visibleIf: '{OCCUPATION_01} = "09"'
        }
      ]
    },
    {
      name: 'OCCUPATION_PAGE_02',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'radiogroup',
          name: 'OCCUPATION_02',
          title:
            '¿A qué se dedica actualmente {CARE_ROLE_02} {CARE_ROLE_02_OTHER}',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Dueña/o de casa'
            },
            {
              value: '02',
              text: 'Desempleado/a'
            },
            {
              value: '03',
              text: 'Empleado tiempo completo'
            },
            {
              value: '04',
              text: 'Empleado a tiempo parcial'
            },
            {
              value: '05',
              text: 'Trabajador independiente o propietario de empresa pequeña'
            },
            {
              value: '06',
              text: 'Propietario de empresa mediana o grande'
            },
            {
              value: '07',
              text: 'Pensionado/a, jubilado/a o retirado/a'
            },
            {
              value: '08',
              text: 'Estudiante'
            },
            {
              value: '09',
              text: 'Otro'
            },
            {
              value: '10',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique',
          name: 'OCCUPATION_02_OTHER',
          isRequired: true,
          visibleIf: '{OCCUPATION_02} = "09"'
        }
      ]
    },
    {
      name: 'OCCUPATION_PAGE_03',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'radiogroup',
          name: 'OCCUPATION_03',
          title:
            '¿A qué se dedica actualmente {CARE_ROLE_03} {CARE_ROLE_03_OTHER}',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Dueña/o de casa'
            },
            {
              value: '02',
              text: 'Desempleado/a'
            },
            {
              value: '03',
              text: 'Empleado tiempo completo'
            },
            {
              value: '04',
              text: 'Empleado a tiempo parcial'
            },
            {
              value: '05',
              text: 'Trabajador independiente o propietario de empresa pequeña'
            },
            {
              value: '06',
              text: 'Propietario de empresa mediana o grande'
            },
            {
              value: '07',
              text: 'Pensionado/a, jubilado/a o retirado/a'
            },
            {
              value: '08',
              text: 'Estudiante'
            },
            {
              value: '09',
              text: 'Otro'
            },
            {
              value: '10',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique',
          name: 'OCCUPATION_03_OTHER',
          isRequired: true,
          visibleIf: '{OCCUPATION_03} = "09"'
        }
      ]
    },

    {
      name: 'WIMP_PAGE_01',
      title: 'Impacto laboral',
      elements: [
        {
          type: 'checkbox',
          name: 'WIMP_01',
          title: `En relación al trabajo o actividad laboral de {CARE_ROLE_01} {CARE_ROLE_01_OTHER}:
          ¿Cuál/es de las siguientes ha/han ocurrido como consecuencia de la enfermedad de {CHILD_NAME}? Dígame si o no: [MARCAR TODAS LAS QUE CORRESPONDAN]:
          `,
          isRequired: true,
          // colCount: 7,
          // hasOther: true,
          choices: [
            {
              value: '01',
              text: 'Aumento de la carga del trabajo doméstico'
            },
            {
              value: '02',
              text: 'Aumento de la carga en el lugar de trabajo'
            },
            {
              value: '03',
              text: 'Ausencias prolongadas del lugar de trabajo'
            },
            {
              value: '04',
              text: 'Despido del trabajo'
            },
            {
              value: '05',
              text: 'Renuncia del trabajo'
            },
            {
              value: '06',
              text: 'Cambio de trabajo'
            },
            {
              value: '07',
              text: 'Disminución de los ingresos'
            },
            {
              value: '08',
              text: 'Ninguna'
            },
            {
              value: '09',
              text: 'Otra'
            },
            {
              value: '10',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otro, especifique:',
          name: 'WIMP_01_OTHER',
          isRequired: true,
          visibleIf: '{WIMP_01} contains "09"'
        }
      ]
    },
    {
      name: 'WIMP_PAGE_02',
      title: 'Impacto laboral',
      elements: [
        {
          type: 'checkbox',
          name: 'WIMP_02',
          title: `En relación al trabajo o actividad laboral de {CARE_ROLE_02} {CARE_ROLE_02_OTHER}:
          ¿Cuál/es de las siguientes ha/han ocurrido como consecuencia de la enfermedad de {CHILD_NAME}? Dígame si o no: [MARCAR TODAS LAS QUE CORRESPONDAN]:
          `,
          isRequired: true,
          // colCount: 7,
          // hasOther: true,
          choices: [
            {
              value: '01',
              text: 'Aumento de la carga del trabajo doméstico'
            },
            {
              value: '02',
              text: 'Aumento de la carga en el lugar de trabajo'
            },
            {
              value: '03',
              text: 'Ausencias prolongadas del lugar de trabajo'
            },
            {
              value: '04',
              text: 'Despido del trabajo'
            },
            {
              value: '05',
              text: 'Renuncia del trabajo'
            },
            {
              value: '06',
              text: 'Cambio de trabajo'
            },
            {
              value: '07',
              text: 'Disminución de los ingresos'
            },
            {
              value: '08',
              text: 'Ninguna'
            },
            {
              value: '09',
              text: 'Otra'
            },
            {
              value: '10',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otro, especifique:',
          name: 'WIMP_02_OTHER',
          isRequired: true,
          visibleIf: '{WIMP_02} contains "09"'
        }
      ]
    },
    {
      name: 'WIMP_PAGE_03',
      title: 'Impacto laboral',
      elements: [
        {
          type: 'checkbox',
          name: 'WIMP_03',
          title: `En relación al trabajo o actividad laboral de {CARE_ROLE_03} {CARE_ROLE_03_OTHER}:
          ¿Cuál/es de las siguientes ha/han ocurrido como consecuencia de la enfermedad de {CHILD_NAME}? Dígame si o no: [MARCAR TODAS LAS QUE CORRESPONDAN]:
          `,
          isRequired: true,
          // colCount: 7,
          // hasOther: true,
          choices: [
            {
              value: '01',
              text: 'Aumento de la carga del trabajo doméstico'
            },
            {
              value: '02',
              text: 'Aumento de la carga en el lugar de trabajo'
            },
            {
              value: '03',
              text: 'Ausencias prolongadas del lugar de trabajo'
            },
            {
              value: '04',
              text: 'Despido del trabajo'
            },
            {
              value: '05',
              text: 'Renuncia del trabajo'
            },
            {
              value: '06',
              text: 'Cambio de trabajo'
            },
            {
              value: '07',
              text: 'Disminución de los ingresos'
            },
            {
              value: '08',
              text: 'Ninguna'
            },
            {
              value: '09',
              text: 'Otra'
            },
            {
              value: '10',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otro, especifique:',
          name: 'WIMP_03_OTHER',
          isRequired: true,
          visibleIf: '{WIMP_03} contains "09"'
        }
      ]
    }
  ]
};

export default baseInfo;
