const SEFFValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '0',
    text: 'No aplica'
  }
];

const SEFF_TITLE = {
  type: 'html',
  name: 'HTML_SEFF_TITLE',
  html: `<div style="font-size:18px; margin-bottom: 30px;">
    <span>Las siguientes frases describen cómo las personas se ven a sí mismas en el trabajo.</span><br />
    <span>Por favor señale en qué medida estas frases le describen a usted usando una escala de 1 (incorrecto) a 4 (cierto).</span>
  </div>`
};

const SelfEfficacy = {
  locale: 'es',
  title: 'Autoeficacia',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'SEFF_01',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_01',
          title:
            'Puedo encontrar la manera de obtener lo que quiero, aunque alguien se me oponga',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_02',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_02',
          title:
            'Puedo resolver problemas difíciles si me esfuerzo lo suficiente',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_03',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_03',
          title:
            'Me es fácil persistir en lo que me he propuesto hasta llegar a alcanzar mis metas',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_04',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_04',
          title:
            'Tengo confianza en que podría manejar eficazmente acontecimientos inesperados',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_05',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_05',
          title:
            'Gracias a mis cualidades y recursos puedo superar situaciones imprevistas',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_06',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_06',
          title:
            'Cuando me encuentro en dificultades puedo permanecer tranquilo/a porque cuento con las habilidades necesarias para manejar situaciones difíciles',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_07',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_07',
          title: 'Venga lo que venga, por lo general soy capaz de manejarlo',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_08',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_08',
          title:
            'Puedo resolver la mayoría de los problemas si me esfuerzo lo necesario',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_09',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_09',
          title:
            'Si me encuentro en una situación difícil, generalmente se me ocurre qué debo hacer',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    },
    {
      name: 'SEFF_10',
      title: '',
      elements: [
        SEFF_TITLE,
        {
          type: 'rating',
          name: 'SEFF_10',
          title:
            'Al tener que hacer frente a un problema, generalmente se me ocurren varias alternativas de como resolverlo',
          isRequired: true,
          rateValues: SEFFValues
        }
      ]
    }
  ]
};

export default SelfEfficacy;
