import React from 'react';
import ChildImpactComponent from '../../Components/DKMS/ChildImpact';
import { StageConsumer } from '../../Context';
import TestingMenu from '../../Components/TestingMenu';
import CodeIdSelector from '../../Components/RutIdSelector/CodeIdSelector';
import ModalSelector from '../../Components/RutIdSelector/ModalSelector';

const BATTERY = 'DKMS';

class ChildImpactPage extends React.Component {
  render() {
    const testing = false;
    const multiple = true;
    return (
      <StageConsumer>
        {({ state, updateContext, nextStage }) => (
          <React.Fragment>
            <ChildImpactComponent
              {...this.props}
              {...state}
              updateContext={updateContext}
              toNextStage={nextStage}
              BATTERY={BATTERY}
            />
            {testing && <TestingMenu />}
            {multiple && (
              <CodeIdSelector
                {...state}
                updateContext={updateContext}
                BATTERY={BATTERY}
              />
            )}
            {multiple && !state.rut && (
              <ModalSelector
                {...state}
                updateContext={updateContext}
                BATTERY={BATTERY}
                codeId
              />
            )}
          </React.Fragment>
        )}
      </StageConsumer>
    );
  }
}

export default ChildImpactPage;
