import React from 'react';

const AREA_A = [
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Estética',
    code: 'A_F_1',
    item: 'Analizar obras artísticas usando conceptos filosóficos'
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Estética',
    code: 'A_F_2',
    item: 'Analizar cómo los eventos históricos influyen en el arte'
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Estética',
    code: 'A_F_3',
    item: 'Discutir sobre el rol del arte en la cultura y la sociedad'
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Filosofía Política',
    code: 'A_F_4',
    item: 'Reflexionar sobre el rol que cumple la política en nuestra sociedad'
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Filosofía Política',
    code: 'A_F_5',
    item: () => (
      <>
        Discutir sobre qué entendemos por{' '}
        <em className='sp-l sp-r'>justicia</em> o
        <em className='sp-l'>libertad</em>
      </>
    )
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Seminario de Filosofía',
    code: 'A_F_6',
    item: 'Comprender en profundidad a qué se dedica la filosofía'
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Seminario de Filosofía',
    code: 'A_F_7',
    item: () => (
      <>
        Reflexionar sobre grandes conceptos filosóficos, como el{' '}
        <em className='sp-l'>bien</em>, la <em className='sp-l'>verdad</em>, y
        la <em className='sp-l'>belleza</em>
      </>
    )
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Seminario de Filosofía',
    code: 'A_F_8',
    item: 'Aprender a plantear preguntas filosóficas'
  },
  {
    area: 'A',
    asignatura: 'Filosofía',
    category: 'Filosofía',
    curso: 'Seminario de Filosofía',
    code: 'A_F_9',
    item: 'Estudiar los planteamientos de grandes filósofos de la historia'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Comprensión Histórica del Presente',
    code: 'A_HGC_1',
    item:
      'Analizar los cambios más recientes de la sociedad chilena desde un punto de vista histórico'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Comprensión Histórica del Presente',
    code: 'A_HGC_2',
    item:
      'Discutir la importancia de conocer la historia para el desarrollo de las sociedades'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Comprensión Histórica del Presente',
    code: 'A_HGC_3',
    item: 'Aprender sobre el origen histórico de la democracia'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Comprensión Histórica del Presente',
    code: 'A_HGC_4',
    item:
      'Aprender a desarrollar una explicación histórica sobre algún evento reciente'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Geografía, Territorio y Desafíos Socioambientales',
    code: 'A_HGC_5',
    item: 'Conocer cómo se planifica el desarrollo de las ciudades'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Geografía, Territorio y Desafíos Socioambientales',
    code: 'A_HGC_6',
    item: 'Aprender a hacer y analizar mapas geográficos'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Economía y Sociedad',
    code: 'A_HGC_7',
    item: 'Entender lo que es una teoría económica'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Economía y Sociedad',
    code: 'A_HGC_8',
    item:
      'Comprender en profundidad conceptos económicos como oferta, demanda, inflación, etc.'
  },
  {
    area: 'A',
    asignatura: 'Historia, Geografía y Ciencias Sociales',
    category: 'Historia, Geografía y Ciencias Sociales',
    curso: 'Economía y Sociedad',
    code: 'A_HGC_9',
    item:
      'Analizar y comparar distintos sistemas económicos, de diversos países'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Taller de Literatura',
    code: 'A_LL_1',
    item:
      'Conocer técnicas para escribir textos literarios como novelas o poesía'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Taller de Literatura',
    code: 'A_LL_2',
    item: 'Aprender a interpretar obras literarias famosas'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Taller de Literatura',
    code: 'A_LL_3',
    item: 'Aprender cómo analizar críticamente una obra literaria'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Taller de Literatura',
    code: 'A_LL_4',
    item: 'Aprender a escribir ensayos que expresen mi opinión sobre un tema'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Literatura y Escritura Especializada',
    code: 'A_LL_5',
    item:
      'Aprender a escribir un texto especializado sobre un tema, como un artículo académico o una columna de opinión'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Literatura y Escritura Especializada',
    code: 'A_LL_6',
    item:
      'Aprender a realizar buenas búsquedas de información para escribir un artículo especializado'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Participación y Argumentación en Democracia',
    code: 'A_LL_7',
    item: 'Participar en concursos de debate'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Participación y Argumentación en Democracia',
    code: 'A_LL_8',
    item:
      'Aprender a desarrollar mejores formas de defender un punto de vista en un debate'
  },
  {
    area: 'A',
    asignatura: 'Lengua y Literatura',
    category: 'Lengua y Literatura',
    curso: 'Participación y Argumentación en Democracia',
    code: 'A_LL_9',
    item: 'Participar en discusiones grupales sobre temáticas controversiales'
  }
];

const AREA_B = [
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Biología de los Ecosistemas',
    code: 'B_CBS_1',
    item:
      'Conocer teorías sobre el origen de la vida y la evolución de las especies'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Biología de los Ecosistemas',
    code: 'B_CBS_2',
    item: 'Estudiar los efectos del cambio climático en distintos ecosistemas'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Biología de los Ecosistemas',
    code: 'B_CBS_3',
    item:
      'Aprender sobre sistemas de generación de energías basados en la naturaleza'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Biología Celular y Molecular',
    code: 'B_CBS_4',
    item: 'Investigar sobre los grandes descubrimientos de la biología celular'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Biología Celular y Molecular',
    code: 'B_CBS_5',
    item:
      'Comprender en profundidad el funcionamiento de las células y sus organelos'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Biología Celular y Molecular',
    code: 'B_CBS_6',
    item: 'Estudiar la forma en que se realizan experimentos genéticos'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Ciencias de la Salud',
    code: 'B_CBS_7',
    item: 'Estudiar los efectos de distintos estilos de vida sobre la salud'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Ciencias de la Salud',
    code: 'B_CBS_8',
    item:
      'Investigar los efectos de la contaminación en la salud de las personas'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias Biológicas',
    curso: 'Ciencias de la Salud',
    code: 'B_CBS_9',
    item:
      'Aprender sobre innovaciones en áreas como la medicina nuclear o nanomedicina'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Física',
    code: 'B_CD_1',
    item:
      'Aprender más sobre teorías de la física como la mecánica cuántica o la teoría de la relatividad'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Física',
    code: 'B_CD_2',
    item:
      'Aplicar conocimientos de la física para analizar el movimiento de los cuerpos'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Física',
    code: 'B_CD_3',
    item:
      'Conocer las principales explicaciones científicas sobre la evolución del universo'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Física',
    code: 'B_CD_4',
    item: 'Explicar el movimiento de las mareas usando conceptos de la física'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Química',
    code: 'B_CD_5',
    item:
      'Realizar experimentos para explicar fenómenos químicos como la oxidación'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Química',
    code: 'B_CD_6',
    item:
      'Conocer los principales avances de la nanoquímica (química de las nanopartículas)'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Química',
    code: 'B_CD_7',
    item:
      'Investigar sobre el efecto de contaminantes químicos en sistemas naturales'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Química',
    code: 'B_CD_8',
    item:
      'Comprender cómo diversas reacciones químicas participan en sistemas de generación de energía'
  },
  {
    area: 'B',
    asignatura: 'Ciencias',
    category: 'Ciencias',
    curso: 'Química',
    code: 'B_CD_9',
    item:
      'Realizar experimentos para entender principios de la química o la física'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Límites, Derivadas e Integrales',
    code: 'B_M_1',
    item:
      'Aprender a resolver problemas complejos a través del cálculo matemático'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Límites, Derivadas e Integrales',
    code: 'B_M_2',
    item:
      'Conocer más sobre cómo se trabaja con funciones en matemáticas en problemas de la vida real'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Límites, Derivadas e Integrales',
    code: 'B_M_3',
    item:
      'Resolver problemas matemáticos usando conceptos avanzados, como integrales o derivadas'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Probabilidades y Estadística Descriptiva',
    code: 'B_M_4',
    item:
      'Aprender a analizar datos reales aplicando fórmulas de la estadística (cómo cálculo de media o desviación estándar)'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Probabilidades y Estadística Descriptiva',
    code: 'B_M_5',
    item: 'Trabajar aplicando modelos de probabilidades matemáticas'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Pensamiento Computacional y Programación',
    code: 'B_M_6',
    item:
      'Aprender a desarrollar códigos computacionales para hacer un software'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Pensamiento Computacional y Programación',
    code: 'B_M_7',
    item:
      'Aprender a desarrollar una aplicación (app) para un dispositivo móvil'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Geometría 3D',
    code: 'B_M_8',
    item:
      'Aplicar los principios de la geometría tridimensional a situaciones reales'
  },
  {
    area: 'B',
    asignatura: 'Matemáticas',
    category: 'Matemáticas',
    curso: 'Geometría 3D',
    code: 'B_M_9',
    item:
      'Diseñar un proyecto que implique el desarrollo de la geometría en tres dimensiones'
  }
];

const AREA_C = [
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Creación y Composición Musical',
    code: 'C_AI_1',
    item: 'Componer una obra musical'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Creación y Composición Musical',
    code: 'C_AI_2',
    item: 'Estudiar y comparar obras musicales de distintas épocas'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Creación y Composición Musical',
    code: 'C_AI_3',
    item: 'Aprender diversas técnicas musicales'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Interpretación y Creación',
    code: 'C_AI_4',
    item: 'Crear una coreografía para expresar una idea o emoción'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Interpretación y Creación',
    code: 'C_AI_5',
    item: 'Proponer una interpretación a una obra de danza'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Interpretación y Creación en Teatro',
    code: 'C_AI_6',
    item: 'Adaptar una obra teatral clásica a un contexto actual'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Interpretación y Creación en Teatro',
    code: 'C_AI_7',
    item: 'Analizar obras teatrales de distintas épocas o géneros'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Interpretación Musical',
    code: 'C_AI_8',
    item: 'Interpretar una obra musical famosa con un instrumento de tu interés'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes Interpretativas',
    curso: 'Interpretación Musical',
    code: 'C_AI_9',
    item: 'Adquirir habilidades de interpretación musical'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Artes Visuales, Audiovisuales y Multimediales',
    code: 'C_AV_1',
    item:
      'Analizar obras artísticas visuales famosas (pinturas, esculturas, obras audiovisuales)'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Artes Visuales, Audiovisuales y Multimediales',
    code: 'C_AV_2',
    item: 'Aprender sobre el lenguaje artístico audiovisual y multimedia'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Artes Visuales, Audiovisuales y Multimediales',
    code: 'C_AV_3',
    item: 'Crear un proyecto audiovisual o multimedia'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Artes Visuales, Audiovisuales y Multimediales',
    code: 'C_AV_4',
    item: 'Crear una obra artística grupal, como un mural, una escultura, etc.'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Diseño y Arquitectura',
    code: 'C_AV_5',
    item: 'Aprender técnicas de dibujo para el diseño y la arquitectura'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Diseño y Arquitectura',
    code: 'C_AV_6',
    item: 'Aprender a dibujar un plano de arquitectura para un edificio'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Diseño y Arquitectura',
    code: 'C_AV_7',
    item: 'Estudiar estilos arquitectónicos de distintas épocas'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Diseño y Arquitectura',
    code: 'C_AV_8',
    item:
      'Aprender a trabajar el diseño de un objeto para solucionar un problema real'
  },
  {
    area: 'C',
    asignatura: 'Artes',
    category: 'Artes visuales',
    curso: 'Diseño y Arquitectura',
    code: 'C_AV_9',
    item: 'Crear un proyecto de diseño utilizando materiales reciclados'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Promoción de Estilos de Vida Activos y Saludables',
    code: 'C_D_1',
    item:
      'Estudiar los beneficios de la actividad física para la salud y bienestar de las personas'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Promoción de Estilos de Vida Activos y Saludables',
    code: 'C_D_2',
    item:
      'Seguir un programa de acondicionamiento físico para mejorar algún aspecto, como la resistencia o la fuerza'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Promoción de Estilos de Vida Activos y Saludables',
    code: 'C_D_3',
    item:
      'Practicar diversas actividades físicas para adquirir un estilo de vida saludable'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Ciencias de Ejercicio Físico y Deportivo',
    code: 'C_D_4',
    item:
      'Diseñar rutinas de entrenamiento para mejorar el rendimiento deportivo'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Ciencias de Ejercicio Físico y Deportivo',
    code: 'C_D_5',
    item:
      'Estudiar aspectos físicos y psicológicos que influyen en el rendimiento deportivo'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Ciencias de Ejercicio Físico y Deportivo',
    code: 'C_D_6',
    item: 'Conocer el impacto del ejercicio físico en el rendimiento deportivo'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Ciencias de Ejercicio Físico y Deportivo',
    code: 'C_D_7',
    item:
      'Conocer los efectos de la alimentación saludable en el rendimiento deportivo'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Expresión Corporal',
    code: 'C_D_8',
    item:
      'Utilizar el lenguaje corporal (movimiento del cuerpo) como una forma de expresión'
  },
  {
    area: 'C',
    asignatura: 'Educación Física y Salud',
    category: 'Deportes',
    curso: 'Expresión Corporal',
    code: 'C_D_9',
    item: 'Conocer las posibilidades de expresión a través del propio cuerpo'
  }
];

const AREAS_ITEMS = [...AREA_A, ...AREA_B, ...AREA_C];

const QUESTION_GRID = [
  {
    Item: '1',
    B: 'B_M_1',
    A: 'A_F_1',
    C: 'C_AI_1'
  },
  {
    Item: '2',
    B: 'B_CBS_1',
    A: 'A_HGC_1',
    C: 'C_AV_1'
  },
  {
    Item: '3',
    B: 'B_CD_1',
    A: 'A_LL_1',
    C: 'C_D_1'
  },
  {
    Item: '4',
    B: 'B_CBS_2',
    A: 'A_F_2',
    C: 'C_AI_2'
  },
  {
    Item: '5',
    B: 'B_CD_2',
    A: 'A_HGC_2',
    C: 'C_AV_2'
  },
  {
    Item: '6',
    B: 'B_M_2',
    A: 'A_LL_2',
    C: 'C_D_2'
  },
  {
    Item: '7',
    B: 'B_CD_3',
    A: 'A_F_3',
    C: 'C_AI_3'
  },
  {
    Item: '8',
    B: 'B_M_3',
    A: 'A_HGC_3',
    C: 'C_AV_3'
  },
  {
    Item: '9',
    B: 'B_CBS_3',
    A: 'A_LL_3',
    C: 'C_D_3'
  },
  {
    Item: '10',
    B: 'B_M_4',
    A: 'A_HGC_4',
    C: 'C_AI_4'
  },
  {
    Item: '11',
    B: 'B_CBS_4',
    A: 'A_LL_4',
    C: 'C_AV_4'
  },
  {
    Item: '12',
    B: 'B_CD_4',
    A: 'A_F_4',
    C: 'C_D_4'
  },
  {
    Item: '13',
    B: 'B_CBS_5',
    A: 'A_HGC_5',
    C: 'C_AI_5'
  },
  {
    Item: '14',
    B: 'B_CD_5',
    A: 'A_LL_5',
    C: 'C_AV_5'
  },
  {
    Item: '15',
    B: 'B_M_5',
    A: 'A_F_5',
    C: 'C_D_5'
  },
  {
    Item: '16',
    B: 'B_CD_6',
    A: 'A_HGC_6',
    C: 'C_AI_6'
  },
  {
    Item: '17',
    B: 'B_M_6',
    A: 'A_LL_6',
    C: 'C_AV_6'
  },
  {
    Item: '18',
    B: 'B_CBS_6',
    A: 'A_F_6',
    C: 'C_D_6'
  },
  {
    Item: '19',
    B: 'B_M_7',
    A: 'A_LL_7',
    C: 'C_AI_7'
  },
  {
    Item: '20',
    B: 'B_CBS_7',
    A: 'A_F_7',
    C: 'C_AV_7'
  },
  {
    Item: '21',
    B: 'B_CD_7',
    A: 'A_HGC_7',
    C: 'C_D_7'
  },
  {
    Item: '22',
    B: 'B_CBS_8',
    A: 'A_LL_8',
    C: 'C_AI_8'
  },
  {
    Item: '23',
    B: 'B_CD_8',
    A: 'A_F_8',
    C: 'C_AV_8'
  },
  {
    Item: '24',
    B: 'B_M_8',
    A: 'A_HGC_8',
    C: 'C_D_8'
  },
  {
    Item: '25',
    B: 'B_CD_9',
    A: 'A_LL_9',
    C: 'C_AI_9'
  },
  {
    Item: '26',
    B: 'B_M_9',
    A: 'A_F_9',
    C: 'C_AV_9'
  },
  {
    Item: '27',
    B: 'B_CBS_9',
    A: 'A_HGC_9',
    C: 'C_D_9'
  }
];

const COMBINATIONS = [
  {
    combination: 'AREAS',
    items: QUESTION_GRID.map(it => {
      const { Item, B, A, C } = it;
      const getAreaItem = code => {
        const areaItem = AREAS_ITEMS.find(a => a.code === code);
        // console.log({ areaItem, code });
        return areaItem.item;
      };
      const options = [
        {
          area: 'B',
          code: B,
          statement: getAreaItem(B)
        },
        {
          area: 'A',
          code: A,
          statement: getAreaItem(A)
        },
        {
          area: 'C',
          code: C,
          statement: getAreaItem(C)
        }
      ];
      const item = { item: Item, options };
      return item;
    })
  }
];

const PGI_BASIC = [...COMBINATIONS];

export default PGI_BASIC;
