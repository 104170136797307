import React from 'react';
import SortableList from './SortablePolarList';
import Wrapper from './wrapper.styles';
import postData from '../API';

class SortablePolarListComponent extends React.Component {

  onComplete = async data => {
    const { stage = 0 } = this.props;
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished, STAGE: stage + 1 };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    // console.log('on Sortable index');
    // console.log({ props: this.props });
    return (
      <Wrapper>
        <div className='uai_header'>
          {/* <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          /> */}
        </div>
        <SortableList {...this.props} onComplete={this.onComplete} />
        {/* <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div> */}
      </Wrapper>
    );
  }
}

export default SortablePolarListComponent;
