import React from 'react';
import MediaQuery from 'react-responsive';
import GoalOrientation from './GoalOrientation';
import GoalOrientationMobile from './GoalOrientationMobile';
import postData from '../../API';

class GoalOrientationPage extends React.Component {
  onComplete = async data => {
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    return (
      <MediaQuery maxWidth={700}>
        {matches => {
          if (matches) {
            return <GoalOrientationMobile onComplete={this.onComplete} />;
          } else {
            return <GoalOrientation onComplete={this.onComplete} />;
          }
        }}
      </MediaQuery>
    );
  }
}

export default GoalOrientationPage;
