import React from 'react';
import Equis1Pilotaje from '../../Components/Equis1Pilotaje';
import { StageConsumer } from '../../Context';
import TestingMenu from '../../Components/TestingMenu';
// import ProgressMenu from '../../Components/ProgressMenu';
import AuthRutId from '../../Components/Auth/EquisOne';
import LogInForm from '../../Components/Auth/EquisOne/LogInForm';
import ModalSelector from '../../Components/Auth/EquisOne/ModalSelector';
import logIn from '../../Components/Auth/EquisOne/login';

const BATTERY = 'EQUIS1_PILOTO';
// users sheet
const SHEET_USER_ID = '1UEdvHPFPXHmy6NCYJoLCkTgdeI6XUuAeLc_J8aC7XiA';

// parse url query string, e.g.
// url test mode ?mode=testingMode
// url school ?school=Santiago+College
const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

class VocationalPage extends React.Component {
  state = {
    user: null,
    rut: localStorage.getItem(`currentCode-${BATTERY}`),
    school:
      getQuery('school') ||
      localStorage.getItem(`currentSchool-${BATTERY}`) ||
      'Universidad Adolfo Ibanez',
    error: null,
    loading: true
  };

  componentDidMount = async () => {
    const { rut, school } = this.state;
    if (!rut) {
      return this.setState({ loading: false });
    }
    const online = true;
    // const cleanedRut = rut.replace(/_/g, '').replace(/\./g, '');
    const cleanedRut = rut.replace(/_/g, '');
    const user = await logIn(cleanedRut, SHEET_USER_ID, school, online);
    if (!user) {
      return this.setState({
        error: `RUT. ${cleanedRut} no existe o no está validado en ${school}`,
        loading: false
      });
    }
    // if user, set on state
    return this.setState({ user, error: null, loading: false });
  };

  render() {
    const { user, rut, error, loading, school } = this.state;
    // console.log({ school });
    // console.log({ user, rut, error });
    const appMode = getQuery('mode');
    const isTestingMode = appMode === 'testingMode';
    // console.log({ appMode, isTestingMode });
    const testing = isTestingMode; // true;
    const multiple = true;
    const withAuth = !!user;
    // console.log({ withAuth });

    return (
      <StageConsumer>
        {({ state, updateContext, nextStage, prevStage }) => {
          if (loading) {
            return <div>Loading...</div>;
          }
          if (!user) {
            return (
              <div>
                <LogInForm
                  {...state}
                  updateContext={updateContext}
                  BATTERY={BATTERY}
                  school={school}
                  error={error}
                />
              </div>
            );
          }
          if (user) {
            return (
              <React.Fragment>
                <Equis1Pilotaje
                  {...this.props}
                  {...state}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  BATTERY={BATTERY}
                  withAuth={withAuth}
                  school={school}
                  rut={rut}
                  user={user}
                />
                {/* <ProgressMenu
                  {...state}
                  BATTERY={BATTERY}
                  toNextStage={nextStage}
                  toPrevStage={prevStage}
                  rut={rut}
                  user={user}
                  totalStages={10}
                /> */}
                {testing && (
                  <TestingMenu
                    {...state}
                    BATTERY={BATTERY}
                    toNextStage={nextStage}
                    toPrevStage={prevStage}
                    rut={rut}
                    user={user}
                    equis1
                  />
                )}
                {multiple && (
                  <AuthRutId
                    {...state}
                    updateContext={updateContext}
                    BATTERY={BATTERY}
                    rut={rut}
                    user={user}
                  />
                )}
                {multiple && !state.rut && (
                  <ModalSelector
                    {...state}
                    updateContext={updateContext}
                    BATTERY={BATTERY}
                    active={!state.rut}
                    rut={rut}
                    user={user}
                  />
                )}
              </React.Fragment>
            );
          }
          return <div></div>;
        }}
      </StageConsumer>
    );
  }
}

export default VocationalPage;
