import React from 'react';
import Survey, { CssNames } from '../../Survey';
import GFLCompleteSurvey from './gfl_complete';
import GFLCompleteWrapper from './GFLComplete.styles';

class GFLCompleteTest extends React.Component {
  state = { timeAvailable: true };

  onComplete = async (survey, options) => {
    const { onComplete } = this.props;
    const { data } = survey;
    const parsed = { ...data };
    // console.log({ parsed });
    await onComplete(parsed);
  };

  checkTimer = async survey => {
    const { maxTimeToFinish, timeSpent } = survey;
    // console.log({ maxTimeToFinish, timeSpent });
    if (timeSpent >= maxTimeToFinish) {
      await this.setState({ timeAvailable: false });
      await this.onComplete(survey);
    }
  };
  render() {
    const GFLCompleteModel = new Survey.Model(GFLCompleteSurvey);
    const { timeAvailable } = this.state;
    return (
      <GFLCompleteWrapper>
        <div className="uai_header">
          <img
            className="uai-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png"
            alt="uai-logo"
          />
        </div>
        {timeAvailable ? (
          <Survey.Survey
            model={GFLCompleteModel}
            css={CssNames}
            onComplete={this.onComplete}
            onTimer={this.checkTimer}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '40vh'
            }}
          >
            <h4>Guardando respuestas para avanzar...</h4>
          </div>
        )}

        <div className="Footer">
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className="PushStickyFooter"></div>
        <div className="Plug">
          <a
            href="http://www.uaimetrics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UAI Metrics
          </a>
        </div>
      </GFLCompleteWrapper>
    );
  }
}

export default GFLCompleteTest;
