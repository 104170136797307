import React from 'react';
import WorkingInfo from '../../Components/WorkingInfo';
import { StageConsumer } from '../../Context';

class WorkingInfoPage extends React.Component {
  render() {
    return (
      <StageConsumer>
        {({ state }) => <WorkingInfo {...this.props} {...state} />}
      </StageConsumer>
    );
  }
}

export default WorkingInfoPage;
