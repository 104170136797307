const baseInfo = {
  locale: 'es',
  title: 'Coordinación Hora',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Coordinación Hora</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                A continuación deberá seleccionar rangos con posibles fechas y horarios donde podremos llamarlo/a.
                </span>
              </span>
              <br>
              <span style="font-size:16px;">
              <span style="font-family:arial,helvetica,sans-serif;">
              <br>
              Por favor, sea lo más flexible posible.
              <br>
              Los tiempos seleccionados serán utilizados como referencia por nuestro equipo.
              </span>
            </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      elements: [
        {
          type: 'text',
          name: 'DATE',
          inputType: 'date',
          isRequired: true,
          title: 'Por favor, seleccione alguna fecha de referencia:',
          validators: [
            // {
            //   type: 'expression',
            //   text: 'expresion:test',
            //   expression: '{DATE} <= new Date().toISOString()'
            // }
          ]
        },
        {
          type: 'dropdown',
          visibleIf: '{DATE} notempty',
          name: 'TIME_1',
          title: '¿En qué momento podría recibir nuestra llamada?',
          isRequired: true,
          hasOther: true,
          choices: [
            {
              value: '1',
              text: '10:00-11:30'
            },
            {
              value: '2',
              text: '11:30-13:00'
            },
            {
              value: '3',
              text: '13:00-14:30'
            },
            {
              value: '4',
              text: '14:30-16:00'
            },
            {
              value: '5',
              text: '16:00-17:30'
            },
            {
              value: '6',
              text: '17:30-19:00'
            },
            {
              value: '7',
              text: '19:00-21:00'
            }
          ],
          otherText: 'Otro (especifique)',
          optionsCaption: 'Opciones...'
        },
        {
          type: 'dropdown',
          visibleIf: '{TIME_1} notempty',
          name: 'TIME_2',
          title:
            '¿En qué *OTRO* momento podría recibir nuestra llamada? (segunda preferencia)',
          isRequired: true,
          hasOther: true,
          choices: [
            {
              value: '1',
              text: '10:00-11:30'
            },
            {
              value: '2',
              text: '11:30-13:00'
            },
            {
              value: '3',
              text: '13:00-14:30'
            },
            {
              value: '4',
              text: '14:30-16:00'
            },
            {
              value: '5',
              text: '16:00-17:30'
            },
            {
              value: '6',
              text: '17:30-19:00'
            },
            {
              value: '7',
              text: '19:00-21:00'
            }
          ],
          otherText: 'Otro (especifique)',
          optionsCaption: 'Opciones...'
        },
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
            <br>
            <br>
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h4>Información Importante</h4>
                  </span>
                </span>
              </div>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                Le recordamos que solo los contactaremos desde los siguientes números de teléfono:
                <br>
                </span>
                <ul style="font-size:16px;">
                  <li>+56 9 1234 5678</li>
                  <li>+56 9 1234 5678</li>
                </ul>
              </span>

            </div>
          </legend>
          `
        }
      ]
    }
    // {
    //   name: 'DEMO_PAGE_03',
    //   title: 'Información general',
    //   elements: [
    //     {
    //       type: 'radiogroup',
    //       name: 'Sexo',
    //       title: 'Sexo',
    //       isRequired: true,
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'Hombre'
    //         },
    //         {
    //           value: '0',
    //           text: 'Mujer'
    //         }
    //       ]
    //     },
    //     {
    //       type: 'text',
    //       name: 'AGE',
    //       title: 'Edad (años)',
    //       isRequired: true,
    //       inputType: 'number',
    //       validators: [
    //         {
    //           type: 'numeric',
    //           minValue: 0,
    //           maxValue: 99
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   name: 'DEMO_PAGE_04',
    //   title: ' ',
    //   // description: 'Para poder hacerle una devolución de sus resultados.',
    //   elements: [
    //     {
    //       type: 'multipletext',
    //       name: 'DEMO_Q02',
    //       title: 'Ingrese sus datos de contacto',
    //       items: [
    //         {
    //           name: 'EMAIL',
    //           title: 'Email',
    //           isRequired: true,
    //           inputType: 'email',
    //           validators: [
    //             {
    //               type: 'email'
    //             }
    //           ]
    //         },
    //         {
    //           name: 'PHONE',
    //           title: 'Teléfono',
    //           placeHolder: '+56 9 1234 5678',
    //           isRequired: true
    //         }
    //       ]
    //     },
    //     {
    //       type: 'html',
    //       name: 'HTML_01',
    //       html: `
    //       <legend>
    //         <div class="QuestionText BorderColor">
    //           <div>
    //             <span style="font-size:16px;">
    //               <span style="font-family:arial,helvetica,sans-serif;">
    //                 <h4>Información Importante</h4>
    //               </span>
    //             </span>
    //           </div>
    //           <br>
    //           <span style="font-size:16px;">
    //             <span style="font-family:arial,helvetica,sans-serif;">
    //             Su número de telefono es importante para poder comunicarnos con usted.
    //             <br>
    //             <br>
    //             Solo los contactaremos desde los siguientes números de telefono:
    //             <br>
    //             </span>
    //             <ul style="font-size:16px;">
    //               <li>+56 9 1234 5678</li>
    //               <li>+56 9 1234 5678</li>
    //             </ul>
    //           </span>

    //         </div>
    //       </legend>
    //       `
    //     }
    //   ]
    // }
  ]
};

export default baseInfo;
