// const labeledOptions = [
//   {
//     value: '1',
//     text: 'No logrado'
//   },
//   {
//     value: '2',
//     text: 'Algo logrado'
//   },
//   {
//     value: '3',
//     text: 'Bien logrado'
//   },
//   {
//     value: '4',
//     text: 'Muy bien logrado'
//   },
//   {
//     value: '0',
//     text: 'No tengo conocimiento/información sobre esto'
//   }
// ];

const labeledOptions = [
  {
    value: '1',
    text: 'No lo conozco nada'
  },
  {
    value: '2',
    text: 'Lo conozco algo'
  },
  {
    value: '3',
    text: 'Lo conozco bastante'
  },
  {
    value: '4',
    text: 'Lo conozco muy bien'
  }
];

const importantOptions = [
  {
    value: '1',
    text: 'Para nada importante'
  },
  {
    value: '2',
    text: 'Algo importante'
  },
  {
    value: '3',
    text: 'Medianamente importante'
  },
  {
    value: '4',
    text: 'Bastante importante'
  },
  {
    value: '5',
    text: 'Muy importante'
  }
];

// const plainOptions = [
//   {
//     value: '1',
//     text: '1'
//   },
//   {
//     value: '2',
//     text: '2'
//   },
//   {
//     value: '3',
//     text: '3'
//   },
//   {
//     value: '4',
//     text: '4'
//   },
//   {
//     value: '5',
//     text: '5'
//   },
//   {
//     value: '6',
//     text: '6'
//   },
//   {
//     value: '7',
//     text: '7'
//   },
//   {
//     value: '8',
//     text: '8'
//   },
//   {
//     value: '9',
//     text: '9'
//   },
//   {
//     value: '10',
//     text: '10'
//   }
// ];

// const questionHeader = {
//   type: 'html',
//   name: 'HTML_PLAN_TITLE_01',
//   html: `
//   <div>
//     <legend>
//       <span style="font-size:16px;">
//         Indique cuál es su evaluación sobre los siguientes aspectos del plan estratégico 2017-2021 utilizando una escala de 1 (No logrado) a 4 (Muy bien logrado).
//       </span>
//       <br />
//       <span style="font-size:16px;">
//         MARQUE UNA ALTERNATIVA FRENTE A CADA EJE Y, SI LO DESEA, COMENTE EN EL ESPACIO A CONTINUACIÓN DE CADA UNO.
//       </span>
//       <br />
//     </legend>
//   </div>
// `
// };

const PlanEstrategicoRating = {
  locale: 'es',
  title: 'Ejes Plan Estratégico',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'IMP_EJES_01',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPORTANT_TITLE',
          html: `
            <div>
              <legend>
                <span style="font-size:16px;">
                  Por favor, responda la siguiente pregunta:
                </span>
                <br />
              </legend>
            </div>
          `
        },
        {
          type: 'rating',
          name: 'KNOW_PLAN',
          title: `¿Conoce usted el plan estratégico 2017-2021?`,
          isRequired: true,
          rateValues: labeledOptions
        }
      ]
    },
    {
      name: 'IMP_EJES_02',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPORTANT_TITLE',
          html: `
            <div>
              <legend>
                <span style="font-size:16px;">
                  Por favor, responda la siguiente pregunta:
                </span>
                <br />
              </legend>
            </div>
          `
        },
        {
          type: 'rating',
          name: 'KNOW_PEDAG',
          title: `¿Conoce usted el eje pedagógico del plan estratégico 2017-2021?`,
          isRequired: true,
          rateValues: labeledOptions
        }
      ]
    },
    {
      name: 'IMP_EJES_03',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPORTANT_TITLE',
          html: `
            <div>
              <legend>
                <span style="font-size:16px;">
                  Por favor, responda la siguiente pregunta:
                </span>
                <br />
              </legend>
            </div>
          `
        },
        {
          type: 'rating',
          name: 'KNOW_COMUN',
          title: `¿Conoce usted el eje comunidad del plan estratégico 2017-2021?`,
          isRequired: true,
          rateValues: labeledOptions
        }
      ]
    },
    {
      name: 'IMP_EJES_04',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPORTANT_TITLE',
          html: `
            <div>
              <legend>
                <span style="font-size:16px;">
                  Por favor, responda la siguiente pregunta:
                </span>
                <br />
              </legend>
            </div>
          `
        },
        {
          type: 'rating',
          name: 'KNOW_GEST',
          title: `¿Conoce usted el eje gestión del plan estratégico 2017-2021?`,
          isRequired: true,
          rateValues: labeledOptions
        }
      ]
    },

    {
      name: 'IMP_EJES_PLAN_01',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_PLAN_TITLE',
          html: `
<div>
  <legend>
    <h4>Eje Pedagógico</h4>
    <span style="font-size:16px;">
      El <strong>Eje Pedagógico</strong> tiene relación con todos los aspectos del proceso de aprendizaje de
      los estudiantes, incluyendo los aprendizajes descritos en el currículum anual,
      las habilidades para que aprendan a aprender, el autoconocimiento personal, la internalización de valores,
      relaciones interpersonales, el ejercicio de su ciudadanía local y global, y sus procesos de Fe.
    </span>
    <br />
    <br />
  </legend>
  <legend>
    <span style="font-size:16px;">
      ¿Cuán importante es el <strong><u>eje pedagógico</u></strong> para la concreción del proyecto educativo institucional? Por favor, marque su respuesta <strong>utilizando la escala proporcionada a continuación:</strong>
    </span>
    <br />
  </legend>
</div>
`
        },
        {
          type: 'rating',
          name: 'IMP_PEDAG',
          title: 'Eje pedagógico',
          isRequired: true,
          rateValues: importantOptions
        }
      ]
    },

    {
      name: 'IMP_EJES_PLAN_02',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_PLAN_TITLE',
          html: `
<div>
  <legend>
    <h4>Eje Comunidad</h4>
    <span style="font-size:16px;">
      En el <strong>Eje Comunidad</strong> se integran todos los elementos relacionados con la construcción y
      el fortalecimiento de la Comunidad Educativa, las características principales de su identidad,
      desarrollar el sentido de pertenencia y aumentar los niveles de participación e identificación en cada grupo.
    </span>
    <br />
    <br />
  </legend>
  <legend>
    <span style="font-size:16px;">
      ¿Cuán importante es el <strong><u>eje comunidad</u></strong> para la concreción del proyecto educativo institucional? Por favor, marque su respuesta <strong>utilizando la escala proporcionada a continuación:</strong>
    </span>
    <br />
  </legend>
</div>
`
        },
        {
          type: 'rating',
          name: 'IMP_COMUN',
          title: 'Eje Comunidad',
          isRequired: true,
          rateValues: importantOptions
        }
      ]
    },

    {
      name: 'IMP_EJES_PLAN_03',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_PLAN_TITLE',
          html: `
<div>
  <legend>
    <h4>Eje Gestión</h4>
    <span style="font-size:16px;">
      El <strong>Eje Gestión</strong> tiene una doble dimensión, por un lado ser soporte institucional
      para el logro de los objetivos estratégicos propios de los otros dos ejes, y por otro lado,
      asegurar la sustentabilidad institucional a corto, mediano y largo plazo.
    </span>
    <br />
    <br />
  </legend>
  <legend>
    <span style="font-size:16px;">
      ¿Cuán importante es el <strong><u>eje gestión</u></strong> para la concreción del proyecto educativo institucional? Por favor, marque su respuesta <strong>utilizando la escala proporcionada a continuación:</strong>
    </span>
    <br />
  </legend>
</div>
`
        },
        {
          type: 'rating',
          name: 'IMP_GEST',
          title: 'Eje Gestión',
          isRequired: true,
          rateValues: importantOptions
        }
      ]
    }

    // {
    //   name: 'PLAN_ESTRATEGICO_01',
    //   title: '',
    //   elements: [
    //     questionHeader,
    //     {
    //       type: 'html',
    //       name: 'HTML_PLAN_TITLE',
    //       html: `
    //       <div>
    //         <legend>
    //         <br />
    //         <h4>Eje Pedagógico</h4>
    //         <span style="font-size:16px;">
    //           El <strong>Eje Pedagógico</strong> tiene relación con todos los aspectos del proceso de aprendizaje de
    //           los estudiantes, incluyendo los aprendizajes descritos en el currículum anual,
    //           las habilidades para que aprendan a aprender, el autoconocimiento personal, la internalización de valores,
    //           relaciones interpersonales, el ejercicio de su ciudadanía local y global, y sus procesos de Fe.
    //         </span>
    //         </legend>
    //       </div>
    //       `
    //     },
    //     {
    //       type: 'rating',
    //       name: 'EJE_PEDAG',
    //       title: 'Eje pedagógico',
    //       isRequired: true,
    //       rateValues: labeledOptions
    //     },
    //     {
    //       type: 'comment',
    //       name: 'EJE_PEDAG_COMMENT',
    //       title: 'Comentarios'
    //     }
    //   ]
    // },
    //     {
    //       name: 'PLAN_ESTRATEGICO_02',
    //       title: '',
    //       elements: [
    //         questionHeader,
    //         {
    //           type: 'html',
    //           name: 'HTML_PLAN_TITLE',
    //           html: `
    // <div>
    //   <legend>
    //     <br />
    //     <h4>Eje Comunidad</h4>
    //     <span style="font-size:16px;">
    //       En el <strong>Eje Comunidad</strong> se integran todos los elementos relacionados con la construcción y
    //       el fortalecimiento de la Comunidad Educativa, las características principales de su identidad,
    //       desarrollar el sentido de pertenencia y aumentar los niveles de participación e identificación en cada grupo.
    //     </span>
    //   </legend>
    // </div>
    // `
    //         },
    //         {
    //           type: 'rating',
    //           name: 'EJE_COMUN',
    //           title: 'Eje Comunidad',
    //           isRequired: true,
    //           rateValues: labeledOptions
    //         },
    //         {
    //           type: 'comment',
    //           name: 'EJE_COMUN_COMMENT',
    //           title: 'Comentarios'
    //         }
    //       ]
    //     },
    //     {
    //       name: 'PLAN_ESTRATEGICO_03',
    //       title: '',
    //       elements: [
    //         questionHeader,
    //         {
    //           type: 'html',
    //           name: 'HTML_PLAN_TITLE',
    //           html: `
    // <div>
    //   <legend>
    //     <br />
    //     <h4>Eje Gestión</h4>
    //     <span style="font-size:16px;">
    //       El <strong>Eje Gestión</strong> tiene una doble dimensión, por un lado ser soporte institucional
    //       para el logro de los objetivos estratégicos propios de los otros dos ejes, y por otro lado,
    //       asegurar la sustentabilidad institucional a corto, mediano y largo plazo.
    //     </span>
    //   </legend>
    // </div>
    // `
    //         },
    //         {
    //           type: 'rating',
    //           name: 'EJE_GEST',
    //           title: 'Eje Gestión',
    //           isRequired: true,
    //           rateValues: labeledOptions
    //         },
    //         {
    //           type: 'comment',
    //           name: 'EJE_GEST_COMMENT',
    //           title: 'Comentarios'
    //         }
    //       ]
    //     }
  ]
};

export default PlanEstrategicoRating;
