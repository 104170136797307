import React from 'react';
import Presentation from './Presentation';
import CalendarBooking from './CalendarBooking';
import FinishedMessage from './FinishedMessage';

import postData from './API';

import getConfig from '../../config';

class DKMSTest extends React.Component {
  componentDidMount = async () => {
    const { BATTERY, updateContext } = this.props;
    // console.log('on DKMS Inscription test');
    // console.log({ props: this.props });
    const rut = localStorage.getItem(`currentCode-${BATTERY}`);
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const { BATTERY, stage, user, rut = 'anon' } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    await postData(data, user, rut);
    if (data.CONSENT === '2') {
      console.log('NO CONSENT');
      this.props.toNextStage(stageName);
    }
    if (stage < 2) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    // const { stage, BATTERY } = this.props;
    // // const { BATTERY } = this.props;
    // // const stage = 0;

    // if (stage > 1) {
    //   return (
    //     <FinishedMessage
    //       // onComplete={this.onComplete}
    //       name='Amal Dababneh'
    //       email='adabaneh@alumnos.uai.cl'
    //     />
    //   );
    // }
    // if (stage === 1) {
    //   return (
    //     <CalendarBooking
    //       onComplete={this.onComplete}
    //       BATTERY={BATTERY}
    //       isLast
    //     />
    //   );
    // }
    // return <Presentation onComplete={this.onComplete} BATTERY={BATTERY} />;
    return <FinishedMessage />;
  }
}

export default DKMSTest;
