const AC1 = [
  {
    combination: 'AC1',
    dimension: 'LM',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'AG',
            code: 'AC1_1_LM_AG',
            statement: 'Participar en la gerencia de una empresa de alimentos'
          },
          {
            area: 'CS',
            code: 'AC1_1_LM_CS',
            statement: 'Dirigir una empresa de selección de personas'
          },
          {
            area: 'T',
            code: 'AC1_1_LM_T',
            statement: 'Dirigir una empresa de soporte en redes computacionales'
          },
          {
            area: 'AC',
            code: 'AC1_1_LM_AC',
            statement: 'Ser gerente comercial de una empresa financiera'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'AG',
            code: 'AC1_2_LM_AG',
            statement: 'Administrar una clínica veterinaria'
          },
          {
            area: 'CS',
            code: 'AC1_2_LM_CS',
            statement:
              'Ser editor en jefe de un periódico de circulación masiva'
          },
          {
            area: 'T',
            code: 'AC1_2_LM_T',
            statement: 'Dirigir una empresa de desarrollo de videojuegos'
          },
          {
            area: 'AC',
            code: 'AC1_2_LM_AC',
            statement: 'Dirigir una fundación para impulsar el emprendimiento'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'AG',
            code: 'AC1_3_LM_AG',
            statement:
              'Dirigir un emprendimiento dedicado a dar nuevos usos a la madera'
          },
          {
            area: 'CS',
            code: 'AC1_3_LM_CS',
            statement: 'Ser el gerente de publicidad de una compañía'
          },
          {
            area: 'T',
            code: 'AC1_3_LM_T',
            statement: 'Administrar una empresa constructora'
          },
          {
            area: 'AC',
            code: 'AC1_3_LM_AC',
            statement: 'Dirigir una oficina de contadores auditores'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'AG',
            code: 'AC1_4_LM_AG',
            statement: 'Ser parte del directorio de una cooperativa agrícola'
          },
          {
            area: 'CS',
            code: 'AC1_4_LM_CS',
            statement: 'Ser jefe del departamento de historia de un colegio'
          },
          {
            area: 'T',
            code: 'AC1_4_LM_T',
            statement:
              'Ser el gerente de una empresa de obras civiles (puentes, carreteras, etc.)'
          },
          {
            area: 'AC',
            code: 'AC1_4_LM_AC',
            statement: 'Administrar un parque de deportes extremos outdoor'
          }
        ]
      }
    ]
  }
];

const AC2 = [
  {
    combination: 'AC2',
    dimension: 'IP',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'CS',
            code: 'AC2_1_IP_CS',
            statement:
              'Presentar los comunicados de prensa de una empresa importante'
          },
          {
            area: 'D',
            code: 'AC2_1_IP_D',
            statement: 'Tener un canal de youtube que entregue consejos legales'
          },
          {
            area: 'E',
            code: 'AC2_1_IP_E',
            statement:
              'Participar en la organización de un congreso que aborde los desafíos de la primera infancia'
          },
          {
            area: 'AC',
            code: 'AC2_1_IP_AC',
            statement: 'Liderar una campaña de marketing de un nuevo producto'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'CS',
            code: 'AC2_2_IP_CS',
            statement:
              'Estar a cargo del equipo de desarrollo de una campaña publicitaria'
          },
          {
            area: 'D',
            code: 'AC2_2_IP_D',
            statement:
              'Entregar asesorías legales sobre temas tributarios a empresas'
          },
          {
            area: 'E',
            code: 'AC2_2_IP_E',
            statement:
              'Animar las actividades recreativas de una escuela de verano para niños'
          },
          {
            area: 'AC',
            code: 'AC2_2_IP_AC',
            statement: 'Dirigir el departamento de personal de una empresa'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'CS',
            code: 'AC2_3_IP_CS',
            statement:
              'Participar en el diseño de políticas públicas sobre emprendimiento social'
          },
          {
            area: 'D',
            code: 'AC2_3_IP_D',
            statement:
              'Liderar un equipo de abogados defensores en un caso importante'
          },
          {
            area: 'E',
            code: 'AC2_3_IP_E',
            statement: 'Dirigir una escuela de entrenamiento para deportistas'
          },
          {
            area: 'AC',
            code: 'AC2_3_IP_AC',
            statement:
              'Liderar un equipo de guías de turismo en San Pedro de Atacama'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'CS',
            code: 'AC2_4_IP_CS',
            statement: 'Ser reclutador de recursos humanos de una empresa'
          },
          {
            area: 'D',
            code: 'AC2_4_IP_D',
            statement: 'Presentar los alegatos de una querella ante un juez'
          },
          {
            area: 'E',
            code: 'AC2_4_IP_E',
            statement:
              'Supervisar las actividades de preparación física de un gimnasio de alto rendimiento'
          },
          {
            area: 'AC',
            code: 'AC2_4_IP_AC',
            statement: 'Dirigir un equipo de ventas de una compañía de seguros'
          }
        ]
      }
    ]
  }
];

const AC3 = [
  {
    combination: 'AC3',
    dimension: 'SE',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'CS',
            code: 'AC3_1_SE_CS',
            statement:
              'Implementar un taller para disminuir el bullying en los colegios'
          },
          {
            area: 'D',
            code: 'AC3_1_SE_D',
            statement:
              'Entregar asesoría legal gratuita a personas de escasos recursos'
          },
          {
            area: 'E',
            code: 'AC3_1_SE_E',
            statement: 'Ser educador/a de párvulos en una escuela hospitalaria'
          },
          {
            area: 'S',
            code: 'AC3_1_SE_S',
            statement: 'Ser parte de un equipo de enfermería en una maternidad'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'CS',
            code: 'AC3_2_SE_CS',
            statement: 'Orientar a personas para que puedan encontrar trabajo'
          },
          {
            area: 'D',
            code: 'AC3_2_SE_D',
            statement:
              'Participar en la redacción de proyectos de ley para mejorar las condiciones de la tercera edad'
          },
          {
            area: 'E',
            code: 'AC3_2_SE_E',
            statement:
              'Apoyar el trabajo escolar de estudiantes con necesidades educativas especiales'
          },
          {
            area: 'S',
            code: 'AC3_2_SE_S',
            statement: 'Ser un dentista que atienda en un consultorio municipal'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'CS',
            code: 'AC3_3_SE_CS',
            statement: 'Ayudar a parejas que tienen dificultades en su relación'
          },
          {
            area: 'D',
            code: 'AC3_3_SE_D',
            statement:
              'Representar legalmente a una fundación que trabaja con personas enfermas de cáncer'
          },
          {
            area: 'E',
            code: 'AC3_3_SE_E',
            statement:
              'Preparar físicamente a deportistas con discapacidad física para los próximos juegos paraolímpicos'
          },
          {
            area: 'S',
            code: 'AC3_3_SE_S',
            statement:
              'Trabajar en zonas rurales orientando a las personas sobre el consumo de medicamentos'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'CS',
            code: 'AC3_4_SE_CS',
            statement:
              'Gestionar los beneficios sociales de los empleados de una empresa'
          },
          {
            area: 'D',
            code: 'AC3_4_SE_D',
            statement:
              'Ser parte de un equipo que entrega ayuda legal a través de una línea telefónica gratuita'
          },
          {
            area: 'E',
            code: 'AC3_4_SE_E',
            statement:
              'Ayudar a las estudiantes de educación media a elegir una carrera'
          },
          {
            area: 'S',
            code: 'AC3_4_SE_S',
            statement:
              'Diseñar planes de nutrición para niños y niñas de escasos recursos con problemas de obesidad'
          }
        ]
      }
    ]
  }
];

const AC4 = [
  {
    combination: 'AC4',
    dimension: 'AL',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'AR',
            code: 'AC4_1_AL_AR',
            statement: 'Elaborar un proyecto de arte textil'
          },
          {
            area: 'H',
            code: 'AC4_1_AL_H',
            statement: 'Crear un libro de filosofía para niños'
          },
          {
            area: 'E',
            code: 'AC4_1_AL_E',
            statement:
              'Desarrollar los apoyos gráficos de un libro de matemáticas para niños pequeños'
          },
          {
            area: 'T',
            code: 'AC4_1_AL_T',
            statement: 'Programar un software para crear comics animados'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'AR',
            code: 'AC4_2_AL_AR',
            statement: 'Componer una obra musical'
          },
          {
            area: 'H',
            code: 'AC4_2_AL_H',
            statement:
              'Ambientar un espacio dentro  de la biblioteca pública de Chile'
          },
          {
            area: 'E',
            code: 'AC4_2_AL_E',
            statement:
              'Crear videos musicales para motivar el aprendizaje de las matemáticas en niños y niñas de educación básica'
          },
          {
            area: 'T',
            code: 'AC4_2_AL_T',
            statement:
              'Diseñar un sistema digital de filtros para hacer fotos artísticas con el celular'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'AR',
            code: 'AC4_3_AL_AR',
            statement: 'Elaborar los planos de una casa'
          },
          {
            area: 'H',
            code: 'AC4_3_AL_H',
            statement:
              'Traducir al español las letras de una obra creada en latín'
          },
          {
            area: 'E',
            code: 'AC4_3_AL_E',
            statement:
              'Crear las coreografías de la selección nacional de gimnasia olímpica'
          },
          {
            area: 'T',
            code: 'AC4_3_AL_T',
            statement: 'Ser el dibujante para el desarrollo de un juego on line'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'AR',
            code: 'AC4_4_AL_AR',
            statement:
              'Desarrollar el diseño gráfico de una campaña publicitaria'
          },
          {
            area: 'H',
            code: 'AC4_4_AL_H',
            statement:
              'Ser editor de una nueva colección infantil de textos clásicos de la literatura'
          },
          {
            area: 'E',
            code: 'AC4_4_AL_E',
            statement:
              'Ser profesor de artes plásticas para estudiantes de educación media'
          },
          {
            area: 'T',
            code: 'AC4_4_AL_T',
            statement:
              'Seleccionar las esculturas a instalar para la construcción de un nuevo centro de negocios'
          }
        ]
      }
    ]
  }
];

const AC5 = [
  {
    combination: 'AC5',
    dimension: 'CN',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'AG',
            code: 'AC5_1_CN_AG',
            statement:
              'Desarrollar nuevas metodologías para una ganadería sostenible'
          },
          {
            area: 'CN',
            code: 'AC5_1_CN_CN',
            statement: 'Participar en un estudio de oceanografía'
          },
          {
            area: 'T',
            code: 'AC5_1_CN_T',
            statement:
              'Desarrollar tecnología para detectar y tempranamente erupciones volcánicas'
          },
          {
            area: 'S',
            code: 'AC5_1_CN_S',
            statement:
              'Desarrollar una nueva técnica kinesiológica para deportistas de alto rendimiento'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'AG',
            code: 'AC5_2_CN_AG',
            statement:
              'Participar en un proyecto para rescatar y valorar cultivos tradicionales de frutas y verduras'
          },
          {
            area: 'CN',
            code: 'AC5_2_CN_CN',
            statement: 'Ser investigador en un observatorio astronómico'
          },
          {
            area: 'T',
            code: 'AC5_2_CN_T',
            statement:
              'Trabajar en un proyecto para desarrollar súper alimentos a través de la manipulación genética'
          },
          {
            area: 'S',
            code: 'AC5_2_CN_S',
            statement:
              'Investigar sobre las mejores dietas alimentarias para adolescentes'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'AG',
            code: 'AC5_3_CN_AG',
            statement:
              'Asesorar a la industria forestal para desarrollar mejores prácticas medioambientales'
          },
          {
            area: 'CN',
            code: 'AC5_3_CN_CN',
            statement:
              'Realizar una investigación sobre los usos de la energía nuclear'
          },
          {
            area: 'T',
            code: 'AC5_3_CN_T',
            statement:
              'Hacer el estudio de impacto ambiental de  instalación de una nueva planta hidroeléctrica'
          },
          {
            area: 'S',
            code: 'AC5_3_CN_S',
            statement:
              'Desarrollar nuevos métodos de anestesia dental para niños pequeños'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'AG',
            code: 'AC5_4_CN_AG',
            statement: 'Atender la salud de animales en una clínica veterinaria'
          },
          {
            area: 'CN',
            code: 'AC5_4_CN_CN',
            statement:
              'Realizar una expedición a los bosques nativos de Chiloé para determinar sus características botánicas'
          },
          {
            area: 'T',
            code: 'AC5_4_CN_T',
            statement:
              'Investigar sobre el desarrollo de nuevos materiales para la construcción'
          },
          {
            area: 'S',
            code: 'AC5_4_CN_S',
            statement:
              'Realizar una investigación acerca de las propiedades de la medicina natural mapuche'
          }
        ]
      }
    ]
  }
];

const AC6 = [
  {
    combination: 'AC6',
    dimension: 'FM',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'AG',
            code: 'AC6_1_FM_AG',
            statement: 'Diseñar un sistema de regadío para zonas de alta sequía'
          },
          {
            area: 'CN',
            code: 'AC6_1_FM_CN',
            statement:
              'Estar a cargo de la calibración de equipos de un laboratorio de biología molecular'
          },
          {
            area: 'T',
            code: 'AC6_1_FM_T',
            statement:
              'Desarrollar el sistema eléctrico de un nuevo modelo de automóvil'
          },
          {
            area: 'S',
            code: 'AC6_1_FM_S',
            statement:
              'Diseñar una herramienta que permita el diagnóstico prematuro del cáncer'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'AG',
            code: 'AC6_2_FM_AG',
            statement:
              'Desarrollar una prótesis para animales con daños en sus caderas'
          },
          {
            area: 'CN',
            code: 'AC6_2_FM_CN',
            statement:
              'Supervisar la mantención de reactores nuclear de un centro de producción energética'
          },
          {
            area: 'T',
            code: 'AC6_2_FM_T',
            statement:
              'Construir el hardware de un prototipo de un nuevo modelo de computadoras'
          },
          {
            area: 'S',
            code: 'AC6_2_FM_S',
            statement:
              'Asegurar el buen funcionamiento de scanner y otros equipos médicos de una clínica'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'AG',
            code: 'AC6_3_FM_AG',
            statement:
              'Supervisar los trabajos de mantención de la maquinaria de una planta procesadora de alimentos'
          },
          {
            area: 'CN',
            code: 'AC6_3_FM_CN',
            statement:
              'Supervisar el funcionamiento de los equipos de una estación geológica'
          },
          {
            area: 'T',
            code: 'AC6_3_FM_T',
            statement:
              'Construir maquinaria para la automatización de procesos industriales'
          },
          {
            area: 'S',
            code: 'AC6_3_FM_S',
            statement:
              'Montar los aparatos necesarios para una clínica móvil de atención dental'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'AG',
            code: 'AC6_4_FM_AG',
            statement:
              'Desarrollar un maquina para automatizar la limpieza de la fruta en una empresa frutícola'
          },
          {
            area: 'CN',
            code: 'AC6_4_FM_CN',
            statement: 'Reparar los telescopios de un observatorio astronómico'
          },
          {
            area: 'T',
            code: 'AC6_4_FM_T',
            statement:
              'Participar en la construcción de un nuevo tipo de estructura para puentes colgantes'
          },
          {
            area: 'S',
            code: 'AC6_4_FM_S',
            statement: 'Construir una prótesis para atletas paraolímpicos'
          }
        ]
      }
    ]
  }
];

const AC7 = [
  {
    combination: 'AC7',
    dimension: 'CD',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'CN',
            code: 'AC7_1_CD_CN',
            statement:
              'Estimar las probabilidades de ocurrencia de accidentes de tránsito de una ciudad'
          },
          {
            area: 'CS',
            code: 'AC7_1_CD_CS',
            statement:
              'Realizar una encuesta de opinión pública y analizar sus resultados'
          },
          {
            area: 'T',
            code: 'AC7_1_CD_T',
            statement:
              'Desarrollar códigos de programación de un software para detectar virus informáticos'
          },
          {
            area: 'AC',
            code: 'AC7_1_CD_AC',
            statement:
              'Analizar las estadísticas de las acciones más transadas en la bolsa de comercio'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'CN',
            code: 'AC7_2_CD_CN',
            statement:
              'Desarrollar un software para la estimación de cálculos matemáticos complejos'
          },
          {
            area: 'CS',
            code: 'AC7_2_CD_CS',
            statement:
              'Realizar un análisis económico de los resultados de un programa de gobierno'
          },
          {
            area: 'T',
            code: 'AC7_2_CD_T',
            statement:
              'Hacer un inventario de los puntos de red en una empresa de tamaño mediano'
          },
          {
            area: 'AC',
            code: 'AC7_2_CD_AC',
            statement:
              'Crear una empresa de análisis de datos de redes sociales'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'CN',
            code: 'AC7_3_CD_CN',
            statement:
              'Resolver las ecuaciones necesarias para un experimento de física cuántica'
          },
          {
            area: 'CS',
            code: 'AC7_3_CD_CS',
            statement:
              'Escribir artículos para un medio de comunicación a partir del análisis de datos masivos (big data)'
          },
          {
            area: 'T',
            code: 'AC7_3_CD_T',
            statement:
              'Desarrollar un  software para el diagnóstico y reparación automática de la calidad de la red de internet'
          },
          {
            area: 'AC',
            code: 'AC7_3_CD_AC',
            statement:
              'Ser responsable de los libros contables de una empresa siguiendo la nueva normativa tributaria'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'CN',
            code: 'AC7_4_CD_CN',
            statement:
              'Trabajar como analista de datos estadísticos para el Instituto Nacional de Estadísticas'
          },
          {
            area: 'CS',
            code: 'AC7_4_CD_CS',
            statement:
              'Analizar los datos demográficos de las familias bajo la línea de la pobreza'
          },
          {
            area: 'T',
            code: 'AC7_4_CD_T',
            statement:
              'Ser experto en la elaboración de algoritmos matemáticos para la programación de datos informáticos'
          },
          {
            area: 'AC',
            code: 'AC7_4_CD_AC',
            statement: 'Analizar los datos de flujo de comercio electrónico'
          }
        ]
      }
    ]
  }
];

const AC8 = [
  {
    combination: 'AC8',
    dimension: 'AP',
    items: [
      {
        item: '1',
        options: [
          {
            area: 'AG',
            code: 'AC8_1_AP_AG',
            statement:
              'Determinar las utilidades anuales de una empresa ganadera'
          },
          {
            area: 'CS',
            code: 'AC8_1_AP_CS',
            statement: 'Ser ejecutivo de cuentas de un banco'
          },
          {
            area: 'T',
            code: 'AC8_1_AP_T',
            statement:
              'Estimar los costos de construcción de una nueva autopista urbana'
          },
          {
            area: 'AC',
            code: 'AC8_1_AP_AC',
            statement:
              'Elaborar la planificación estratégica a cuatro años para una empresa financiera'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            area: 'AG',
            code: 'AC8_2_AP_AG',
            statement:
              'Diseñar el presupuesto para el desarrollo de un proyecto de reforestación'
          },
          {
            area: 'CS',
            code: 'AC8_2_AP_CS',
            statement:
              'Administrar la planilla de recursos humanos de una empresa'
          },
          {
            area: 'T',
            code: 'AC8_2_AP_T',
            statement:
              'Elaborar un plan de emergencia de telecomunicaciones en situaciones de catástrofe'
          },
          {
            area: 'AC',
            code: 'AC8_2_AP_AC',
            statement:
              'Ser el responsable de la mesa de dinero de un banco de inversiones internacionales'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            area: 'AG',
            code: 'AC8_3_AP_AG',
            statement: 'Entregar asesoría financiera a empresas agrícolas'
          },
          {
            area: 'CS',
            code: 'AC8_3_AP_CS',
            statement: 'Diseñar la estrategia comunicacional de una empresa'
          },
          {
            area: 'T',
            code: 'AC8_3_AP_T',
            statement:
              'Diseñar el plan de prevención de riesgos de una empresa industrial'
          },
          {
            area: 'AC',
            code: 'AC8_3_AP_AC',
            statement: 'Ser el contador principal de una empresa de auditoría'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            area: 'AG',
            code: 'AC8_4_AP_AG',
            statement:
              'Evaluar los informes financieros de proyectos del Instituto de Desarrollo Agropecuario (INDAP)'
          },
          {
            area: 'CS',
            code: 'AC8_4_AP_CS',
            statement:
              'Elaborar la planificación anual de un servicio de la administración pública'
          },
          {
            area: 'T',
            code: 'AC8_4_AP_T',
            statement:
              'Planificar el trabajo del equipo de técnicos informáticos de Google para Latinoamérica'
          },
          {
            area: 'AC',
            code: 'AC8_4_AP_AC',
            statement:
              'Ser administrador de personal de una fábrica de alimentos'
          }
        ]
      }
    ]
  }
];

const PGIBIP_AREAS = [
  ...AC1,
  ...AC2,
  ...AC3,
  ...AC4,
  ...AC5,
  ...AC6,
  ...AC7,
  ...AC8
];

export default PGIBIP_AREAS;
