import axios from 'axios';
// import { USER_ID } from '../config';

const postData = async (values, USER_ID) => {
  const POST_API =
    'https://iathnyvk8h.execute-api.us-west-2.amazonaws.com/dev/submit';
  const config = { SHEET_ID: '1u5V8DrlE4rZF7xHi0wWwASDiDqmU7jpakpVX0MiKGB8' };
  const caseid = USER_ID;
  const data = { ...values, caseid, config };
  // console.log({ data });
  await axios.post(`${POST_API}`, data);
  return Promise.resolve('200');
};

export default postData;
