import React from 'react';
import MBAComponent from '../../Components/MBA';
import { StageConsumer } from '../../Context';
import TestingMenu from '../../Components/TestingMenu';
import CodeIdSelector from '../../Components/RutIdSelector/CodeIdSelector';
import ModalSelector from '../../Components/RutIdSelector/ModalSelector';

const BATTERY = 'MBA2020';

// parse url query string, e.g.
// url test mode ?mode=testingMode
const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

class MBAPage extends React.Component {
  render() {
    const appMode = getQuery('mode');
    const isTestingMode = appMode === 'testingMode';
    // console.log({ appMode, isTestingMode });
    const testing = isTestingMode; // true;
    const multiple = false;
    return (
      <StageConsumer>
        {({ state, updateContext, nextStage, prevStage }) => (
          <React.Fragment>
            <MBAComponent
              {...this.props}
              {...state}
              updateContext={updateContext}
              toNextStage={nextStage}
              BATTERY={BATTERY}
            />
            {testing && (
              <TestingMenu
                {...state}
                BATTERY={BATTERY}
                toNextStage={nextStage}
                toPrevStage={prevStage}
                rut={state.rut}
                // user={user}
              />
            )}
            {multiple && (
              <CodeIdSelector
                {...state}
                updateContext={updateContext}
                BATTERY={BATTERY}
              />
            )}
            {multiple && !state.rut && (
              <ModalSelector
                {...state}
                updateContext={updateContext}
                BATTERY={BATTERY}
                codeId
              />
            )}
          </React.Fragment>
        )}
      </StageConsumer>
    );
  }
}

export default MBAPage;
