import React from 'react';
import SSCCWorkDemandsComponent from '../../Components/SSCC/WorkDemands';
import { StageConsumer } from '../../Context';
import TestingMenu from '../../Components/TestingMenu';
import RutIdSelector from '../../Components/RutIdSelector';
import ModalSelector from '../../Components/RutIdSelector/ModalSelector';

const BATTERY = 'SSCC';

class SSCCWorkDemandsPage extends React.Component {
  render() {
    const testing = false;
    const multiple = true;
    return (
      <StageConsumer>
        {({ state, updateContext, nextStage }) => (
          <React.Fragment>
            <SSCCWorkDemandsComponent
              {...this.props}
              {...state}
              updateContext={updateContext}
              toNextStage={nextStage}
              BATTERY={BATTERY}
            />
            {testing && <TestingMenu />}
            {multiple && (
              <RutIdSelector
                {...state}
                updateContext={updateContext}
                BATTERY={BATTERY}
              />
            )}
            {multiple && !state.rut && (
              <ModalSelector
                {...state}
                updateContext={updateContext}
                BATTERY={BATTERY}
              />
            )}
          </React.Fragment>
        )}
      </StageConsumer>
    );
  }
}

export default SSCCWorkDemandsPage;
