import React from 'react';
import CareerSuccess from '../../Components/CareerSuccess';
import { StageConsumer } from '../../Context';

class CareerSuccessPage extends React.Component {
  render() {
    return (
      <StageConsumer>
        {({ state }) => <CareerSuccess {...this.props} {...state} />}
      </StageConsumer>
    );
  }
}

export default CareerSuccessPage;
