const baseInfo = {
  locale: 'es',
  title: 'Presentación',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Consentimiento Informado</h3>
                  </span>
                </span>
              </div>
              <br> <br>
              <div style="text-align:center;">
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                  <h4>Estudio costos socio-económicos de enfermedades onco-hematológicas
                  en pacientes pediátricos en Chile</h4>
                  </span>
                </span>
              </div>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Este estudio tiene por finalidad conocer el impacto de las enfermedades onco-hematológicas pediátricas sobre la vida familiar, la actividad laboral e ingresos de los familiares adultos, los costos directos e indirectos de la enfermedad, y la vida social de los integrantes de la familia. Este estudio es llevado a cabo por un equipo de investigación de la Escuela de Gobierno y la Escuela de Psicología de la Universidad Adolfo Ibáñez, por encargo de la Fundación DKMS.
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Para acceder a participar en este estudio usted deberá aceptar el presente Consentimiento Informado, autorizando a la Universidad Adolfo Ibáñez y Fundación DKMS a tratar sus datos personales para el solo efecto de la realización de este estudio en los términos de la Ley 19.628 sobre Protección a la Vida Privada y su publicación en los términos indicados más abajo en este instrumento.
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Su participación en este estudio es voluntaria. Esto quiere decir que usted puede optar por no participar incluso abandonar el estudio después de aceptado este Consentimiento Informado.
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Si accede a participar en este estudio se le solicitará responder un cuestionario que dura <strong>entre 15 y 20 minutos</strong>.  El conducto a través del cual se llevará a cabo el cuestionario, será vía telefónica, por lo que se le solicitará un número telefónico y un horario en el cuál pueda ser contactada/o para responder este cuestionario. La información recogida a través del cuestionario es confidencial, y no será utilizada para otros propósitos distintos a los del estudio. Los resultados de este estudio podrán ser publicados en diferentes medios (prensa, etc.), en todo caso, los datos personales que sean entregados por usted en respuesta a las preguntas, se presentarán como datos estadísticos, de manera que éstos no puedan ser asociado a un titular identificado o identificable.
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Si tiene preguntas en relación a este estudio, puede contactar a Alejandra Pavez al +562 26051464 o al email <a href="mailto:alejandra.pavez@dkms.cl">alejandra.pavez@dkms.cl</a>. Esta investigación ha sido aprobada por el Comité de Ética de la Universidad Adolfo Ibáñez. Para reportar problemas o en caso de tener preguntas en relación a sus derechos como participante, puede ponerse en contacto con el representante del Comité de Ética, Paula Rojas llamando al +562 23311812 o al mail <a href="mailto:paula.rojas@uai.cl">paula.rojas@uai.cl.</a>
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Por favor asegúrese de haber leído la información anterior, haber hecho preguntas en caso de tener dudas y haber recibido respuestas satisfactorias frente a cada una de ellas. Al chequear el casillero de más abajo, usted acepta participar en este estudio y autoriza expresamente a la Universidad Adolfo Ibáñez y Fundación DKMS para tratar sus datos personales a los fines de este estudio y posterior publicación.
                <br>
                </span>
              </span>

              <span style="font-size:14px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  <br>
                  <strong>
                  "Sí, acepto participar en este estudio, autorizando expresamente a la Universidad Adolfo Ibáñez y Fundación DKMS a tratar mis datos personales para los fines de este estudio y su posterior publicación. Los procedimientos, riesgos, y beneficios me han sido explicados satisfactoriamente y mis dudas han sido aclaradas en relación al objeto de este estudio"</strong>.
                </span>
              </span>

              <br>
            </div>
            <hr />
          </legend>
          `
        },
        {
          type: 'multipletext',
          name: 'NAME_BASE',
          // isRequired: true,
          title: 'Ingrese su Nombre y Apellido',
          items: [
            {
              name: 'NAME',
              title: 'Nombre',
              isRequired: true
            },
            {
              name: 'LASTNAME',
              title: 'Apellido',
              isRequired: true
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'CONSENT',
          title: 'Seleccione:',
          isRequired: true,
          colCount: 2,
          choices: [
            {
              value: '1',
              text: 'Acepto participar en el estudio'
            },
            {
              value: '2',
              text: 'No acepto participar en el estudio'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      title: 'Información general',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          visibleIf: '{CONSENT} = "1"',
          html: `
            <div class="QuestionText BorderColor">
              <span style="font-size:16px;">
              ¡Gracias por su disposición a participar en este estudio!
              <br>
              <br>
              Le recordamos que la información recogida a través del cuestionario es confidencial,
              y no será utilizada para otros propósitos distintos a los del estudio.
              </span>
            </div>
          `
        },
        {
          type: 'radiogroup',
          name: 'SEX',
          title: 'Sexo',
          isRequired: true,
          visibleIf: '{CONSENT} = "1"',
          choices: [
            {
              value: '1',
              text: 'Hombre'
            },
            {
              value: '2',
              text: 'Mujer'
            }
          ]
        },
        {
          type: 'text',
          name: 'AGE',
          title: 'Edad (años)',
          isRequired: true,
          inputType: 'number',
          visibleIf: '{CONSENT} = "1"',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'RELATION',
          title: '¿Cuál es su relación con el niño/a?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Otro'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'RELATION_OTHER',
          isRequired: true,
          visibleIf: '{RELATION} = "03"'
        }
      ]
    },
    {
      name: 'DEMO_PAGE_03',
      title: 'Diagnóstico',
      elements: [
        // {
        //   type: 'multipletext',
        //   name: 'NAME_BASE',
        //   // isRequired: true,
        //   title: 'Ingrese su Nombre y Apellido',
        //   visibleIf: '{CONSENT} = "1"',
        //   items: [
        //     {
        //       name: 'NAME',
        //       title: 'Nombre',
        //       isRequired: true
        //     },
        //     {
        //       name: 'LASTNAME',
        //       title: 'Apellido',
        //       isRequired: true
        //     }
        //   ]
        // },
        {
          type: 'radiogroup',
          name: 'CHILD_DIAG',
          title: '¿Con qué ha sido diagnosticado el niño/a?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Leucemia'
            },
            {
              value: '02',
              text: 'Linfoma'
            },
            {
              value: '03',
              text: 'Mielodispasia'
            },
            {
              value: '04',
              text: 'Otro'
            },
            {
              value: '05',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'CHILD_DIAG_OTHER',
          isRequired: true,
          visibleIf: '{CHILD_DIAG} = "04"'
        }
        // {
        //   type: 'text',
        //   name: 'G_CODE',
        //   title: 'Escriba el código de su grupo'
        // }
      ]
    },

    {
      name: 'DEMO_PAGE_04',
      title: 'Datos de contacto',
      // description: 'Para poder hacerle una devolución de sus resultados.',
      elements: [
        {
          type: 'text',
          name: 'EMAIL',
          title: 'Email',
          isRequired: true,
          inputType: 'email',
          visibleIf: '{CONSENT} = "1"',
          validators: [
            {
              type: 'email'
            }
          ]
        },
        {
          type: 'text',
          name: 'PHONE',
          title: 'Teléfono',
          // inputType: 'tel',
          isRequired: true,
          inputType: 'masked',
          correctAnswer: '(+56) 9 9999 9999',
          visibleIf: '{CONSENT} = "1"'
        },
        {
          type: 'html',
          name: 'HTML_01',
          visibleIf: '{CONSENT} = "1"',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h4>Información Importante</h4>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                Su número de telefono es importante para poder comunicarnos con usted.
                <br>
                <br>
                Para la encuesta los contactará <em>Amal Dababneh</em> desde el siguiente número de teléfono:
                <br>
                </span>
                <ul style="font-size:16px;">
                  <li>+56 9 4436 7167</li>
                </ul>
                <span style="font-family:arial,helvetica,sans-serif;">
                  Le recomendamos guardar este número para que sepa que somos nosotros quienes lo estamos llamando el día de la entrevista.
                  <br>
                </span>
              </span>
            </div>
          </legend>
          `
        },
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <span style="font-size:16px;">
                Haga click en el botón "Continuar" para avanzar:
              </span>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
