function getYearsOptions(years) {
  const data = [];
  for (var i = 0; i < years + 1; i++) {
    data.push({
      value: `${i}`,
      text: i === 1 ? `${i} año` : `${i} años`
    });
  }
  return data;
}

const baseInfo = {
  locale: 'es',
  title: 'Demográficos',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
<legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          <h3>Presentación</h3>
        </span>
      </span>
    </div>
    <br>
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        El propósito de esta encuesta, es recoger sus opiniones y comentarios respecto a la
        gestión directiva del rector y su rol en el marco del plan estratégico 2017-2021.
        Este estudio es conducido por un equipo externo al Colegio Sagrados Corazones integrado por la
        consultora Ágape y por UAI Metrics de la Universidad Adolfo Ibáñez.
      <br>
      </span>
    </span>

    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        <br>
        <strong>
          Si accede a participar en este estudio, le pediremos responder una encuesta que le tomará aproximadamente 8 minutos.
        </strong>
        <br>
        <br>
          En particular, nos interesa conocer su opinión sobre el estado de avance y
          las prioridades del plan estratégico 2017-2021, su opinión sobre la metodología VESS,
          y sobre el estilo de liderazgo del rector, las exigencias del trabajo que usted realiza y su satisfacción
          con diversos aspectos de su trabajo.
        <br>
        <br>
          Es crítico que sus respuestas a las preguntas sean lo más honestas y objetivas posibles,
          de modo de que los resultados del estudio puedan contribuir efectivamente a tener una visión
          realista respecto a los aspectos antes señalado.
          Para garantizar la confidencialidad de la información recogida, el análisis de los datos será
          conducido por un equipo externo al colegio SSCC. Ninguna persona del colegio ni del directorio
          tendrá acceso a sus respuestas individuales.
          Los resultados del estudio se analizarán de manera agregada de modo que no será posible en
          ningún caso individualizar a los participantes.
        <br>
        <br>
        Si tiene preguntas en relación a este estudio, puede contactar a Jorge Sanhueza al
        email <a href="mailto:jorge.sanhueza@uai.cl">jorge.sanhueza@uai.cl</a>.
        Por favor asegúrese de haber leído la información anterior, haber hecho preguntas en caso de
        tener dudas y haber recibido respuestas satisfactorias frente a cada una de ellas.
      </span>
    </span>
  </div>
  <hr />
</legend>
`
        }
        // {
        //   type: 'radiogroup',
        //   name: 'DEM_Q00',
        //   title: 'Por favor, marque una opción para continuar:',
        //   isRequired: true,
        //   colCount: 2,
        //   choices: [
        //     {
        //       value: '1',
        //       text: 'Acepto participar'
        //     },
        //     {
        //       value: '2',
        //       text: 'No acepto participar'
        //     }
        //   ]
        // }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      elements: [
        {
          type: 'radiogroup',
          name: 'DEM_Q01',
          title:
            '¿Pertenece Ud. a alguno de los siguientes equipos directivos? Consejo Directivo, Consejo Coordinación, Liderazgo Ampliado, Vicerrectoría Académica, EVA , Vicerrectoría de Formación, Vicerrectoría Administración y Finanzas.',
          isRequired: true,
          colCount: 2,
          choices: [
            {
              value: '1',
              text: 'Si'
            },
            {
              value: '0',
              text: 'No'
            }
          ]
        },
        {
          type: 'checkbox',
          name: 'DEM_Q02',
          title:
            'Indique a cuál de los siguientes grupos pertenece. MARQUE TODAS LAS QUE CORRESPONDAN',
          isRequired: true,
          colCount: 2,
          // validators: [
          //   {
          //     type: 'expression',
          //     expression: 'checkboxMinSelected({DEM_Q02}, 2) = true',
          //     text: 'Por favor selecciona 2 opciones'
          //   }
          // ],
          choices: [
            {
              value: '1',
              text: '1er ciclo'
            },
            {
              value: '2',
              text: '2do ciclo'
            },
            {
              value: '3',
              text: '3er ciclo'
            },
            {
              value: '0',
              text: 'Ninguno'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_03',
      elements: [
        {
          type: 'checkbox',
          name: 'DEM_Q03',
          title:
            '¿A cuál de las siguientes áreas pertenece? MARQUE TODAS LAS QUE CORRESPONDAN',
          isRequired: true,
          colCount: 7,
          // validators: [
          //   {
          //     type: 'expression',
          //     expression: 'checkboxMinSelected({CICLE}, 2) = true',
          //     text: 'Por favor selecciona 2 opciones'
          //   }
          // ],
          choices: [
            {
              value: '1',
              text: 'Departamento de Lenguaje'
            },
            {
              value: '2',
              text: 'Departamento de Matemáticas'
            },
            {
              value: '3',
              text: 'Departamento de Inglés'
            },
            {
              value: '4',
              text: 'Departamento de Ciencias'
            },
            {
              value: '5',
              text: 'Departamento de Religión y Filosofía'
            },
            {
              value: '6',
              text: 'Departamento de Educación Física'
            },
            {
              value: '7',
              text: 'Departamento de Artes'
            },
            {
              value: '8',
              text: 'Departamento de Integración y Psicopedagogía'
            },
            {
              value: '9',
              text: 'Departamento de Historia'
            },
            {
              value: '10',
              text: 'Biblioteca'
            },
            {
              value: '11',
              text: 'TICS'
            },
            {
              value: '12',
              text: 'Pastoral'
            },
            {
              value: '13',
              text: 'Orientación y Psicología'
            },
            {
              value: '14',
              text: 'Convivencia Escolar'
            },
            {
              value: '15',
              text: 'SSGG y Mantención'
            },
            {
              value: '16',
              text: 'Administrativo/Directivo'
            },
            {
              value: '17',
              text: 'Ninguno de los anteriores'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_04',
      elements: [
        {
          type: 'dropdown',
          name: 'DEM_Q04',
          title: '¿Hace cuánto tiempo se incorporó al colegio SSCC?',
          isRequired: true,
          hasOther: true,
          choices: getYearsOptions(45),
          otherText: 'Otro (especifique)',
          optionsCaption: 'Años...'
        }
      ]
    }
  ]
};

export default baseInfo;
