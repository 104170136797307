const quizLetterSeries = {
  locale: 'es',
  title: 'Serie de Números',
  showCompletedPage: true,
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `...`,
  maxTimeToFinish: 300,
  // maxTimeToFinishPage: 0,
  showTimerPanel: 'top',
  showTimerPanelMode: 'survey',
  // completedHtml:
  //   '<h4>Respondiste <b>{correctedAnswers}</b> preguntas correctas de <b>{questionCount}</b>.</h4>',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Serie de números</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  Ahora que finalizó los ítems de práctica comenzará el test.
                  <br>
                  <br>
                  En cada problema verá una serie de números ordenados de izquierda a derecha.
                  <br>
                  <br>
                  SU TAREA consiste en inferir qué número debe aparecer a continuación en la serie.
                  <br>
                  Haga click en la respuesta que usted crea es la correcta.
                  <br>
                  <br>
                  En la siguiente página comenzará con el test.
                  <br>
                  Los problemas son <strong>progresivemente más difíciles</strong>.
                  <br><br>
                  Haga su máximo esfuerzo para encontrar la respuesta correcta para cada problema.
                  <br>
                  Tendrá <strong>5 minutos</strong> para resolver la mayor cantidad de ítems que pueda.
                  <br>
                  <br>
                  <br>
                  Presione el botón "Continuar" para comenzar
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'GFN01',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN01',
          title: `6  –  11  –  15  –  18  –  20`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '1',
          choices: [
            {
              value: '1',
              text: '21'
            },
            {
              value: '2',
              text: '22'
            },
            {
              value: '3',
              text: '23'
            },
            {
              value: '4',
              text: '24'
            },
            {
              value: '5',
              text: '25'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN02',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN02',
          title: `20  –  15  –  11  –  8  –  6`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '1',
          choices: [
            {
              value: '1',
              text: '5'
            },
            {
              value: '2',
              text: '4'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '24'
            },
            {
              value: '5',
              text: '1'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN03',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN03',
          title: `1  –  3  –  7  –  15  –  31`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '5',
          choices: [
            {
              value: '1',
              text: '32'
            },
            {
              value: '2',
              text: '46'
            },
            {
              value: '3',
              text: '38'
            },
            {
              value: '4',
              text: '61'
            },
            {
              value: '5',
              text: '63'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN04',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN04',
          title: `1  –  3  –  7  –  15  –  15  –  7`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '1',
          choices: [
            {
              value: '1',
              text: '3'
            },
            {
              value: '2',
              text: '4'
            },
            {
              value: '3',
              text: '5'
            },
            {
              value: '4',
              text: '6'
            },
            {
              value: '5',
              text: '7'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN05',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN05',
          title: `5  –  6  –  4  –  6  –  7  –  5  –  7  –  8  –  6`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '3',
          choices: [
            {
              value: '1',
              text: '6'
            },
            {
              value: '2',
              text: '7'
            },
            {
              value: '3',
              text: '8'
            },
            {
              value: '4',
              text: '9'
            },
            {
              value: '5',
              text: '10'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN06',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN06',
          title: `3  –  10  –  17  –  24  –  31  –  38`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '2',
          choices: [
            {
              value: '1',
              text: '43'
            },
            {
              value: '2',
              text: '45'
            },
            {
              value: '3',
              text: '47'
            },
            {
              value: '4',
              text: '49'
            },
            {
              value: '5',
              text: '50'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN07',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN07',
          title: `0  –  1  –  0  –  0  –  2  –  0  –  0  –  0  –  4  –  0  –  0  –  0`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '1',
          choices: [
            {
              value: '1',
              text: '0'
            },
            {
              value: '2',
              text: '22'
            },
            {
              value: '3',
              text: '4'
            },
            {
              value: '4',
              text: '8'
            },
            {
              value: '5',
              text: '16'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN08',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN08',
          title: `0  –  9  –  16  –  21  –  24`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '1',
          choices: [
            {
              value: '1',
              text: '25'
            },
            {
              value: '2',
              text: '26'
            },
            {
              value: '3',
              text: '28'
            },
            {
              value: '4',
              text: '31'
            },
            {
              value: '5',
              text: '32'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN09',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN09',
          title: `2  –  4  –  9  –  18  –  23  –  46  –  51`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '4',
          choices: [
            {
              value: '1',
              text: '56'
            },
            {
              value: '2',
              text: '64'
            },
            {
              value: '3',
              text: '92'
            },
            {
              value: '4',
              text: '102'
            },
            {
              value: '5',
              text: '103'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN10',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN10',
          title: `1  –  3  –  5  –  8  –  11  –  15  –  19  –  24  –  29`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '4',
          choices: [
            {
              value: '1',
              text: '32'
            },
            {
              value: '2',
              text: '33'
            },
            {
              value: '3',
              text: '34'
            },
            {
              value: '4',
              text: '35'
            },
            {
              value: '5',
              text: '36'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN11',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN11',
          title: `3  –  0  –  3  –  4  –  6  –  3  –  6  –  7  –  9  –  6  –  9  –  10`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '3',
          choices: [
            {
              value: '1',
              text: '10'
            },
            {
              value: '2',
              text: '11'
            },
            {
              value: '3',
              text: '12'
            },
            {
              value: '4',
              text: '13'
            },
            {
              value: '5',
              text: '14'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN12',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN12',
          title: `10  –  11  –  12  –  14  –  16  –  19  –  22`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '3',
          choices: [
            {
              value: '1',
              text: '24'
            },
            {
              value: '2',
              text: '25'
            },
            {
              value: '3',
              text: '26'
            },
            {
              value: '4',
              text: '28'
            },
            {
              value: '5',
              text: '30'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN13',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN13',
          title: `4  –  8  –  16  –  14  –  12  –  24  –  48  –  46  –  44`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '4',
          choices: [
            {
              value: '1',
              text: '42'
            },
            {
              value: '2',
              text: '56'
            },
            {
              value: '3',
              text: '78'
            },
            {
              value: '4',
              text: '88'
            },
            {
              value: '5',
              text: '98'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN14',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN14',
          title: `3  –  8  –  16  –  21  –  42  –  47`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '5',
          choices: [
            {
              value: '1',
              text: '52'
            },
            {
              value: '2',
              text: '57'
            },
            {
              value: '3',
              text: '66'
            },
            {
              value: '4',
              text: '68'
            },
            {
              value: '5',
              text: '94'
            }
          ]
        }
      ]
    },
    {
      name: 'GFN15',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN15',
          title: `6  –  6  –  6  –  1  –  5  –  5  –  2  –  2  –  4  –  3  –  3  –  3`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '2',
          choices: [
            {
              value: '1',
              text: '3'
            },
            {
              value: '2',
              text: '4'
            },
            {
              value: '3',
              text: '7'
            },
            {
              value: '4',
              text: '8'
            },
            {
              value: '5',
              text: '9'
            }
          ]
        }
      ]
    }
  ]
};

export default quizLetterSeries;
