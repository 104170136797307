const baseInfo = {
  locale: 'es',
  title: 'La enfermedad y su impacto en la familia.',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      title: 'Impacto en los hermanos/as',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
              Ahora le voy a preguntar sobre los hermanos:
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'radiogroup',
          name: 'SIBIMP_00',
          title: '¿{CHILD_NAME} tiene hermanos o hermanas?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'SIBIMP_01',
          title:
            '¿Diría usted que, a propósito de la enfermedad, la relación de los padres del niño diagnosticado con los hermanos se ha visto afectada negativamente?',
          isRequired: true,
          visibleIf: '{SIBIMP_00} = "01"',
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            }
          ]
        },
        {
          type: 'comment',
          name: 'SIBIMP_02',
          title:
            'A propósito de la enfermedad, ¿Qué cambios ha habido en el tipo de educación que reciben los hermanos de {CHILD_NAME}? (por ejemplo, cambio de colegio, ingreso o retiro de sala cuna o jardín infantil)',
          visibleIf: '{SIBIMP_00} = "01"'
        },
        {
          type: 'radiogroup',
          name: 'SIBIMP_03',
          title:
            'A consecuencia de la enfermedad, ¿Alguno de los hermanos ha repetido una o más veces de curso?',
          isRequired: true,
          visibleIf: '{SIBIMP_00} = "01"',
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            }
          ]
        },
        {
          type: 'comment',
          name: 'SIBIMP_04',
          title:
            '¿Qué cambios en las rutinas de los hermanos han ocurrido a propósito de la enfermedad?',
          visibleIf: '{SIBIMP_00} = "01"'
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      title: 'Impacto Familiar',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
                Ahora le preguntaré sobre la familia del niño/a diagnosticado:
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'radiogroup',
          name: 'FIMP_01',
          title:
            'Debido al tratamiento de la enfermedad, ¿Algún miembro de la familia (padres y/o hermanos) de {CHILD_NAME}, tuvo que cambiarse de ciudad para facilitar el tratamiento?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            }
          ]
        },
        {
          type: 'comment',
          name: 'FIMP_02',
          visibleIf: '{FIMP_01} = "01"',
          title:
            '¿Me podría indicar desde qué ciudad y hacia qué ciudad se trasladaron? ¿Quiénes debieron trasladarse?'
        },
        {
          type: 'radiogroup',
          name: 'FIMP_03',
          title: '¿Fue un cambio temporal o permanente?',
          isRequired: true,
          visibleIf: '{FIMP_01} = "01"',
          choices: [
            {
              value: '01',
              text: 'Temporal'
            },
            {
              value: '02',
              text: 'Permanente'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'FIMP_04',
          title:
            '¿Cuál es la situación actual de vivienda de los miembros familiares que debieron trasladarse de ciudad?',
          isRequired: true,
          visibleIf: '{FIMP_01} = "01"',
          choices: [
            {
              value: '01',
              text: 'Casa/departamento propio'
            },
            {
              value: '02',
              text: 'Casa/departamento arrendado'
            },
            {
              value: '03',
              text: 'En la casa/departamento de otra persona (allegado)'
            },
            {
              value: '04',
              text: 'Casa de acogida'
            },
            {
              value: '05',
              text: 'No sabe / No responde'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_03',
      title: 'Impacto Familiar',
      elements: [
        {
          type: 'radiogroup',
          name: 'FIMP_05',
          title:
            'En relación a los impactos negativos de la enfermedad de {CHILD_NAME}, ¿Cuál de las siguientes opciones que le leeré a continuación reflejan de mejor forma el impacto que la enfermedad ha tenido en la vida familiar?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'No ha tenido ningún efecto en la vida familiar'
            },
            {
              value: '02',
              text:
                'Ha tenido un efecto negativo en la vida familiar, pero este ha sido leve'
            },
            {
              value: '03',
              text:
                'Ha tenido un efecto negativo importante en la vida familiar'
            },
            {
              value: '04',
              text:
                'Ha tenido un efecto extremadamente negativo en la vida familiar'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
