import React from 'react';
import ChatBot, { ChatCustomComp } from '../ChatBot';
import ChatWrapper from './chat.style';
import NabuPic from './img/nabu-full.png';

class SortableIntro extends React.Component {
  state = { loading: false };

  handleEnd = ({ steps, values }) => {
    const { stage = 0 } = this.props;
    setTimeout(() => {
      this.setState({ loading: true });
      // console.log({ steps });
      // console.log({ values });
      const data = { TUTORIAL_2: '1', STAGE: stage + 1 };
      this.props.onComplete(data);
    }, 2000);
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <ChatWrapper>
          <h4>Guardando respuestas para avanzar...</h4>
        </ChatWrapper>
      );
    }
    return (
      <ChatWrapper>
        <div className='nabu-chat-person'>
          <img className='nabu-pic' src={NabuPic} alt='nabu-pic' />
        </div>
        <ChatBot
          className='nabu-chat-wrapper'
          headerTitle='NABU'
          placeholder='Escribe un mensaje'
          botDelay={3200}
          handleEnd={this.handleEnd}
          steps={[
            {
              id: '1',
              message: 'Perfecto!',
              delay: 1000,
              trigger: '2'
            },
            {
              id: '2',
              message: 'Comencemos ahora la segunda parte del test. ',
              delay: 2000,
              trigger: 'instructions'
            },
            {
              id: 'instructions',
              component: (
                <ChatCustomComp>
                  La idea es que, pensando en tus intereses,{' '}
                  <strong>
                    identifiques los conocimientos o habilidades te gustaría
                    aprender durante el próximo año en el colegio.
                  </strong>
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 4500,
              trigger: '4'
            },
            {
              id: '4',
              component: (
                <ChatCustomComp>
                  Para esto, te voy a mostrar <strong>tres actividades</strong>{' '}
                  que podrías realizar como parte de tus actividades escolares.
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 6500,
              trigger: '5'
            },
            {
              id: '5',
              component: (
                <ChatCustomComp>
                  Al igual que en la primera parte, te voy a pedir que me
                  indiques cuál es la que <strong>MÁS</strong> te gustaría
                  hacer, arrastrándola hacia arriba y cuál es la que{' '}
                  <strong>MENOS</strong> te gustaría, arrastrándola hacia abajo.
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 7500,
              trigger: 'instruction_replay'
            },
            {
              id: 'instruction_replay',
              delay: 8500,
              message: '¿Estás listo para comenzar?',
              trigger: 'replay_select'
            },
            {
              id: 'replay_select',
              options: [
                { value: 1, label: 'Si, comencemos!', trigger: 'end_last' },
                {
                  value: 2,
                  label: 'No, Repetir instrucciones',
                  trigger: 'instructions'
                }
              ]
            },
            {
              id: 'end_last',
              message: 'Aquí vamos!',
              end: true
            }
          ]}
        />
      </ChatWrapper>
    );
  }
}

export default SortableIntro;
