const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  },
  {
    value: '8',
    text: '8'
  },
  {
    value: '9',
    text: '9'
  }
];

const questionFooter = {
  type: 'html',
  name: 'HTML_QUESTION_FOOTER_DBC',
  html: `
  <div class="qstn-likert-label pb-5">
    <div>Totalmente en desacuerdo</div>
    <div></div>
    <div>Totalmente de acuerdo</div>
  </div>
  `
};
const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_Header',
  html: `
  <div class="QuestionText BorderColor">
    <span style="font-size:16px;">
      Por favor indica en qué medida estas afirmaciones te representan utilizando la siguiente escala:
    </span>
  </div>
  `
};

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DCB_PAGE_00',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01DBC',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <h3>Instrucciones</h3>
    </span>
    <br />
    Ahora, te presentaremos una serie de ideas u opiniones que las personas pueden tener sobre el proceso de elección de carrera.
    <br />
    <br />
    Por favor indica qué tan de acuerdo estás con cada afirmación, usando una escala de <strong>1</strong> (Totalmente en desacuerdo) a <strong>9</strong> (Totalmente de acuerdo), tal como se presenta a continuación:
    <br />
    <br />
    <div class="btn-group">
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>1</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>2</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>3</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>4</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>5</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="6" value="6"><span class="quanta_q_rating_item_text"><span>6</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="7" value="7"><span class="quanta_q_rating_item_text"><span>7</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="8" value="8"><span class="quanta_q_rating_item_text"><span>8</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DCB_00" aria-required="true" aria-label="9" value="9"><span class="quanta_q_rating_item_text"><span>9</span></span></label>
    </div>
    <div class="qstn-likert-label pb-5">
      <div>Totalmente en desacuerdo</div>
      <div></div>
      <div>Totalmente de acuerdo</div>
    </div>
  </span>
</div>
`
        }
      ]
    }
  ]
};

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DCB_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_01',
          title:
            'Es importante elegir una carrera que se ajuste a mis preferencias, ya que esto determinará si estoy satisfecho/a con mi decisión',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_02',
          title:
            'No vale la pena invertir tanto tiempo y esfuerzo en elegir una carrera, ya que al final todo en la vida es una cuestión de suerte',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_03',
          title:
            'Creo que elegir una carrera es adquirir un compromiso para toda la vida',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_04',
          title:
            'Las personas significativas en mi vida (como familiares o amigos) me conocen mejor de lo que yo me conozco, así que pueden saber mejor que yo qué carrera podría ser mejor para mi',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_05',
          title:
            'Un orientador vocacional podría saber exactamente qué carreras se ajustan a mis características',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_06_M',
      title: '',
      visibleIf: '{SEX} = "1"',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_06_M',
          title:
            'Creo que no podría trabajar en una profesión que requiera habilidades que probablemente yo no tengo, porque que se consideran "femeninas" (por ejemplo, habilidades terapéuticas)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_06_F',
      title: '',
      visibleIf: '{SEX} = "2"',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_06_F',
          title:
            'Creo que no podría trabajar en una profesión que requiera habilidades que probablemente yo no tengo, porque que se consideran "masculinas" (por ejemplo, habilidades de mecánica)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_07',
          title:
            'En realidad no importa qué carrera escoja, ya que finalmente el destino determinará mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_08',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_08',
          title:
            'Elegir una carrera es una decisión crucial en la vida, por lo que no debo cometer errores en esta elección',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_09',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_09',
          title:
            'Mis padres saben mucho mejor que yo qué carrera debiera escoger',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_10',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_10',
          title:
            'Los test psicológicos (como pruebas de habilidades o de orientación vocacional) pueden predecir cuán exitoso/a seré en la carrera que elija.',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_11_M',
      title: '',
      visibleIf: '{SEX} = "1"',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_11_M',
          title:
            'Hay algunas carreras que no considero entre mis opciones porque parecen demasiado "femeninas", aunque pueden ser interesantes (por ejemplo, educación de párvulos, enfermería o trabajo social)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_11_F',
      title: '',
      visibleIf: '{SEX} = "2"',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_11_F',
          title:
            'Hay algunas carreras que no considero entre mis opciones porque parecen demasiado "masculinas", aunque pueden ser interesantes (por ejemplo, ingeniería eléctrica o mecánica)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_12',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_12',
          title:
            'No es necesario invertir demasiado esfuerzo en elegir una carrera, ya que distintas oportunidades aparecerán tarde o temprano',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_13',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_13',
          title:
            'Debo invertir todos mis esfuerzos en elegir una carrera, ya que esta decisión afectará el resto de mi vida',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_14',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_14',
          title:
            'Las personas importantes en mi vida (como familiares y amigos) ya saben qué carrera debo elegir.',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_15',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_15',
          title:
            'Un orientador vocacional es capaz de tomar la mejor decisión para mi',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_16_M',
      title: '',
      visibleIf: '{SEX} = "1"',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_16_M',
          title:
            'Solo consideraré opciones limitadas de carrera, porque no podría trabajar en ocupaciones que generalmente son consideradas como "femeninas"',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DCB_16_F',
      title: '',
      visibleIf: '{SEX} = "2"',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DCB_16_F',
          title:
            'Solo consideraré opciones limitadas de carrera, porque no podría trabajar en ocupaciones que generalmente son consideradas como "masculinas"',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    }
  ]
};

export default testInfo;
