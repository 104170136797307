import React, { useState } from 'react';
import TestingMenuWrapper from './style';

const TestingMenu = ({
  rut = 'anon',
  BATTERY,
  toNextStage,
  toPrevStage,
  stage,
  equis1,
  equis2
}) => {
  const stageName = `userStage-${rut}-${BATTERY}`;
  const ItemIndex = `itemIndex-${rut}-${BATTERY}`;
  const valuesKey = `itemValues-${rut}-${BATTERY}`;
  const studyItemIndex = `studyItemsIndex-${rut}-${BATTERY}`;
  const studyValuesKey = `studyItemsValues-${rut}-${BATTERY}`;

  const [open, setOpen] = useState(false);
  const clearState = () => {
    localStorage.clear();
  };
  const reloadPage = () => {
    window.location.reload();
  };
  const clearX1Index = () => {
    localStorage.setItem(ItemIndex, 0);
  };
  const clearX1Values = () => {
    localStorage.setItem(valuesKey, JSON.stringify([]));
  };
  const clearX2Index = () => {
    localStorage.setItem(studyItemIndex, 0);
  };
  const clearX2Values = () => {
    localStorage.setItem(studyValuesKey, JSON.stringify([]));
  };
  const prevStage = () => toPrevStage(stageName);

  const nextStage = () => toNextStage(stageName);

  return (
    <TestingMenuWrapper open={open}>
      <div className='toggle'>
        <button onClick={() => setOpen(!open)}>{open ? '>' : '<'}</button>
      </div>
      <div className='options'>
        <button onClick={clearState}>Clear State</button>
        <button onClick={reloadPage}>Recargar Página</button>
        {equis1 && (
          <React.Fragment>
            <button disabled>Equis1 Rankings</button>
            <div className='x1-nav'>
              <button onClick={clearX1Index}>Clear Index</button>
              <button onClick={clearX1Values}>Clear Values</button>
            </div>
          </React.Fragment>
        )}
        {equis2 && (
          <React.Fragment>
            <button disabled>Equis2 Rankings</button>
            <div className='x1-nav'>
              <button onClick={clearX2Index}>Clear Index</button>
              <button onClick={clearX2Values}>Clear Values</button>
            </div>
          </React.Fragment>
        )}
        <div className='stage-nav'>
          <button onClick={prevStage}>Prev Stage</button>
          <button disabled className='middle'>
            {stage}
          </button>
          <button onClick={nextStage}>Next Stage</button>
        </div>
      </div>
    </TestingMenuWrapper>
  );
};

export default TestingMenu;
