import styled, { css } from 'styled-components';

const SortableListWrapper = styled.div`
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
  @media (max-width: 979px) {
    height: inherit;
  }
`;

export const DroppableListWrapper = styled.div`
  padding: 10px;
  ${'' /* width: 450px; */}
  background: #f1f1f1;
  border-radius: 4px;
  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      background: lightblue;
    `};
`;

export const ListItemWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 10px;
  margin: 0.5rem;
  font-weight: 700;
  color: ${({ isDragging }) => (isDragging ? '#fff' : '#212529')};
  background: ${({ isDragging, theme }) =>
    isDragging ? theme.colors.brandColor : '#fff'};
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: background 0.2s ease-in-out;
  ${'' /* display: block; */}
  text-align: center;
  border-radius: 4px;
  box-shadow: ${({ isDragging, theme }) =>
    isDragging ? `2px 2px 1px ${theme.colors.darkBackground}` : 'none'};

  &:hover {
    background: ${({ theme }) => theme.colors.itemBackgroundHover};
  }
  &:focus {
    /* disable standard focus color */
    outline: none;

    /* use our own awesome one */
    border-color: ${({ isDragging }) => (isDragging ? '#E3FCEF' : '#2684FF')};
  }
`;

export const DropZone = styled.div`
  ${'' /* display: flex; */}
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  ${'' /* align-items: start; */}
  /* stop the list collapsing when empty */
  min-width: 600px;
  /* stop the list collapsing when it has no items */
  min-height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const ScrollContainer = styled.div`
  overflow: auto;
`;

// $ExpectError - not sure why
export const Container = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    flex parent
    needed to allow width to grow greater than body
  */
  display: inline-flex;
`;

export const ButtonWrapper = styled.div`
  padding: 2rem;
  text-align: center;
`;
export const Button = styled.button`
  ${'' /* display: flex; */}
  align-self: center;
  padding: 15px 30px;
  margin: 0.5rem;
  font-weight: 700;
  color: #fff;
  background: ${({ theme }) => theme.colors.brandColor};
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: all 0.2s ease-in-out;
  ${'' /* display: block; */}
  text-align: center;
  border-radius: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.brandSolid};
    border-color: ${({ theme }) => theme.colors.brandSolid};
  }
`;

export default SortableListWrapper;
