const baseInfo = {
  locale: 'es',
  title: 'Antecedentes',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'TREAT_PAGE_01',
      title: 'En relación al tratamiento de {CHILD_NAME}',
      elements: [
        {
          type: 'radiogroup',
          name: 'TREAT_01',
          title:
            '¿Ha tenido, está actualmente en o está por iniciar un tratamiento de quimioterapia?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'TREAT_02',
          title:
            '¿Ha tenido o está programado un trasplante de células madre sanguíneas, o médula ósea?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'TREAT_03',
          title:
            '¿Ha tenido, está actualmente o va iniciar otro tratamiento distinto de la quimioterapia?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'TREAT_03_DETAIL',
          isRequired: true,
          visibleIf: '{TREAT_03} = "01"'
        },
        {
          type: 'multipletext',
          name: 'TREAT_04',
          // isRequired: true,
          title:
            'En el tiempo que {CHILD_NAME} lleva en tratamiento, ¿cuántas semanas o meses ha estado hospitalizado?',
          items: [
            {
              name: 'TREAT_04_DAYS',
              title: 'Días'
              // isRequired: true
            },
            {
              name: 'TREAT_04_MONTHS',
              title: 'Meses'
              // isRequired: true
            }
          ]
        },
        {
          type: 'multipletext',
          name: 'TREAT_05',
          // isRequired: true,
          title:
            '¿Cuánto ha sido la hospitalización más larga que ha tenido {CHILD_NAME}?',
          items: [
            {
              name: 'TREAT_05_DAYS',
              title: 'Días'
              // isRequired: true
            },
            {
              name: 'TREAT_05_MONTHS',
              title: 'Meses'
              // isRequired: true
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'TREAT_06',
          title:
            'Tomando en consideración la hospitalización más larga, ¿esta fue en un recinto hospitalario público o privado?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Público'
            },
            {
              value: '02',
              text: 'Privado'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
