import React from "react";
import Game from "./Game";
import postData from "../../API";

class GameDodgy extends React.Component {
  onComplete = async data => {
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    return <Game boardSize={15} playerSize={30} onComplete={this.onComplete} />;
  }
}

export default GameDodgy;
