const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: 'Siguiente',
  pagePrevText: 'Atrás',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMOG_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Bienvenido a Equis Junior</h3>
                    <br>
                    Este es un test de orientación vocacional, que te puede ayudar a empezar a identificar tus intereses en relación al mundo del trabajo y las disciplinas que te gustaría conocer más en durante tu época escolar.
                    <br><br>
                    El objetivo de este test es poder visualizar y aclarar aquellos intereses y disciplinas que <strong>a ti te gustan o que preferirías estudiar</strong>. Todas las personas pueden pensar y/o sentir de forma distinta, por lo que en este test <strong>no existen respuestas correctas ni incorrectas, lo importante es que respondas de la forma más honestamente posible</strong>.
                    <br><br>
                    El test consta de dos partes. En la primera deberás seleccionar aquellas actividades del mundo del trabajo que crees que te gustaría hacer en un futuro. En la segunda parte, deberás seleccionar aquellas cosas que más te gustaría estudiar en tus próximos años en el colegio.
                    <br><br>
                    Puedes responder este cuestionario desde el lugar que más te acomode, pero <strong>te recomendamos responderlo desde un computador</strong>, para que te sea más simple, en un espacio cómodo donde te puedas concentrar.
                    <br><br>
                    El cuestionario total tarda aproximadamente <strong>30 minutos</strong>, por lo que te recomendamos realizarlo con tiempo.
                    <br><br>
                    Haz click en <em>Siguiente</em> para continuar.
                  </span>
                </span>
              </div>
              <br>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'DEMOG_PAGE_02',
      title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <span style="font-size:16px;">
                Por favor, completa los datos requeridos a continuación:
              </span>
              <br>
            </div>
          </legend>
          `
        },
        // {
        //   type: 'text',
        //   inputType: 'date',
        //   name: 'BIRTH',
        //   title: 'Fecha de nacimiento',
        //   isRequired: true,
        //   min: '1950-01-05',
        //   max: '2020-01-01'
        // },
        {
          type: 'text',
          name: 'BIRTH',
          title: 'Fecha de nacimiento (DD/MM/AA)',
          isRequired: true,
          inputType: 'masked',
          correctAnswer: '99/99/99'
        },
        {
          type: 'radiogroup',
          name: 'SEX',
          title: 'Sexo',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Hombre'
            },
            {
              value: '2',
              text: 'Mujer'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
