import React, { Fragment, useState, useEffect } from 'react';
import { SimpleValuesProvider, SimpleValuesConsumer } from '../innerContext';
import SortableListGroup from './SortableListGroup';
import SortableListWrapper from './sortableList.style';
import Progress from '../../Progress';
import { getFirstNWinners, getDimensionItems } from '../../helpers';

import ITEMS from './areaItems';

const SortablePolar = props => {
  const { dimension = '', label, onComplete } = props;
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const random = true;
  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    const { allItems, randomItems } = getDimensionItems(ITEMS, dimension);
    // console.log('USE EFFECT');
    // console.log({ allItems, randomItems });
    setItems(random ? randomItems : allItems);
  }, [dimension]);

  const handleComplete = async ({ values }) => {
    await setLoading(true);
    const responses = values
      .map(resp => Object.entries(resp).map(c => c[1]))
      .flat();

    const allAreas = responses.map(c => c.area);
    const areas = Array.from(new Set([...allAreas]));

    const resume = areas.map(area => {
      const filtered = responses.filter(resp => resp.area === area);
      const sum = filtered.reduce((acc, current, i) => acc + current.value, 0);
      const mean = sum / filtered.length;
      return { area: area, sum, mean };
    });

    const areasSorted = [...resume].sort((a, b) => b.sum - a.sum);
    const firstTwoAreas = getFirstNWinners(areasSorted, 2);
    const labeled = resume.reduce((acc, current) => {
      const { area, sum } = current;
      return { ...acc, [`${area}_${label}_SUM`]: sum };
    }, {});
    const labeledWinners = firstTwoAreas.reduce((acc, current, index) => {
      const { area } = current;
      return { ...acc, [`TOP_${label}_AREA_${index + 1}`]: area };
    }, {});

    const parsedValues = responses.reduce((acc, response, index) => {
      return { ...acc, [response.code]: response.value };
    }, {});

    const parsedWithSums = { ...parsedValues, ...labeled, ...labeledWinners };
    await setLoading(false);
    return onComplete(parsedWithSums);
  };

  const handleNext = async (items, toNextItem) => {
    // flag visible transition
    await setVisible(false);

    const firstItem = { ...items[0], value: 1 };
    const lastItem = { ...items[items.length - 1], value: -1 };
    const clone = items.slice();
    clone[0] = firstItem;
    clone[items.length - 1] = lastItem;

    const data = [{ ...items, ...clone }];

    // flag visible transition
    await toNextItem(data, handleComplete);
    setTimeout(() => setVisible(true), 300);
  };

  if (loading) {
    return (
      <SortableListWrapper>
        <h4>Guardando respuestas para avanzar...</h4>
      </SortableListWrapper>
    );
  }
  if (!items || items.length === 0) {
    return (
      <SortableListWrapper>
        <h4>Cargando items...</h4>
      </SortableListWrapper>
    );
  }
  return (
    <SimpleValuesProvider items={items}>
      <SimpleValuesConsumer>
        {({ state, toNextItem }) => {
          return (
            <Fragment>
              <Progress currentIndex={state.currentIndex} allItems={items} />
              <SortableListGroup
                items={items[state.currentIndex]}
                toNextItem={its => handleNext(its, toNextItem)}
                visible={visible}
                internalScroll
              />
            </Fragment>
          );
        }}
      </SimpleValuesConsumer>
    </SimpleValuesProvider>
  );
};

export default SortablePolar;
