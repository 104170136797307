const quizLetterSeries = {
  locale: 'es',
  title: 'Serie de Números (Ítems de Práctica)',
  showCompletedPage: true,
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `...`,
  // maxTimeToFinish: 600,
  // maxTimeToFinishPage: 0,
  // showTimerPanel: 'top',
  // showTimerPanelMode: 'survey',
  // completedHtml:
  //   '<h4>Respondiste <b>{correctedAnswers}</b> preguntas correctas de <b>{questionCount}</b>.</h4>',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Serie de números</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  A continuación, realizará un test para evaluar su capacidad para analizar patrones numéricos.
                  <br>
                  <br>
                  En cada problema verá una serie de números ordenados de izquierda a derecha.
                  <br>
                  <br>
                  SU TAREA consiste en inferir qué número debe aparecer a continuación en la serie.
                  <br>
                  Haga click en la respuesta que usted crea es la correcta.
                  <br>
                  <br>
                  En la siguiente página encontrará algunos <u>items de práctica</u>.
                  <br>
                  <br>
                  <br>
                  Presione el botón "Continuar" para comenzar
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'GFN01_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN01_Test',
          title: `10  –  11  –  12  –  13  –  14`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '1',
          choices: [
            {
              value: '1',
              text: '15'
            },
            {
              value: '2',
              text: '16'
            },
            {
              value: '3',
              text: '17'
            },
            {
              value: '4',
              text: '18'
            },
            {
              value: '5',
              text: '19'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_02',
          html: `
          <div style="text-align:center;" class="QuestionText BorderColor">
            <span style="font-size:16px;font-family:arial,helvetica,sans-serif;">
              La respuesta correcta sería <strong>15</strong>. Presione <strong>15</strong> como tu respuesta correcta.
            </span>
          </div>
          `
        }
      ]
    },
    {
      name: 'GFN02_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN02_Test',
          title: `1  –  3  –  5  –  7   –  9  –  11`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '3',
          choices: [
            {
              value: '1',
              text: '11'
            },
            {
              value: '2',
              text: '12'
            },
            {
              value: '3',
              text: '13'
            },
            {
              value: '4',
              text: '14'
            },
            {
              value: '5',
              text: '15'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_02',
          html: `
          <div style="text-align:center;" class="QuestionText BorderColor">
            <span style="font-size:16px;font-family:arial,helvetica,sans-serif;">
              El próximo número en la serie superior sería <strong>13</strong>.
              <br>
              Presione el número <strong>13</strong> como su respuesta
            </span>
          </div>
          `
        }
      ]
    },
    {
      name: 'GFN03_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN03_Test',
          title: `1  –  4  –  3  –  2  –  5  –  4  –  3  –  6  –  5`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '2',
          choices: [
            {
              value: '1',
              text: '3'
            },
            {
              value: '2',
              text: '4'
            },
            {
              value: '3',
              text: '5'
            },
            {
              value: '4',
              text: '6'
            },
            {
              value: '5',
              text: '7'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_02',
          html: `
          <div style="text-align:center;" class="QuestionText BorderColor">
            <span style="font-size:16px;font-family:arial,helvetica,sans-serif;">
              En este problema, la serie va <em>143  254  365</em>.
              <br>
              Debería presionar el <strong>4</strong> como el siguiente número de la serie.
            </span>
          </div>
          `
        }
      ]
    },

    {
      name: 'GFN04_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN04_Test',
          title: `1  –  2  –  3  –  4  –  1  –  2  –  3  –  5  –  1  –  2  –  3  –  6  –  1  –  2  –  3`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '4',
          choices: [
            {
              value: '1',
              text: '4'
            },
            {
              value: '2',
              text: '5'
            },
            {
              value: '3',
              text: '6'
            },
            {
              value: '4',
              text: '7'
            },
            {
              value: '5',
              text: '8'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_02',
          html: `
          <div style="text-align:center;" class="QuestionText BorderColor">
            <span style="font-size:16px;font-family:arial,helvetica,sans-serif;">
              En este problema, la serie va <em>1234 – 1235 – 1236 – 123</em>.
              <br>
              Debería presionar el <strong>7</strong> como el siguiente número de la serie
            </span>
          </div>
          `
        }
      ]
    },

    {
      name: 'GFN05_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFN05_Test',
          title: `2  –  5  –  8  –  11  –  14  –  17`,
          isRequired: true,
          // titleLocation: 'hidden',
          correctAnswer: '4',
          choices: [
            {
              value: '1',
              text: '17'
            },
            {
              value: '2',
              text: '18'
            },
            {
              value: '3',
              text: '19'
            },
            {
              value: '4',
              text: '20'
            },
            {
              value: '5',
              text: '26'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_02',
          html: `
          <div style="text-align:center;" class="QuestionText BorderColor">
            <span style="font-size:16px;font-family:arial,helvetica,sans-serif;">
              En este problema, se suma 3 a cada número en la serie.
              <br>
              2+3  –>  5+3  –>  8+3  ->  14+3  ->  17+3.
              <br>
              Debería presionar el <strong>20</strong> como el siguiente número de la serie.
            </span>
          </div>
          `
        }
      ]
    }
  ]
};

export default quizLetterSeries;
