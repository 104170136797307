import styled, { css } from 'styled-components';

const SortableListWrapper = styled.div`
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
`;

export const DroppableListWrapper = styled.div`
  padding: 2rem 1rem;
  margin-top: 1rem;
  ${'' /* width: 450px; */}
  ${'' /* background: #f1f1f1; */}
  ${'' /* background: ${({ theme }) => theme.colors.extraLight}; */}
  background-color: rgba(250, 250, 250, 0.7);
  border-radius: 4px;
  transition: all 0.1s;
  ${({ theme, isDraggingOver }) =>
    isDraggingOver &&
    css`
      ${'' /* background: lightblue; */}
      ${'' /* background-color: #cce5ff; */}
      background-color:rgba(73,165,186, 0.5);
      border-radius: 0px;
    `};
  @media (max-width: 574px){
    padding: 1.5rem 0.5rem;
    margin-top: 0;
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 15px 20px;
  margin: 10px 2.85rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: #212529;
  background: #fff;
  border: 1px dashed ${({ theme }) => theme.colors.blue};
  transition: background 0.2s ease-in-out;
  border-radius: 4px;

  @media (max-width: 574px) {
    padding: 10px 15px;
    margin: 5px 10px;
    font-size: 0.85rem;
  }

  ${({ isDragging }) =>
    isDragging &&
    css`
      background: ${({ theme }) => theme.colors.brandColor};
      color: #fff;
    `};

  &:hover {
    background: ${({ theme }) => theme.colors.itemBackgroundHover};
  }

  &:nth-child(1) {
    border: 1px dashed ${({ theme }) => theme.colors.success};
    color: #155724;
    background-color: #d4edda;
  }
  &:nth-child(4) {
    border: 1px dashed ${({ theme }) => theme.colors.danger};
    color: #721c24;
    background-color: #f8d7da;
  }
`;

export const DropZone = styled.div`
  ${'' /* display: flex; */}
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  ${'' /* align-items: start; */}
  /* stop the list collapsing when empty */
  min-width: 600px;
  /* stop the list collapsing when it has no items */
  min-height: 80px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;

  @media (max-width: 574px) {
    min-width: initial;
  }
`;

export const ScrollContainer = styled.div`
  overflow: auto;
`;

export const Container = styled.div`
  transition: opacity 0.15s, transform 0.2s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  ${'' /* transform: translateY(0px); */}
  transform: ${({ state }) => {
    switch (state) {
      case 'entering':
        return 'translateY(500px)';
      case 'entered':
        return 'inherit';
      case 'exiting':
        return 'translateY(-400px)';
      case 'exited':
        return 'translateY(-800px)';
      default:
        return 'inherit';
    }
  }};
`;

export const InstructionsWrapper = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
  ${'' /* background-color: rgba(250, 250, 250, 0.7); */}
  background-color: #fff;
  border-radius: 4px;

  .heading {
    font-size: 1.25rem;
    max-width: 600px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    strong {
      font-weight: 700;
    }
  }
  .subheading {
    font-size: 0.9rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }

  @media (max-width: 574px) {
    padding: 0.5rem;
    text-align: center;
    border-radius: 0px;
    .heading {
      font-size: 1rem;
    }
    .subheading {
      font-size: 0.85rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  padding: 2rem;
  text-align: center;
  @media (max-width: 574px) {
    padding: 1rem 0.5px 0.5rem 10px;
    text-align: center;
    margin: 0 10px;
  }
`;

export const Button = styled.button`
  width: 300px;
  align-self: center;
  padding: 15px 30px;
  font-weight: 600;
  color: #fff;
  background: ${({ theme }) => theme.colors.brandColor};
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: all 0.2s ease-in-out;
  text-align: center;
  border-radius: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.brandSolid};
    border-color: ${({ theme }) => theme.colors.brandSolid};
    transform: translateY(-2px);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  }
  &:focus {
    outline: none;
  }

  @media (max-width: 574px) {
    font-size: 0.85rem;
    width: 100%;
    padding: 10px 30px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.disabled};
      border-color: ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
      &:hover {
        background: ${({ theme }) => theme.colors.disabled};
        border-color: ${({ theme }) => theme.colors.disabled};
      }
    `}
`;

export default SortableListWrapper;
