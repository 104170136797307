// const questionHeader = {
//   type: 'html',
//   name: 'HTML_IMPACT_TITLE',
//   html: `
//   <div>
//     <legend>
//       <span style="font-size:16px;">
//         Por favor lee cuidadosamente cada enunciado e indica tu grado de acuerdo con cada uno de ellos usando la siguiente escala:
//         <br /><br />
//       </span>
//     </legend>
//   </div>
//   `
// };
const questionHeader = {
  type: 'html',
  name: 'HTML_IMPACT_TITLE',
  html: `
  <div>

  </div>
  `
};

const baseInfo = {
  locale: 'es',
  title: 'Perceived Difficulty',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'PDQ01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'PDQ01',
          title:
            'En tu opinión, ¿cuán difícil es para una persona mejorar sus competencias?',
          isRequired: true,
          rateValues: [
            {
              value: '1',
              text: 'Extremadamente difícil'
            },
            {
              value: '2',
              text: 'Muy difícil'
            },
            {
              value: '3',
              text: 'Bastante difícil'
            },
            {
              value: '4',
              text: 'Ni fácil ni difícil'
            },
            {
              value: '5',
              text: 'Bastante fácil'
            },
            {
              value: '6',
              text: 'Muy fácil'
            },
            {
              value: '7',
              text: 'Extremadamente fácil'
            }
          ]
        }
      ]
    },
    {
      name: 'PDQ02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'PDQ02',
          title:
            'En tu opinión, ¿cuánto debe esforzarse una persona si quiere mejorar una competencia?',
          isRequired: true,
          rateValues: [
            {
              value: '1',
              text: 'Muchísimo'
            },
            {
              value: '2',
              text: 'Mucho'
            },
            {
              value: '3',
              text: 'Bastante'
            },
            {
              value: '4',
              text: 'Más o menos'
            },
            {
              value: '5',
              text: 'Algo'
            },
            {
              value: '6',
              text: 'Casi nada'
            },
            {
              value: '7',
              text: 'Nada'
            }
          ]
        }
      ]
    },
    {
      name: 'PDQ03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'PDQ03',
          title:
            'Independientemente de aprobar o no la certificación, ¿cuán importante es para ti desarrollar las competencias evaluadas en la certificación?',
          isRequired: true,
          rateValues: [
            {
              value: '1',
              text: 'Muchísimo'
            },
            {
              value: '2',
              text: 'Mucho'
            },
            {
              value: '3',
              text: 'Bastante'
            },
            {
              value: '4',
              text: 'Más o menos'
            },
            {
              value: '5',
              text: 'Algo'
            },
            {
              value: '6',
              text: 'Casi nada'
            },
            {
              value: '7',
              text: 'Nada'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
