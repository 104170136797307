import styled from 'styled-components';

export const TestWrapper = styled.div`
  button {
    background: ${({ theme }) => theme.colors.brandColor};
    color: white;
    font-size: 1em;
    padding: 0.25em 1em;
    border: 2px solid ${({ theme }) => theme.colors.brandColor};
    border-radius: 3px;
    transition: 0.2s all;
    &:hover {
      background: ${({ theme }) => theme.colors.brandSolid};
      border: 2px solid ${({ theme }) => theme.colors.brandSolid};
      color: white;
      box-shadow: ${({ theme }) => theme.colors.itemBackgroundHover};
    }
  }
  .uai_header {
    position: absolute;
    right: 5%;
    top: 2%;
    img {
      max-width: 150px;
    }
  }
  .${({ theme }) => theme.baseName}_main {
    .progress {
      position: fixed;
      top: 0;
      width: 100%;
      border-radius: 0;
      height: 5px;
      span {
        opacity: 0;
        width: 0;
      }
      .progress-bar {
        margin: 0px;
        background-color: ${({ theme }) => theme.colors.brandColor} !important;
      }
    }
    .panel-heading {
      padding: 1.5rem;
      border: none;
      background-color: ${({ theme }) => theme.colors.greyBackground};
      color: white;
      height: 200px;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      border-radius: 0;
      h3 {
        font-weight: 300;
      }
    }
    .${({ theme }) => theme.baseName}_page {
      margin-top: -100px;
    }
  }
  .example_list {
    padding: 0.5rem;
    .example_item {
      padding: 0.5rem;
      .info {
        color: ${({ theme }) => theme.colors.textColor};
        padding-top: 0.5rem;
        line-height: 1.5em;
      }
    }
  }
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -40px;

  .${({ theme }) => theme.baseName}_main {
    height: 100%;
    min-height: 85vh;
  }
  .quanta_page,
  .panel-footer {
    max-width: 840px;
    padding: 2rem 3rem;
    margin: 0 auto;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
  }
  @media only screen and (max-width: 767px) {
    .quanta_page,
    .panel-footer {
      margin: 0 auto;
      width: 80%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }
  }
  @media (min-width: 980px) {
    .quanta_page,
    .panel-footer {
      display: table;
      min-width: 840px;
    }
  }
  @media (max-width: 979px) {
    .quanta_page,
    .panel-footer {
      display: block;
      min-width: 0;
    }
  }
  @media (max-width: 564px) {
    .quanta_page,
    .panel-footer {
      width: 100%;
    }
  }

  .Footer {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 14px;
    max-width: 810px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    img {
      max-width: 250px;
    }
    .btg-logo {
      max-width: 125px;
    }
  }
  .PushStickyFooter {
    height: 50px;
  }
  .Plug {
    text-align: center;
    // display: block;
    display: flex;
    justify-content: flex-end;
    a {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.textColor};
      text-decoration: none;
      padding: 12px 24px;
    }

    @media (min-width: 769px) {
      a {
        color: #fff;
        height: 40px;
        float: right;
        background: #e6e6e6;
        background: rgba(61, 61, 68, 0.3);
        transition: background 0.3s;
        text-align: center;
        border-radius: 5px 0 0 0;
      }
    }
  }

  .title_img_table {
    padding: 1rem;
    img {
      width: 100%;
    }
    &.table_title {
      margin-bottom: -50px;
    }
  }
`;
