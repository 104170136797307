import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import CompromisoCambioSurvey from './compromiso_cambio';
import CompromisoCambioWrapper from './CompromisoCambio.styles';
// import showdown from 'showdown';
// const converter = new showdown.Converter();

class CompromisoCambio extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // console.log('Survey results: ');
    const { data } = survey;
    const { onComplete } = this.props;
    const { C_CHANGE_01, CHANGE_COMMENT } = data;
    const parsed = {
      ...C_CHANGE_01,
      CHANGE_COMMENT
    };
    await onComplete(parsed);
  };

  render() {
    const CompromisoCambioModel = new Survey.Model(CompromisoCambioSurvey);

    return (
      <CompromisoCambioWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={CompromisoCambioModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </CompromisoCambioWrapper>
    );
  }
}

export default CompromisoCambio;
