import React from 'react';
import BigFive from '../../Components/BigFive';
import { StageConsumer } from '../../Context';

class BigFivePage extends React.Component {
  render() {
    return (
      <StageConsumer>
        {({ state }) => <BigFive {...this.props} {...state} />}
      </StageConsumer>
    );
  }
}

export default BigFivePage;
