import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import SortableListWrapper, {
  ButtonWrapper,
  Button,
  ErrorMessage
} from './sortableList.style';
import ItemList from './ItemList';
import reorder from './reorder';

class SortableListGroup extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { items } = props;
    const { innerItems } = state;
    if (items) {
      // if pristine (no drag), copy props items to state
      if (!innerItems) {
        return { items };
      }
      // if props items are new, clean innerItems to use new
      const itemCodes = items.map(item => item.code);
      const innerCodes = innerItems.map(item => item.code);
      // if itemCodes don't include an old item code, then is new
      const isNewItems = !itemCodes.includes(innerCodes[0]);
      if (isNewItems) {
        return { items, innerItems: null };
      }
    }
    return {};
  }

  state = { items: [], innerItems: null, animate: false, invalid: false };

  onDragEnd = result => {
    const currItems = this.state.innerItems || this.state.items;
    const itemsClone = [].concat(currItems);
    // super simple, just removing the dragging item
    if (result.combine) {
      const items = [...itemsClone];
      items.splice(result.source.index, 1);
      this.setState({ innerItems: items });
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      itemsClone,
      result.source.index,
      result.destination.index
    );

    this.setState({
      innerItems: items
    });
  };

  validateOrder = items => {
    const { validate } = this.props;
    if (!validate) {
      return true;
    }
    const { first, last } = validate;
    if (first) {
      // check if valid
      const isFirstValid = items[0].id === first;
      if (isFirstValid) {
        return true;
      }
    }
    if (last) {
      // check if last valid
      const reverseItems = [...items].reverse();
      const isLastValid = reverseItems[0].id === last;
      if (isLastValid) {
        return true;
      }
    }
    // if conditions don't apply, reject
    return false;
  };

  onSubmit = async items => {
    const { toNextItem } = this.props;
    await this.setState({ invalid: false });
    const isValid = this.validateOrder(items);
    if (!isValid) {
      return this.setState({ invalid: true });
    }
    return toNextItem(items);
  };

  render() {
    const { items, innerItems, invalid } = this.state;
    const { disabled } = this.props;
    const currItems = innerItems || items;
    return (
      <SortableListWrapper>
        <div style={{ width: '100%' }}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <ItemList items={currItems} id='polarDropExample' />
          </DragDropContext>
          {invalid && (
            <ErrorMessage>
              Vuelve a leer las instrucciones y arrastra el item al lugar donde
              se pide
            </ErrorMessage>
          )}
          <ButtonWrapper>
            <Button
              disabled={disabled}
              block
              onClick={() => this.onSubmit(currItems)}
            >
              Siguiente
            </Button>
          </ButtonWrapper>
        </div>
      </SortableListWrapper>
    );
  }
}

export default SortableListGroup;
