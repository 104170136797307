const Remuneration = {
  locale: 'es',
  title: 'Remunerations',
  completedHtml:
  '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'bottom',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: true,
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'dropdown',
          name: 'REMU_LAST_INCOME',
          title:
            '¿Cuál fue el último sueldo base líquido mensual recibido (en pesos chilenos)?  ',
          description: '(Sueldo garantizado mensual sin componente variable)',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: '350---'
            },
            '2',
            {
              value: '3',
              text: 'mas de 20m'
            }
          ],
          optionsCaption: 'Opciones'
        }
      ]
    },
    {
      name: 'page2',
      elements: [
        {
          type: 'html',
          name: 'REMU_INFO_TEXT',
          html:
            '<b>INCLUYA</b> en su sueldo los descuentos por planilla correspondientes a:  <br />\n<ul>\n<li>préstamos y consumos en casas comerciales </li>\n<li>cuotas sindicales o a clubes</li>\n<li>días de licencia médica y subsidio maternal</li>\n<li>ahorro previsional voluntario o ahorro voluntario</li>\n</ul>\n<br />\n<b>EXCLUYA</b> de su sueldo los descuentos legales correspondientes a:  • sistema previsional  • sistema de salud  • impuestos a las remuneraciones. <br />\n<b>EXCLUYA</b> también los pagos por:  <br />\n<ul>\n<li>horas extras</li>\n<li>bonificaciones</li>\n<li>gratificaciones</li>\n<li>aguinaldos y otros beneficios </li>\n<li>asignaciones familiares </li>\n</ul>\n'
        }
      ]
    },
    {
      name: 'page3',
      title:
        'Por favor, informe ahora el monto anual que recibió el año pasado por conceptos de:',
      description:
        'Por favor, informe ahora el monto anual que recibió el año pasado por conceptos de:',
      elements: [
        {
          type: 'dropdown',
          name: 'REMU_ANUAL_VARIABLE',
          title:
            'Remuneración anual variable por desempeño y actividades adicionales',
          description: ' (Por ej.: bonos, comisiones por ventas, etc.)',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Mi cargo no considera remuneración variable por desempeño '
            },
            {
              value: '2',
              text: '....'
            },
            {
              value: '3',
              text: 'Más de $20.000.001'
            }
          ],
          optionsCaption: 'Opciones...'
        }
      ]
    },
    {
      name: 'page4',
      elements: [
        {
          type: 'comment',
          name: 'REMU_EXPLAIN',
          title:
            'Si lo considera necesario, explique qué asignaciones incluye su remuneración por desempeño y actividades adicionales'
        }
      ]
    },
    {
      name: 'page5',
      title: 'Otras asignaciones',
      description:
        '(Por ej.: décimo tercer sueldo, bono de vacaciones, aguinaldos, etc.)',
      elements: [
        {
          type: 'multipletext',
          name: 'REMU_TYPE_AMOUNT',
          title: 'Indique tipo de asignación y monto anual aproximado:',
          items: [
            {
              name: 'REMTA_01',
              title: '01'
            },
            {
              name: 'REMTA_02',
              title: '02'
            },
            {
              name: 'REMTA_03',
              title: '03'
            },
            {
              name: 'REMTA_04',
              title: '04'
            },
            {
              name: 'REMTA_05',
              title: '05'
            }
          ]
        }
      ]
    }
  ]
};

export default Remuneration;
