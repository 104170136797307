import React from 'react';
import ChatBot from '../../ChatBot';
import ChatWrapper from './chat.style';
import NabuPic from './img/nabu-full.png';

class NabuIntro extends React.Component {
  state = { loading: false };

  handleEnd = ({ steps, values }) => {
    setTimeout(() => {
      this.props.onComplete();
    }, 2000);
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <ChatWrapper>
          <h4>Guardando respuestas para avanzar...</h4>
        </ChatWrapper>
      );
    }
    return (
      <ChatWrapper>
        <div className='nabu-chat-person'>
          <img className='nabu-pic' src={NabuPic} alt='nabu-pic' />
        </div>
        <ChatBot
          className='nabu-chat-wrapper'
          headerTitle='NABU'
          placeholder='Escribe un mensaje'
          botDelay={2800}
          handleEnd={this.handleEnd}
          steps={[
            {
              id: '1',
              message: 'Vas muy bien!',
              trigger: '2'
            },
            {
              id: '2',
              message: 'Ya estamos llegando al final',
              trigger: '3'
            },
            {
              id: '3',
              message:
                'Solo queda realizar una última actividad y responder algunas preguntas',
              trigger: '4'
            },
            {
              id: '4',
              message: 'Entretenido, no?',
              trigger: '5'
            },
            {
              id: '5',
              message: '...',
              trigger: 'end_last'
            },
            {
              id: 'end_last',
              message: 'Continuemos!',
              end: true
            }
          ]}
        />
      </ChatWrapper>
    );
  }
}

export default NabuIntro;
