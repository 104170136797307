import React from 'react';
import GFNComplete from './GFNComplete';
import postData from '../API';

class GFNCompleteComponent extends React.Component {
  onComplete = async data => {
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    return <GFNComplete onComplete={this.onComplete} />;
  }
}

export default GFNCompleteComponent;
