import React from 'react';
import GFNComplete from '../../Components/GFNComplete';
import { StageConsumer } from '../../Context';

class GFNCompletePage extends React.Component {
  render() {
    return (
      <StageConsumer>
        {({ state }) => <GFNComplete {...this.props} {...state} />}
      </StageConsumer>
    );
  }
}

export default GFNCompletePage;
