import React from 'react';
import Demographics from './Demographics';
import EjesPlanEstrategico from './EjesPlanEstrategico';
import Prioridades from './Prioridades';
import CompromisoCambio from './CompromisoCambio';
import PersonalAttributes from './PersonalAttributes';
import WorkDemands from './WorkDemands';
import FinishedMessage from './FinishedMessage';
import FinalComments from './FinalComments';
import ClosedMessage from './ClosedMessage';

import postData from './API';

import getConfig from '../../config';

class SSCCTest extends React.Component {
  componentDidMount = async () => {
    const { BATTERY, updateContext } = this.props;
    // console.log('on SSCC test');
    // console.log({ props: this.props });
    const rut = localStorage.getItem(`currentCode-${BATTERY}`);
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE } = await getConfig(BATTERY);
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE
      });
    }
  };

  onComplete = async data => {
    const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const stageName = `userStage-${rut}-${BATTERY}`;
    await postData(data, user);
    if (stage < 7) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const closed = true;
    if (closed) {
      return (
        <ClosedMessage name='Jorge Sanhueza' email='jorge.sanhueza@uai.cl' />
      );
    }

    const { stage } = this.props;

    if (stage > 6) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='Jorge Sanhueza'
          email='jorge.sanhueza@uai.cl'
        />
      );
    }
    if (stage === 6) {
      return <FinalComments onComplete={this.onComplete} isLast />;
    }
    if (stage === 5) {
      return <WorkDemands onComplete={this.onComplete} isLast />;
    }
    if (stage === 4) {
      return <PersonalAttributes onComplete={this.onComplete} />;
    }
    if (stage === 3) {
      return <CompromisoCambio onComplete={this.onComplete} />;
    }
    if (stage === 2) {
      return <Prioridades onComplete={this.onComplete} />;
    }
    if (stage === 1) {
      return <EjesPlanEstrategico onComplete={this.onComplete} />;
    }
    return <Demographics onComplete={this.onComplete} />;
  }
}

export default SSCCTest;
