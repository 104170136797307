const finished = ({ name = 'XX XX', email = 'XX@XX.com' }) => ({
  locale: 'es',
  title: 'Finalizado',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'Finished_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'Finished_PAGE_01',
          html: `
<legend>
  <div class="QuestionText BorderColor" style="text-align: center;">
    <br>
    <br>
    <span style="font-size:22px;">
      <span style="font-family:arial,helvetica,sans-serif;">
      Esta encuesta ya no se encuentra disponible.
      </span>
    </span>
    <br>
    <br>
  </div>
</legend>
`
        }
      ]
    }
  ]
});

export default finished;
