const SelfEfficacy = {
  locale: 'es',
  title: 'Autoeficacia',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'GENERAL_AUTOEFF_01',
      title: {
        es:
          'Las siguientes frases describen cómo las personas se ven a sí mismas en el trabajo. Por favor señale en qué medida estas frases le describen a usted usando una escala de 1 (incorrecto) a 4 (cierto).'
      },
      elements: [
        {
          type: 'matrix',
          name: 'GENERAL_AUTOEFF_01',
          title: 'Responda en una escala de 1 (Incorrecto) a 4 (Cierto).',
          isRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'SEFF_01',
              text:
                'Puedo encontrar la manera de obtener lo que quiero, aunque alguien se me oponga'
            },
            {
              value: 'SEFF_02',
              text:
                'Puedo resolver problemas difíciles si me esfuerzo lo suficiente'
            },
            {
              value: 'SEFF_03',
              text:
                'Me es fácil persistir en lo que me he propuesto hasta llegar a alcanzar mis metas'
            },
            {
              value: 'SEFF_04',
              text:
                'Tengo confianza en que podría manejar eficazmente acontecimientos inesperados'
            },
            {
              value: 'SEFF_05',
              text:
                'Gracias a mis cualidades y recursos puedo superar situaciones imprevistas'
            },
            {
              value: 'SEFF_06',
              text:
                'Cuando me encuentro en dificultades puedo permanecer tranquilo/a porque cuento con las habilidades necesarias para manejar situaciones difíciles'
            },
            {
              value: 'SEFF_07',
              text: 'Venga lo que venga, por lo general soy capaz de manejarlo'
            },
            {
              value: 'SEFF_08',
              text:
                'Puedo resolver la mayoría de los problemas si me esfuerzo lo necesario'
            },
            {
              value: 'SEFF_09',
              text:
                'Si me encuentro en una situación difícil, generalmente se me ocurre qué debo hacer'
            },
            {
              value: 'SEFF_10',
              text:
                'Al tener que hacer frente a un problema, generalmente se me ocurren varias alternativas de como resolverlo'
            }
          ]
        }
      ]
    }
  ]
};

export default SelfEfficacy;
