const baseInfo = {
  locale: 'es',
  title: 'La enfermedad y su impacto en la familia.',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'COMP_PAGE_01',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
      En la siguiente sección queremos indagar sobre la enfermedad y el impacto de esa enfermadad en su familia:
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'checkbox',
          name: 'COMP_NUCLEO',
          title:
            '¿Quiénes viven actualmente en el hogar de {CHILD_NAME}? [MARCAR TODAS LAS QUE CORRESPONDAN]:',
          isRequired: true,
          // colCount: 7,
          // hasOther: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Hermano 1'
            },
            {
              value: '04',
              text: 'Hermano 2'
            },
            {
              value: '05',
              text: 'Hermano 3'
            },
            {
              value: '06',
              text: 'Hermano 4'
            },
            {
              value: '07',
              text: 'Hermano 5'
            },
            {
              value: '08',
              text: 'Hermana 1'
            },
            {
              value: '09',
              text: 'Hermana 2'
            },
            {
              value: '10',
              text: 'Hermana 3'
            },
            {
              value: '11',
              text: 'Hermana 4'
            },
            {
              value: '12',
              text: 'Hermana 5'
            },
            {
              value: '13',
              text: 'Abuelo 1'
            },
            {
              value: '14',
              text: 'Abuelo 2'
            },
            {
              value: '15',
              text: 'Abuela 1'
            },
            {
              value: '16',
              text: 'Abuela 2'
            },
            {
              value: '17',
              text: 'Tío 1'
            },
            {
              value: '18',
              text: 'Tío 2'
            },
            {
              value: '19',
              text: 'Tío 3'
            },
            {
              value: '20',
              text: 'Tío 4'
            },
            {
              value: '21',
              text: 'Tía 1'
            },
            {
              value: '22',
              text: 'Tía 2'
            },
            {
              value: '23',
              text: 'Tía 3'
            },
            {
              value: '24',
              text: 'Tía 4'
            },
            {
              value: '25',
              text: 'Asesora del hogar puertas adentro'
            },
            {
              value: '26',
              text: 'Enfermero/a'
            },
            {
              value: '27',
              text: 'Otra persona'
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <strong>**Si la persona no me menciona que vive en la casa, preguntale si tambien vive con el/la niño/a</strong>
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'text',
          title: 'Si otro, especifique:',
          name: 'COMP_NUCLEO_OTHER',
          isRequired: true,
          visibleIf: '{COMP_NUCLEO} contains "27"'
        }
      ]
    },
    {
      name: 'COMP_PAGE_02',
      title: 'Identificación de roles',
      elements: [
        {
          type: 'radiogroup',
          name: 'COMP_SAME',
          title:
            '¿Es esta la misma composición familiar que tenía el niño antes del diagnóstico?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'checkbox',
          name: 'COMP_PREV',
          title:
            '¿Quiénes vivían con {CHILD_NAME} antes del diagnóstico? [MARCAR TODAS LAS QUE CORRESPONDAN]:',
          isRequired: true,
          visibleIf: '{COMP_SAME} = "02"',
          // colCount: 7,
          // hasOther: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Hermano 1'
            },
            {
              value: '04',
              text: 'Hermano 2'
            },
            {
              value: '05',
              text: 'Hermano 3'
            },
            {
              value: '06',
              text: 'Hermano 4'
            },
            {
              value: '07',
              text: 'Hermano 5'
            },
            {
              value: '08',
              text: 'Hermana 1'
            },
            {
              value: '09',
              text: 'Hermana 2'
            },
            {
              value: '10',
              text: 'Hermana 3'
            },
            {
              value: '11',
              text: 'Hermana 4'
            },
            {
              value: '12',
              text: 'Hermana 5'
            },
            {
              value: '13',
              text: 'Abuelo 1'
            },
            {
              value: '14',
              text: 'Abuelo 2'
            },
            {
              value: '15',
              text: 'Abuela 1'
            },
            {
              value: '16',
              text: 'Abuela 2'
            },
            {
              value: '17',
              text: 'Tío 1'
            },
            {
              value: '18',
              text: 'Tío 2'
            },
            {
              value: '19',
              text: 'Tío 3'
            },
            {
              value: '20',
              text: 'Tío 4'
            },
            {
              value: '21',
              text: 'Tía 1'
            },
            {
              value: '22',
              text: 'Tía 2'
            },
            {
              value: '23',
              text: 'Tía 3'
            },
            {
              value: '24',
              text: 'Tía 4'
            },
            {
              value: '25',
              text: 'Asesora del hogar puertas adentro'
            },
            {
              value: '26',
              text: 'Enfermero/a'
            },
            {
              value: '27',
              text: 'Otra persona'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otro, especifique:',
          name: 'COMP_PREV_OTHER',
          isRequired: true,
          visibleIf: '{COMP_PREV} contains "27"'
        }
      ]
    }
  ]
};

export default baseInfo;
