import styled, { css } from 'styled-components';

const SortableListWrapper = styled.div`
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
  @media (max-width: 979px) {
    height: inherit;
  }
`;

export const DroppableListWrapper = styled.div`
  padding: 10px;
  width: 450px;
  background: #f1f1f1;
  border-radius: 4px;
  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      background: lightblue;
    `};
`;

export const ListItemWrapper = styled.div`
  user-select: none;
  padding: 15px;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 10px;
  font-weight: 700;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #212529;
  background: #fff;
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: background 0.2s ease-in-out;
  display: block;
  text-align: center;
  border-radius: 4px;
  ${({ isDragging }) =>
    isDragging &&
    css`
      background: ${({ theme }) => theme.colors.brandColor};
      color: #fff;
    `};

  &:hover {
    background: ${({ theme }) => theme.colors.itemBackgroundHover};
  }
`;

export const ButtonWrapper = styled.div`
  padding: 2rem;
  text-align: center;
`;
export const Button = styled.button`
  ${'' /* display: flex; */}
  align-self: center;
  padding: 15px 30px;
  margin: 0.5rem;
  font-weight: 700;
  color: #fff;
  background: ${({ theme }) => theme.colors.brandColor};
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: all 0.2s ease-in-out;
  ${'' /* display: block; */}
  text-align: center;
  border-radius: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.brandSolid};
    border-color: ${({ theme }) => theme.colors.brandSolid};
  }
`;

export default SortableListWrapper;
