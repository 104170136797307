import React from 'react';
import Presentation from './Presentation';
import AntecedentesEncuestado from './AntecedentesEncuestado';
import AntecedentesNino from './AntecedentesNino';
import ChildTreatment from './ChildTreatment';
import ChildCare from './ChildCare';
// import Occupation from './Occupation';
import RoleIndentification from './RoleIndentification';
// import WorkImpact from './WorkImpact';
import ChildImpact from './ChildImpact';
import FamilyImpact from './FamilyImpact';

import PartnerImpact from './PartnerImpact';
import EconomicCosts from './EconomicCosts';
import IllnessPositivity from './IllnessPositivity';

import FinishedMessage from './FinishedMessage';
import ReflectionComments from './ReflectionComments';

import postData from './API';

import getConfig from '../../config';

class DKMSTest extends React.Component {
  componentDidMount = async () => {
    const { BATTERY, updateContext } = this.props;
    console.log('on DKMS test');
    // console.log({ props: this.props });
    const rut = localStorage.getItem(`currentCode-${BATTERY}`);
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const { BATTERY, stage, user, rut = 'anon' } = this.props;
    console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    await postData(data, user, rut);
    if (stage < 12) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, BATTERY } = this.props;

    if (stage > 11) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='XXXXXX'
          email='xxxxx@xxxx.xx'
        />
      );
    }
    if (stage === 11) {
      return (
        <ReflectionComments
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          isLast
        />
      );
    }
    if (stage === 10) {
      return (
        <IllnessPositivity onComplete={this.onComplete} BATTERY={BATTERY} />
      );
    }
    if (stage === 9) {
      return <EconomicCosts onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 8) {
      return <PartnerImpact onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 7) {
      return <FamilyImpact onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 6) {
      return <ChildImpact onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    // if (stage === 6) {
    //   return <WorkImpact onComplete={this.onComplete} BATTERY={BATTERY} />;
    // }
    // if (stage === 6) {
    //   return <Occupation onComplete={this.onComplete} BATTERY={BATTERY} />;
    // }
    if (stage === 5) {
      return <ChildCare onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 4) {
      return (
        <RoleIndentification onComplete={this.onComplete} BATTERY={BATTERY} />
      );
    }
    if (stage === 3) {
      return <ChildTreatment onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 2) {
      return (
        <AntecedentesNino onComplete={this.onComplete} BATTERY={BATTERY} />
      );
    }
    if (stage === 1) {
      return (
        <AntecedentesEncuestado
          onComplete={this.onComplete}
          BATTERY={BATTERY}
        />
      );
    }
    return <Presentation onComplete={this.onComplete} />;
  }
}

export default DKMSTest;
