const baseInfo = {
  locale: 'es',
  title: 'Reflexión',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_04',
      elements: [
        {
          type: 'comment',
          name: 'NEW_CONTACT',
          title:
            '¿Podría compartir la información de contacto de otras familias que se encuentren en una situación similar para poder entrevistarlos a ellos también?'
        }
      ]
    }
    // {
    //   name: 'DEMO_PAGE_05',
    //   elements: [
    //     {
    //       type: 'comment',
    //       name: 'REFLECT_COMMENTS',
    //       title:
    //         'Si pudiese decirle algo a otras familias que están viviendo algo similar, ¿Qué les gustaría compartir?'
    //     }
    //   ]
    // }
  ]
};

export default baseInfo;
