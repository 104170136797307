const baseInfo = {
  locale: 'es',
  title: 'Prueba Audio',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Prueba Audio</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                A continuación, se le presentará una tarea de comprensión auditiva.
                <br>
                Deberá escuchar una conversación en idioma ingles y luego se le presentará con una serie de preguntas en relación a la conversación que acaba de escuchar.
                </span>
              </span>
              <br>
              <span style="font-size:16px;">
              <span style="font-family:arial,helvetica,sans-serif;">
              <br>
              Por favor, preste la mayor atención posible para esta tarea
              </span>
            </span>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
