import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Transition } from 'react-transition-group';
import SortableListWrapper, {
  InstructionsWrapper,
  ButtonWrapper,
  Button,
  ScrollContainer
} from './sortableList.style';
import ItemList from './ItemList';
import reorder from './reorder';

class SortableListGroup extends React.Component {
  static getDerivedStateFromProps(props, state) {
    // console.log('derived');
    // console.log({ props, state });
    const { items } = props;
    const { innerItems } = state;
    // console.log({ items, innerItems });
    if (items) {
      // if pristine (no drag), copy props items to state
      if (!innerItems) {
        return { items };
      }
      // if props items are new, clean innerItems to use new
      const itemCodes = items.map(item => item.code);
      const innerCodes = innerItems.map(item => item.code);
      // console.log({ itemCodes, innerCodes });
      // if itemCodes don't include an old item code, then is new
      const isNewItems = !itemCodes.includes(innerCodes[0]);
      // console.log({ isNewItems });
      if (isNewItems) {
        return { items, innerItems: null };
      }
    }
    return {};
  }

  state = { items: [], innerItems: null, loading: false };

  onDragEnd = result => {
    const currItems = this.state.innerItems || this.state.items;
    const itemsClone = [].concat(currItems);
    // super simple, just removing the dragging item
    if (result.combine) {
      const items = [...itemsClone];
      items.splice(result.source.index, 1);
      this.setState({ innerItems: items });
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      itemsClone,
      result.source.index,
      result.destination.index
    );

    this.setState({
      innerItems: items
    });
  };

  handleNextItem = async currItems => {
    await this.setState({ loading: true });
    // await this.setState({ loading: false });
    setTimeout(() => this.setState({ loading: false }), 1000);
    const { toNextItem } = this.props;
    await toNextItem(currItems);
  };

  render() {
    const { items, innerItems, loading } = this.state;
    const { internalScroll, visible } = this.props;
    const currItems = innerItems || items;
    // console.log({ visible, items, innerItems });
    return (
      <SortableListWrapper>
        <ScrollContainer>
          <InstructionsWrapper>
            <div className='heading'>
              Arrastra hacia el primer lugar la actividad que{' '}
              <strong>más</strong> te gustaría hacer en tu futuro, y al final,
              la que <strong>menos</strong> te gustaría hacer
            </div>
            <div className='subheading'>
              No es necesario ordenar las opciones intermedias
            </div>
          </InstructionsWrapper>
          <DragDropContext onDragEnd={this.onDragEnd}>
            {/* Transition change state with `in` props */}
            <Transition in={visible} timeout={300}>
              {enterState => (
                // enterState change: exited -> entering -> entered -> exiting -> exited
                <ItemList
                  items={currItems}
                  id='polarDrop'
                  internalScroll={internalScroll}
                  enterState={enterState}
                />
              )}
            </Transition>
          </DragDropContext>
          <ButtonWrapper>
            <Button
              onClick={() => this.handleNextItem(currItems)}
              disabled={loading}
            >
              Siguiente
            </Button>
          </ButtonWrapper>
        </ScrollContainer>
      </SortableListWrapper>
    );
  }
}

export default SortableListGroup;
