import React from 'react';
import styled from 'styled-components';

const CodeIdWrapper = styled.div`
  background: ${({ theme }) => theme.colors.greyBackground};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  padding: 0.5rem 2rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 0.9rem;
  }
  .multi-options {
    display: flex;
    align-items: center;
    button {
      border: none;
      -webkit-transition: background 0.2s ease-in-out;
      padding: 8px 20px;
      text-align: center;
      border-radius: 4px;
      margin-left: 0.5rem;
      background: #f1f1f1;
      color: ${({ theme }) => theme.colors.textColor};
      font-weight: 500;
      cursor: pointer;
    }
  }
  @media (max-width: 564px) {
    display: initial;
    text-align: center;
    .multi-options {
      justify-content: center;
      margin-top: 5px;
      button {
        font-weight: 600;
        padding: 6px 15px;
      }
    }
  }
`;

class RutIdSelector extends React.Component {
  // onChange = async data => {
  //   const { updateContext, rut } = this.props;

  //   await updateContext({
  //     rut: USER_STAGE
  //   });

  //   const { stage, user, rut = 'anon' } = this.props;
  //   const stageName = `userStage-${rut}-${BATTERY}`;
  //   await postData(data, user);
  //   if (stage < 6) {
  //     return this.props.toNextStage(stageName);
  //   }
  // };

  removeCurrentRut = async () => {
    const { updateContext, BATTERY } = this.props;
    const CURRENT_CODE = `currentCode-${BATTERY}`;
    localStorage.removeItem(CURRENT_CODE);
    await updateContext({
      rut: null
    });
  };

  render() {
    const { rut } = this.props;
    if (!rut) {
      return null;
    }
    return (
      <CodeIdWrapper>
        <div>
          Respondiendo como: <span>{rut}</span>
        </div>
        <div className='multi-options'>
          <div>¿No eres tu?</div>
          <button onClick={this.removeCurrentRut}>
            Cambiar de participante
          </button>
        </div>
      </CodeIdWrapper>
    );
  }
}

export default RutIdSelector;
