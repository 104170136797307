const baseInfo = {
  locale: 'es',
  title: 'Antecedentes',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      title: 'Antecedentes personales del encuestado',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
      Para comenzar, le pediré algunos datos y le recuerdo que la encuesta es totalmente confidencial. Por favor me podría indicar:
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'text',
          name: 'NAME',
          title: '¿Cuál es su nombre?',
          isRequired: true,
          inputType: 'text'
        },
        {
          type: 'text',
          name: 'RUT',
          title: '¿Cuál es su RUT?',
          isRequired: true,
          inputType: 'maskedrtl',
          correctAnswer: '99.999.999-9'
        },
        {
          type: 'text',
          name: 'BIRTH',
          title: '¿Cuál es su mes y año de nacimiento? (Mes / Año):',
          isRequired: true,
          inputType: 'masked',
          correctAnswer: '99/9999'
        },
        {
          type: 'radiogroup',
          name: 'SEX',
          title: 'Sexo [preguntar solo en caso de dudas]:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Masculino'
            },
            {
              value: '02',
              text: 'Femenino'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      title: 'Antecedentes personales del encuestado',
      elements: [
        {
          type: 'text',
          name: 'CHILD_NAME',
          title:
            '¿Cuál es el nombre de pila de el/la niño/a que tiene la enfermedad?',
          isRequired: true,
          inputType: 'text'
        },
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
    <strong>Ojo:</strong> si la persona NO responde Madre, Padre o Apoyo Principal, la encuesta termina y se debe pedir el Número de alguno de ellos!!
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'radiogroup',
          name: 'RELATION',
          title: '¿Cuál es su relación con {CHILD_NAME}?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Madre'
            },
            {
              value: '02',
              text: 'Padre'
            },
            {
              value: '03',
              text: 'Otro'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'RELATION_OTHER',
          isRequired: true,
          visibleIf: '{RELATION} = "03"'
        }
      ]
    }
  ]
};

export default baseInfo;
