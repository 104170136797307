const baseInfo = {
  locale: 'es',
  title: 'Presentación',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">

                </span>
              </div>

              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    Junto con agradecer tu participación, queremos enfatizar en que tus respuestas a las siguientes preguntas son confidenciales.
                    <br /><br />
                    Tus respuestas no serán compartidas con profesores del MPO ni de la certificación de competencias, bajo ninguna circunstancia.
                  </span>
                </span>
              </div>
              <br>
            </div>
            <hr />
          </legend>
          `
        },
        {
          type: 'multipletext',
          name: 'NAME_BASE',
          // isRequired: true,
          title: 'Ingrese su Nombre y Apellido',
          items: [
            {
              name: 'NAME',
              title: 'Nombre',
              isRequired: true
            },
            {
              name: 'LASTNAME',
              title: 'Apellido',
              isRequired: true
            }
          ]
        },
        {
          type: 'text',
          name: 'RUT',
          title:
            '¿Cuál es su RUT? Si su RUT termina en “K” favor reemplazar por cero',
          isRequired: true,
          inputType: 'maskedrtl',
          correctAnswer: '99.999.999-9'
        }
      ]
    }
  ]
};

export default baseInfo;
