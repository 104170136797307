const baseInfo = {
  locale: 'es',
  title: 'Antecedentes',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      title: 'Antecedentes personales de el/la niño/a',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
      Continuando con la encuesta, las siguientes preguntas se relacionan con los antecedentes de [nombre del niño/a], entonces, me podría indicar:
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'text',
          name: 'CHILD_BIRTH',
          title:
            '¿Cuál es el mes y año de nacimiento de {CHILD_NAME}? (Mes / Año):',
          isRequired: true,
          inputType: 'masked',
          correctAnswer: '99/9999'
        },
        {
          type: 'radiogroup',
          name: 'CHILD_SEX',
          title: 'Sexo de {CHILD_NAME}. [preguntar solo en caso de dudas]:',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Masculino'
            },
            {
              value: '02',
              text: 'Femenino'
            }
          ]
        },
        {
          type: 'multipletext',
          name: 'ADDRESS',
          // isRequired: true,
          title: '¿Cuál es el lugar de residencia actual de {CHILD_NAME}?',
          items: [
            {
              name: 'COMUNA',
              title: 'Comuna',
              isRequired: true
            },
            {
              name: 'CIUDAD',
              title: 'Ciudad',
              isRequired: true
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      title: 'Antecedentes personales de el/la niño/a',
      elements: [
        {
          type: 'radiogroup',
          name: 'CHILD_DIAG',
          title:
            '¿Podría confirmarme con qué ha sido diagnosticado {CHILD_NAME}?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Leucemia'
            },
            {
              value: '02',
              text: 'Linfoma'
            },
            {
              value: '03',
              text: 'Mielodispasia'
            },
            {
              value: '04',
              text: 'Otro'
            },
            {
              value: '05',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'CHILD_DIAG_OTHER',
          isRequired: true,
          visibleIf: '{CHILD_DIAG} = "04"'
        },
        {
          type: 'text',
          name: 'CHILD_DIAG_DATE',
          title:
            '¿Me podría indicar el mes y año en que {CHILD_NAME} recibió este diagnóstico? (Mes / Año):',
          isRequired: true,
          inputType: 'masked',
          correctAnswer: '99/9999'
        },
        {
          type: 'radiogroup',
          name: 'CHILD_TREAT',
          title:
            '¿Se encuentra actualmente en tratamiento por este diagnóstico?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title:
            '¿Cuánto tiempo duró el tratamiento del/a niño/a? Responda en meses (no sabe = 99)',
          name: 'CHILD_TREAT_2',
          isRequired: true,
          inputType: 'number',
          visibleIf: '{CHILD_TREAT} = "02"'
        }

        // {
        //   type: 'radiogroup',
        //   name: 'LAST_YEAR',
        //   title:
        //     '¿Cuál fue el último año completamente cursado de {CHILD_NAME}?',
        //   isRequired: true,
        //   choices: [
        //     {
        //       value: '01',
        //       text: 'Sala cuna'
        //     },
        //     {
        //       value: '02',
        //       text: 'Jardín infantil'
        //     },
        //     {
        //       value: '03',
        //       text: 'Pre kinder'
        //     },
        //     {
        //       value: '04',
        //       text: 'Kinder'
        //     },
        //     {
        //       value: '05',
        //       text: 'Primero Básico'
        //     },
        //     {
        //       value: '06',
        //       text: 'Segundo Básico'
        //     },
        //     {
        //       value: '07',
        //       text: 'Tercero Básico'
        //     },
        //     {
        //       value: '08',
        //       text: 'Cuarto Básico'
        //     },
        //     {
        //       value: '09',
        //       text: 'Quinto Básico'
        //     },
        //     {
        //       value: '10',
        //       text: 'Sexto Básico'
        //     },
        //     {
        //       value: '11',
        //       text: 'Séptimo Básico'
        //     },
        //     {
        //       value: '12',
        //       text: 'Octavo Básico'
        //     },
        //     {
        //       value: '00',
        //       text: 'Nunca ha asistido a la escuela/colegio'
        //     }
        //   ]
        // }
      ]
    },

    {
      name: 'COSTS_PAGE_01',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'radiogroup',
          name: 'SISMED_01',
          title: '¿Con qué sistema previsional de salud cuenta {CHILD_NAME}?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Isapre'
            },
            {
              value: '02',
              text: 'Fonasa'
            },
            {
              value: '03',
              text: 'Capredena'
            },
            {
              value: '04',
              text: 'PRAIS'
            },
            {
              value: '05',
              text: 'Otro'
            },
            {
              value: '06',
              text: 'Ninguno'
            },
            {
              value: '07',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'SISMED_01_OTHER',
          isRequired: true,
          visibleIf: '{SISMED_01} = "05"'
        },
        {
          type: 'radiogroup',
          name: 'SISMED_02',
          title: '¿En qué tramo se encuentra {CHILD_NAME}?',
          isRequired: true,
          visibleIf: '{SISMED_01} = "02"',
          choices: [
            {
              value: '1',
              text: 'A'
            },
            {
              value: '2',
              text: 'B'
            },
            {
              value: '3',
              text: 'C'
            },
            {
              value: '4',
              text: 'D'
            },
            {
              value: '5',
              text: 'No sabe / No responde'
            }
          ]
        }
      ]
    },

    {
      name: 'COSTS_PAGE_02',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'radiogroup',
          name: 'SISMED_03',
          title:
            '¿{CHILD_NAME} cuenta actualmente con seguro complementario o catastrófico de salud?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        }
      ]
    },

    {
      name: 'DEMO_PAGE_03',
      title: 'Antecedentes personales de el/la niño/a',
      elements: [
        {
          type: 'radiogroup',
          name: 'E3_CURRENT_ACT',
          title:
            'Antes del diagnóstico, ¿asistía a algún establecimiento educacional, jardín infantil, sala cuna u otro programa no convencional de Educación Parvularia?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'E6',
          title:
            '¿A que nivel educacional asistía antes de recibir el diagnóstico? [Lea alternativas]',
          isRequired: true,
          visibleIf: '{E3_CURRENT_ACT} = "01"',
          choices: [
            {
              value: '01',
              text: 'Nunca asistió'
            },
            {
              value: '02',
              text: 'Sala cuna'
            },
            {
              value: '03',
              text: 'Jardín Infantil (Medio menor y Medio mayor)'
            },
            {
              value: '04',
              text: 'Prekinder / Kinder (Transición Menor y Transición Mayor)'
            },
            {
              value: '05',
              text: 'Educación Especial (Diferencial)'
            },
            {
              value: '07',
              text: 'Educación Básica'
            },
            {
              value: '09',
              text: 'Educación Media Científico-Humanista'
            },
            {
              value: '11',
              text: 'Educación Media Técnica Profesional'
            },
            {
              value: '12',
              text: 'No sabe / No responde'
            }
          ]
        },
        // {
        //   type: 'text',
        //   title:
        //     'En ese nivel educacional, ¿cuál fue el último curso que aprobó (para los que no están estudiando) o que cursa actualmente (para los que están estudiando)?',
        //   name: 'E6B',
        //   isRequired: true,
        //   visibleIf: '{E3_CURRENT_ACT} = "01"'
        // },
        {
          type: 'radiogroup',
          name: 'E6B',
          title:
            'Durante el tratamiento, ¿asiste o asistió a algún establecimiento educacional?',
          isRequired: true,
          visibleIf: '{E3_CURRENT_ACT} = "01"',
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'E6C',
          title:
            '¿A que nivel educacional asisite o asisitió durante el tratamiento? [Lea alternativas]',
          isRequired: true,
          visibleIf: '{E6B} = "01"',
          choices: [
            {
              value: '01',
              text: 'Sala cuna'
            },
            {
              value: '02',
              text: 'Jardín Infantil (Medio menor y Medio mayor)'
            },
            {
              value: '03',
              text: 'Prekinder / Kinder (Transición Menor y Transición Mayor)'
            },
            {
              value: '04',
              text: 'Educación Especial (Diferencial)'
            },
            {
              value: '05',
              text: 'Educación Básica'
            },
            {
              value: '06',
              text: 'Educación Media Científico-Humanista'
            },
            {
              value: '07',
              text: 'Educación Media Técnica Profesional'
            },
            {
              value: '08',
              text: 'Educación Hospitalaria'
            },
            {
              value: '12',
              text: 'No sabe / No responde'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
