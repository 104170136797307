import React, { Fragment, useState } from 'react';
import { ValuesProvider, ValuesConsumer } from '../innerContext';
import SortableListGroup from './SortableListGroup';
import SortableListWrapper from './sortableList.style';
import Progress from '../../Progress';
import { getFirstNWinners } from '../../helpers';

import ITEMS from './items';

const SortablePolar = props => {
  const { onComplete, user, label = '', BATTERY } = props;
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleComplete = async ({ values }) => {
    await setLoading(true);
    const responses = values
      .map(resp => Object.entries(resp).map(c => c[1]))
      .flat();

    const allAreas = responses.map(c => c.area);
    const areas = Array.from(new Set([...allAreas]));

    const resume = areas.map(area => {
      const filtered = responses.filter(resp => resp.area === area);
      const sum = filtered.reduce((acc, current, i) => acc + current.value, 0);
      const mean = sum / filtered.length;
      return { area: area, sum, mean };
    });

    const areasSorted = [...resume].sort((a, b) => b.sum - a.sum);
    const firstTwoAreas = getFirstNWinners(areasSorted, 2);
    const labeled = resume.reduce((acc, current) => {
      const { area, sum } = current;
      return { ...acc, [`${area}_${label}_SUM`]: sum };
    }, {});
    const labeledWinners = firstTwoAreas.reduce((acc, current, index) => {
      const { area } = current;
      return { ...acc, [`TOP_${label}_AREA_${index + 1}`]: area };
    }, {});

    const parsedValues = responses.reduce((acc, response, index) => {
      return { ...acc, [response.code]: response.value };
    }, {});

    const parsedWithSums = { ...parsedValues, ...labeled, ...labeledWinners };
    return onComplete(parsedWithSums).then(() => setLoading(false));
  };

  const handleNext = async (items, toNextItem) => {
    // flag visible transition
    await setVisible(false);

    const firstItem = { ...items[0], value: 1 };
    const lastItem = { ...items[items.length - 1], value: -1 };
    const clone = items.slice();
    clone[0] = firstItem;
    clone[items.length - 1] = lastItem;

    const data = [{ ...items, ...clone }];

    // get finished status
    let finished = false;
    const innerComplete = data => {
      finished = true;
      return handleComplete(data);
    };
    await toNextItem(data, innerComplete);
    if (finished) {
      return true;
    }
    // animation if no complete
    // flag visible transition
    setTimeout(() => setVisible(true), 300);
  };

  const random = true;

  if (loading) {
    return (
      <SortableListWrapper>
        <h4>Guardando respuestas para avanzar...</h4>
      </SortableListWrapper>
    );
  }

  return (
    <ValuesProvider items={ITEMS} user={user} BATTERY={BATTERY} random={random}>
      <ValuesConsumer>
        {({
          state,
          toNextItem,
          getValues,
          currentItem,
          allItems
          // allItemsOrdered
        }) => {
          return (
            <Fragment>
              <Progress currentIndex={state.currentIndex} allItems={allItems} />
              <SortableListGroup
                items={currentItem}
                toNextItem={items => handleNext(items, toNextItem)}
                visible={visible}
                internalScroll
              />
            </Fragment>
          );
        }}
      </ValuesConsumer>
    </ValuesProvider>
  );
};

export default SortablePolar;
