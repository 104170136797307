const sortableList = {
  locale: 'es',
  title: 'Orden Items',
  showCompletedPage: true,
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `...`,
  // maxTimeToFinish: 600,
  // maxTimeToFinishPage: 0,
  // showTimerPanel: 'top',
  // showTimerPanelMode: 'survey',
  // completedHtml:
  //   '<h4>Respondiste <b>{correctedAnswers}</b> preguntas correctas de <b>{questionCount}</b>.</h4>',
  pages: [
    // {
    //   name: 'page1',
    //   elements: [
    //     {
    //       type: 'html',
    //       name: 'HTML_01',
    //       html: `
    //       <legend>
    //         <div class="QuestionText BorderColor">
    //           <div>
    //             <span style="font-size:16px;">
    //               <span style="font-family:arial,helvetica,sans-serif;">
    //                 <h3>Serie de números</h3>
    //               </span>
    //             </span>
    //           </div>
    //           <br>
    //           <span style="font-size:16px;">
    //             <span style="font-family:arial,helvetica,sans-serif;">
    //               A continuación, realizará un test para evaluar su capacidad para analizar patrones numéricos.
    //               <br>
    //               <br>
    //               En cada problema verá una serie de números ordenados de izquierda a derecha.
    //               <br>
    //               <br>
    //               SU TAREA consiste en inferir qué número debe aparecer a continuación en la serie.
    //               <br>
    //               Haga click en la respuesta que usted crea es la correcta.
    //               <br>
    //               <br>
    //               En la siguiente página encontrará algunos <u>items de práctica</u>.
    //               <br>
    //               <br>
    //               <br>
    //               Presione el botón "Continuar" para comenzar
    //             </span>
    //           </span>
    //         </div>
    //       </legend>
    //       `
    //     }
    //   ]
    // },
    {
      name: 'SORT_01',
      elements: [
        {
          type: 'sortablelist',
          name: 'SORT_01',
          title: 'Ranking Prioridades',
          // description: 'mueve',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Item 1'
            },
            {
              value: '2',
              text: 'Item 2'
            },
            {
              value: '3',
              text: 'Item 3'
            },
            {
              value: '4',
              text: 'Item 4'
            }
          ],
          validators: [
            {
              type: 'expression',
              expression: 'listMinSelected({SORT_01}, 4) = true',
              text: 'Por favor ordena las 4 opciones'
            }
          ],
          choicesOrder: 'random',
          emptyText: 'Ordena los items dentro de este cuadro...'
        }
      ]
    }
  ]
};

export default sortableList;
