const SocioDemographics = {
  locale: 'es',
  title: 'Datos Socio-demográficos',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'SOCIO_DEMO_01',
      elements: [
        {
          type: 'dropdown',
          name: 'CIVIL_STATUS',
          title: '¿Cuál es su estado civil o conyugal actual?',
          isRequired: true,
          hasOther: true,
          choices: [
            {
              value: '1',
              text: 'Soltero(a)'
            },
            {
              value: '2',
              text: 'Casado(a)'
            },
            {
              value: '3',
              text: 'En Unión Civil '
            },
            {
              value: '4',
              text: 'Conviviente o pareja '
            },
            {
              value: '5',
              text: 'Anulado(a) '
            },
            {
              value: '6',
              text: 'Separado(a)'
            },
            {
              value: '7',
              text: 'Divorciado(a)'
            },
            {
              value: '8',
              text: 'Viudo(a)'
            }
          ],
          otherText: 'Otro (especifique)',
          optionsCaption: 'Opciones...'
        }
      ]
    },
    {
      name: 'SOCIO_DEMO_02',
      elements: [
        {
          type: 'dropdown',
          name: 'CHILDREN',
          title: '¿Tiene hijos?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Si'
            },
            {
              value: '0',
              text: 'No'
            }
          ]
        },
        {
          type: 'text',
          name: 'CHILDREN_N',
          isRequired: true,
          visibleIf: '{CHILDREN} = "1"',
          title: '¿Cuántos hijos?',
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 30
            }
          ]
        },
        {
          type: 'multipletext',
          name: 'CHILDREN_N_DETAILS',
          visibleIf: '{CHILDREN} = "1"',
          title: '¿Cuántos hijos tiene en cada una de las siguientes etapas?',
          items: [
            {
              name: 'CHILD_D_01',
              inputType: 'number',
              title: 'Preescolar [0 a 4 años]'
            },
            {
              name: 'CHILD_D_02',
              inputType: 'number',
              title: 'Escolar [5 a 13 años]'
            },
            {
              name: 'CHILD_D_03',
              inputType: 'number',
              title: 'Adolescente [14 a 17 años]'
            },
            {
              name: 'CHILD_D_04',
              inputType: 'number',
              title: 'Adulto [18 o más años]'
            }
          ]
        }
      ]
    },
    {
      name: 'SOCIO_DEMO_03',
      elements: [
        {
          type: 'radiogroup',
          name: 'STUDY_03',
          title: '¿Cuál fue el último grado de estudios que usted cursó?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Nunca cursé educación formal '
            },
            {
              value: '2',
              text:
                'Educación Básica incompleta | Primaria o Preparatoria incompleta '
            },
            {
              value: '3',
              text: 'Educación Básica | Primaria o Preparatoria '
            },
            {
              value: '4',
              text:
                'Educación Media Científico-Humanista incompleta | Humanidades  incompleta '
            },
            {
              value: '5',
              text: 'Educación Media Científico-Humanista | Humanidades '
            },
            {
              value: '6',
              text:
                'Educación Media Técnica Profesional incompleta | Técnica Comercial. Industrial o Normalista incompleta '
            },
            {
              value: '7',
              text:
                'Educación Media Técnica Profesional | Técnica Comercial. Industrial o Normalista '
            },
            {
              value: '8',
              text: 'Técnico Nivel Superior (carreras 1 a 3 años) incompleta '
            },
            {
              value: '9',
              text: 'Técnico Nivel Superior (carreras 1 a 3 años) '
            },
            {
              value: '10',
              text: 'Profesional (carreras de 4 o más años) incompleta '
            },
            {
              value: '11',
              text: 'Profesional (carreras de 4 o más años) '
            },
            {
              value: '12',
              text: 'Magíster incompleto '
            },
            {
              value: '13',
              text: 'Magister '
            },
            {
              value: '14',
              text: 'Doctorado incompleto '
            },
            {
              value: '15',
              text: 'Doctorado '
            }
          ]
        }
      ]
    },
    {
      name: 'SOCIO_DEMO_04',
      // title: '¿Qué estudió y en qué institución?',
      isRequired: true,
      elements: [
        {
          type: 'text',
          name: 'STUDY_04',
          title: '¿Cuál es su profesión? ¿A que se dedica?',
          description: 'Ingrese su ocupación actual'
        }
      ]
    }
    // {
    //   name: 'SOCIO_DEMO_04',
    //   title: '¿Qué estudió y en qué institución?',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_04',
    //       title:
    //         'Indique todas las carreras y la institución en la que estudió cada una. ',
    //       description:
    //         'Ejemplo: Técnico en turismo, INACAP; Técnico en Gastronomía, IP'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_05',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_05',
    //       title:
    //         '¿Cuál es su título de Técnico? (Si tiene más de uno, indique todos los obtenidos)'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_06',
    //   title: '¿Qué estudió en Pregrado y en qué institución?',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_06',
    //       title:
    //         'Indique todas las carreras y la institución en la que estudió cada una',
    //       description: 'Ejemplo: Ingeniería Comercial, UChile; Derecho, UC'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_07',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_07',
    //       title:
    //         '¿Cuál es su título de Pregrado? (Si tiene más de uno, indique todos los obtenidos)'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_08',
    //   title: '¿Qué Magíster estudió y en qué Institución?',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_08',
    //       title:
    //         'Indique todas las carreras y la institución en la que estudió cada una',
    //       description: 'Ejemplo: MBA, UChile; Microeconomía Aplicada, UC'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_09',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_09',
    //       title:
    //         '¿Cuál es su Grado de Magister? (Si tiene más de uno, indique todos los obtenidos)'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_10',
    //   title: '¿Qué Doctorado estudió y en qué Institución?',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_10',
    //       title:
    //         'Indique todas las carreras y la institución en la que estudió cada una.',
    //       description:
    //         'Ejemplo: Doc en Administración de Negocios, UChile; Doc en Ciencias de la Administración, USACH'
    //     }
    //   ]
    // },
    // {
    //   name: 'SOCIO_DEMO_11',
    //   elements: [
    //     {
    //       type: 'text',
    //       name: 'STUDY_11',
    //       title:
    //         '¿Cuál es su Grado de Doctor? (Si tiene más de uno, indique todos los obtenidos)'
    //     }
    //   ]
    // }
  ]
};

export default SocioDemographics;
