import React from 'react';
import Survey, { CssNames } from '../../Survey';
import PolarSelectSurvey from './polar_select';
import PolarSelectWrapper from './polar_select.styles';

class PolarSelectTest extends React.Component {
  _times = {};

  onComplete = async (survey, options) => {
    const { data } = survey;
    const { onComplete } = this.props;
    console.log({ data, times: this._times });
    const parsed = {
      ...data,
      ...this._times
    };
    await onComplete(parsed);
  };

  onValidate = async survey => {
    const { currentPage } = survey;
    const { questions } = currentPage;
    // const currentQuestion = questions.length > 2 ? questions[2] : questions[1];
    const currentQuestion = questions[0];
    const { name } = currentQuestion;
    const qstName = `${name}_TIME`;
    const qstTime = currentPage.timeSpent;
    console.log({ qstName, qstTime });

    this._times = { ...this._times, [qstName]: qstTime };
  };

  render() {
    const PolarSelectModel = new Survey.Model(PolarSelectSurvey);
    PolarSelectModel.startTimer();
    return (
      <PolarSelectWrapper>
        <div className="uai_header">
          <img
            className="uai-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png"
            alt="uai-logo"
          />
        </div>
        <Survey.Survey
          model={PolarSelectModel}
          css={CssNames}
          onComplete={this.onComplete}
          onValidatedErrorsOnCurrentPage={this.onValidate}
        />
        <div className="Footer">
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className="PushStickyFooter"></div>
        <div className="Plug">
          <a
            href="http://www.uaimetrics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UAI Metrics
          </a>
        </div>
      </PolarSelectWrapper>
    );
  }
}

export default PolarSelectTest;
