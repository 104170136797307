const ratingValues = [
  {
    value: '1',
    text: 'Para nada'
  },
  {
    value: '2',
    text: 'Un poco'
  },
  {
    value: '3',
    text: 'Medianamente'
  },
  {
    value: '4',
    text: 'Bastante'
  },
  {
    value: '5',
    text: 'Totalmente'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_Header',
  html: `
  <div class="">
    <span style="font-size:16px;">
      Por favor indica en qué medida esta afirmación te representa:
    </span>
    <br />
    <br />
  </div>
  `
};

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'EE_PAGE_00_T',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
  <div class="QuestionText BorderColor">
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        <h3>Instrucciones</h3>
      </span>
      <br />
      A continuación te presentamos una serie de afirmaciones respecto de la forma en que estudiantes pueden enfrentar su proceso de estudio. Para cada afirmación, indica si consideras que es algo que te representa totalmente (es decir, es algo que generalmente tu haces o piensas) o no te representa para nada (es decir, el algo que no haces para nada, o no crees para nada), utilizando la siguiente escala:
      <br />
      <br />
      <div class="qstn-likert-label top">
        <div>No me representa para nada</div>
      </div>
      <div class="btn-group">
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="EE_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>Para nada</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="EE_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>Un poco</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="EE_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>Medianamente</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="EE_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>Bastante</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="EE_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>Totalmente</span></span></label>
      </div>
      <div class="qstn-likert-label bottom">
        <div>Me representa totalmente</div>
      </div>
    </span>
  </div>
`
        }
      ]
    }
  ]
};

const ITEMS = [
  {
    code: 'EP_MA_1',
    statement:
      'Al estudiar, mi meta es poder comprender por mi mismo/a lo que tenemos que aprender'
  },
  {
    code: 'ES_ES_1',
    statement:
      'Cuando estudio para una prueba, trato de memorizar todo, aunque no lo entienda bien'
  },
  {
    code: 'EL_ML_1',
    statement:
      'Cuando estudio, mi meta principal es poder estar entre las notas más altas del curso'
  },
  {
    code: 'EP_AP_1',
    statement:
      'Cuando estudio, trato de relacionar los nuevos contenidos con cosas que ya he estudiado antes'
  },
  {
    code: 'ES_MS_1',
    statement:
      'Al estudiar, mi meta es saber solo lo que entra en la prueba, sin gastar más tiempo del necesario'
  },
  {
    code: 'EL_EL_1',
    statement:
      'Me gusta tener mi lugar de estudio ordenado y con lo que necesito a mano, antes de ponerme a estudiar'
  },
  {
    code: 'EP_MA_2',
    statement:
      'Me gusta averiguar más sobre un tema interesante que hemos visto en clases'
  },
  {
    code: 'ES_ES_2',
    statement:
      'Me preocupo de averiguar exactamente lo que va a entrar y lo que no va a entrar en una prueba antes de estudiar'
  },
  {
    code: 'EL_ML_2',
    statement:
      'Para mi es muy importante demostrar que soy de los/las mejores estudiantes de mi curso'
  },
  {
    code: 'EP_AP_2',
    statement:
      'Cuando me cuesta entender algo en clases, me preocupo de buscar más información por mi mismo/a'
  },
  {
    code: 'ES_MS_2',
    statement:
      'Aunque me esfuerce mucho en los estudios, siempre estoy pensando que me voy a sacar una mala nota'
  },
  {
    code: 'EL_EL_2',
    statement:
      'Normalmente organizo con anticipación el tiempo que dedicaré al estudio y trabajos del colegio '
  },
  {
    code: 'EP_MA_3',
    statement:
      'Me pone muy contento/a cuando logro aprender algo nuevo en clases'
  },
  {
    code: 'ES_ES_3',
    statement:
      'Creo que es mejor aprenderse algunas cosas de memoria más que tratar de entender toda la materia'
  },
  {
    code: 'EL_ML_3',
    statement:
      'Me esfuerzo al máximo para destacar en los estudios, aunque no me guste el tema que tengo que estudiar'
  },
  {
    code: 'EP_AP_3',
    statement:
      'Generalmente para estudiar hago resúmenes o mapas conceptuales para ordenar mis ideas'
  },
  {
    code: 'ES_MS_3',
    statement:
      'Mi objetivo es  tener un buen promedio, más que entender o profundizar lo que estudiamos en clases'
  },
  {
    code: 'EL_EL_3',
    statement:
      'Hago las tareas o trabajo en cuanto son asignados, para no atrasarme'
  },
  {
    code: 'EP_MA_4',
    statement: 'Me encanta aprender sobre muchos temas distintos'
  },
  {
    code: 'ES_ES_4',
    statement:
      'Prefiero los trabajos en que me dicen exactamente qué hacer y cómo se evaluará'
  },
  {
    code: 'EL_ML_4',
    statement:
      'El sacar una buena nota me confirma que soy una persona capaz de lograr lo que quiero'
  },
  {
    code: 'EP_AP_4',
    statement:
      'Cuando estoy estudiando un tema, busco más información que me pueda ayudar a entender mejor'
  },
  {
    code: 'ES_MS_4',
    statement:
      'No tiene sentido para mi buscar más información sobre un tema que aprendimos en el colegio, sino me van a preguntar sobre ese tema'
  },
  {
    code: 'EL_EL_4',
    statement:
      'Me resulta fácil organizar mis tiempos cuando tengo que hacer más de un trabajo o preparar pruebas'
  },
  {
    code: 'EP_MA_5',
    statement:
      'Cuando estudio, me siento muy satisfecho/a cuando consigo entender claramente la materia'
  },
  {
    code: 'ES_ES_5',
    statement:
      'Cuando tengo que presentar, trato de memorizar todo lo que voy a decir, aunque no tenga tan claras algunas ideas'
  },
  {
    code: 'EL_ML_5',
    statement:
      'Me gusta cuando se publican los resultados de las notas, y aparezco de los/las primeros/as de la lista'
  },
  {
    code: 'EP_AP_5',
    statement:
      'Cuando estoy leyendo un texto de estudio, me gusta subrayar o tomar apuntes sobre las ideas que me surgen'
  },
  {
    code: 'ES_MS_5',
    statement: 'Cuando estudio o hago un trabajo, mi meta es no sacarme un rojo'
  },
  {
    code: 'EL_EL_5',
    statement:
      'Antes de estudiar, preparo mi lugar de estudio para asegurar que me podré concentrar'
  },
  {
    code: 'EP_MA_6',
    statement:
      'Me entretiene mucho buscar por mi  mismo/a información sobre temas que encuentro interesantes'
  },
  {
    code: 'ES_ES_6',
    statement:
      'Para mi, la mejor forma de estudiar es repetir lo que me enseñan muchas veces, hasta que logro saberlo de memoria'
  },
  {
    code: 'EL_ML_6',
    statement:
      'Me gusta esforzarme en los estudios para poder estar entre los primeros/as del curso'
  },
  {
    code: 'EP_AP_6',
    statement:
      'Cuando aprendemos algo nuevo en clases, trato de relacionarlo con lo que hemos aprendido en otros cursos o en años anteriores'
  },
  {
    code: 'ES_MS_6',
    statement:
      'Prefiero dedicar el mínimo de tiempo al estudio y solo asegurarme de que manejo bien lo que va a entrar en una prueba'
  },
  {
    code: 'EL_EL_6',
    statement:
      'Generalmente logro cumplir con los horarios de estudio que me propongo'
  }
];

const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: ITEMS.map(item => {
    const { code, statement } = item;
    return {
      name: code,
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: code,
          title: statement,
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa totalmente',
          rateValues: ratingValues
        }
      ]
    };
  })
};

export default baseInfo;
