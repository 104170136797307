const LearningAgility = {
  locale: 'es',
  title: 'Características de personalidad',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'BIG_FIVE_01',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>

          </div>
        `
        },
        {
          type: 'matrix',
          name: 'BIG5_PAGE_01',
          title: `Yo soy una persona que:`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'Ligeramente en desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'Ligeramente de acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'BIG5_01',
              text: 'Es sociable, le gusta conocer a otras personas'
            },
            {
              value: 'BIG5_02',
              text: 'Es compasivo(a), bondadoso'
            },
            {
              value: 'BIG5_03',
              text: 'Tiende a ser desorganizado(a)'
            },
            {
              value: 'BIG5_04',
              text: 'Es relajado(a), maneja bien el estrés'
            },
            {
              value: 'BIG5_05',
              text: 'Tiene pocos intereses artísticos'
            },
            {
              value: 'BIG5_06',
              text: 'Tiene una personalidad dominante'
            },
            {
              value: 'BIG5_07',
              text: 'Es considerado(a), trata a las demás personas con respeto'
            },
            {
              value: 'BIG5_08',
              text: 'Con frecuencia siente flojera'
            },
            {
              value: 'BIG5_09',
              text: 'Se mantiene optimista después de tener un contratiempo'
            },
            {
              value: 'BIG5_10',
              text: 'Siente curiosidad por diferentes temas'
            }
          ]
        }
      ]
    },
    {
      name: 'BIG_FIVE_02',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>

          </div>
        `
        },
        {
          type: 'matrix',
          name: 'BIG5_PAGE_02',
          title: `Yo soy una persona que:`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'Ligeramente en desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'Ligeramente de acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'BIG5_11',
              text: 'Raramente se siente entusiasmado(a)'
            },
            {
              value: 'BIG5_12',
              text: 'Tiende a criticar a los demás'
            },
            {
              value: 'BIG5_13',
              text: 'Es confiable'
            },
            {
              value: 'BIG5_14',
              text: 'Es temperamental, de humor cambiante'
            },
            {
              value: 'BIG5_15',
              text:
                'Es ingenioso(a), encuentra formas diferentes de hacer las cosas'
            },
            {
              value: 'BIG5_16',
              text: 'Tiende a ser callado(a)'
            },
            {
              value: 'BIG5_17',
              text: 'Se interesa poco por las otras personas'
            },
            {
              value: 'BIG5_18',
              text: 'Es organizado(a), le gusta mantener las cosas en orden'
            },
            {
              value: 'BIG5_19',
              text: 'Con frecuencia se pone tenso(a)'
            },
            {
              value: 'BIG5_20',
              text: 'Le gusta mucho el arte, la música, o la literatura'
            }
          ]
        }
      ]
    },
    {
      name: 'BIG_FIVE_03',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>

          </div>
        `
        },
        {
          type: 'matrix',
          name: 'BIG5_PAGE_03',
          title: `Yo soy una persona que:`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'Ligeramente en desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'Ligeramente de acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'BIG5_21',
              text: 'Es influyente, actúa como un líder'
            },
            {
              value: 'BIG5_22',
              text: 'Con frecuencia inicia discusiones con otras personas'
            },
            {
              value: 'BIG5_23',
              text: 'Le cuesta empezar a hacer una tarea'
            },
            {
              value: 'BIG5_24',
              text: 'Se siente satisfecho(a) consigo mismo(a)'
            },
            {
              value: 'BIG5_25',
              text: 'Evita las discusiones intelectuales o filosóficas'
            },
            {
              value: 'BIG5_26',
              text: 'No es tan activo(a) como otras personas'
            },
            {
              value: 'BIG5_27',
              text: 'Le es fácil perdonar a otras personas'
            },
            {
              value: 'BIG5_28',
              text: 'A veces puede ser descuidado(a) '
            },
            {
              value: 'BIG5_29',
              text: 'Es estable emocionalmente, no se molesta con facilidad '
            },
            {
              value: 'BIG5_30',
              text: 'Tiene poca creatividad'
            }
          ]
        }
      ]
    },
    {
      name: 'BIG_FIVE_04',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>

          </div>
        `
        },
        {
          type: 'matrix',
          name: 'BIG5_PAGE_04',
          title: `Yo soy una persona que:`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'Ligeramente en desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'Ligeramente de acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'BIG5_31',
              text: 'A veces es tímido(a), introvertido(a) '
            },
            {
              value: 'BIG5_32',
              text: 'Es generoso(a) y ayuda a los demás '
            },
            {
              value: 'BIG5_33',
              text: 'Mantiene las cosas limpias y ordenadas '
            },
            {
              value: 'BIG5_34',
              text: 'Se estresa mucho por las cosas '
            },
            {
              value: 'BIG5_35',
              text: 'Valora el arte y la belleza '
            },
            {
              value: 'BIG5_36',
              text: 'Encuentra difícil influenciar a otras personas '
            },
            {
              value: 'BIG5_37',
              text: 'A veces es maleducado(a) con los demás '
            },
            {
              value: 'BIG5_38',
              text: 'Es eficiente, termina las cosas '
            },
            {
              value: 'BIG5_39',
              text: 'A menudo se siente triste '
            },
            {
              value: 'BIG5_40',
              text: 'Es un pensador profundo '
            }
          ]
        }
      ]
    },
    {
      name: 'BIG_FIVE_05',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>

          </div>
        `
        },
        {
          type: 'matrix',
          name: 'BIG5_PAGE_05',
          title: `Yo soy una persona que:`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'Ligeramente en desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'Ligeramente de acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'BIG5_41',
              text: 'Es una persona llena de energía '
            },
            {
              value: 'BIG5_42',
              text: 'Es desconfiado(a) de las intenciones de otros '
            },
            {
              value: 'BIG5_43',
              text: 'Es responsable '
            },
            {
              value: 'BIG5_44',
              text: 'Mantiene sus emociones bajo control '
            },
            {
              value: 'BIG5_45',
              text: 'Tiene poca imaginación '
            },
            {
              value: 'BIG5_46',
              text: 'Es bien hablador(a)'
            },
            {
              value: 'BIG5_47',
              text: 'Puede ser frío(a) y distante '
            },
            {
              value: 'BIG5_48',
              text: 'Es desordenado(a) '
            },
            {
              value: 'BIG5_49',
              text: 'Raramente se siente ansioso(a) '
            },
            {
              value: 'BIG5_50',
              text: 'Cree que la poesía y el teatro son aburridos '
            }
          ]
        }
      ]
    },
    {
      name: 'BIG_FIVE_06',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>

          </div>
        `
        },
        {
          type: 'matrix',
          name: 'BIG5_PAGE_06',
          title: `Yo soy una persona que:`,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'Ligeramente en desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'Ligeramente de acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'BIG5_51',
              text: 'Prefiere que otros tomen la iniciativa '
            },
            {
              value: 'BIG5_52',
              text: 'Es respetuoso(a), cortés con las demás personas '
            },
            {
              value: 'BIG5_53',
              text:
                'Es persistente, trabaja hasta que la tarea esté finalizada '
            },
            {
              value: 'BIG5_54',
              text: 'Tiende a sentirse deprimido, triste '
            },
            {
              value: 'BIG5_55',
              text: 'Tiene poco interés en las ideas abstractas '
            },
            {
              value: 'BIG5_56',
              text: 'Es una persona muy entusiasta '
            },
            {
              value: 'BIG5_57',
              text: 'Cree que las personas tienen buenas intenciones  '
            },
            {
              value: 'BIG5_58',
              text: 'A veces se comporta irresponsablemente '
            },
            {
              value: 'BIG5_59',
              text: 'Es temperamental, se irrita fácilmente '
            },
            {
              value: 'BIG5_60',
              text: 'Es original, se le ocurren nuevas ideas '
            }
          ]
        }
      ]
    }
  ]
};

export default LearningAgility;
