import React, { createContext, Component } from 'react';

const { Provider, Consumer } = createContext({
  values: [],
  currentIndex: 0
});

export class SimpleValuesProvider extends Component {
  state = {
    values: [],
    currentIndex: 0
  };

  toNextItem = async (data, isFinishedCallback) => {
    const { values, currentIndex } = this.state;
    const { items } = this.props;
    // Get NEW VALUES
    const newValues = [...values, ...data];

    await this.setState(state => ({
      ...state,
      values: newValues,
      currentIndex: state.currentIndex + 1
    }));
    // is not next item, finish and isFinishedCallback
    const nextItemOnArray = items[currentIndex + 1];
    if (!nextItemOnArray) {
      await isFinishedCallback({ values: newValues });
      return this.setState({ currentIndex: 0, values: [] });
    }
  };

  render = () => (
    <Provider
      value={{
        state: this.state,
        toNextItem: this.toNextItem
      }}
    >
      {this.props.children}
    </Provider>
  );
}

export const SimpleValuesConsumer = Consumer;
