import React from 'react';
import PerceivedDiff from './PerceivedDiff';
import postData from '../API';

class PerceivedDiffPage extends React.Component {
  state = {
    CONSENT: localStorage.getItem(`current-${this.props.BATTERY}-CONSENT`)
  };

  onComplete = async data => {
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    const initialData = {
      CONSENT:
        this.state.CONSENT ||
        localStorage.getItem(`current-${this.props.BATTERY}-CONSENT`)
    };
    if (!initialData.CONSENT) {
      delete initialData.CONSENT;
    }
    return (
      <PerceivedDiff onComplete={this.onComplete} initialData={initialData} />
    );
  }
}

export default PerceivedDiffPage;
