import React, { createContext, Component } from 'react';

const { Provider, Consumer } = createContext({
  values: [],
  currentIndex: 0,
  allItems: [],
  allItemsOrdered: [],
  currentItem: []
});

export class ValuesProvider extends Component {
  state = {
    values: [],
    currentIndex: 0,
    allItems: [],
    allItemsOrdered: [],
    currentItem: []
  };

  componentDidMount = async () => {
    const { items, BATTERY, random } = this.props;
    // get current rut or null
    const currentCode =
      localStorage.getItem(`currentCode-${BATTERY}`) || 'null';
    const allItems = items.reduce((acc, current, index) => {
      const options = current.items.map(it => it.options);
      const parsedOptions = options.map(opts =>
        opts.map(option => ({ ...option, id: option.code, value: 0 }))
      );
      const allOptions = [...acc, ...parsedOptions];
      return allOptions;
    }, []);

    // get local index
    const localIndex =
      localStorage.getItem(`itemIndex-${currentCode}-${BATTERY}`) || 0;
    const itemIndex = parseInt(localIndex);
    // get local values
    const valuesKey = `itemValues-${currentCode}-${BATTERY}`;
    const storedValues = localStorage.getItem(valuesKey);
    const localValues = storedValues ? JSON.parse(storedValues) : [];

    // –– RANDOMIZE LIST ITEMS ––
    // get array of indexes
    const indexes = allItems.map((it, index) => index);
    // copy to let var
    let indexesLet = [...indexes];
    // declare shuffle func
    function shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
    // shuffle (mutate) let var
    shuffle(indexesLet);
    // get a clone from mutated pattern
    const innerPattern = [...indexesLet];
    // assign string key
    const patternKey = `itemsPattern-${currentCode}-${BATTERY}`;
    // get previous pattern from localStorage, if any
    const storedPattern = localStorage.getItem(patternKey);
    // if not previous pattern, set new pattern on localStorage
    if (!storedPattern) {
      localStorage.setItem(patternKey, JSON.stringify(innerPattern));
    }
    // get workable pattern, either stored or new
    const localPattern = storedPattern
      ? JSON.parse(storedPattern)
      : innerPattern;
    // FINALLY, use pattern on list items
    const itemsRandomized = localPattern.map(n => allItems[n]);
    // –––– FINISH RANDOM ––––

    await this.setState(state => ({
      ...state,
      values: localValues,
      currentIndex: itemIndex,
      allItems: random ? itemsRandomized : allItems,
      allItemsOrdered: allItems,
      currentItem: random ? itemsRandomized[itemIndex] : allItems[itemIndex]
    }));
  };

  toNextItem = async (data, isFinishedCallback) => {
    const { values, allItems, currentIndex } = this.state;
    const { BATTERY } = this.props;
    const currentCode =
      localStorage.getItem(`currentCode-${BATTERY}`) || 'null';

    // Get NEW VALUES
    const newValues = [...values, ...data];
    // set response index on localStorage
    const indexKey = `itemIndex-${currentCode}-${BATTERY}`;
    localStorage.setItem(indexKey, currentIndex + 1);
    // set values on localStorage
    const valuesKey = `itemValues-${currentCode}-${BATTERY}`;
    localStorage.setItem(valuesKey, JSON.stringify(newValues));

    await this.setState(state => ({
      ...state,
      values: newValues,
      currentIndex: state.currentIndex + 1,
      currentItem: state.allItems[state.currentIndex + 1]
    }));
    // console.log({ values, data, newCurrentIndex: currentIndex });
    // is not next item, finish and isFinishedCallback
    const nextItemOnArray = allItems[currentIndex + 1];
    if (!nextItemOnArray) {
      return isFinishedCallback({ values: newValues });
    }
  };

  getValues = async () => {
    const { values } = this.state;
    return values;
  };

  updateContext = async newContext => {
    await this.setState(state => ({
      ...state,
      ...newContext
    }));
  };

  render = () => (
    <Provider
      value={{
        state: this.state,
        toNextItem: this.toNextItem,
        getValues: this.getValues,
        currentItem: this.state.currentItem,
        updateContext: this.updateContext,
        allItems: this.state.allItems,
        allItemsOrdered: this.state.allItemsOrdered
      }}
    >
      {this.props.children}
    </Provider>
  );
}

export const ValuesConsumer = Consumer;
