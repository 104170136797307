const GoalOrientation = {
  locale: 'es',
  title: 'Goal Orientation',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'GOAL_ORIENTATION_01',
      // title: {
      //   es:
      //     'Las siguientes frases describen cómo las personas se ven a sí mismas en el trabajo.  Por favor señale en qué medida estas frases lo representan a usted.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_GOALO_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                Las siguientes frases describen <strong>cómo las personas se ven a sí mismas en el trabajo</strong>. Por favor señale en qué medida de estas frases le describen a usted usando una escala de 1 (Muy en desacuerdo) a 7 (Muy de acuerdo).
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'GOAL_ORIENTATION_01',
          title:
            'Responda usando una escala de 1 (Muy en desacuerdo) a 7 (Muy de acuerdo)',
          isRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'LGO_01',
              text:
                'La oportunidad de hacer tareas desafiantes es importante para mí'
            },
            {
              value: 'LGO_02',
              text:
                'Cuando fallo al completar una tarea difícil, planeo esforzarme más la próxima vez que trabaje en ella'
            },
            {
              value: 'LGO_03',
              text:
                'Prefiero trabajar en cosas que me fuerzan a aprender nuevas cosas'
            },
            {
              value: 'LGO_04',
              text:
                'La oportunidad de aprender nuevas cosas es importante para mí'
            },
            {
              value: 'LGO_05',
              text: 'Doy lo mejor de mí cuando trabajo en una tarea difícil'
            },
            {
              value: 'LGO_06',
              text: 'Me esfuerzo harto en mejorar mi desempeño pasado'
            },
            {
              value: 'LGO_07',
              text:
                'La oportunidad de expandir mi repertorio de habilidades es importante para mí'
            },
            {
              value: 'LGO_08',
              text:
                'Cuando tengo dificultades resolviendo un problema, disfruto usando distintos enfoques para ver cuál funcionará'
            }
          ]
        }
      ]
    },

    {
      name: 'GOAL_ORIENTATION_02',
      // title: {
      //   es:
      //     'Las siguientes frases describen cómo las personas se ven a sí mismas en el trabajo.  Por favor señale en qué medida estas frases lo representan a usted.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_GOALO_TITLE_2',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                Las siguientes frases describen <strong>cómo las personas se ven a sí mismas en el trabajo</strong>. Por favor señale en qué medida de estas frases le describen a usted usando una escala de 1 (Muy en desacuerdo) a 7 (Muy de acuerdo).
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'GOAL_ORIENTATION_02',
          title:
            'Responda usando una escala de 1 (Muy en desacuerdo) a 7 (Muy de acuerdo)',
          isRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            },
            {
              value: '6',
              text: '6'
            },
            {
              value: '7',
              text: '7'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'LGO_09',
              text:
                'Prefiero hacer cosas que puedo hacer bien que cosas que hago mal'
            },
            {
              value: 'LGO_10',
              text:
                'Soy feliz cuando hago tareas en las que sé que no voy a cometer errores'
            },
            {
              value: 'LGO_11',
              text: 'Las cosas que disfruto más son las cosas que hago mejor'
            },
            {
              value: 'LGO_12',
              text:
                'Las opiniones que otros tienen sobre cuán bien hago ciertas cosas son importantes para mí'
            },
            {
              value: 'LGO_13',
              text: 'Me siento inteligente cuando hago algo sin cometer errores'
            },
            {
              value: 'LGO_14',
              text:
                'Me gusta sentirme bastante seguro de que puedo completar una tarea de manera exitosa antes de empezarla'
            },
            {
              value: 'LGO_15',
              text: 'Me gusta trabajar en tareas que he hecho bien en el pasado'
            },
            {
              value: 'LGO_16',
              text:
                'Me siento inteligente cuando hago algo mejor que la mayoría de la gente'
            }
          ]
        }
      ]
    }
  ]
};

export default GoalOrientation;
