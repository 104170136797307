const rateValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  },
  {
    value: '0',
    text: 'No aplica'
  }
];

const GOAL_TITLE = {
  type: 'html',
  name: 'HTML_GOAL_TITLE',
  html: `<div style="font-size:18px; margin-bottom: 30px;">
    <span>Las siguientes frases describen <strong>cómo las personas se ven a sí mismas en el trabajo</strong>.</span><br />
    <span>Por favor señale en qué medida de estas frases le describen a usted usando una escala de 1 (Muy en desacuerdo) a 7 (Muy de acuerdo).</span>
  </div>`
};

const GoalOrientation = {
  locale: 'es',
  title: 'Goal Orientation',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'LGO_01',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_01',
          title:
            'La oportunidad de hacer tareas desafiantes es importante para mí',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_02',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_02',
          title:
            'Cuando fallo al completar una tarea difícil, planeo esforzarme más la próxima vez que trabaje en ella',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_03',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_03',
          title:
            'Prefiero trabajar en cosas que me fuerzan a aprender nuevas cosas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_04',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_04',
          title:
            'La oportunidad de aprender nuevas cosas es importante para mí',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_05',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_05',
          title: 'Doy lo mejor de mí cuando trabajo en una tarea difícil',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_06',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_06',
          title: 'Me esfuerzo harto en mejorar mi desempeño pasado',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_07',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_07',
          title:
            'La oportunidad de expandir mi repertorio de habilidades es importante para mí',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_08',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_08',
          title:
            'Cuando tengo dificultades resolviendo un problema, disfruto usando distintos enfoques para ver cuál funcionará',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_09',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_09',
          title:
            'Prefiero hacer cosas que puedo hacer bien que cosas que hago mal',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_10',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_10',
          title:
            'Soy feliz cuando hago tareas en las que sé que no voy a cometer errores',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_11',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_11',
          title: 'Las cosas que disfruto más son las cosas que hago mejor',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_12',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_12',
          title:
            'Las opiniones que otros tienen sobre cuán bien hago ciertas cosas son importantes para mí',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_13',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_13',
          title: 'Me siento inteligente cuando hago algo sin cometer errores',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_14',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_14',
          title:
            'Me gusta sentirme bastante seguro de que puedo completar una tarea de manera exitosa antes de empezarla',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_15',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_15',
          title: 'Me gusta trabajar en tareas que he hecho bien en el pasado',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LGO_16',
      title: '',
      elements: [
        GOAL_TITLE,
        {
          type: 'rating',
          name: 'LGO_16',
          title:
            'Me siento inteligente cuando hago algo mejor que la mayoría de la gente',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    }
  ]
};

export default GoalOrientation;
