import React, { useState, useEffect } from 'react';
import Survey, { CssNames } from '../../../Survey';
import DemographicsSurvey from './demographics';
import DemographicsWrapper, { FixedHeadingBar } from './demographics.styles';
import showdown from 'showdown';

const TopBarScale = () => {
  const [visible, setVisible] = useState(false);
  const handler = event => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    }
    if (window.pageYOffset < 300) {
      setVisible(false);
    }
  };
  useEffect(() => {
    const event = 'scroll';
    // initiate the event handler
    window.addEventListener(event, handler);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handler);
    };
  });

  if (visible) {
    return (
      <FixedHeadingBar>
        <div className='row'>
          <div className='col'></div>
          <div className='scale'>
            {/* <div className='label'>No me representa para nada</div> */}
            <div className='value'>
              {/* <span className='label left'>No me representa para nada</span> */}
              1
            </div>
            <div className='value'>2</div>
            <div className='value'>3</div>
            <div className='value'>4</div>
            <div className='value'>5</div>
            <div className='value'>6</div>
            <div className='value'>
              {/* <span className='label right'>Me representa muy bien</span> */}
              7
            </div>
            {/* <div className='label'>Me representa muy bien</div> */}
          </div>
        </div>
      </FixedHeadingBar>
    );
  }
  return null;
};
class Demographics extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete
  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { onComplete } = this.props;

    const { data } = survey;
    const { IG_MATRIX } = data;
    const parsed = {
      ...data,
      ...IG_MATRIX
    };

    delete parsed.IG_MATRIX;

    await onComplete(parsed);
  };

  render() {
    const DemographicsModel = new Survey.Model(DemographicsSurvey);
    // setting starting data
    DemographicsModel.data = this.props.initialData || {};

    //Create showdown markdown converter
    var converter = new showdown.Converter();
    DemographicsModel.onTextMarkdown.add((survey, options) => {
      //convert the mardown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });

    return (
      <DemographicsWrapper>
        <div className='uai_header'>
          {/* <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          /> */}
        </div>
        <Survey.Survey
          model={DemographicsModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        {/* <div className='Footer'>

        </div> */}
        {/* <div className='PushStickyFooter'></div> */}
        <TopBarScale />
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </DemographicsWrapper>
    );
  }
}

export default Demographics;
