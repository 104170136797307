// import axios from 'axios';
// import { USER_ID } from '../config';

const postData = (values, USER_ID) => {
  // const POST_API =
  //   'https://3f3ujycj0a.execute-api.us-west-2.amazonaws.com/dev/submit';
  const config = { SHEET_ID: '1aGT-BgqWHfmdu0X4CrZyJSelu8doQOlvvtvJUrs2IE4' };
  const caseid = USER_ID;
  const data = { ...values, caseid, config };
  console.log({ data });
  // return axios.post(`${POST_API}`, data);
};

export default postData;
