import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  DroppableListWrapper,
  ListItemWrapper,
  DropZone
} from './sortableList.style';

const Item = ({ provided, snapshot, content }) => {
  return (
    <ListItemWrapper
      ref={ref => provided.innerRef(ref)}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style }}
      isDragging={snapshot.isDragging}
    >
      {content}
    </ListItemWrapper>
  );
};

class DraggableItemList extends React.Component {
  render() {
    const { id, items, listType, isCombineEnabled } = this.props;
    return (
      <Droppable
        droppableId={id}
        type={listType}
        isCombineEnabled={isCombineEnabled}
      >
        {(provided, snapshot) => (
          <DroppableListWrapper
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            <DropZone ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <Item
                      content={item.statement}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </DropZone>
          </DroppableListWrapper>
        )}
      </Droppable>
    );
  }
}

export default DraggableItemList;
