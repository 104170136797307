import React, { useState, useEffect, useRef } from 'react';
import Inputmask from 'inputmask';
import EquisOneLogo from '../img/equis_1_logo.png';
import {
  Form,
  FormInput,
  Row,
  Col,
  SchoolInfoWrapper,
  Footer,
  ErrorMessage
} from './login.styles';

const LoginBox = props => {
  const { error, updateContext, BATTERY } = props;
  const [school, setSchool] = useState(
    props.school || 'Universidad Adolfo Ibanez'
  );
  const [editable, setEditable] = useState(false);
  const [rut, setRut] = useState('');
  const inputEl = useRef(null);

  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    Inputmask({ mask: '99.999.999-9', numericInput: true }).mask(inputEl);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    setEditable(false);
    // console.log('submitting', rut);

    const cleanRut = rut.replace(/_/g, ''); // clean "_" chars from rut.
    // console.log({ school, rut, cleanRut });
    const CURRENT_CODE = `currentCode-${BATTERY}`;
    const CURRENT_SCHOOL = `currentSchool-${BATTERY}`;
    localStorage.setItem(CURRENT_CODE, cleanRut);
    localStorage.setItem(CURRENT_SCHOOL, school);
    await updateContext({
      rut: cleanRut,
      school: school
    });
    window.location.reload();
  };

  return (
    <Row>
      <Col>
        <div className='logo-wrapper'>
          <img src={EquisOneLogo} alt='equis1 logo' />
        </div>
        <br />
        <div className='equis-info'>
          <p>
            Bienvenido a <strong>Equis One</strong>. Ingresa tu rut a
            continuación.
          </p>
          <p>
            Esta información solo se utilizará para guardar los avances de esta
            encuesta en este dispositivo o computadora, y es requisito para
            poder registrar cada una de tus respuestas.
          </p>
          <p>
            En caso de necesitarlo, puedes retomar tus avances volviendo a
            introducir tu RUT en esta encuesta.
          </p>
          <p>
            Si tienes alguna pregunta o deseas ponerte en contacto con nosotros,
            puedes contactar a UAI METRICS al email{' '}
            <a href={`mailto:uaimetrics@uai.cl`}>uaimetrics@uai.cl</a>.
          </p>
        </div>
      </Col>
      <Col>
        <Form onSubmit={onSubmit}>
          <FormInput>
            {editable ? (
              <React.Fragment>
                <label htmlFor='colegio'>Colegio</label>
                <input
                  name='colegio'
                  value={school}
                  onChange={e => setSchool(e.target.value)}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label htmlFor='colegio'>Colegio</label>
                <SchoolInfoWrapper>
                  <div>{school}</div>
                  <button type='button' onClick={() => setEditable(!editable)}>
                    No es correcto?
                  </button>
                </SchoolInfoWrapper>
              </React.Fragment>
            )}
          </FormInput>
          <FormInput>
            <label htmlFor='rutid'>Ingresa tu Rut</label>
            <input
              ref={inputEl}
              name='rutid'
              value={rut}
              onChange={e => setRut(e.target.value)}
            />
            <p>
              <strong>Importante: </strong>
              Ingresa tu RUT <u>sin puntos ni guión</u>. <br />
              En caso que termine en <em>K</em> reemplázalo por un <em>0</em>.
            </p>
          </FormInput>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Footer>
            <button type='submit'>Ingresar</button>
          </Footer>
        </Form>
      </Col>
      <div>
        <div className='bl' />
        <div className='tr' />
      </div>
    </Row>
  );
};
export default LoginBox;
