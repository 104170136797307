import styled, { css } from 'styled-components';
import TopLeftImg from './imgs/top-left.png';
import TopRightImg from './imgs/top-right.png';
import BottomLeftImg from './imgs/bottom-left.png';
import BottomRightImg from './imgs/bottom-right.png';

const VocationalWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  @media (max-width: 574px) {
    ${({ withAuth }) =>
      withAuth &&
      css`
        padding-bottom: 100px;
      `}
  }
  .tl {
    background-image: url("${TopLeftImg}");
    position: absolute;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: -1;
    height: 80%;
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;
    @media (max-width: 1440px) {
      height: 90%;
      width: 50%;
    }
    @media (max-width: 1224px) {
      height: 100%;
      width: 50%;
    }
    @media (max-width: 991px) {
      height: 100%;
      width: 50%;
    }
    @media (max-width: 574px) {
      background-image: none;
    }
  }
  .tr {
    background-image: url(${TopRightImg});
    position: absolute;
    opacity: 0.5;
    top: 0;
    right: 0;
    z-index: -1;
    height: 60%;
    width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    @media (max-width: 1440px) {
      height: 50%;
      width: 70%;
    }
    @media (max-width: 1224px) {
      height: 50%;
      width: 80%;
    }
    @media (max-width: 991px) {
      height: 40%;
      width: 80%;
    }
    @media (max-width: 774px) {
      height: 30%;
      width: 70%;
    }
    ${'' /* @media (max-width: 574px) {
      background-image: none;
    } */}
  }
  .bl {
    background-image: url(${BottomLeftImg});
    position: absolute;
    opacity: 0.5;
    ${'' /* bottom: -10px; */}
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 70%;
    width: 30%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    @media (max-width: 1440px) {
      height: 70%;
      width: 30%;
    }
    @media (max-width: 1224px) {
      height: 80%;
      width: 40%;
    }
    @media (max-width: 991px) {
      height: 100%;
      width: 50%;
    }
    ${'' /* @media (max-width: 574px) {
      background-image: none;
    } */}
  }
  .br {
    background-image: url("${BottomRightImg}");
    position: absolute;
    opacity: 0.5;
    bottom: 0;
    right: 0;
    z-index: -1;
    height: 60%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    @media (max-width: 1440px) {
      height: 60%;
      width: 100%;
    }
    @media (max-width: 1224px) {
      height: 60%;
      width: 100%;
    }
    @media (max-width: 991px) {
      height: 60%;
      width: 100%;
    }
    @media (max-width: 574px) {
      background-image: none;
    }
  }
.${({ theme }) => theme.baseName}_main {
    ${({ withAuth }) =>
      withAuth &&
      css`
        padding-bottom: 100px;
      `}
  }
`;

export default VocationalWrapper;
