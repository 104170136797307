import React from 'react';
import Presentation from './Presentation';
import SCCI from './SCCI';
import SCCIINTRO from './SCCI_INTRO';
import DCareerBelief from './CreenciasErroneasCarrera';
import DificultadInfo from './DificultadInfo';
import IndecisionGen from './IndecisionGeneral';
import SortableIntro from './Sortable_Intro';
import SortablePolarList from './SortablePolarList';
import SortablePolarDimension from './SortablePolarDimension';
import FinishedMessage from './FinishedMessage/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../../config';
import VocationalWrapper from './votacional.style';
import getWinningDimensions from './helpers';

class VocationalTest extends React.Component {
  state = {
    // sortable second stage
    error: '',
    firstDimension: '',
    secondDimension: ''
    // firstDimension: 'LM',
    // secondDimension: 'IP'
  };

  componentDidMount = async () => {
    // const { BATTERY, updateContext } = this.props;
    const { BATTERY, updateContext, rut } = this.props;
    // const rut = localStorage.getItem(`currentCode-${BATTERY}`);
    const firstDimFallBack = 'IP';
    const secondDimFallBack = 'LM';
    const firstDimension =
      localStorage.getItem(`FirstDim-${BATTERY}-${rut}`) || firstDimFallBack;
    const secondDimension =
      localStorage.getItem(`SecondDim-${BATTERY}-${rut}`) || secondDimFallBack;

    // set prev dimensions
    await this.setState({ firstDimension, secondDimension });
    // await this.setState({ firstDimension: 'IP', secondDimension: 'LM' });
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onSortableComplete = async data => {
    const caseId = this.props.state.user;
    // const { BATTERY, user, rut = 'anon' } = this.props;
    const {
      BATTERY,
      rut = 'anon',
      school = 'Universidad Adolfo Ibanez'
    } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    // await postData(data, user, rut);
    try {
      await postData(data, caseId, rut, school);
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }

    // set winning dimensions on storage stage
    const { first, second } = getWinningDimensions(data);
    localStorage.setItem(`FirstDim-${BATTERY}-${rut}`, first);
    localStorage.setItem(`SecondDim-${BATTERY}-${rut}`, second);
    // set new conditional stages
    await this.setState({ firstDimension: first, secondDimension: second });
    // await this.setState({ firstDimension: 'IP', secondDimension: 'LM' });
    return this.props.toNextStage(stageName);
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const {
      BATTERY,
      stage,
      rut = 'anon',
      school = 'Universidad Adolfo Ibanez'
    } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    // await postData(data, user, rut);
    try {
      await postData(data, caseId, rut, school);
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    if (data.CONSENT === '2') {
      console.log('NO CONSENT');
      this.props.toNextStage(stageName);
    }
    if (stage < 10) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, BATTERY, user, rut, school } = this.props;
    // const { BATTERY, user, rut } = this.props;
    // const { BATTERY } = this.props;
    // const stage = 9;
    const { firstDimension, secondDimension, error } = this.state;
    // console.log({ stage, firstDimension, secondDimension });
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (stage > 9) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='UAI METRICS'
          email='uaimetrics@uai.cl'
        />
      );
    }

    if (stage === 9) {
      return (
        <IndecisionGen
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          isLast
        />
      );
    }
    if (stage === 8) {
      return (
        <DificultadInfo
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
        />
      );
    }
    if (stage === 7) {
      return (
        <DCareerBelief
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          SEX={localStorage.getItem(`current-${rut}-SEX`) || '1'}
        />
      );
    }
    if (stage === 6) {
      return <SCCI onComplete={this.onComplete} BATTERY={BATTERY} rut={rut} />;
    }
    if (stage === 5) {
      return (
        <SCCIINTRO onComplete={this.onComplete} BATTERY={BATTERY} rut={rut} />
      );
    }
    if (stage === 4) {
      return (
        <SortablePolarDimension
          dimension={secondDimension}
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          user={user}
          rut={rut}
          label='SECOND'
        />
      );
    }
    if (stage === 3) {
      return (
        <SortablePolarDimension
          dimension={firstDimension}
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          user={user}
          rut={rut}
          label='FIRST'
        />
      );
    }
    if (stage === 2) {
      return (
        <SortablePolarList
          onComplete={this.onSortableComplete}
          BATTERY={BATTERY}
          user={user}
          rut={rut}
        />
      );
    }
    if (stage === 1) {
      return (
        <SortableIntro
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
        />
      );
    }
    return (
      <Presentation
        onComplete={this.onComplete}
        BATTERY={BATTERY}
        rut={rut}
        user={user}
        school={school}
      />
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <VocationalTest {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};
export default WithWrapper;
