import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import PlanEstrategicoSurvey from './plan_estrategico';
import PlanEstrategicoWrapper from './planEstrategico.styles';
// import showdown from 'showdown';
// const converter = new showdown.Converter();

class PlanEstrategico extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    // console.log('Survey results: ');
    const { data } = survey;
    const { onComplete } = this.props;
    const {
      // EJE_PAGE_01,
      // EJE_PAGE_02,
      // EJE_PAGE_03,
      EJE_PAGE_04,
      EJE_PAGE_05,
      EJE_PAGE_06,
      // EJE_PEDAG_COMMENT,
      // EJE_COMUN_COMMENT,
      // EJE_GEST_COMMENT,
      KNOW_PLAN,
      KNOW_PEDAG,
      KNOW_COMUN,
      KNOW_GEST
    } = data;
    const parsed = {
      // ...EJE_PAGE_01,
      // ...EJE_PAGE_02,
      // ...EJE_PAGE_03,
      ...EJE_PAGE_04,
      ...EJE_PAGE_05,
      ...EJE_PAGE_06,
      // EJE_PEDAG_COMMENT,
      // EJE_COMUN_COMMENT,
      // EJE_GEST_COMMENT,
      KNOW_PLAN,
      KNOW_PEDAG,
      KNOW_COMUN,
      KNOW_GEST
    };
    await onComplete(parsed);
  };

  render() {
    const PlanEstrategicoModel = new Survey.Model(PlanEstrategicoSurvey);
    // //Create showdown mardown converter
    // LearningAgilityModel.onTextMarkdown.add((survey, options) => {
    //   //convert the mardown text to html
    //   var str = converter.makeHtml(options.text);
    //   //remove root paragraphs <p></p>
    //   str = str.substring(3);
    //   str = str.substring(0, str.length - 4);
    //   //set html
    //   options.html = str;
    // });
    return (
      <PlanEstrategicoWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={PlanEstrategicoModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </PlanEstrategicoWrapper>
    );
  }
}

export default PlanEstrategico;
