import React from 'react';
import Survey, { CssNames } from '../../Survey';
import GFNExampleSurvey from './gfn_example';
import GFNExampleWrapper from './GFNExample.styles';

class GFNExampleTest extends React.Component {
  onComplete = async (survey, options) => {
    const { onComplete } = this.props;
    const { data } = survey;
    const parsed = { ...data };
    // console.log({ parsed });
    await onComplete(parsed);
  };

  render() {
    const GFNExampleModel = new Survey.Model(GFNExampleSurvey);
    return (
      <GFNExampleWrapper>
        <div className="uai_header">
          <img
            className="uai-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png"
            alt="uai-logo"
          />
        </div>
        <Survey.Survey
          model={GFNExampleModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className="Footer">
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className="PushStickyFooter"></div>
        <div className="Plug">
          <a
            href="http://www.uaimetrics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UAI Metrics
          </a>
        </div>
      </GFNExampleWrapper>
    );
  }
}

export default GFNExampleTest;
