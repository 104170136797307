import React from 'react';
import SortableList from './SortablePolarList';
import SortableListWrapper from './SortablePolarList/sortableList.style';

import Wrapper from './wrapper.styles';
import postData from '../API';

const SortablePolarListComponent = props => {
  const { dimension } = props;

  React.useEffect(() => {
    // console.log({ dimension });
    if (!dimension) {
      goNext();
    }
    if (dimension === undefined) {
      goNext();
    }
    if (dimension === 'undefined') {
      // console.log('go next!!');
      goNext();
    }
  }, [dimension]);

  // if not dimension, go next
  const goNext = () => {
    let finished = { finished: 0 };
    const { stage = 0 } = props;
    if (props.isLast) {
      finished.finished = 1;
    }
    const emptyData = { ...finished, STAGE: stage + 1 };
    // if parent has onComplete
    if (props.onComplete) {
      return props.onComplete(emptyData);
    }
  };

  const onComplete = async data => {
    let finished = { finished: 0 };
    const { stage = 0 } = props;
    if (props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished, STAGE: stage + 1 };
    // if parent has onComplete
    if (props.onComplete) {
      return props.onComplete(newData);
    }
    return postData(newData);
  };

  if (!dimension) {
    return (
      <Wrapper>
        <div className='uai_header'></div>
        <SortableListWrapper>
          <h4>Cargando items...</h4>
        </SortableListWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className='uai_header'>
        {/* <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          /> */}
      </div>
      <SortableList {...props} onComplete={onComplete} />
      {/* <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div> */}
    </Wrapper>
  );
};

export default SortablePolarListComponent;
