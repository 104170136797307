const finished = ({ name = 'XX XX', email = 'XX@XX.com' }) => ({
  locale: 'es',
  title: 'Finalizado',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'Finished_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'Finished_PAGE_01',
          html: `
<legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          <h3>¡Muchas Gracias!</h3>
        </span>
      </span>
    </div>
    <br>
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        La información entregada ha sido registrada de forma exitosa.
        <br>
        <br>
      </span>
    </span>

    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        Si tiene alguna pregunta o desea ponerse en contacto con nosotros, favor contactar a ${name} al email <a href="mailto:${email}">${email}</a>.
      </span>
    </span>
    <br>
  </div>
  <hr />
</legend>
`
        }
      ]
    }
  ]
});

export default finished;
