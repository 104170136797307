import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ItemList from './ItemList';

import SortableListWrapper, {
  ButtonWrapper,
  Button
} from './sortableList.style';
import reorder from './reorder';

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`,
    value: 1
  }));

class SortablePolar extends Component {
  state = {
    items: getItems(4)
  };

  onComplete = async () => {
    const { items } = this.state;
    const { onComplete } = this.props;
    const firstItem = { ...items[0], value: 2 };
    const lastItem = { ...items[items.length - 1], value: 0 };
    const clone = items.slice();
    clone[0] = firstItem;
    clone[items.length - 1] = lastItem;
    const valuedItems = { ...items, ...clone };
    const parsed = {
      ...valuedItems
    };
    await onComplete(parsed);
  };

  // onDragEnd = result => {
  //   // dropped outside the list
  //   if (!result.destination) {
  //     return;
  //   }

  //   // get new ordered list
  //   const items = reorder(
  //     this.state.items,
  //     result.source.index,
  //     result.destination.index
  //   );

  //   // set new list to state
  //   this.setState({
  //     items
  //   });
  // };

  onDragEnd = result => {
    // super simple, just removing the dragging item
    if (result.combine) {
      const items = [...this.state.items];
      items.splice(result.source.index, 1);
      this.setState({ items });
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <SortableListWrapper>
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <ItemList
              items={this.state.items}
              id='droppablePolar'
              internalScroll
            />
          </DragDropContext>
          <ButtonWrapper>
            <Button onClick={this.onComplete}>Siguiente</Button>
          </ButtonWrapper>
        </div>
      </SortableListWrapper>
    );
  }
}

export default SortablePolar;
