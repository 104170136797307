const rateValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  },
  {
    value: '0',
    text: 'No aplica'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_LEARNING_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        <strong><u>Pensando en los últimos 6 meses</u></strong>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos <strong><u>en su vida laboral</u></strong>.
      </span>
      <br />
      <span style="font-size:16px;">
        Utilizando un rango desde 1 (Nada) a 7 (Muy frecuentemente).
      </span>
    </legend>
    <div class="title_img_table">
      <img src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564616197/uaimetrics/learning_agility_table.png" />
    </div>
  </div>
  `
};

const LearningAgility = {
  locale: 'es',
  title: 'Learning Agility',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_02',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
<legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          <h3>Aprendizaje</h3>
        </span>
      </span>
    </div>
    <br>
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        A continuación, encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. <u>Pensando en los últimos 6 meses</u>, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.
        <br>
        <br>
        Por ejemplo, imagine que durante los últimos 6 meses Ud. ha intentado seguir una dieta baja en calorías. Si ha sido capaz de seguir la dieta casi todos los días o todos los días, Ud. debiera marcar un valor cercano a 7 (Muy frecuentemente). Si sólo algunos días ha seguido la dieta debiese marcar una opción entorno a un valor de 4 (Ocasionalmente). Por último, si en verdad no ha sido capaz de seguir la dieta Ud. debiese marcar un valor cercano a 1 (Nada).
        <br>
        <br>
        Puede marcar la opción “No aplica” si por alguna <u>condición muy excepcional</u> es imposible que se dé el comportamiento descrito en su trabajo. Sin embargo, le pedimos que en lo posible <u>evite</u> usar esta opción. Por ejemplo, se entiende que algunas personas pueden no tener un jefe/a, pero seguramente tiene algún superior a quien reportar o pedir feedback sobre su desempeño. En general, los comportamientos descritos se pueden realizar en el trabajo; por tanto, que quizá sea difícil llevarlos a cabo no implica que sean imposibles.
        <br>
        <br>
        Le recordamos que es de vital importancia que responda las siguientes preguntas de manera honesta y sin pensarlo demasiado. El objetivo de este test es ayudarle a pensar sobre sí mismo/a y descubrir sus fortalezas y oportunidades de desarrollo.
        <br>
        <br>
        <br>
        Presione el botón para continuar
      </span>
    </span>
  </div>
</legend>
`
        }
      ]
    },
    {
      name: 'BF_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BF_01',
          title: 'Pedir a mis pares que me den feedback sobre mi desempeño',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BF_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BF_02',
          title:
            'Pedir a mi jefe/a que me entregue feedback sobre mi desempeño',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BF_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BF_03',
          title:
            'Conversar con mi jefe/a sobre qué necesito mejorar para avanzar dentro de la organización',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BF_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BF_04',
          title:
            'Pedir directamente a otros su opinión sobre cómo podría mejorar mi desempeño',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BI_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BI_01',
          title:
            'Buscar información nueva sobre temas relacionados con mi trabajo o mi área',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BI_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BI_02',
          title:
            'Actualizar mis conocimientos y experticia a través de capacitaciones o educación formal',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BI_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BI_03',
          title:
            'Para estar informado, leer revistas especializadas, artículos en el diario, libros y otras fuentes',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BI_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'BI_04',
          title:
            'Buscar datos para mejorar mi conocimiento, ver cómo voy y analizar los próximos pasos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRD_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRD_01',
          title: 'Asumir nuevos roles o tareas que sean desafiantes',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRD_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRD_02',
          title:
            'Involucrarse en tareas complejas en donde no se sabe de antemano cuál es la mejor manera de actuar',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRD_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRD_03',
          title:
            'Aceptar con gusto trabajos que implican riesgo, incluso si los resultados son inciertos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRD_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRD_04',
          title:
            'Ofrecerse de forma voluntaria para realizar tareas o proyectos que involucren la posibilidad de fallar',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_01',
          title:
            '"Poner sobre la mesa" los problemas o temas difíciles con los demás',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_02',
          title: 'Pedir ayuda a los demás cuando lo necesito',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_03',
          title: 'Conversar mis errores con otros',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_04',
          title:
            'Desafiar las ideas y opiniones de otros, incluso cuando son compartidas por muchas personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'COL_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'COL_01',
          title:
            'Buscar formas de potenciar las habilidades, conocimientos y talentos únicos de los demás',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'COL_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'COL_02',
          title:
            'Trabajar con personas de distintos ‘mundos’ o disciplinas para compartir puntos de vista',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'COL_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'COL_03',
          title:
            'Colaborar con personas que se encuentran en otras áreas de la organización donde trabajo',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'COL_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'COL_04',
          title:
            'Preguntar a varias partes implicadas (stakeholders) su punto de vista',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'EXP_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'EXP_01',
          title:
            'Evaluar nuevas técnicas o formas diferentes de resolver problemas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'EXP_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'EXP_02',
          title:
            'Experimentar con ideas que no han sido probadas poniéndolas a prueba',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'EXP_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'EXP_03',
          title:
            'Probar diferentes enfoques para ver cuál de ellos genera los mejores resultados',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'EXP_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'EXP_04',
          title: 'Lanzarse a la acción y aprender por medio de ensayo y error',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'REF_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'REF_01',
          title:
            'Detenerse a reflexionar sobre los procesos y proyectos laborales',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'REF_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'REF_02',
          title:
            'Tomarse el tiempo para reflexionar sobre cómo ser más efectivo/a',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'REF_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'REF_03',
          title:
            'Considerar las razones y las consecuencias de mis acciones o de eventos recientes',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'REF_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'REF_04',
          title:
            'Evaluar en forma crítica, con los demás, los eventos relacionados con el trabajo, con el fin de entender qué sucedió',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'REF_05_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'REF_05_new',
          title:
            'Entender bien por qué hago las cosas y cuáles son las consecuencias de mis acciones',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'COL_05_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'COL_05_new',
          title:
            'Preguntar a los distintos actores involucrados para construir soluciones colaborativas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_05_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_05_new',
          title:
            'Preguntar a mis pares cuando hay algo que no sé cómo resolver',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_06_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_06_new',
          title:
            'Discutir con mis pares acerca de los problemas que pueden estar ocurriendo',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'TRI_07_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'TRI_07_new',
          title:
            'Tener conversaciones difíciles que otras personas prefieren evitar',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_01',
          title: 'Considerar diferentes opciones antes de actuar',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_02',
          title:
            'Alternar entre diferentes tareas y trabajos según sea necesario',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_03',
          title: 'Encontrar temas comunes entre puntos de vista opuestos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_04',
          title:
            'Integrar ideas o perspectivas que aparentemente son contrarias',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_05',
          title: 'Proponer soluciones que otros consideran como innovadoras',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_01',
          title: 'Desarrollar rápidamente soluciones a los problemas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_02',
          title: '"Ponerse al día" rápidamente al sumarse a un nuevo proyecto',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_03',
          title:
            'Adquirir en forma rápida y fácil nuevas habilidades y conocimientos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_04',
          title: 'Reaccionar bien ante problemas inesperados',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_05',
          title: 'Comprender nuevas ideas o conceptos con rapidez',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_06_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_06_new',
          title:
            'Al involucrarse en un nuevo proyecto, aprender rápido todo lo que tengo que aprender',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_06_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_06_new',
          title: 'Integrar diferentes perspectivas u opiniones',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'FLEX_07_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FLEX_07_new',
          title:
            'Cambiar la manera en que se está enfocando un problema para encontrar soluciones',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'VEL_07_new',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'VEL_07_new',
          title: 'Responder rápidamente a situaciones imprevistas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    }
  ]
};

export default LearningAgility;
