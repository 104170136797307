import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import PersonalAttributesSurvey from './personal_attributes';
import PersonalAttributesWrapper from './attributes.styles';
// import showdown from 'showdown';
// const converter = new showdown.Converter();

function checkboxMaxSelected(params) {
  const [responses, n] = params;
  const validSelected = responses.length <= n;
  if (validSelected) {
    return true;
  }
  return false;
}

class PersonalAttributes extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // console.log('Survey results: ');
    const { data } = survey;
    const { onComplete } = this.props;
    const { P_ATTRIBUTES_01, P_ATTRIBUTES_02, LEADER_WORK = [] } = data;
    const parsed = {
      ...P_ATTRIBUTES_01,
      ...P_ATTRIBUTES_02,
      LEADER_WORK: LEADER_WORK.join('_')
    };
    await onComplete(parsed);
  };

  render() {
    const PersonalAttributesModel = new Survey.Model(PersonalAttributesSurvey);
    Survey.FunctionFactory.Instance.register(
      'checkboxMaxSelected',
      checkboxMaxSelected,
      false // last param indicates if async callback or not
    );
    return (
      <PersonalAttributesWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={PersonalAttributesModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </PersonalAttributesWrapper>
    );
  }
}

export default PersonalAttributes;
