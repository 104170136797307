import React from 'react';
import MessageBox from './MessageBox';
import FinishedWrapper from './finished.styles';

const FinishedInfo = props => {
  return (
    <FinishedWrapper>
      <MessageBox {...props} />
    </FinishedWrapper>
  );
};

export default FinishedInfo;
