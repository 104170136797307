import React from 'react';
import ProgressWrapper from './progress.style';

const Progress = ({ currentIndex, allItems }) => {
  const progress = (currentIndex / allItems.length) * 100;
  return (
    <ProgressWrapper>
      <div className='progress center-block mx-auto mb-4'>
        <div
          className='progress-bar block progress-bar-striped bg-success'
          role='progressbar'
          aria-valuemin='0'
          aria-valuemax='100'
          style={{ width: `${progress}%` }}
        >
          <span className='undefined sv-hidden'>
            Answered {currentIndex}/{allItems.length} questions
          </span>
        </div>
        <span className='undefined undefined'>
          Answered {currentIndex}/{allItems.length} questions
        </span>
      </div>
    </ProgressWrapper>
  );
};

export default Progress;
