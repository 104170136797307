const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  },
  {
    value: '8',
    text: '8'
  },
  {
    value: '9',
    text: '9'
  }
];

const questionFooter = {
  type: 'html',
  name: 'HTML_QUESTION_FOOTER',
  html: `
  <div class="qstn-likert-label pb-5">
    <div>No me representa para nada</div>
    <div></div>
    <div>Me representa muy bien</div>
  </div>
  `
};
const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_Header',
  html: `
  <div class="QuestionText BorderColor">
    <span style="font-size:16px;">
      Por favor indica en qué medida estas afirmaciones te representan utilizando la siguiente escala:
    </span>
  </div>
  `
};

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'SCCI_PAGE_00',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <h3>Instrucciones</h3>
    </span>
    <br />
    A continuación encontrarás una lista de afirmaciones sobre cosas que las personas hacen, sienten o piensan en relación a su elección de carrera.
    <br />
    <br />
    Por favor indica en qué medida estas afirmaciones te representan usando una escala de <strong>1</strong> (No me representa para nada) a <strong>9</strong> (Me representa muy bien), tal como se presenta a continuación:
    <br />
    <br />
    <div class="btn-group">
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>1</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>2</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>3</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>4</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>5</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="6" value="6"><span class="quanta_q_rating_item_text"><span>6</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="7" value="7"><span class="quanta_q_rating_item_text"><span>7</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="8" value="8"><span class="quanta_q_rating_item_text"><span>8</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="9" value="9"><span class="quanta_q_rating_item_text"><span>9</span></span></label>
    </div>
    <div class="qstn-likert-label pb-5">
      <div>No me representa para nada</div>
      <div></div>
      <div>Me representa muy bien</div>
    </div>
  </span>
</div>
`
        }
      ]
    }
  ]
};

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    //     {
    //       name: 'SCCI_PAGE_00',
    //       title: '',
    //       elements: [
    //         {
    //           type: 'html',
    //           name: 'HTML_DIALOG_01',
    //           html: `
    // <div class="QuestionText BorderColor">
    //   <span style="font-size:16px;">
    //     <span style="font-family:arial,helvetica,sans-serif;">
    //       <h3>Instrucciones</h3>
    //     </span>
    //     <br />
    //     A continuación encontrarás una lista de afirmaciones sobre cosas que las personas hacen, sienten o piensan en relación a su elección de carrera.
    //     <br />
    //     <br />
    //     Por favor indica en qué medida estas afirmaciones te representan usando una escala de <strong>1</strong> (No me representa para nada) a <strong>9</strong> (Me representa muy bien), tal como se presenta a continuación:
    //     <br />
    //     <br />
    //     <div class="btn-group">
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>1</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>2</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>3</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>4</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>5</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="6" value="6"><span class="quanta_q_rating_item_text"><span>6</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="7" value="7"><span class="quanta_q_rating_item_text"><span>7</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="8" value="8"><span class="quanta_q_rating_item_text"><span>8</span></span></label>
    //       <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="SCCI_00" aria-required="true" aria-label="9" value="9"><span class="quanta_q_rating_item_text"><span>9</span></span></label>
    //     </div>
    //     <div class="qstn-likert-label pb-5">
    //       <div>No me representa para nada</div>
    //       <div></div>
    //       <div>Me representa muy bien</div>
    //     </div>
    //   </span>
    // </div>
    // `
    //         }
    //       ]
    //     },
    {
      name: 'SCCI_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_01',
          title:
            'La pregunta sobre qué hacer después de salir del colegio es una de las cosas sobre las que he estado pensando recientemente',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_02',
          title:
            'Consulto con otras personas sobre los pasos que debería seguir para tomar una buena decisión sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_03',
          title:
            'Para tomar una decisión sobre qué hacer después del colegio, he juntado información detallada sobre carreras, lugares de trabajo, oportunidades de desarrollo, y programas de estudio, entre otras cosas',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_04',
          title:
            'Estoy posponiendo la decisión sobre qué hacer después de salir del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_05',
          title:
            'Le pido ayuda a otros para reducir el estrés que implica tomar una decisión sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_06',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_06',
          title:
            'Buscar información adicional (sobre carreras, puestos de trabajos, oportunidades de desarrollo, programas de estudio, y otros) me ayuda a reducir el estrés de tomar una decisión sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_07',
          title:
            'Siento que NO tengo la fortaleza para enfrentar la incertidumbre que implica tomar una decisión sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_08',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_08',
          title:
            'Le pido a otros que me digan que debería hacer después de salir del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_09',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_09',
          title:
            'Cuando pienso sobre qué haré después del colegio, evalúo las ventajas y desventajas de cada una de las opciones que estoy considerando',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_10',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_10',
          title:
            'No quiero que otros sepan cómo me siento con respecto a lo que quiero hacer cuando salga del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_11',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_11',
          title:
            'Cuando pienso en qué haré después del colegio, estoy dispuesto a dejar de lado algunas de mis preferencias porque reconozco las limitaciones de la realidad',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_12',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_12',
          title:
            'Estoy constantemente preocupado de equivocarme y elegir una carrera inadecuada para mí',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_13',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_13',
          title:
            'Mantengo la esperanza de que tomaré una buena decisión sobre qué hacer después del colegio (me digo a mi mismo/a "Yo puedo")',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_14',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_14',
          title:
            'Estoy enojado con los que me rodean porque solo me hacen más difícil decidir qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_15',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_15',
          title:
            'Soy capaz de manejar el estrés que implica tomar una decisión sobre mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_16',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_16',
          title:
            'Para mí es importante elegir una carrera que se ajuste a mis intereses y habilidades',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_17',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_17',
          title:
            'Consulto con otras personas cómo hacer realidad lo que quiero de mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_18',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_18',
          title:
            'Para tomar una decisión informada sobre qué hacer después de la graduación busco información de distintas fuentes (descripciones de puestos de trabajo en Internet, hablo con personas de distintas empresas, entre otras cosas)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_19',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_19',
          title:
            'Estoy aplazando la decisión sobre qué hacer después de salir del colegio, con la esperanza de que el paso del tiempo lo haga más fácil',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_20',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_20',
          title:
            'Le pido ayuda a otros sobre cómo aliviar mis preocupaciones sobre tener que tomar una decisión sobre mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_21',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_21',
          title:
            'Buscar información adicional (sobre carreras, puestos de trabajos, oportunidades de desarrollo, programas de estudio, y otros) me ayuda a reducir mis preocupaciones sobre mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_22',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_22',
          title:
            'Me siento desesperanzado cuando me enfrento a la pregunta sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_23',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_23',
          title: 'Le pregunto a otras personas qué carrera es adecuada para mí',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_24',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_24',
          title:
            'Cuando pienso sobre qué haré después del colegio, pongo sobre la balanza qué aspectos son importante para mí (horarios de trabajo, flexibilidad, e ingresos, entre otros)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_25',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_25',
          title:
            'No quisiera que otros sepan cuán preocupado y ansioso me siento sobre lo que haré después de salir del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_26',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_26',
          title:
            'Cuando pienso en qué haré después del colegio, estoy dispuesto a transar entre mis aspiraciones y lo que es factible en el mundo real',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_27',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_27',
          title:
            'Estoy constantemente preocupado de que mis expectativas sobre mi futuro laboral no se cumplan',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_28',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_28',
          title:
            'Me mantengo optimista sobre tomar una buena decisión sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_29',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_29',
          title:
            'Me molestan los que están a mi alrededor porque siento que me hacen más difícil tomar una decisión sobre mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_30',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_30',
          title:
            'Soy capaz de reducir la preocupación que implica tomar una decisión sobre mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_31',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_31',
          title:
            'No me molestaría si terminara eligiendo una carrera inadecuada',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_32',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_32',
          title:
            'La pregunto a otros qué factores debería tomar en cuenta para decidir qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_33',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_33',
          title:
            'Para tomar una decisión sobre qué hacer después del colegio, busco información relevante y actualizada sobre las carreras que estoy considerando',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_34',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_34',
          title:
            'Me doy cuenta de que trato de evitar pensar sobre la decisión que tendré que tomar al salir del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_35',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_35',
          title:
            'Le pido ayuda a otros para aliviar mis preocupaciones y mi frustración sobre tomar una decisión de carrera',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_36',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_36',
          title:
            'Buscar información adicional (sobre carreras, puestos de trabajos, oportunidades de desarrollo, programas de estudio, y otros) me ayuda a sentirme menos ansioso sobre lo que haré después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_37',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_37',
          title:
            'Siento que NO puedo hacer frente a la complejidad de decidir qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_38',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_38',
          title:
            'Le pido a otras personas que resuelvan la confusión que tengo sobre qué hacer después de salir del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_39',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_39',
          title:
            'Cuando pienso sobre qué haré después del colegio, comparo mis opciones en términos de sus posibles resultados',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_40',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_40',
          title:
            'No quisiera que otros supieran cuan duro es para mí tener que deliberar sobre qué hacer cuando salga del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_41',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_41',
          title:
            'Cuando pienso en qué haré después del colegio, trato de encontrar un balance entre mis preferencias y los requerimientos del mundo real',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_42',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_42',
          title:
            'Estoy constantemente preocupado sobre qué pasará si tomo una decisión sobre mi futuro laboral de la que me pueda arrepentir',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_43',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_43',
          title:
            'Tengo una actitud positiva sobre el desafío de tomar una decisión de carrera',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_44',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_44',
          title:
            'Siento que las personas que me rodean son responsables por lo difícil que ha sido para mí tomar una decisión sobre qué hacer después del colegio',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'SCCI_45',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'SCCI_45',
          title:
            'Soy capaz de manejar mi ansiedad cuando ésta dificulta tomar una decisión sobre mi futuro laboral',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    }
  ]
};

export default testInfo;
