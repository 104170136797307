import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// import HomeComponent from '../../Components/Index';
import EquisOneComponent from '../../Components/AppEquisOne';
import AppEquisOnePiloto from '../../Components/AppEquisOnePiloto';
import AppEquisOneSortableItems from '../../Components/AppEquisOneSortableItems';
import AppEquisOneSortableDimension from '../../Components/AppEquisOneSortableDimension';
import EquisJuniorComponent from '../../Components/AppEquisJunior';
import AppEquisJuniorPiloto from '../../Components/AppEquisJuniorPiloto';
import AuthRutId from '../../Components/Auth/App';
import LogInForm from '../../Components/Auth/App/LogInForm';
import TestingMenu from '../../Components/AppTestingMenu';

import { StageProvider, StageConsumer } from '../../Context/app-stage-context';
import { DataProvider, useDataContext } from '../../Context/campaign-context';

// parse url query string, e.g.
// url test mode ?mode=testingMode
// url school ?school=Santiago+College
const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

const SwitchPage = props => {
  const [user, setUser] = useState(null);
  const { campaign, isLoading, userData, setUserData } = useDataContext();

  useEffect(() => {
    // login and set user??
    // console.log({ userData });
    if (userData === null) {
      setUser(null);
    }
    if (userData) {
      setUser(userData);
    }
    // setUser({
    //   rut: '654654645',
    //   name: 'Testing',
    //   lastname: 'EquisOne',
    //   email: 'testing@testing.com'
    // });
  }, [userData]);

  const appMode = getQuery('mode');
  const isTestingMode = appMode === 'testingMode';
  // console.log({ appMode, isTestingMode });
  const testing = isTestingMode; // true;

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!campaign && !isLoading) {
    console.log('not found, redirect');
    return <div>Redirect, not found</div>;
  }
  // console.log({ campaign });
  const { SKU } = campaign;

  const multiple = true;
  const withAuth = !!user;
  // console.log({ user });

  return (
    <StageProvider>
      <StageConsumer>
        {({ state, updateContext, nextStage, prevStage }) => {
          if (!user) {
            return (
              <div>
                <Helmet>
                  {SKU === 'EQUIS_ONE' && (
                    <title>
                      {campaign.organization || campaign.title} - Equis One |
                      UAI Metrics
                    </title>
                  )}
                  {SKU === 'EQUIS_ONE_PILOTO' && (
                    <title>
                      {campaign.organization || campaign.title} - Equis One |
                      UAI Metrics
                    </title>
                  )}
                  {SKU === 'EQUIS_ONE_SORTABLE_ITEMS' && (
                    <title>
                      {campaign.organization || campaign.title} - Equis One |
                      UAI Metrics
                    </title>
                  )}
                  {SKU === 'EQUIS_ONE_DIMENSION_ITEMS' && (
                    <title>
                      {campaign.organization || campaign.title} - Equis One |
                      UAI Metrics
                    </title>
                  )}
                  {SKU === 'EQUIS_JUNIOR' && (
                    <title>
                      {campaign.organization || campaign.title} - Equis Junior |
                      UAI Metrics
                    </title>
                  )}
                  {SKU === 'EQUIS_JUNIOR_PILOTO' && (
                    <title>
                      {campaign.organization || campaign.title} - Equis Junior |
                      UAI Metrics
                    </title>
                  )}
                </Helmet>
                <LogInForm {...state} campaign={campaign} SKU={SKU} />
              </div>
            );
          }

          return (
            <React.Fragment>
              <Helmet>
                {SKU === 'EQUIS_ONE' && (
                  <title>
                    {campaign.organization || campaign.title} - Equis One | UAI
                    Metrics
                  </title>
                )}
                {SKU === 'EQUIS_ONE_PILOTO' && (
                  <title>
                    {campaign.organization || campaign.title} - Equis One | UAI
                    Metrics
                  </title>
                )}
                {SKU === 'EQUIS_ONE_SORTABLE_ITEMS' && (
                  <title>
                    {campaign.organization || campaign.title} - Equis One | UAI
                    Metrics
                  </title>
                )}
                {SKU === 'EQUIS_ONE_DIMENSION_ITEMS' && (
                  <title>
                    {campaign.organization || campaign.title} - Equis One | UAI
                    Metrics
                  </title>
                )}
                {SKU === 'EQUIS_JUNIOR' && (
                  <title>
                    {campaign.organization || campaign.title} - Equis Junior |
                    UAI Metrics
                  </title>
                )}
                {SKU === 'EQUIS_JUNIOR_PILOTO' && (
                  <title>
                    {campaign.organization || campaign.title} - Equis Junior |
                    UAI Metrics
                  </title>
                )}
              </Helmet>
              {SKU === 'EQUIS_ONE' && (
                <EquisOneComponent
                  {...props}
                  {...state}
                  setUserData={setUserData}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  campaign={campaign}
                  withAuth={withAuth}
                  user={user}
                />
              )}

              {SKU === 'EQUIS_ONE_PILOTO' && (
                <AppEquisOnePiloto
                  {...props}
                  {...state}
                  setUserData={setUserData}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  campaign={campaign}
                  withAuth={withAuth}
                  user={user}
                />
              )}

              {SKU === 'EQUIS_ONE_SORTABLE_ITEMS' && (
                <AppEquisOneSortableItems
                  {...props}
                  {...state}
                  setUserData={setUserData}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  campaign={campaign}
                  withAuth={withAuth}
                  user={user}
                />
              )}

              {SKU === 'EQUIS_ONE_DIMENSION_ITEMS' && (
                <AppEquisOneSortableDimension
                  {...props}
                  {...state}
                  setUserData={setUserData}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  campaign={campaign}
                  withAuth={withAuth}
                  user={user}
                />
              )}

              {SKU === 'EQUIS_JUNIOR' && (
                <EquisJuniorComponent
                  {...props}
                  {...state}
                  setUserData={setUserData}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  campaign={campaign}
                  withAuth={withAuth}
                  user={user}
                  // school={school}
                  // rut={rut}
                />
              )}
              {SKU === 'EQUIS_JUNIOR_PILOTO' && (
                <AppEquisJuniorPiloto
                  {...props}
                  {...state}
                  setUserData={setUserData}
                  state={state}
                  updateContext={updateContext}
                  toNextStage={nextStage}
                  campaign={campaign}
                  withAuth={withAuth}
                  user={user}
                  // school={school}
                  // rut={rut}
                />
              )}

              {testing && (
                <TestingMenu
                  {...state}
                  campaign={campaign}
                  user={user}
                  toNextStage={nextStage}
                  toPrevStage={prevStage}
                  equis1={Boolean(
                    [
                      'EQUIS_ONE',
                      'EQUIS_ONE_PILOTO',
                      'EQUIS_ONE_DIMENSION_ITEMS',
                      'EQUIS_ONE_SORTABLE_ITEMS'
                    ].includes(SKU)
                  )}
                  equis2={Boolean(
                    ['EQUIS_JUNIOR', 'EQUIS_JUNIOR_PILOTO'].includes(SKU)
                  )}
                />
              )}
              {multiple && (
                <AuthRutId {...state} campaign={campaign} user={user} />
              )}
            </React.Fragment>
          );
        }}
      </StageConsumer>
    </StageProvider>
  );

  return <div>An error ocurred, check if your url is correct</div>;
};

const DataWrapper = props => {
  // console.log({ props });
  return (
    <DataProvider id={props.match.params.id}>
      <SwitchPage {...props} />
    </DataProvider>
  );
};
export default DataWrapper;
