const rateValues = [
  {
    value: '1',
    text: '●'
  },
  {
    value: '2',
    text: '◉'
  },
  {
    value: '3',
    text: '⦿'
  },
  {
    value: '4',
    text: '⦿'
  },
  {
    value: '5',
    text: '◉'
  },
  {
    value: '6',
    text: '●'
  }
];

const PairRangeDistance = {
  locale: 'es',
  title: 'Pair preference Distance',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'PAIR_DIST_PAGE_01',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'rating',
          name: 'PAIR_DIST_01',
          title: 'En un continuo, que actividad preferirías?',
          isRequired: true,
          rateValues: rateValues,
          minRateDescription: 'Vender artículos en tienda',
          maxRateDescription: 'Vender artículos en terreno'
        },
        {
          type: 'html',
          name: 'HTML_RANGE_GRADIENT',
          html: `
          <div class="pair_range_gradient">
          </div>
        `
        }
      ]
    },
    {
      name: 'PAIR_DIST_PAGE_02',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'rating',
          name: 'PAIR_DIST_02',
          title: 'En un continuo, que actividad preferirías?',
          isRequired: true,
          rateValues: rateValues,
          minRateDescription: 'Actividad 1',
          maxRateDescription: 'Actividad 2'
        },
        {
          type: 'html',
          name: 'HTML_RANGE_GRADIENT',
          html: `
          <div class="pair_range_gradient">
          </div>
        `
        }
      ]
    }
  ]
};

export default PairRangeDistance;
