import React from 'react';
import Presentation from './Presentation';
import SortableIntro from './Sortable_Intro';
import SortablePolarList from './SortablePolarList';
import SortableStudyArea from './SortableStudyArea';
import SortableStudyIntro from './SortableStudy_Intro';
import PilotajeFeedback from './PilotajeFeedback';
import PGIPiloto from './PGI_PILOTO';
import FinishedMessage from './FinishedMessage/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../../config';
import VocationalWrapper from './votacional.style';

class Equis2Test extends React.Component {
  state = {
    error: '',
    feedbackForm: 'A' // default
  };

  componentDidMount = async () => {
    // const { BATTERY, updateContext } = this.props;
    const { BATTERY, updateContext, rut } = this.props;
    // const rut = localStorage.getItem(`currentCode-${BATTERY}`);

    // assign random Form logic
    const forms = ['A', 'B'];
    // select random
    const randomForm = forms[Math.floor(Math.random() * forms.length)];
    // get local form
    const localForm = localStorage.getItem(`feedbackForm-${BATTERY}-${rut}`);
    if (!localForm) {
      // if not local form, assign form
      localStorage.setItem(`feedbackForm-${BATTERY}-${rut}`, randomForm);
    }
    // use local form or new selected form as fallback
    const feedbackForm = localForm || randomForm;
    // set prev feedbackForm
    await this.setState({ feedbackForm });

    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    // const { BATTERY, stage, user, rut = 'anon' } = this.props;
    const {
      BATTERY,
      stage,
      rut = 'anon',
      school = 'Universidad Adolfo Ibanez'
    } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    // await postData(data, user, rut);
    try {
      await postData(data, caseId, rut, school);
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    if (data.CONSENT === '2') {
      console.log('NO CONSENT');
      this.props.toNextStage(stageName);
    }
    if (stage < 7) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, BATTERY, user, rut, school } = this.props;
    // const { BATTERY, user, rut, school } = this.props;
    // const stage = 5;
    const { error, feedbackForm } = this.state;
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (stage > 6) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='UAI METRICS'
          email='uaimetrics@uai.cl'
        />
      );
    }
    if (stage === 6) {
      return (
        <PGIPiloto
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          stage={stage}
          form={feedbackForm}
          isLast
        />
      );
    }
    if (stage === 5) {
      return (
        <PilotajeFeedback
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 4) {
      return (
        <SortableStudyArea
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          user={user}
          rut={rut}
          stage={stage}
          label='STUDY'
        />
      );
    }
    if (stage === 3) {
      return (
        <SortableStudyIntro
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 2) {
      return (
        <SortablePolarList
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 1) {
      return (
        <SortableIntro
          onComplete={this.onComplete}
          BATTERY={BATTERY}
          rut={rut}
          stage={stage}
        />
      );
    }
    return (
      <Presentation
        onComplete={this.onComplete}
        BATTERY={BATTERY}
        rut={rut}
        user={user}
        school={school}
        stage={stage}
      />
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <Equis2Test {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};

export default WithWrapper;
