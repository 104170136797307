const baseInfo = {
  locale: 'es',
  title: 'Demográficos',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Consentimiento Informado</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                  Este estudio tiene por finalidad evaluar un conjunto de aspectos relacionados con las actitudes académicas de los estudiantes de la Universidad Adolfo Ibáñez. Los resultados de este estudio nos permitirán diseñar estrategias para fomentar una cultura académica positiva y apoyar estudiantes que requieren un apoyo especial para enfrentar los desafíos que supone la vida universitaria. Este estudio es llevado a cabo por un equipo de investigación de la Escuela de Psicología de la Universidad Adolfo Ibáñez con el apoyo de [insertar nombre área que dirige Camila aquí].
                  <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Su participación en este estudio es voluntaria. Esto quiere decir que usted puede optar por no participar o incluso abandonar el estudio en cualquier momento sin alterar su relación actual o futura con la Universidad.
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Si accede a participar en este estudio se le solicitará responder un cuestionario que dura entre 10 y 15 minutos [modificar duración del estudio]. Las preguntas contenidas en el cuestionario indagan sobre sus características personales y sobre la medida en que usted participa en un conjunto de actividades vinculadas con la vida universitaria. Sus respuestas a este cuestionario son confidenciales. Sus respuestas individuales NO serán publicadas ni reveladas a terceras personas.
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Si tiene preguntas en relación a este estudio, puede contactar a Hedy Acosta llamando al (2)331-1282 o al email hedy.acosta@uai.cl. Esta investigación ha sido aprobada por el Comité de Ética de la Universidad Adolfo Ibáñez. Para reportar problemas o en caso de tener preguntas en relación a sus derechos como participante, puede ponerse en contacto con el representante del Comité de Ética, [insertar nombre persona encargada] llamando al [fono] o al mail [mail de contacto].
                <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Por favor asegúrese de haber leído la información anterior, haber hecho preguntas en caso de tener dudas y haber recibido respuestas satisfactorias frente a cada una de ellas. Al chequear el casillero de más abajo, usted acepta participar en este estudio.
                <br>
                </span>
              </span>

              <span style="font-size:14px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  <br>
                  <strong>
                  "Sí, acepto participar en este estudio y entiendo que no estoy renunciando a ninguno de mis derechos legales por estar en este estudio. Los procedimientos, riesgos, y beneficios me han sido explicados satisfactoriamente y mis dudas han sido aclaradas. Entiendo que en caso de haber cambios en el estudio estos serán debidamente informados y que el investigador me informará si debo ser excluido del estudio."</strong>.
                </span>
              </span>

              </span>
              <br>
            </div>
            <hr />
          </legend>
          `
        },
        {
          type: 'radiogroup',
          name: 'DEMO_Q01',
          title:
            'Seleccionando "Acepto participar en el estudio" declaro haber comprendido los alcances del estudio y mis derechos como participante del mismo.',
          isRequired: true,
          colCount: 2,
          choices: [
            {
              value: '1',
              text: 'Acepto participar en el estudio'
            },
            {
              value: '2',
              text: 'No acepto participar en el estudio'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_01_2',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>INFORMACIÓN GENERAL</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Junto con saludarte, te invitamos a responder los siguientes cuestionarios. Para que esta información sea útil es importante que respondas de acuerdo a lo que realmente sientes y piensas y no en función de lo que otros creen o esperan de ti. Tus respuestas son confidenciales.        <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                Por favor asegúrate de preguntar en caso de tener dudas sobre este cuestionario y haber recibido respuestas satisfactorias frente a cada una de ellas.      <br>
                </span>
              </span>

              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                <br>
                Gracias
                <br>
                </span>
              </span>

              </span>
              <br>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      elements: [
        {
          type: 'multipletext',
          name: 'NAME_BASE',
          // isRequired: true,
          title: 'Ingrese su Nombre y Apellido',
          items: [
            {
              name: 'NAME',
              title: 'Nombre',
              isRequired: true
            },
            {
              name: 'LASTNAME',
              title: 'Apellido',
              isRequired: true
            }
          ]
        }
        // {
        //   type: 'text',
        //   name: 'G_CODE',
        //   title: 'Escriba el código de su grupo'
        // }
      ]
    },
    {
      name: 'DEMO_PAGE_03',
      title: 'Información general',
      elements: [
        {
          type: 'radiogroup',
          name: 'Sexo',
          title: 'Sexo',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Hombre'
            },
            {
              value: '0',
              text: 'Mujer'
            }
          ]
        },
        {
          type: 'text',
          name: 'AGE',
          title: 'Edad (años)',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_04',
      title: ' ',
      // description: 'Para poder hacerle una devolución de sus resultados.',
      elements: [
        {
          type: 'multipletext',
          name: 'DEMO_Q02',
          title: 'Ingrese sus datos de contacto',
          items: [
            {
              name: 'EMAIL',
              title: 'Email',
              isRequired: true,
              inputType: 'email',
              validators: [
                {
                  type: 'email'
                }
              ]
            },
            {
              name: 'PHONE',
              title: 'Teléfono',
              inputType: 'tel',
              placeHolder: '+56 9 1234 5678',
              isRequired: true
            }
          ]
        },
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h4>Información Importante</h4>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                Su número de telefono es importante para poder comunicarnos con usted.
                <br>
                <br>
                Solo los contactaremos desde los siguientes números de telefono:
                <br>
                </span>
                <ul style="font-size:16px;">
                  <li>+56 9 1234 5678</li>
                  <li>+56 9 1234 5678</li>
                </ul>
              </span>

            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
