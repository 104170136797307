import React from 'react';
import Survey, { CssNames } from '../../Survey';
import CalendlyBlankSurvey from './calendly_back';
import CalendlyWrapper from './calendly.styles';

class Calendly extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete
  _script = null;
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    this._script = script;
    head.appendChild(this._script);
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
    const head = document.querySelector('head');
    head.removeChild(this._script);
    this._script = null;
  }

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { data } = survey;
    const { onComplete } = this.props;
    const parsed = { ...data };
    await onComplete(parsed);
  };

  render() {
    const BlankModel = new Survey.Model(CalendlyBlankSurvey);
    return (
      <CalendlyWrapper>
        <div className="uai_header">
          <img
            className="uai-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png"
            alt="uai-logo"
          />
        </div>
        <Survey.Survey
          model={BlankModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div id="schedule_form" className="schedule_form">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/dialcortez/llamadas"
            style={{ minWidth: '320px', height: '630px' }}
          />
        </div>
        <div className="Footer">
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className="PushStickyFooter"></div>
        <div className="Plug">
          <a
            href="http://www.uaimetrics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UAI Metrics
          </a>
        </div>
      </CalendlyWrapper>
    );
  }
}

export default Calendly;
