import styled, { css } from 'styled-components';

const SortableListWrapper = styled.div`
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
  @media (max-width: 979px) {
    height: inherit;
  }
`;

export const DroppableListWrapper = styled.div`
  padding: 2rem 1rem;
  ${'' /* width: 450px; */}
  ${'' /* background: #f1f1f1; */}
  background: ${({ theme }) => theme.colors.extraLight};
  border-radius: 4px;
  ${({ theme, isDraggingOver }) =>
    isDraggingOver &&
    css`
      ${'' /* background: lightblue; */}
      background: #cce5ff;
    `};
`;

export const ListItemWrapper = styled.div`
  user-select: none;
  padding: 10px 15px;
  margin: 10px 3rem;
  font-weight: 700;
  color: #212529;
  background: #fff;
  border: 1px dashed ${({ theme }) => theme.colors.blue};
  transition: background 0.2s ease-in-out;
  display: block;
  text-align: center;
  border-radius: 4px;
  ${({ isDragging }) =>
    isDragging &&
    css`
      background: ${({ theme }) => theme.colors.brandColor};
      color: #fff;
    `};

  &:hover {
    background: ${({ theme }) => theme.colors.itemBackgroundHover};
  }

  &:nth-child(1) {
    border: 1px dashed ${({ theme }) => theme.colors.success};
    color: #155724;
    background-color: #d4edda;
    ${'' /* border-color: #c3e6cb; */}
  }
  &:nth-child(4) {
    border: 1px dashed ${({ theme }) => theme.colors.danger};
    color: #721c24;
    background-color: #f8d7da;
    ${'' /* border-color: #f5c6cb; */}
  }
`;

export const DropZone = styled.div`
  ${'' /* display: flex; */}
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  ${'' /* align-items: start; */}
  /* stop the list collapsing when empty */
  min-width: 600px;
  /* stop the list collapsing when it has no items */
  min-height: 80px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`;

export const ScrollContainer = styled.div`
  overflow: auto;
`;

export const Container = styled.div`
  /* flex child */
  ${'' /* flex-grow: 1; */}
  /*
    flex parent
    needed to allow width to grow greater than body
  */
  ${'' /* display: inline-flex; */}
`;

export const ButtonWrapper = styled.div`
  padding: 2rem;
  text-align: center;
`;
export const Button = styled.button`
  ${'' /* display: flex; */}
  align-self: center;
  padding: 15px 30px;
  margin: 0.5rem;
  font-weight: 700;
  color: #fff;
  background: ${({ theme }) => theme.colors.brandColor};
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: all 0.2s ease-in-out;
  ${'' /* display: block; */}
  text-align: center;
  border-radius: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.brandSolid};
    border-color: ${({ theme }) => theme.colors.brandSolid};
  }
`;

export default SortableListWrapper;
