const quizLetterSeries = {
  locale: 'es',
  title: 'Serie de Letras',
  showCompletedPage: true,
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  maxTimeToFinish: 600,
  // maxTimeToFinishPage: 0,
  showTimerPanel: 'top',
  showTimerPanelMode: 'survey',
  // completedHtml:
  //   '<h4>Respondiste <b>{correctedAnswers}</b> preguntas correctas de <b>{questionCount}</b>.</h4>',
  pages: [
    {
      name: 'page1',
      elements: [
        // {
        //   type: 'html',
        //   name: 'question1',
        //   html: `Este es un test para evaluar su capacidad para analizar patrones.<br />
        //     Cada problema en esta prueba tiene cinco sets de letras con cuatro letras en cada set. <br />
        //     Cuatro de estos sets de letras son similares de alguna manera. <br />
        //     TU TAREA consiste en inferir la regla que hace similares a estos sets. <br />
        //     Un set de letras será diferente a los otros y no encajará en esta regla. <br />
        //     <br />
        //     Haz click en el set de letras que tú crees NO SIGUE la regla.
        //     <br /><br /><br />
        //     Las reglas NO estarán basadas en el sonido del set de palabras, la forma de las letras, o si las combinaciones de letras forman palabras o partes de alguna.
        //     <br />
        //     En la siguiente página se encuentra el primer ejemplo de práctica.
        //     <br />
        //     Intenta encontrar la regla que aplica a cuatro sets de letras, luego haz click en el set de letras que NO sigue esa regla.
        //     <br />
        //     Si tienes alguna consulta, por favor pregunta al experimentador ahora.
        //     <br /><br />
        //     Haz click en el botón "Continuar" para comenzar.
        //     `
        // },
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Serie de letras</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  A continuación, realizará un test para evaluar su capacidad para analizar patrones.
                  <br>
                  <br>
                  Cada problema en esta prueba tiene cinco sets de letras con cuatro letras en cada set.
                  <br>
                  Cuatro de estos sets de letras son similares de alguna manera.
                  <br>
                  <br>
                  SU TAREA consiste en inferir la regla que hace similares a estos sets.
                  <br>
                  Un set de letras será diferente a los otros y no encajará en esta regla.
                  <br>
                  Haga click en el set de letras que usted crea <strong>NO SIGA </strong>la regla.
                  <br>
                  <br>
                  Las reglas NO estarán basadas en el sonido del set de palabras, la forma de las letras, o si las combinaciones de letras forman palabras o partes de alguna.
                  <br>
                  <br>
                  En la siguiente página comenzará con el test.
                  <br>
                  Esta actividad contactará con un tiempo máximo para responder de <strong>10 minutos.</strong>
                  <br><br>
                  Intente encontrar la regla que aplique a cuatro sets de letras, luego haga click en el set de letras que <strong>NO</strong> siga esa regla.
                  <br>
                  <br>
                  <br>
                  Presione el botón "Continuar" para comenzar
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'GFL01',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL01',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '1',
          // // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'QPPQ'
            },
            {
              value: '2',
              text: 'HGHH'
            },
            {
              value: '3',
              text: 'TTTU'
            },
            {
              value: '4',
              text: 'DDDE'
            },
            {
              value: '5',
              text: 'MLMM'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL02',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL02',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '5',
          // // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'ABCX'
            },
            {
              value: '2',
              text: 'EFGX'
            },
            {
              value: '3',
              text: 'IJKX'
            },
            {
              value: '4',
              text: 'OPQX'
            },
            {
              value: '5',
              text: 'UVWZ'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL03',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL03',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BCDE'
            },
            {
              value: '2',
              text: 'FGHI'
            },
            {
              value: '3',
              text: 'JKLM'
            },
            {
              value: '4',
              text: 'PRST'
            },
            {
              value: '5',
              text: 'VWXY'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL04',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL04',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'LNLV'
            },
            {
              value: '2',
              text: 'DTFL'
            },
            {
              value: '3',
              text: 'CLNL'
            },
            {
              value: '4',
              text: 'HRLL'
            },
            {
              value: '5',
              text: 'LLWS'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL05',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL05',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BVZC'
            },
            {
              value: '2',
              text: 'FVZG'
            },
            {
              value: '3',
              text: 'JVZK'
            },
            {
              value: '4',
              text: 'PWXQ'
            },
            {
              value: '5',
              text: 'SVZT'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL06',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL06',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'ABCE'
            },
            {
              value: '2',
              text: 'EFGI'
            },
            {
              value: '3',
              text: 'IJKM'
            },
            {
              value: '4',
              text: 'OPQT'
            },
            {
              value: '5',
              text: 'UVWY'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL07',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL07',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '3',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BCEF'
            },
            {
              value: '2',
              text: 'FGIJ'
            },
            {
              value: '3',
              text: 'STWX'
            },
            {
              value: '4',
              text: 'CDFG'
            },
            {
              value: '5',
              text: 'PQST'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL08',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL08',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '3',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'GFFG'
            },
            {
              value: '2',
              text: 'DCCD'
            },
            {
              value: '3',
              text: 'STTS'
            },
            {
              value: '4',
              text: 'RQQR'
            },
            {
              value: '5',
              text: 'MLLM'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL09',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL09',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BCCB'
            },
            {
              value: '2',
              text: 'GFFG'
            },
            {
              value: '3',
              text: 'LMML'
            },
            {
              value: '4',
              text: 'QRRQ'
            },
            {
              value: '5',
              text: 'WXXW'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL10',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL10',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'DCDD'
            },
            {
              value: '2',
              text: 'HGHH'
            },
            {
              value: '3',
              text: 'MMLM'
            },
            {
              value: '4',
              text: 'QQQR'
            },
            {
              value: '5',
              text: 'WWVW'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL11',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL11',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '3',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'AAPP'
            },
            {
              value: '2',
              text: 'CCRR'
            },
            {
              value: '3',
              text: 'QQBB'
            },
            {
              value: '4',
              text: 'EETT'
            },
            {
              value: '5',
              text: 'DDSS'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL12',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL12',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'FEDC'
            },
            {
              value: '2',
              text: 'MKJI'
            },
            {
              value: '3',
              text: 'DCBA'
            },
            {
              value: '4',
              text: 'HGFE'
            },
            {
              value: '5',
              text: 'JIHG'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL13',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL13',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'ABDC'
            },
            {
              value: '2',
              text: 'EGFH'
            },
            {
              value: '3',
              text: 'IJLK'
            },
            {
              value: '4',
              text: 'OPRQ'
            },
            {
              value: '5',
              text: 'UVXW'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL14',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL14',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BDBB'
            },
            {
              value: '2',
              text: 'BFDB'
            },
            {
              value: '3',
              text: 'BHBB'
            },
            {
              value: '4',
              text: 'BBJB'
            },
            {
              value: '5',
              text: 'BBLB'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL15',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL15',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'CERT'
            },
            {
              value: '2',
              text: 'KMTV'
            },
            {
              value: '3',
              text: 'FHXZ'
            },
            {
              value: '4',
              text: 'BODQ'
            },
            {
              value: '5',
              text: 'HJPR'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL16',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL16',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '5',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BDCE'
            },
            {
              value: '2',
              text: 'FHGI'
            },
            {
              value: '3',
              text: 'JLKM'
            },
            {
              value: '4',
              text: 'PRQS'
            },
            {
              value: '5',
              text: 'TVWU'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL17',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL17',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '5',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'PABQ'
            },
            {
              value: '2',
              text: 'SEFT'
            },
            {
              value: '3',
              text: 'VIJW'
            },
            {
              value: '4',
              text: 'COPD'
            },
            {
              value: '5',
              text: 'FUZG'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL18',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL18',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '5',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BDEF'
            },
            {
              value: '2',
              text: 'FHIJ'
            },
            {
              value: '3',
              text: 'HJKL'
            },
            {
              value: '4',
              text: 'NPQR'
            },
            {
              value: '5',
              text: 'SVWX'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL19',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL19',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'CFCR'
            },
            {
              value: '2',
              text: 'JCVC'
            },
            {
              value: '3',
              text: 'CGCS'
            },
            {
              value: '4',
              text: 'CLXC'
            },
            {
              value: '5',
              text: 'KCWC'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL20',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL20',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '5',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'NABQ'
            },
            {
              value: '2',
              text: 'PEFS'
            },
            {
              value: '3',
              text: 'RIJV'
            },
            {
              value: '4',
              text: 'GOPK'
            },
            {
              value: '5',
              text: 'CUWG'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL21',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL21',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '1',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'XDBK'
            },
            {
              value: '2',
              text: 'TNLL'
            },
            {
              value: '3',
              text: 'VEGV'
            },
            {
              value: '4',
              text: 'PFCC'
            },
            {
              value: '5',
              text: 'ZAGZ'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL22',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL22',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'DEGF'
            },
            {
              value: '2',
              text: 'KLHJ'
            },
            {
              value: '3',
              text: 'NOQP'
            },
            {
              value: '4',
              text: 'PQSR'
            },
            {
              value: '5',
              text: 'TURS'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL23',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL23',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'CAEZ'
            },
            {
              value: '2',
              text: 'CEIZ'
            },
            {
              value: '3',
              text: 'CIOZ'
            },
            {
              value: '4',
              text: 'CGVZ'
            },
            {
              value: '5',
              text: 'CAUZ'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL24',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL24',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '1',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'AOUI'
            },
            {
              value: '2',
              text: 'CTZR'
            },
            {
              value: '3',
              text: 'JHTN'
            },
            {
              value: '4',
              text: 'PBRL'
            },
            {
              value: '5',
              text: 'RTVH'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL25',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL25',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '1',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'VEBT'
            },
            {
              value: '2',
              text: 'XGDV'
            },
            {
              value: '3',
              text: 'ZIFX'
            },
            {
              value: '4',
              text: 'KXVH'
            },
            {
              value: '5',
              text: 'MZXJ'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL26',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL26',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '5',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'BEPW'
            },
            {
              value: '2',
              text: 'HJTX'
            },
            {
              value: '3',
              text: 'KNRZ'
            },
            {
              value: '4',
              text: 'KOSV'
            },
            {
              value: '5',
              text: 'WRPM'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL27',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL27',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '3',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'AFBG'
            },
            {
              value: '2',
              text: 'EJFK'
            },
            {
              value: '3',
              text: 'GKHM'
            },
            {
              value: '4',
              text: 'PSQT'
            },
            {
              value: '5',
              text: 'RWSX'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL28',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL28',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'RRBR'
            },
            {
              value: '2',
              text: 'QQAR'
            },
            {
              value: '3',
              text: 'FTEF'
            },
            {
              value: '4',
              text: 'JXIJ'
            },
            {
              value: '5',
              text: 'SSCS'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL29',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL29',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '3',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'KGDB'
            },
            {
              value: '2',
              text: 'DFIM'
            },
            {
              value: '3',
              text: 'KIFB'
            },
            {
              value: '4',
              text: 'HJMQ'
            },
            {
              value: '5',
              text: 'LHEC'
            }
          ]
        }
      ]
    },
    {
      name: 'GFL30',
      elements: [
        {
          type: 'radiogroup',
          name: 'GFL30',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '1',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'QIFB'
            },
            {
              value: '2',
              text: 'CGIJ'
            },
            {
              value: '3',
              text: 'BCOR'
            },
            {
              value: '4',
              text: 'ZRED'
            },
            {
              value: '5',
              text: 'JIFC'
            }
          ]
        }
      ]
    }
  ]
};

export default quizLetterSeries;
