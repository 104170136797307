import axios from 'axios';
// import { USER_ID } from '../config';

function removeDiacritics(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const postData = async (values, USER_ID, CODE, school) => {
  // const POST_API =
  //   'https://iathnyvk8h.execute-api.us-west-2.amazonaws.com/dev/submit';
  const POST_API = '/api/equis-two';

  const normalized_string = removeDiacritics(school);
  // 'Universidad Adolfo Ibáñez' => 'Universidad Adolfo Ibanez'
  // console.log({ normalized_string });
  const school_sheet = normalized_string
    .toLowerCase()
    .trim()
    .replace(/ /g, '-');
  // console.log({ school_sheet });
  // 'Universidad Adolfo Ibanez' => universidad-adolfo-ibanez
  // console.log({ school_sheet });
  const config = {
    SHEET_ID: '1i8C9zadoBhq2c5-cHYMDffg3OKMOp9RRr8KBUUEQLak',
    SHEET_TITLE: school_sheet
  };

  const caseid = USER_ID;
  const data = { ...values, caseid, RUT: CODE, config };
  // console.log({ data });
  await axios.post(POST_API, data);
  return Promise.resolve('200');
};

export default postData;
