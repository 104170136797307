import React, { createContext, Component } from 'react';
// import uuidv4 from 'uuid/v4';
// import { USER_ID } from '../config';

const { Provider, Consumer } = createContext({
  stage: 0,
  user: null,
  rut: null,
  school: null
});

export class StageProvider extends Component {
  state = {
    stage: 0,
    user: null,
    rut: null,
    school: null
  };

  toNextStage = async (userStage = 'userStage-anon') => {
    const { stage } = this.state;
    const newStage = stage + 1;
    localStorage.setItem(userStage, newStage);
    await this.setState(state => ({
      ...state,
      stage: state.stage + 1
    }));
  };

  toPrevStage = async (userStage = 'userStage-anon') => {
    const { stage } = this.state;
    const newStage = stage - 1;
    localStorage.setItem(userStage, newStage < 0 ? 0 : newStage);
    await this.setState(state => ({
      ...state,
      stage: state.stage - 1 < 0 ? 0 : state.stage - 1
    }));
  };

  updateContext = async newContext => {
    await this.setState(state => ({
      ...state,
      ...newContext
    }));
  };

  // componentDidMount = async () => {
  //   let user;
  //   let stage;

  //   const existingId = localStorage.getItem(USER_ID);
  //   const prevStage = localStorage.getItem('userStage');
  //   // check if user on localStorage
  //   if (existingId) {
  //     user = existingId;
  //   } else {
  //     const tempUser = uuidv4();
  //     user = tempUser;
  //     localStorage.setItem(USER_ID, tempUser);
  //   }
  //   // check if stage on localStorage
  //   if (prevStage) {
  //     stage = parseInt(prevStage, 10);
  //   } else {
  //     stage = 0;
  //     localStorage.setItem('userStage', 0);
  //   }
  //   // console.log('setting');
  //   // console.log({ user, stage });
  //   await this.setState(state => ({
  //     ...state,
  //     user,
  //     stage
  //   }));
  // };

  render = () => (
    <Provider
      value={{
        state: this.state,
        updateContext: this.updateContext,
        nextStage: this.toNextStage,
        prevStage: this.toPrevStage
      }}
    >
      {this.props.children}
    </Provider>
  );
}

export const StageConsumer = Consumer;
