import axios from 'axios';
// import { USER_ID } from '../config';

const postData = async (values, USER_ID, CODE) => {
  const POST_API = '/api/sheet';
  const config = { SHEET_ID: '1w0-cccRfjv2D24bazg-Wb71pmdIeG69erm1By8jybzw' };
  const caseid = USER_ID;
  const data = { ...values, caseid, code: CODE, config };
  // console.log({ data });
  await axios.post(`${POST_API}`, data);
  return Promise.resolve('200');
};

export default postData;
