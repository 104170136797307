import React from 'react';
import ChatBot, { ChatCustomComp } from '../ChatBot';
import ChatWrapper, { ChatTableWrapper } from './chat.style';
import NabuPic from './img/nabu-full.png';
import SortablePolarExample from './SortablePolarExample';

const ExampleList = props => {
  return (
    <ChatTableWrapper>
      <ul>
        <li>Ensalada de tomate</li>
        <li>Pizza con piña</li>
        <li>Pescado con papas fritas</li>
        <li>Helado de piña</li>
      </ul>
    </ChatTableWrapper>
  );
};

class SortableIntro extends React.Component {
  state = { loading: false };

  handleEnd = ({ steps, values }) => {
    setTimeout(() => {
      this.setState({ loading: true });
      // console.log({ steps });
      // console.log({ values });
      const data = { TUTORIAL: '1', CHAT_NAME: steps['name'].value || '' };
      this.props.onComplete(data);
    }, 2000);
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <ChatWrapper>
          <h4>Guardando respuestas para avanzar...</h4>
        </ChatWrapper>
      );
    }
    return (
      <ChatWrapper>
        <div className='nabu-chat-person'>
          <img className='nabu-pic' src={NabuPic} alt='nabu-pic' />
        </div>
        <ChatBot
          className='nabu-chat-wrapper'
          headerTitle='NABU'
          placeholder='Escribe un mensaje'
          botDelay={3200}
          handleEnd={this.handleEnd}
          steps={[
            {
              id: '1',
              message: '¡Hola!',
              delay: 1000,
              trigger: '2'
            },
            {
              id: '2',
              message: 'Mi nombre es NABU.',
              delay: 2000,
              trigger: '3'
            },
            {
              id: '3',
              message:
                'Estoy aquí para ayudarte a pensar qué actividades del mundo del trabajo podrían ser más interesantes para ti en un futuro',
              trigger: '4'
            },
            {
              id: '4',
              message: '¿Cuál es tu nombre?',
              trigger: 'name'
            },
            {
              id: 'name',
              user: true,
              trigger: '6'
            },
            {
              id: '6',
              message: 'Hola {previousValue}, gusto en conocerte!',
              trigger: 'instructions'
            },
            {
              id: 'instructions',
              delay: 5000,
              message:
                'Para ayudarte a pensar qué actividades podrían ser más interesantes para ti, te voy a mostrar cuatro actividades que podrías hacer cuando trabajes.',
              trigger: 'ins_01'
            },
            {
              id: 'ins_01',
              message: 'Para esto, voy a pedir que me indiques:',
              delay: 6000,
              trigger: '8'
            },
            {
              id: '8',
              component: (
                <ChatCustomComp>
                  Cuál es la que <strong>MÁS</strong> te gustaría hacer y cuál
                  es la que <strong>MENOS</strong> te gustaría hacer en el
                  futuro.
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 7000,
              trigger: '9'
            },
            {
              id: '9',
              component: (
                <ChatCustomComp>
                  Para hacer esto, lo que tendrás que hacer es arrastrar la
                  opción que <strong>MÁS</strong> te gusta hacia arriba y la que{' '}
                  menos te gusta hacia <strong>ABAJO</strong>.
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 7000,
              trigger: 'example'
            },
            {
              id: 'example',
              message:
                'Ensayemos con un ejemplo. Mira los siguientes platos de comida:',
              delay: 7000,
              trigger: 'example_list'
            },
            {
              id: 'example_list',
              component: <ExampleList />,
              asMessage: true,
              delay: 6000,
              trigger: 'ranking_01'
            },
            {
              id: 'ranking_01',
              component: (
                <ChatCustomComp>
                  Me fascina el helado, ¡especialmente si es de piña! <br />
                  Arrastra el item
                  <strong>
                    <em> “Helado de piña” </em>
                  </strong>
                  al principio de la lista, luego haz click en "Siguiente"
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 7000,
              trigger: 'ranking_02'
            },
            {
              id: 'ranking_02',
              component: (
                <SortablePolarExample validate={{ first: 'EXP_04' }} />
              ),
              waitAction: true,
              delay: 9000,
              trigger: 'ranking_03'
            },
            {
              id: 'ranking_03',
              component: (
                <ChatCustomComp>
                  Fíjate que ahora
                  <strong>
                    <em> “Helado de piña” </em>
                  </strong>{' '}
                  quedó en color <strong>VERDE</strong>. Eso indica que es lo
                  que <strong>MÁS</strong> me gustaría comer
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 5000,
              trigger: 'ranking_04'
            },
            {
              id: 'ranking_04',
              component: (
                <ChatCustomComp>
                  A mi me encanta la piña, ¡pero por favor no en mi pizza!
                  <br />
                  Ayúdame a mover
                  <strong>
                    <em> “Pizza con piña” </em>
                  </strong>
                  al fondo de la lista, luego haz click en "Siguiente"
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 8000,
              trigger: 'ranking_05'
            },
            {
              id: 'ranking_05',
              component: <SortablePolarExample validate={{ last: 'EXP_02' }} />,
              waitAction: true,
              delay: 9000,
              trigger: 'ranking_06'
            },
            {
              id: 'ranking_06',
              component: (
                <ChatCustomComp>
                  Fíjate que ahora
                  <strong>
                    <em> “Pizza con piña” </em>
                  </strong>
                  quedó en color <strong>ROJO</strong>. Eso indica que es lo que{' '}
                  <strong>MENOS</strong> me gustaría comer
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 5000,
              trigger: 'ranking_07'
            },
            {
              id: 'ranking_07',
              component: (
                <ChatCustomComp>
                  Lo importante es seleccionar lo que <strong>MÁS</strong> te
                  gustaría y lo que <strong>MENOS</strong> te gustaría.
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 8000,
              trigger: 'ranking_08'
            },
            {
              id: 'ranking_08',
              message:
                'El orden de las opciones restantes no es importante para obtener tu puntaje en este test.',
              delay: 7000,
              trigger: 'ranking_09'
            },

            {
              id: 'ranking_09',
              message: 'Ahora comenzará el test de verdad.',
              delay: 4000,
              trigger: 'ranking_10'
            },
            {
              id: 'ranking_10',
              delay: 5000,
              message:
                'Es importante que pienses bien sobre cada actividad y te imagines si te gustaría realmente estar haciendo algo como eso en el futuro.',
              trigger: 'ranking_11'
            },
            {
              id: 'ranking_11',
              message:
                '¡Si respondes este test de manera honesta las recomendaciones que te haremos serán útiles!',
              delay: 8000,
              trigger: 'instruction_replay'
            },
            {
              id: 'instruction_replay',
              delay: 7000,
              message:
                '¿Quieres que repita las instrucciones desde el comienzo?',
              trigger: 'replay_select'
            },
            {
              id: 'replay_select',
              options: [
                { value: 1, label: 'Si, repetir', trigger: 'instructions' },
                {
                  value: 2,
                  label: 'No, Comenzar test!',
                  trigger: 'end_last'
                }
              ]
            },
            // {
            //   id: 'end_first',
            //   message: 'Listo! Aquí viene la primera lista de actividades.',
            //   trigger: 'end_middle'
            // },
            // {
            //   id: 'end_middle',
            //   message:
            //     'Deja en la parte de arriba la actividad que más te gustaría hacer en el futuro y abajo la que menos te gustaría hacer en el futuro.',
            //   trigger: 'end_last'
            // },
            {
              id: 'end_last',
              message: 'Aquí vamos!',
              end: true
            }
          ]}
        />
      </ChatWrapper>
    );
  }
}

export default SortableIntro;
