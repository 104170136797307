const WorkDemands = {
  locale: 'es',
  title: 'Exigencias en el Trabajo',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'WORK_DEMANDS',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
              Indique su grado de acuerdo con las siguientes frases <strong>utilizando una escala de 1 (Totalmente en Desacuerdo) a 4 (Completamente de Acuerdo).</strong>
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'WORK_DEMANDS',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            }
          ],
          rows: [
            {
              value: 'WDEM_01',
              text: 'Mi trabajo exige ir muy deprisa'
            },
            {
              value: 'WDEM_02',
              text: 'Mi trabajo exige trabajar con mucho esfuerzo mental'
            },
            {
              value: 'WDEM_03',
              text: 'Se me pide hacer una cantidad excesiva de trabajo'
            },
            {
              value: 'WDEM_04',
              text: 'No tengo suficiente tiempo para hacer mi trabajo'
            },
            {
              value: 'WDEM_05',
              text: 'Recibo peticiones contradictorias de los demás'
            },
            {
              value: 'WDEM_06',
              text:
                'Mi trabajo me obliga a concentrarme durante largos periodos de tiempo'
            },
            {
              value: 'WDEM_07',
              text:
                'Mi tarea es a menudo interrumpida antes de haberla acabado y debo finalizarla más tarde'
            },
            {
              value: 'WDEM_08',
              text:
                'A menudo me retraso en mi trabajo porque debo esperar al trabajo de los demás'
            }
          ]
        }
      ]
    },
    {
      name: 'WORK_SATISFACTION',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                ¿Qué tan satisfecho se encuentra actualmente con los siguientes aspectos? Por favor, responda <strong>utilizando una escala de 1 a 5, donde 1 es “Nada satisfecho” y 5 es “Muy satisfecho”.</strong>
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'WORK_SATISFACTION',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: '1'
            },
            {
              value: '2',
              text: '2'
            },
            {
              value: '3',
              text: '3'
            },
            {
              value: '4',
              text: '4'
            },
            {
              value: '5',
              text: '5'
            }
          ],
          rows: [
            {
              value: 'WSAT_01',
              text: 'La relación con los compañeros de trabajo'
            },
            {
              value: 'WSAT_02',
              text: 'La estabilidad de su trabajo'
            },
            {
              value: 'WSAT_03',
              text: 'El prestigio del colegio'
            },
            {
              value: 'WSAT_04',
              text:
                'La compatibilidad del trabajo con la vida personal y familiar'
            },
            {
              value: 'WSAT_05',
              text: 'El estilo de liderazgo de su jefe o supervisor directo'
            },
            {
              value: 'WSAT_06',
              text: 'Las posibilidades de opinión y participación'
            },
            {
              value: 'WSAT_07',
              text: 'El nivel de entretención que le produce su trabajo'
            },
            {
              value: 'WSAT_08',
              text:
                'El grado de cooperación que existe entre las distintas áreas'
            },
            {
              value: 'WSAT_09',
              text: 'Su sueldo y beneficios económicos'
            },
            {
              value: 'WSAT_10',
              text: 'Las posibilidades de capacitación y desarrollo profesional'
            },
            {
              value: 'WSAT_11',
              text: 'Las posibilidades para crear e innovar que se le otorgan'
            }
          ]
        }
      ]
    }
  ]
};

export default WorkDemands;
