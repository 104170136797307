import React from 'react';
import BigFive from './BigFive';
import CareerSuccess from './CareerSuccess';
import Demographics from './Demographics';
// import GFLExample from '../GFLExample';
// import GFLComplete from '../GFLComplete';
import GFNExample from './GFNExample';
import GFNComplete from './GFNComplete';
// import GoalOrientation from '../Test/GoalOrientation';
import LearningAgility from './LearningAgility';
// import Sociodemographics from '../Test/Sociodemographics';
import WorkingInfo from './WorkingInfo';
import GoalOrientation from './GoalOrientation';
import GeneralSelfEff from './AutoefGeneralizada';
import FinishedMessage from './FinishedMessage';

import postData from './API';
import getConfig from '../../config';

class MBATest extends React.Component {
  componentDidMount = async () => {
    // const { BATTERY, updateContext } = this.props;
    const { BATTERY, updateContext, rut } = this.props;
    // const rut = localStorage.getItem(`currentCode-${BATTERY}`);

    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    // const { stage } = this.props;
    // await postData(data);
    // if (stage < 7) {
    //   return this.props.toNextStage();
    // }
    const { BATTERY, stage, user, rut = 'anon' } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    await postData(data, user, rut);
    if (stage < 9) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, BATTERY } = this.props;

    if (stage > 8) {
      return <FinishedMessage />;
    }
    if (stage === 8) {
      return (
        <GeneralSelfEff onComplete={this.onComplete} BATTERY={BATTERY} isLast />
      );
    }
    if (stage === 7) {
      return <GoalOrientation onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 6) {
      return <WorkingInfo onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 5) {
      return <CareerSuccess onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 4) {
      return <GFNComplete onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 3) {
      return <GFNExample onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 2) {
      return <BigFive onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 1) {
      return <LearningAgility onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    return <Demographics onComplete={this.onComplete} BATTERY={BATTERY} />;
  }
}

export default MBATest;
