const baseInfo = {
  locale: 'es',
  title: 'Calendario',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01_2',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend class="legend-blank-space">
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3> </h3>
                  </span>
                </span>
              </div>
              <br>

            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
