const rateValues = [
  {
    value: '1',
    text: 'Muy en desacuerdo'
  },
  {
    value: '2',
    text: 'Ligeramente en desacuerdo'
  },
  {
    value: '3',
    text: 'Ni de acuerdo ni en desacuerdo'
  },
  {
    value: '4',
    text: 'Ligeramente de acuerdo'
  },
  {
    value: '5',
    text: 'Muy de acuerdo'
  }
];

const surveyBig5 = {
  locale: 'es',
  title: 'Características de personalidad',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'BIG5_01',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>
          </div>
        `
        },
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_01',
          title: 'Es sociable, le gusta conocer a otras personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_02',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_02',
          title: 'Es compasivo(a), bondadoso',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_03',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_03',
          title: 'Tiende a ser desorganizado(a)',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_04',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_04',
          title: 'Es relajado(a), maneja bien el estrés',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_05',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_05',
          title: 'Tiene pocos intereses artísticos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_06',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_06',
          title: 'Tiene una personalidad dominante',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_07',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_07',
          title: 'Es considerado(a), trata a las demás personas con respeto',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_08',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_08',
          title: 'Con frecuencia siente flojera',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_09',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_09',
          title: 'Se mantiene optimista después de tener un contratiempo',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_10',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_10',
          title: 'Siente curiosidad por diferentes temas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_11',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_11',
          title: 'Raramente se siente entusiasmado(a)',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_12',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_12',
          title: 'Tiende a criticar a los demás',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_13',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_13',
          title: 'Es confiable',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_14',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_14',
          title: 'Es temperamental, de humor cambiante',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_15',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_15',
          title:
            'Es ingenioso(a), encuentra formas diferentes de hacer las cosas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_16',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_16',
          title: 'Tiende a ser callado(a)',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_17',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_17',
          title: 'Se interesa poco por las otras personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_18',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_18',
          title: 'Es organizado(a), le gusta mantener las cosas en orden',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_19',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_19',
          title: 'Con frecuencia se pone tenso(a)',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_20',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_20',
          title: 'Le gusta mucho el arte, la música, o la literatura ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_21',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_21',
          title: 'Es influyente, actúa como un líder',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_22',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_22',
          title: 'Con frecuencia inicia discusiones con otras personas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_23',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_23',
          title: 'Le cuesta empezar a hacer una tarea ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_24',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_24',
          title: 'Se siente satisfecho(a) consigo mismo(a) ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_25',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_25',
          title: 'Evita las discusiones intelectuales o filosóficas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_26',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_26',
          title: 'No es tan activo(a) como otras personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_27',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_27',
          title: 'Le es fácil perdonar a otras personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_28',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_28',
          title: 'A veces puede ser descuidado(a) ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_29',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_29',
          title: 'Es estable emocionalmente, no se molesta con facilidad ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_30',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_30',
          title: 'Tiene poca creatividad',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_31',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_31',
          title: 'A veces es tímido(a), introvertido(a) ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_32',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_32',
          title: 'Es generoso(a) y ayuda a los demás ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_33',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_33',
          title: 'Mantiene las cosas limpias y ordenadas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_34',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_34',
          title: 'Se estresa mucho por las cosas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_35',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_35',
          title: 'Valora el arte y la belleza ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_36',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_36',
          title: 'Encuentra difícil influenciar a otras personas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_37',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_37',
          title: 'A veces es maleducado(a) con los demás ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_38',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_38',
          title: 'Es eficiente, termina las cosas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_39',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_39',
          title: 'A menudo se siente triste ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_40',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_40',
          title: 'Es un pensador profundo ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_41',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_41',
          title: 'Es una persona llena de energía ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_42',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_42',
          title: 'Es desconfiado(a) de las intenciones de otros ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_43',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_43',
          title: 'Es responsable ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_44',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_44',
          title: 'Mantiene sus emociones bajo control ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_45',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_45',
          title: 'Tiene poca imaginación ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_46',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_46',
          title: 'Es bien hablador(a)',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_47',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_47',
          title: 'Puede ser frío(a) y distante ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_48',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_48',
          title: 'Es desordenado(a) ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_49',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_49',
          title: 'Raramente se siente ansioso(a) ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_50',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_50',
          title: 'Cree que la poesía y el teatro son aburridos ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_51',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_51',
          title: 'Prefiere que otros tomen la iniciativa ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_52',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_52',
          title: 'Es respetuoso(a), cortés con las demás personas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_53',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_53',
          title: 'Es persistente, trabaja hasta que la tarea esté finalizada ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_54',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_54',
          title: 'Tiende a sentirse deprimido, triste ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_55',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_55',
          title: 'Tiene poco interés en las ideas abstractas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_56',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_56',
          title: 'Es una persona muy entusiasta ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_57',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_57',
          title: 'Cree que las personas tienen buenas intenciones  ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_58',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_58',
          title: 'A veces se comporta irresponsablemente ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_59',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_59',
          title: 'Es temperamental, se irrita fácilmente ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_60',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_60',
          title: 'Es original, se le ocurren nuevas ideas ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    }
  ]
};

export default surveyBig5;
