const baseInfo = {
  locale: 'es',
  title: 'Positividad frente a la enfermedad',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'POS_PAGE_01',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
    Ya estamos terminando la encuesta.
    <br />
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <br />
            <legend>
              <span style="font-size:16px;">
              Incluso cuando las personas están viviendo situaciones difíciles o extremas pueden haber momentos buenos o surgir experiencias positivas. En relación a esto:
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'radiogroup',
          name: 'POS_01',
          title:
            'Como familia ¿Han tenido experiencias que usted catalogaría como positivas relacionadas con la enfermedad?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            }
          ]
        },
        {
          type: 'text',
          title:
            'En una frase ¿Cómo describiría esas experiencias o momentos positivos en su familia?',
          name: 'POS_02',
          isRequired: true,
          visibleIf: '{POS_01} = "01"'
        }

        // {
        //   type: 'checkbox',
        //   name: 'POS_02',
        //   title: `¿Qué palabras usaría para describir esas experiencias o momentos positivos?`,
        //   isRequired: true,
        //   visibleIf: '{POS_01} = "1"',
        //   // colCount: 7,
        //   // hasOther: true,
        //   choices: [
        //     {
        //       value: '01',
        //       text: 'Paciencia'
        //     },
        //     {
        //       value: '02',
        //       text: 'Fe'
        //     },
        //     {
        //       value: '03',
        //       text: 'Felicidad'
        //     },
        //     {
        //       value: '04',
        //       text: 'Esperanza'
        //     },
        //     {
        //       value: '05',
        //       text: 'Aceptación'
        //     },
        //     {
        //       value: '06',
        //       text: 'Estabilidad'
        //     },
        //     {
        //       value: '07',
        //       text: 'Compasión'
        //     },
        //     {
        //       value: '08',
        //       text: 'Fortaleza'
        //     },
        //     {
        //       value: '09',
        //       text: 'Tranquilidad'
        //     },
        //     {
        //       value: '10',
        //       text: 'Apoyo'
        //     },
        //     {
        //       value: '11',
        //       text: 'Satisfacción'
        //     },
        //     {
        //       value: '12',
        //       text: 'Otro'
        //     }
        //   ]
        // },
        // {
        //   type: 'text',
        //   title: 'Si otro, especifique:',
        //   name: 'POS_02_OTHER',
        //   isRequired: true,
        //   visibleIf: '{POS_02} contains "12"'
        // }
      ]
    }
    // {
    //   name: 'POS_PAGE_02',
    //   // title: 'Impacto familiar',
    //   elements: [
    //     {
    //       type: 'html',
    //       name: 'HTML_IMPACT_TITLE',
    //       html: `
    //       <div>
    //         <br />
    //         <legend>
    //           <span style="font-size:16px;">
    //           Incluso cuando las personas experimentan situaciones difíciles o extremas aparecen
    //           recursos y capacidades que ayudan a sobrellevar la situación y generan momentos o
    //           surgen experiencias positivas. A propósito de lo dicho anteriormente:
    //           </span>
    //         </legend>
    //       </div>
    //       `
    //     },
    //     {
    //       type: 'checkbox',
    //       name: 'POS_03',
    //       title: `En relación a su núcleo familiar, ¿Han experimentado algunos de estos cambios? Maque todas las alternativas correctas.`,
    //       isRequired: true,
    //       // colCount: 7,
    //       // hasOther: true,
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'Mayor unión familiar'
    //         },
    //         {
    //           value: '2',
    //           text: 'Valoración de la vida'
    //         },
    //         {
    //           value: '3',
    //           text: 'Valorar el tiempo compartido'
    //         },
    //         {
    //           value: '4',
    //           text: 'Mejores relaciones personales de la familia'
    //         },
    //         {
    //           value: '5',
    //           text: 'Expansión de las redes de apoyo '
    //         },
    //         {
    //           value: '6',
    //           text: 'Otros cambios'
    //         }
    //       ]
    //     },
    //     {
    //       type: 'text',
    //       title: 'Si otro, especifique:',
    //       name: 'POS_03_OTHER',
    //       isRequired: true,
    //       visibleIf: '{POS_03} contains "6"'
    //     }
    //   ]
    // }
  ]
};

export default baseInfo;
