const baseInfo = {
  locale: 'es',
  title: 'La enfermedad y su impacto en la familia.',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      title: 'Impacto en el/la niño/a en tratamiento',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
    *La siguiente pregunta se responde <strong>sólo si el niño/a va/fue  en el colegio</strong>
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'radiogroup',
          name: 'TREAT_IMP_00',
          title: '¿{CHILD_NAME} va o fue al colegio?',
          isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'TREAT_IMP_01',
          title:
            'A consecuencia de la enfermedad, ¿Han tenido que cambiarlo o sacarlo de la escuela/colegio?',
          isRequired: true,
          visibleIf: '{TREAT_IMP_00} = "01"',
          choices: [
            {
              value: '01',
              text: 'Sacarlo'
            },
            {
              value: '02',
              text: 'Cambiarlo'
            },
            {
              value: '03',
              text: 'Ambos'
            },
            {
              value: '04',
              text: 'Ninguna'
            },
            {
              value: '05',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'TREAT_IMP_02',
          title:
            'Y a consecuencia de la enfermedad ¿Ha repetido una o más veces de curso?',
          isRequired: true,
          visibleIf: '{TREAT_IMP_00} = "01"',
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      title: 'Impacto en el/la niño/a en tratamiento',
      elements: [
        {
          type: 'comment',
          name: 'TREAT_IMP_03',
          isRequired: true,
          title:
            '¿Qué cambios ha observado en la conducta y/o estado emocional de {CHILD_NAME}, después del diagnóstico?'
        }
      ]
    },
    {
      name: 'DEMO_PAGE_03',
      title: 'Impacto en el/la niño/a en tratamiento',
      elements: [
        {
          type: 'radiogroup',
          name: 'TREAT_IMP_04',
          isRequired: true,
          title:
            '¿Diría usted que, a propósito de la enfermedad, la vida de {CHILD_NAME} se ha visto afectada en alguno de los siguientes aspectos?',
          choices: [
            {
              value: '01',
              text:
                'Menos tiempo para actividades de juego e interacción con otros niños/as'
            },
            {
              value: '02',
              text: 'Más dificultades para reunirse con amistades'
            },
            {
              value: '03',
              text: 'Tener que renunciar al cuidado de una mascota'
            },
            {
              value: '04',
              text:
                'Menos o dificultades para dedicarse a sus hobbies o deporte favorito'
            },
            {
              value: '05',
              text:
                'Dificultades para participar en actividades extracurriculares del colegio'
            },
            {
              value: '06',
              text: 'Otra dificultad'
            },
            {
              value: '07',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'TREAT_IMP_04_OTHER',
          isRequired: true,
          visibleIf: '{TREAT_IMP_04} = "06"'
        }
      ]
    }

    // {
    //   name: 'DEMO_PAGE_02',
    //   title: 'Impacto en el/la niño/a en tratamiento',
    //   elements: [
    //     {
    //       type: 'radiogroup',
    //       name: 'CHILD_02',
    //       title:
    //         '¿Diría usted que, a propósito de la enfermedad, la relación con sus otros hijos se ha visto afectada negativamente?',
    //       isRequired: true,
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'Menos tiempo para jugar con los amigos'
    //         },
    //         {
    //           value: '2',
    //           text: 'Menos tiempo para hacer actividades que le gustan'
    //         },
    //         {
    //           value: '3',
    //           text: 'Dificultades para hacer cosas que le gustan'
    //         },
    //         {
    //           value: '4',
    //           text: 'Otro'
    //         }
    //       ]
    //     },
    //     {
    //       type: 'text',
    //       title: 'Especifique:',
    //       name: 'CHILD_02_OTHER',
    //       isRequired: true,
    //       visibleIf: '{CHILD_02} = "4"'
    //     }
    //   ]
    // }
  ]
};

export default baseInfo;
