import React from 'react';
import BigFive from '../BigFive';
import CareerSuccess from '../CareerSuccess';
import Demographics from '../Demographics';
import GFLComplete from '../GFLComplete';
import GFLExample from '../GFLExample';
import GFNComplete from '../GFNComplete';
import GFNExample from '../GFNExample';
import GoalOrientation from '../GoalOrientation';
import LearningAgility from '../LearningAgility';
import Remuneration from '../Remuneration';
import Sociodemographics from '../Sociodemographics';
import WorkingInfo from '../WorkingInfo';
import CalendarBooking from '../CalendarBooking';
import CalendlyComponent from '../CalendlyComponent';
import AudioTest from '../AudioTest';
import ResponseTime from '../ResponseTime';
import PairRangeDistance from '../PairRangeDistance';
import PolarSelect from '../PolarSelect';
import FinishedMessage from '../FinishedMessage';
import SortableList from '../SortableList';
import SortableSimpleList from '../SortableSimpleList';
import SortableHorizontal from '../SortableHorizontal';
import SortablePolarList from '../SortablePolarList';
import GameDodgy from '../GameDodgy';
import { TestWrapper } from './test.styles';

class TestSurvey extends React.Component {
  state = { test: '' };
  //Define a callback methods on survey complete
  onComplete = data => {
    //Write survey results into database
    console.log('Survey results: ');
    console.log(data);
  };

  gotoTest = test => this.setState({ test });

  renderSelector = () => {
    return (
      <TestWrapper>
        <div className='quanta_main'>
          <div className='panel-heading'>
            <h3>Repositorio</h3>
          </div>
          <div className='card-block mt-4'>
            <div className='quanta_page'>
              <div>
                <span>
                  Lista ejemplo de test disponibles y desarrollados hasta el
                  momento:
                </span>
                <br />
                <br />
              </div>
              <div className='example_list'>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('Demographics')}>
                    Demográficos
                  </button>
                  <div className='info'>Demográficos básico</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('BigFive')}>
                    Big Five
                  </button>
                  <div className='info'>Personalidad, BIG 5, 60 items</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('CareerSuccess')}>
                    Career Success
                  </button>
                  <div className='info'>Desarrollo de carrera</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('GFLExample')}>
                    GFL Example
                  </button>
                  <div className='info'>Gf, Serie de Letras, Ejemplo</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('GFLComplete')}>
                    GFL Complete
                  </button>
                  <div className='info'>Gf, Serie de Letras, Completo</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('GFNComplete')}>
                    GFN Complete
                  </button>
                  <div className='info'>Gf, Serie de números, Completo</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('GFNExample')}>
                    GFN Example
                  </button>
                  <div className='info'>Gf, Serie de números, ejemplos</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('Sociodemographics')}>
                    SocioDemographics
                  </button>
                  <div className='info'>
                    Demográfico, información estudios y sociodemográfico
                  </div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('WorkingInfo')}>
                    Working Info
                  </button>
                  <div className='info'>
                    Demográfico, información de trabajo
                  </div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('Remuneration')}>
                    Remuneration
                  </button>
                  <div className='info'>
                    Demográfico, remuneraciones (ingresos)
                  </div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('GoalOrientation')}>
                    Goal Orientation
                  </button>
                  <div className='info'>Orientación a tareas</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('LearningAgility')}>
                    Learning Agility
                  </button>
                  <div className='info'>Aprendizaje</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('CalendarBooking')}>
                    Agendar calendario
                  </button>
                  <div className='info'>Agendar Calendario</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('CalendlyComponent')}>
                    Calendly Component
                  </button>
                  <div className='info'>Calendario con Calendly (externo)</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('AudioTest')}>
                    AudioTest
                  </button>
                  <div className='info'>Prueba de audio (ingles)</div>
                </div>

                <div className='example_item'>
                  <button onClick={() => this.gotoTest('ResponseTime')}>
                    ResponseTime
                  </button>
                  <div className='info'>Tiempo de respuesta (opcional)</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('PairRangeDistance')}>
                    PairRangeDistance
                  </button>
                  <div className='info'>Distancia de pares de conceptos</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('PolarSelect')}>
                    PolarSelect
                  </button>
                  <div className='info'>Selección polar binaria</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('FinishedMessage')}>
                    FinishedMessage
                  </button>
                  <div className='info'>Mensaje Agradecimiento</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('SortableList')}>
                    SortableList
                  </button>
                  <div className='info'>Orden Lista items</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('SortableSimpleList')}>
                    SortableSimpleList
                  </button>
                  <div className='info'>Orden Lista items simple</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('SortableHorizontal')}>
                    SortableHorizontal
                  </button>
                  <div className='info'>Orden Lista items simple</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('SortablePolarList')}>
                    SortablePolarList
                  </button>
                  <div className='info'>Orden Lista items polares</div>
                </div>
                <div className='example_item'>
                  <button onClick={() => this.gotoTest('GameDodgy')}>
                    GameDodgy
                  </button>
                  <div className='info'>Juego Dodgy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TestWrapper>
    );
  };

  render() {
    // console.log(this.props);
    const { test } = this.state;
    switch (test) {
      case 'Demographics':
        return <Demographics onComplete={this.onComplete} />;
      case 'BigFive':
        return <BigFive onComplete={this.onComplete} />;
      case 'CareerSuccess':
        return <CareerSuccess onComplete={this.onComplete} />;
      case 'GFLComplete':
        return <GFLComplete onComplete={this.onComplete} />;
      case 'GFLExample':
        return <GFLExample onComplete={this.onComplete} />;
      case 'GFNComplete':
        return <GFNComplete onComplete={this.onComplete} />;
      case 'GFNExample':
        return <GFNExample onComplete={this.onComplete} />;
      case 'Sociodemographics':
        return <Sociodemographics onComplete={this.onComplete} />;
      case 'GoalOrientation':
        return <GoalOrientation onComplete={this.onComplete} />;
      case 'Remuneration':
        return <Remuneration onComplete={this.onComplete} />;
      case 'LearningAgility':
        return <LearningAgility onComplete={this.onComplete} />;
      case 'WorkingInfo':
        return <WorkingInfo onComplete={this.onComplete} />;
      case 'CalendarBooking':
        return <CalendarBooking onComplete={this.onComplete} />;
      case 'CalendlyComponent':
        return <CalendlyComponent onComplete={this.onComplete} />;
      case 'AudioTest':
        return <AudioTest onComplete={this.onComplete} />;
      case 'ResponseTime':
        return <ResponseTime onComplete={this.onComplete} />;
      case 'PairRangeDistance':
        return <PairRangeDistance onComplete={this.onComplete} />;
      case 'PolarSelect':
        return <PolarSelect onComplete={this.onComplete} />;
      case 'FinishedMessage':
        return <FinishedMessage onComplete={this.onComplete} />;
      case 'SortableList':
        return <SortableList onComplete={this.onComplete} />;
      case 'SortableSimpleList':
        return <SortableSimpleList onComplete={this.onComplete} />;
      case 'SortableHorizontal':
        return <SortableHorizontal onComplete={this.onComplete} />;
      case 'SortablePolarList':
        return <SortablePolarList onComplete={this.onComplete} />;
      case 'GameDodgy':
        return <GameDodgy onComplete={this.onComplete} />;
      default:
        return this.renderSelector();
    }
  }
}

export default TestSurvey;
