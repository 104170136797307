const baseInfo = {
  locale: 'es',
  title: 'Presentación',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
<legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          <h3>Presentación</h3>
        </span>
      </span>
    </div>
    <br>
    <span style="font-size:16px;">
      <hr style="border: 2px dashed grey;">
      <span style="font-family:arial,helvetica,sans-serif;">
        Buenos dias / Buenas tardes, hablo con (Nombre del encuestado)?
        <br />
        Mi nombre es Amal Dababneh.
        <br />
        Lo/la estoy llamando de parte de DKMS, para hacer la encuesta telefonica sobre la enfermedades oncológicas en pacientes pedriátricos en Chile. Se acuerda?
        <br />
        ¿Tiene un tiempo para hablar ahora?
        <br />
        [<strong>OJO: </strong>en caso que la relación no sea padre o madre:]
        [Esta encuesta está diseñada para ser respondida por el Padre o la Madre del Niño. ¿Tiene algún contacto para poder enviarle información sobre la encuesta?]
        <br />
        <strong>Le recuerdo que le encuesta dura aprox. 20 min.</strong>
        <br>
      </span>
      <hr style="border: 2px dashed grey;">
    </span>

  </div>
  <hr />
</legend>
`
        }
      ]
    }
  ]
};

export default baseInfo;
