import styled, { css } from 'styled-components';

const SortableListWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const DroppableListWrapper = styled.div`
  ${'' /* padding: 2rem 1rem; */}
  background-color: inherit;
  border-radius: 4px;
  transition: all 0.1s;
  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      background-color: rgba(250, 250, 250, 0.7);
    `};
`;

export const ListItemWrapper = styled.div`
  user-select: none;
  padding: 10px 15px;
  margin: 5px 10px;
  font-weight: 500;
  font-size: 0.85rem;
  color: #212529;
  background: #fff;
  border: 1px dashed ${({ theme }) => theme.colors.blue};
  transition: background 0.2s ease-in-out;
  display: block;
  text-align: center;
  border-radius: 4px;
  ${({ isDragging }) =>
    isDragging &&
    css`
      background: ${({ theme }) => theme.colors.brandColor};
      color: #fff;
    `};

  &:hover {
    background: ${({ theme }) => theme.colors.itemBackgroundHover};
  }

  &:nth-child(1) {
    border: 1px dashed ${({ theme }) => theme.colors.success};
    color: #155724;
    background-color: #d4edda;
  }
  &:nth-child(4) {
    border: 1px dashed ${({ theme }) => theme.colors.danger};
    color: #721c24;
    background-color: #f8d7da;
  }
`;

export const DropZone = styled.div`
  ${'' /* display: flex; */}
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  ${'' /* align-items: start; */}
  /* stop the list collapsing when empty */
  ${'' /* min-width: 600px; */}
  /* stop the list collapsing when it has no items */
  min-height: 80px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`;

export const ScrollContainer = styled.div`
  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  padding: 1rem 10px 0.5rem 10px;
  text-align: center;
`;
export const Button = styled.button`
  align-self: center;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 0.85rem;
  color: #fff;
  background: ${({ theme }) => theme.colors.brandColor};
  border: 2px solid ${({ theme }) => theme.colors.brandColor};
  transition: all 0.2s ease-in-out;
  text-align: center;
  border-radius: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.brandSolid};
    border-color: ${({ theme }) => theme.colors.brandSolid};
  }
  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
      border: none;
      &:hover {
        background: ${({ theme }) => theme.colors.disabled};
        border-color: ${({ theme }) => theme.colors.disabled};
      }
    `}
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  background-color: ${({ theme }) => theme.colors.extraLight};
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  margin: 5px 10px;
`;

export default SortableListWrapper;
