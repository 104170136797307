import React, { Fragment, useState } from 'react';
import { ValuesProvider, ValuesConsumer } from '../innerContext';
import SortableListGroup from './SortableListGroup';
import SortableListWrapper from './sortableList.style';
import Progress from '../../Progress';
import { getFirstNWinners } from '../../helpers';

import ITEMS from './items';

const SortablePolar = props => {
  const { onComplete, user, campaign } = props;
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleComplete = async ({ values }) => {
    await setLoading(true);
    // console.log('is Finished');
    // console.log({ values });
    // console.log(JSON.stringify(values, null, 2));
    const responses = values
      .map(resp => Object.entries(resp).map(c => c[1]))
      .flat();

    const allDimensions = responses.map(c => c.dimension);
    const dimensions = Array.from(new Set([...allDimensions]));

    const resume = dimensions.map(dim => {
      const filtered = responses.filter(resp => resp.dimension === dim);
      const sum = filtered.reduce((acc, current, i) => acc + current.value, 0);
      const mean = sum / filtered.length;
      return { dimension: dim, sum, mean };
    });
    const dimensionsSorted = [...resume].sort((a, b) => b.sum - a.sum);

    const firstTwoDimensions = getFirstNWinners(dimensionsSorted, 2);

    const labeled = resume.reduce((acc, current) => {
      const { dimension, sum } = current;
      return { ...acc, [`${dimension}_SUM`]: sum };
    }, {});

    const labeledWinners = firstTwoDimensions.reduce((acc, current, index) => {
      const { dimension } = current;
      return { ...acc, [`TOP_DIM_${index + 1}`]: dimension };
    }, {});

    // console.log({ resume, dimensionsSorted, labeled, labeledWinners });

    const parsedValues = responses.reduce((acc, response, index) => {
      return { ...acc, [response.code]: response.value };
    }, {});

    // console.log({ parsedValues, labeled });
    const parsedWithSums = { ...parsedValues, ...labeled, ...labeledWinners };
    return onComplete(parsedWithSums);
  };

  const handleNext = async (items, toNextItem) => {
    // flag visible transition
    await setVisible(false);

    const firstItem = { ...items[0], value: 1 };
    const lastItem = { ...items[items.length - 1], value: -1 };
    const clone = items.slice();
    clone[0] = firstItem;
    clone[items.length - 1] = lastItem;

    const data = [{ ...items, ...clone }];

    // get finished status
    let finished = false;
    const innerComplete = data => {
      finished = true;
      return handleComplete(data);
    };
    await toNextItem(data, innerComplete);
    if (finished) {
      return true;
    }
    // animation if no complete
    // flag visible transition
    setTimeout(() => setVisible(true), 300);
  };

  const random = true;

  if (loading) {
    return (
      <SortableListWrapper>
        <h4>Guardando respuestas para avanzar...</h4>
      </SortableListWrapper>
    );
  }

  return (
    <ValuesProvider items={ITEMS} user={user} campaign={campaign} random={random}>
      <ValuesConsumer>
        {({
          state,
          toNextItem,
          getValues,
          currentItem,
          allItems
          // allItemsOrdered
        }) => {
          return (
            <Fragment>
              <Progress currentIndex={state.currentIndex} allItems={allItems} />
              <SortableListGroup
                items={currentItem}
                toNextItem={items => handleNext(items, toNextItem)}
                visible={visible}
                internalScroll
              />
            </Fragment>
          );
        }}
      </ValuesConsumer>
    </ValuesProvider>
  );
};

export default SortablePolar;
