const baseInfo = {
  locale: 'es',
  title: 'Prueba Audio',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'AUDIO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Preguntas</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                A continuación deberá responder una serie de preguntas en relación al audio recién escuchado.
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'AUDIO_PAGE_02',
      elements: [
        {
          type: 'radiogroup',
          name: 'AUD_Q01',
          title: 'De que se trataba la conversación?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text:
                'Sobre un trabajo pendiente que tenían que entregar durante el día'
            },
            {
              value: '2',
              text: 'Sobre cuál era el mejor almuerzo de cada uno'
            },
            {
              value: '3',
              text: 'Sobre una historia de manejo de automóvil'
            },
            {
              value: '4',
              text: 'Sobre los peligros de los autos'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'AUD_Q02',
          title: 'Cual era la preocupación de la mujer?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text:
                'Sobre un trabajo pendiente que tenían que entregar durante el día'
            },
            {
              value: '2',
              text: 'Sobre cuál era el mejor almuerzo de cada uno'
            },
            {
              value: '3',
              text: 'Sobre una historia de manejo de automóvil'
            },
            {
              value: '4',
              text: 'Sobre los peligros de los autos'
            }
          ]
        }
      ]
    },
    {
      name: 'AUDIO_PAGE_03',
      elements: [
        {
          type: 'radiogroup',
          name: 'AUD_Q03',
          title: 'Otra....',
          isRequired: true,
          choices: [
            {
              value: '1',
              text:
                'Sobre un trabajo pendiente que tenían que entregar durante el día'
            },
            {
              value: '2',
              text: 'Sobre cuál era el mejor almuerzo de cada uno'
            },
            {
              value: '3',
              text: 'Sobre una historia de manejo de automóvil'
            },
            {
              value: '4',
              text: 'Sobre los peligros de los autos'
            }
          ]
        }
      ]
    },
    {
      name: 'AUDIO_PAGE_04',
      elements: [
        {
          type: 'radiogroup',
          name: 'AUD_Q04',
          title: 'Otra más....',
          isRequired: true,
          choices: [
            {
              value: '1',
              text:
                'Sobre un trabajo pendiente que tenían que entregar durante el día'
            },
            {
              value: '2',
              text: 'Sobre cuál era el mejor almuerzo de cada uno'
            },
            {
              value: '3',
              text: 'Sobre una historia de manejo de automóvil'
            },
            {
              value: '4',
              text: 'Sobre los peligros de los autos'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
