const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  },
  {
    value: '8',
    text: '8'
  },
  {
    value: '9',
    text: '9'
  }
];

const questionFooter = {
  type: 'html',
  name: 'HTML_QUESTION_FOOTER',
  html: `
  <div class="qstn-likert-label pb-5">
    <div>No me representa para nada</div>
    <div></div>
    <div>Me representa muy bien</div>
  </div>
  `
};
const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_Header',
  html: `
  <div class="QuestionText BorderColor">
    <span style="font-size:16px;">
      Por favor indica en qué medida estas afirmaciones te representan utilizando la siguiente escala:
    </span>
  </div>
  `
};

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DI_PAGE_00_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01_DIM',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <h3>Instrucciones</h3>
    </span>
    <br />
    Continuando con el ejercicio, la siguiente lista de afirmaciones representa diversas dificultades que las personas suelen tener al momento de decidir qué hacer al terminar el colegio.
    <br />
    <br />
    Por favor indica en qué medida estas afirmaciones te representan usando una escala de <strong>1</strong> (No me representa para nada) a <strong>9</strong> (Me representa muy bien), tal como se presenta a continuación:
    <br />
    <br />
    <div class="btn-group">
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>1</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>2</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>3</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>4</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>5</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="6" value="6"><span class="quanta_q_rating_item_text"><span>6</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="7" value="7"><span class="quanta_q_rating_item_text"><span>7</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="8" value="8"><span class="quanta_q_rating_item_text"><span>8</span></span></label>
      <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="9" value="9"><span class="quanta_q_rating_item_text"><span>9</span></span></label>
    </div>
    <div class="qstn-likert-label pb-5">
      <div>No me representa para nada</div>
      <div></div>
      <div>Me representa muy bien</div>
    </div>
  </span>
</div>
`
        }
      ]
    }
  ]
};

const testInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DI_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_01',
          title:
            'No tengo claro qué información necesito para poder tomar una decisión sobre mi futura carrera',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_02',
          title:
            'Todavía no tengo claro cuáles son las carreras que podrían interesarme',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_03',
          title:
            'No tengo suficiente información sobre mis habilidades (por ejemplo habilidades verbales, numéricas, etc.), o mis características personales (como  persistencia, iniciativa, paciencia, etc)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_04',
          title:
            'No tengo información suficiente sobre la variedad de carreras ni programas de estudio que existen',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_05',
          title: 'No se cómo serán en un futuro las carreras que me interesan',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_06',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_06',
          title:
            'Me atraen de igual forma muchas carreras y me es difícil elegir una sola',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_07',
          title:
            'No me gustan las carreras o programas donde sé que podría ser admitido',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_08',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_08',
          title:
            'La carrera que me interesa tiene algunas características que no me atraen (por ejemplo, me interesa medicina, pero no quiero estudiar tantos años)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_09',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_09',
          title:
            'Mis preferencias no encajan en una única carrera, y no quiero renunciar a lo que quiero (por ejemplo, preferiría una carrera que me permitiera trabajar como independiente, pero también quiero un sueldo estable)',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_10',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_10',
          title:
            'Mis habilidades y características no calzan con los requisitos de las carreras que me gustaría estudiar',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_11',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_11',
          title:
            'Las personas que son importantes para mi (como familia o amigos) no están de acuerdo con las opciones de carrera que estoy considerado',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    },
    {
      name: 'DI_12',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'DI_12',
          title:
            'Distintas personas me han realizado recomendaciones contradictorias y variadas sobre la carrera que debería seguir o los factores que debería considerar en mi decisión',
          // isRequired: true,
          rateValues: ratingValues
        },
        questionFooter
      ]
    }
  ]
};

export default testInfo;
