import React from 'react';
import MediaQuery from 'react-responsive';
import CareerSuccess from './CareerSuccess';
import CareerSuccessMobile from './CareerSuccessMobile';
import postData from '../API';

class CareerSuccessPage extends React.Component {
  onComplete = async data => {
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    return postData(newData);
  };

  render() {
    return (
      <MediaQuery maxWidth={700}>
        {matches => {
          if (matches) {
            return <CareerSuccessMobile onComplete={this.onComplete} />;
          } else {
            return <CareerSuccess onComplete={this.onComplete} />;
          }
        }}
      </MediaQuery>
    );
  }
}

export default CareerSuccessPage;
