import React from 'react';
import Presentation from './Presentation';
import FeedbackAcceptance from './FeedbackAcceptance';
import GrowthMindset from './GrowthMindset';
import PerceivedDiff from './PerceivedDiff';
import FinishedMessage from './FinishedMessage';

import postData from './API';

import getConfig from '../../config';

class SIOPTest extends React.Component {
  componentDidMount = async () => {
    const { BATTERY, updateContext } = this.props;
    // console.log({ props: this.props });
    const rut = localStorage.getItem(`currentCode-${BATTERY}`);
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        BATTERY,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const { BATTERY, stage, user, rut = 'anon' } = this.props;
    // console.log({ rut });
    const stageName = `userStage-${rut}-${BATTERY}`;
    await postData(data, user, rut);
    if (data.CONSENT === '2') {
      console.log('NO CONSENT');
      this.props.toNextStage(stageName);
    }
    if (stage < 4) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, BATTERY } = this.props;
    // const { BATTERY } = this.props;
    // const stage = 0;

    if (stage > 3) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='UAI METRICS'
          email='uaimetrics@uai.cl'
        />
      );
    }
    if (stage === 3) {
      return (
        <PerceivedDiff onComplete={this.onComplete} BATTERY={BATTERY} isLast />
      );
    }
    if (stage === 2) {
      return <GrowthMindset onComplete={this.onComplete} BATTERY={BATTERY} />;
    }
    if (stage === 1) {
      return (
        <FeedbackAcceptance onComplete={this.onComplete} BATTERY={BATTERY} />
      );
    }
    return <Presentation onComplete={this.onComplete} BATTERY={BATTERY} />;
  }
}

export default SIOPTest;
