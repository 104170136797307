import { normal } from 'jstat';

let dataInit = [
  {
    caseid: 'bdc47238-5c31-4e3b-9d83-f3504cdff310',
    createdat: 'Tue 17 Nov 2020 16:03:50 GMT',
    updatedat: 'Tue 17 Nov 2020 17:22:24 GMT',
    STAGE: '7',
    finished: '1',
    school: 'Universidad Adolfo Ibanez',
    grade: '',
    name: 'Sebastian',
    lastname: 'Solar',
    email: '',
    RUT: '21.934.879-7',
    BIRTH: '09-12-05',
    SEX: '1',
    CHAT_NAME: 'sebastian',
    TUTORIAL: '1',
    TUTORIAL_2: '1',
    C1_1_CN: '1',
    C1_1_FM: '-1',
    C1_1_CD: '0',
    C1_1_AP: '0',
    C1_2_CN: '-1',
    C1_2_FM: '0',
    C1_2_CD: '1',
    C1_2_AP: '0',
    C1_3_CN: '1',
    C1_3_FM: '0',
    C1_3_CD: '-1',
    C1_3_AP: '0',
    C1_4_CN: '1',
    C1_4_FM: '0',
    C1_4_CD: '0',
    C1_4_AP: '-1',
    C2_1_AL: '0',
    C2_1_CN: '1',
    C2_1_FM: '0',
    C2_1_CD: '-1',
    C2_2_AL: '-1',
    C2_2_CN: '1',
    C2_2_FM: '0',
    C2_2_CD: '0',
    C2_3_AL: '0',
    C2_3_CN: '1',
    C2_3_FM: '-1',
    C2_3_CD: '0',
    C2_4_AL: '-1',
    C2_4_CN: '1',
    C2_4_FM: '0',
    C2_4_CD: '0',
    C3_1_SE: '-1',
    C3_1_AL: '0',
    C3_1_CN: '0',
    C3_1_FM: '1',
    C3_2_SE: '-1',
    C3_2_AL: '0',
    C3_2_CN: '1',
    C3_2_FM: '0',
    C3_3_SE: '0',
    C3_3_AL: '-1',
    C3_3_CN: '1',
    C3_3_FM: '0',
    C3_4_SE: '1',
    C3_4_AL: '0',
    C3_4_CN: '0',
    C3_4_FM: '-1',
    C4_1_IP: '0',
    C4_1_SE: '0',
    C4_1_AL: '-1',
    C4_1_CN: '1',
    C4_2_IP: '0',
    C4_2_SE: '-1',
    C4_2_AL: '0',
    C4_2_CN: '1',
    C4_3_IP: '0',
    C4_3_SE: '0',
    C4_3_AL: '-1',
    C4_3_CN: '1',
    C4_4_IP: '0',
    C4_4_SE: '0',
    C4_4_AL: '-1',
    C4_4_CN: '1',
    C5_1_LM: '1',
    C5_1_IP: '0',
    C5_1_SE: '0',
    C5_1_AL: '-1',
    C5_2_LM: '1',
    C5_2_IP: '0',
    C5_2_SE: '0',
    C5_2_AL: '-1',
    C5_3_LM: '1',
    C5_3_IP: '0',
    C5_3_SE: '0',
    C5_3_AL: '-1',
    C5_4_LM: '0',
    C5_4_IP: '1',
    C5_4_SE: '-1',
    C5_4_AL: '0',
    C6_1_AP: '1',
    C6_1_LM: '0',
    C6_1_IP: '0',
    C6_1_SE: '-1',
    C6_2_AP: '0',
    C6_2_LM: '1',
    C6_2_IP: '0',
    C6_2_SE: '-1',
    C6_3_AP: '0',
    C6_3_LM: '1',
    C6_3_IP: '0',
    C6_3_SE: '-1',
    C6_4_AP: '0',
    C6_4_LM: '0',
    C6_4_IP: '-1',
    C6_4_SE: '1',
    C7_1_CD: '-1',
    C7_1_AP: '0',
    C7_1_LM: '1',
    C7_1_IP: '0',
    C7_2_CD: '0',
    C7_2_AP: '0',
    C7_2_LM: '1',
    C7_2_IP: '-1',
    C7_3_CD: '0',
    C7_3_AP: '0',
    C7_3_LM: '1',
    C7_3_IP: '-1',
    C7_4_CD: '0',
    C7_4_AP: '0',
    C7_4_LM: '1',
    C7_4_IP: '-1',
    C8_1_FM: '0',
    C8_1_CD: '1',
    C8_1_AP: '0',
    C8_1_LM: '-1',
    C8_2_FM: '1',
    C8_2_CD: '0',
    C8_2_AP: '-1',
    C8_2_LM: '0',
    C8_3_FM: '0',
    C8_3_CD: '0',
    C8_3_AP: '-1',
    C8_3_LM: '1',
    C8_4_FM: '-1',
    C8_4_CD: '0',
    C8_4_AP: '0',
    C8_4_LM: '1',
    AL_SUM: '-9',
    AP_SUM: '-2',
    CD_SUM: '-1',
    CN_SUM: '12',
    FM_SUM: '-2',
    IP_SUM: '-3',
    LM_SUM: '10',
    SE_SUM: '-5',
    TOP_DIM_1: 'CN',
    TOP_DIM_2: 'LM',
    B_M_1: '1',
    A_F_1: '0',
    C_AI_1: '-1',
    B_CBS_1: '1',
    A_HGC_1: '0',
    C_AV_1: '-1',
    B_CD_1: '0',
    A_LL_1: '-1',
    C_D_1: '1',
    B_CBS_2: '1',
    A_F_2: '-1',
    C_AI_2: '0',
    B_CD_2: '1',
    A_HGC_2: '0',
    C_AV_2: '-1',
    B_M_2: '1',
    A_LL_2: '-1',
    C_D_2: '0',
    B_CD_3: '1',
    A_F_3: '-1',
    C_AI_3: '0',
    B_M_3: '1',
    A_HGC_3: '-1',
    C_AV_3: '0',
    B_CBS_3: '0',
    A_LL_3: '-1',
    C_D_3: '1',
    B_M_4: '1',
    A_HGC_4: '0',
    C_AI_4: '-1',
    B_CBS_4: '1',
    A_LL_4: '0',
    C_AV_4: '-1',
    B_CD_4: '1',
    A_F_4: '0',
    C_D_4: '-1',
    B_CBS_5: '1',
    A_HGC_5: '0',
    C_AI_5: '-1',
    B_CD_5: '1',
    A_LL_5: '0',
    C_AV_5: '-1',
    B_M_5: '0',
    A_F_5: '-1',
    C_D_5: '1',
    B_CD_6: '1',
    A_HGC_6: '0',
    C_AI_6: '-1',
    B_M_6: '1',
    A_LL_6: '0',
    C_AV_6: '-1',
    B_CBS_6: '0',
    A_F_6: '-1',
    C_D_6: '1',
    B_M_7: '0',
    A_LL_7: '1',
    C_AI_7: '-1',
    B_CBS_7: '1',
    A_F_7: '0',
    C_AV_7: '-1',
    B_CD_7: '0',
    A_HGC_7: '-1',
    C_D_7: '1',
    B_CBS_8: '1',
    A_LL_8: '0',
    C_AI_8: '-1',
    B_CD_8: '1',
    A_F_8: '-1',
    C_AV_8: '0',
    B_M_8: '1',
    A_HGC_8: '0',
    C_D_8: '-1',
    B_CD_9: '1',
    A_LL_9: '0',
    C_AI_9: '-1',
    B_M_9: '1',
    A_F_9: '0',
    C_AV_9: '-1',
    B_CBS_9: '1',
    A_HGC_9: '0',
    C_D_9: '-1',
    A_STUDY_SUM: '-9',
    B_STUDY_SUM: '21',
    C_STUDY_SUM: '-12',
    TOP_STUDY_AREA_1: 'B',
    TOP_STUDY_AREA_2: 'A',
    FUTURE_CHOICE: '4',
    FEED_01: '4',
    FEED_02: '4',
    FEED_03: '5',
    FEED_04: '3',
    FEED_05: '1',
    FEED_05_OTHER: '',
    FORM: 'B',
    PGI_A01: '',
    PGI_A02: '',
    PGI_A03: '',
    PGI_A04: '',
    PGI_A05: '',
    PGI_A06: '',
    PGI_A07: '',
    PGI_A08: '',
    PGI_A09: '',
    PGI_A10: '',
    PGI_A11: '',
    PGI_A12: '',
    PGI_A13: '',
    PGI_A14: '',
    PGI_A15: '',
    PGI_A16: '',
    PGI_B01: '1',
    PGI_B02: '1',
    PGI_B03: '1',
    PGI_B04: '5',
    PGI_B05: '2',
    PGI_B06: '1',
    PGI_B07: '1',
    PGI_B08: '3',
    PGI_B09: '1',
    PGI_B10: '1',
    PGI_B11: '1',
    PGI_B12: '3',
    PGI_B13: '1',
    PGI_B14: '1',
    PGI_B15: '1',
    PGI_B16: '5'
  }
];

const dataResult = [
  {
    school: 'Universidad Adolfo Ibanez',
    grade: '',
    name: 'Sebastian',
    lastname: 'Solar',
    email: '',
    RUT: '21.934.879-7',
    BIRTH: '2005/09/12 00:00:00',
    SEX: 'Masculino',
    age: '15',
    updatedat: 'Tue 17 Nov 2020 17:22:24 GMT',
    TOP_DIM_1: 'CN',
    TOP_DIM_2: 'LM',
    CONS_AL: '-21.875',
    CONS_SE: '-12.5',
    CONS_IP: '-3.125',
    CONS_LM: '-6.25',
    CONS_AP: '21.875',
    CONS_CD: '12.5',
    CONS_FM: '3.125',
    CONS_CN: '6.25',
    perc_AL_SUM: '21.875',
    perc_AP_SUM: '43.75',
    perc_CD_SUM: '46.875',
    perc_CN_SUM: '87.5',
    perc_FM_SUM: '43.75',
    perc_IP_SUM: '40.625',
    perc_LM_SUM: '81.25',
    perc_SE_SUM: '34.375',
    perc_A_F: '22.2222222222222',
    perc_A_HGC: '38.8888888888889',
    perc_A_LL: '38.8888888888889',
    perc_B_CBS: '88.8888888888889',
    perc_B_CD: '88.8888888888889',
    perc_B_M: '88.8888888888889',
    perc_C_AI: '11.1111111111111',
    perc_C_AV: '11.1111111111111',
    perc_C_D: '61.1111111111111',
    A: '33.3333333333333',
    B: '88.8888888888889',
    C: '27.7777777777778'
  }
];

let selectFieldsFromObject = (fields, object) => {
  return fields.reduce((acc, curr) => {
    return { ...acc, [curr]: object[curr] };
  }, {});
};

let arrayFromObject = object => {
  const fields = Object.keys(object);
  return fields.map(field => ({ [field]: object[field] }));
};

let getSumFomValues = (values = []) => {
  const sum = values.reduce((acc, curr) => acc + parseFloat(curr), 0);
  return sum;
};

let getMeanFromValues = (values = []) => {
  const sum = getSumFomValues(values);
  const mean = sum / values.length;
  return mean;
};

let getMeanFromFields = (fields, object) => {
  const values = fields.map(field => {
    return parseInt(object[field]);
  });
  const mean = getMeanFromValues(values);
  return mean;
};

let calculateAgeFromBirthday = birthDate => {
  // date comes as "2001-01-02" (year-month-day) from date input
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
  // https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
};

let greaterThanZero = val => {
  return val >= 0 ? val : 0;
};

const getResults = data => {
  const workingData = { ...data };
  const workingKeys = Object.keys(workingData);

  const baseInfo = selectFieldsFromObject(
    [
      'school',
      'grade',
      'name',
      'lastname',
      'email',
      'RUT',
      'BIRTH',
      'SEX',
      'updatedat',
      'TOP_DIM_1',
      'TOP_DIM_2'
    ],
    workingData
  );
  const age = calculateAgeFromBirthday(baseInfo.BIRTH); // "07-19-19"
  // ALL _ SE perc
  const EquisDomains = selectFieldsFromObject(
    [
      'AL_SUM',
      'AP_SUM',
      'CD_SUM',
      'CN_SUM',
      'FM_SUM',
      'IP_SUM',
      'LM_SUM',
      'SE_SUM'
    ],
    workingData
  );
  const EQUIS_percentiles = arrayFromObject(EquisDomains).reduce(
    (acc, curr) => {
      const key = Object.keys(curr)[0];
      const value = Object.values(curr)[0];
      const percentile = ((parseInt(value) + 16) / 32) * 100;
      return { ...acc, [`perc_${key}`]: percentile };
    },
    {}
  );

  const Equis_Consistencies = {
    CONS_AL: greaterThanZero(
      EQUIS_percentiles.perc_AL_SUM - EQUIS_percentiles.perc_AP_SUM
    ),
    CONS_SE: greaterThanZero(
      EQUIS_percentiles.perc_SE_SUM - EQUIS_percentiles.perc_CD_SUM
    ),
    CONS_IP: greaterThanZero(
      EQUIS_percentiles.perc_IP_SUM - EQUIS_percentiles.perc_FM_SUM
    ),
    CONS_LM: greaterThanZero(
      EQUIS_percentiles.perc_LM_SUM - EQUIS_percentiles.perc_CN_SUM
    ),
    CONS_AP: greaterThanZero(
      EQUIS_percentiles.perc_AP_SUM - EQUIS_percentiles.perc_AL_SUM
    ),
    CONS_CD: greaterThanZero(
      EQUIS_percentiles.perc_CD_SUM - EQUIS_percentiles.perc_SE_SUM
    ),
    CONS_FM: greaterThanZero(
      EQUIS_percentiles.perc_FM_SUM - EQUIS_percentiles.perc_IP_SUM
    ),
    CONS_CN: greaterThanZero(
      EQUIS_percentiles.perc_CN_SUM - EQUIS_percentiles.perc_LM_SUM
    )
  };

  const A_F_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'A_F_1',
          'A_F_2',
          'A_F_3',
          'A_F_4',
          'A_F_5',
          'A_F_6',
          'A_F_7',
          'A_F_8',
          'A_F_9'
        ],
        workingData
      )
    )
  );
  const A_HGC_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'A_HGC_1',
          'A_HGC_2',
          'A_HGC_3',
          'A_HGC_4',
          'A_HGC_5',
          'A_HGC_6',
          'A_HGC_7',
          'A_HGC_8',
          'A_HGC_9'
        ],
        workingData
      )
    )
  );
  const A_LL_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'A_LL_1',
          'A_LL_2',
          'A_LL_3',
          'A_LL_4',
          'A_LL_5',
          'A_LL_6',
          'A_LL_7',
          'A_LL_8',
          'A_LL_9'
        ],
        workingData
      )
    )
  );
  const B_CBS_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'B_CBS_1',
          'B_CBS_2',
          'B_CBS_3',
          'B_CBS_4',
          'B_CBS_5',
          'B_CBS_6',
          'B_CBS_7',
          'B_CBS_8',
          'B_CBS_9'
        ],
        workingData
      )
    )
  );
  const B_CD_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'B_CD_1',
          'B_CD_2',
          'B_CD_3',
          'B_CD_4',
          'B_CD_5',
          'B_CD_6',
          'B_CD_7',
          'B_CD_8',
          'B_CD_9'
        ],
        workingData
      )
    )
  );
  const B_M_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'B_M_1',
          'B_M_2',
          'B_M_3',
          'B_M_4',
          'B_M_5',
          'B_M_6',
          'B_M_7',
          'B_M_8',
          'B_M_9'
        ],
        workingData
      )
    )
  );
  const C_AI_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'C_AI_1',
          'C_AI_2',
          'C_AI_3',
          'C_AI_4',
          'C_AI_5',
          'C_AI_6',
          'C_AI_7',
          'C_AI_8',
          'C_AI_9'
        ],
        workingData
      )
    )
  );
  const C_AV_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'C_AV_1',
          'C_AV_2',
          'C_AV_3',
          'C_AV_4',
          'C_AV_5',
          'C_AV_6',
          'C_AV_7',
          'C_AV_8',
          'C_AV_9'
        ],
        workingData
      )
    )
  );
  const C_D_SUM = getSumFomValues(
    Object.values(
      selectFieldsFromObject(
        [
          'C_D_1',
          'C_D_2',
          'C_D_3',
          'C_D_4',
          'C_D_5',
          'C_D_6',
          'C_D_7',
          'C_D_8',
          'C_D_9'
        ],
        workingData
      )
    )
  );

  // console.log({ A_F_SUM });
  const areasJunior = [
    { A_F: A_F_SUM },
    { A_HGC: A_HGC_SUM },
    { A_LL: A_LL_SUM },
    { B_CBS: B_CBS_SUM },
    { B_CD: B_CD_SUM },
    { B_M: B_M_SUM },
    { C_AI: C_AI_SUM },
    { C_AV: C_AV_SUM },
    { C_D: C_D_SUM }
  ];
  const AREAS_perc = areasJunior.reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    const value = Object.values(curr)[0] || 0;
    const percentile = ((parseInt(value) + 9) / 18) * 100;
    return { ...acc, [`perc_${key}`]: percentile };
  }, {});

  const means = {
    A: getMeanFromValues([
      AREAS_perc['perc_A_F'],
      AREAS_perc['perc_A_HGC'],
      AREAS_perc['perc_A_LL']
    ]),
    B: getMeanFromValues([
      AREAS_perc['perc_B_CBS'],
      AREAS_perc['perc_B_CD'],
      AREAS_perc['perc_B_M']
    ]),
    C: getMeanFromValues([
      AREAS_perc['perc_C_AI'],
      AREAS_perc['perc_C_AV'],
      AREAS_perc['perc_C_D']
    ])
  };

  // new Estilo aprendizaje scales
  const EP_ESC_KEYS = workingKeys.filter(field => field.startsWith('EP_'));
  const ES_ESC_KEYS = workingKeys.filter(field => field.startsWith('ES_'));
  const EL_ESC_KEYS = workingKeys.filter(field => field.startsWith('EL_'));
  // sub-scales
  const MA_SUB_KEYS = workingKeys.filter(field => field.includes('_MA_'));
  const ES_SUB_KEYS = workingKeys.filter(field => field.includes('_ES_'));
  const ML_SUB_KEYS = workingKeys.filter(field => field.includes('_ML_'));
  const AP_SUB_KEYS = workingKeys.filter(field => field.includes('_AP_'));
  const MS_SUB_KEYS = workingKeys.filter(field => field.includes('_MS_'));
  const EL_SUB_KEYS = workingKeys.filter(field => field.includes('_EL_'));

  // scales sums and averages
  const EP_ESC_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(EP_ESC_KEYS, workingData))
  );
  const ES_ESC_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(ES_ESC_KEYS, workingData))
  );
  const EL_ESC_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(EL_ESC_KEYS, workingData))
  );

  const EP_ESC_MEAN = getMeanFromFields(EP_ESC_KEYS, workingData)
  const ES_ESC_MEAN = getMeanFromFields(ES_ESC_KEYS, workingData)
  const EL_ESC_MEAN = getMeanFromFields(EL_ESC_KEYS, workingData)

  // sub-scales sums and averages
  const MA_SUB_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(MA_SUB_KEYS, workingData))
  );
  const ES_SUB_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(ES_SUB_KEYS, workingData))
  );
  const ML_SUB_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(ML_SUB_KEYS, workingData))
  );
  const AP_SUB_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(AP_SUB_KEYS, workingData))
  );
  const MS_SUB_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(MS_SUB_KEYS, workingData))
  );
  const EL_SUB_SUM = getSumFomValues(
    Object.values(selectFieldsFromObject(EL_SUB_KEYS, workingData))
  );

  const MA_SUB_MEAN = getMeanFromFields(MA_SUB_KEYS, workingData)
  const ES_SUB_MEAN = getMeanFromFields(ES_SUB_KEYS, workingData)
  const ML_SUB_MEAN = getMeanFromFields(ML_SUB_KEYS, workingData)
  const AP_SUB_MEAN = getMeanFromFields(AP_SUB_KEYS, workingData)
  const MS_SUB_MEAN = getMeanFromFields(MS_SUB_KEYS, workingData)
  const EL_SUB_MEAN = getMeanFromFields(EL_SUB_KEYS, workingData)

  const estiloScales = {
    EP_ESC_SUM,
    ES_ESC_SUM,
    EL_ESC_SUM,
    EP_ESC_MEAN,
    ES_ESC_MEAN,
    EL_ESC_MEAN,
    //
    MA_SUB_SUM,
    ES_SUB_SUM,
    ML_SUB_SUM,
    AP_SUB_SUM,
    MS_SUB_SUM,
    EL_SUB_SUM,
    MA_SUB_MEAN,
    ES_SUB_MEAN,
    ML_SUB_MEAN,
    AP_SUB_MEAN,
    MS_SUB_MEAN,
    EL_SUB_MEAN
  }

  return {
    ...baseInfo,
    SEX: parseInt(workingData.SEX) === 1 ? 'Masculino' : 'Femenino',
    age,
    ...EQUIS_percentiles,
    ...Equis_Consistencies,
    ...AREAS_perc,
    ...means,
    ...estiloScales
  };
};

export default getResults;

let res = {
  school: 'Universidad Adolfo Ibanez',
  grade: '',
  name: 'Sebastian',
  lastname: 'Solar',
  email: '',
  RUT: '21.934.879-7',
  BIRTH: '09-12-05',
  SEX: 'Masculino',
  updatedat: 'Tue 17 Nov 2020 17:22:24 GMT',
  TOP_DIM_1: 'CN',
  TOP_DIM_2: 'LM',
  age: 15,
  perc_AL_SUM: 21.875,
  perc_AP_SUM: 43.75,
  perc_CD_SUM: 46.875,
  perc_CN_SUM: 87.5,
  perc_FM_SUM: 43.75,
  perc_IP_SUM: 40.625,
  perc_LM_SUM: 81.25,
  perc_SE_SUM: 34.375,
  CONS_AL: 0,
  CONS_SE: 0,
  CONS_IP: 0,
  CONS_LM: 0,
  CONS_AP: 21.875,
  CONS_CD: 12.5,
  CONS_FM: 3.125,
  CONS_CN: 6.25,
  perc_A_F: 22.22222222222222,
  perc_A_HGC: 38.88888888888889,
  perc_A_LL: 38.88888888888889,
  perc_B_CBS: 88.88888888888889,
  perc_B_CD: 88.88888888888889,
  perc_B_M: 88.88888888888889,
  perc_C_AI: 11.11111111111111,
  perc_C_AV: 11.11111111111111,
  perc_C_D: 61.111111111111114,
  A: 33.333333333333336,
  B: 88.88888888888887,
  C: 27.777777777777782
};
