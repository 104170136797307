import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SortableListWrapper, {
  DroppableListWrapper,
  ListItemWrapper,
  ButtonWrapper,
  Button
} from './sortableList.style';
// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class SortableList extends Component {
  state = {
    items: getItems(8)
  };

  onComplete = async () => {
    const { items } = this.state;
    const { onComplete } = this.props;
    const clone = items
      .slice()
      .map((item, index) => ({ ...item, order: index + 1 }));
    const parsed = {
      ...clone
    };
    await onComplete(parsed);
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // get new ordered list
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    // set new list to state
    this.setState({
      items
    });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <SortableListWrapper>
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <DroppableListWrapper
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {this.state.items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ListItemWrapper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style }}
                          isDragging={snapshot.isDragging}
                        >
                          {item.content}
                        </ListItemWrapper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </DroppableListWrapper>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonWrapper>
            <Button onClick={this.onComplete}>Siguiente</Button>
          </ButtonWrapper>
        </div>
      </SortableListWrapper>
    );
  }
}

export default SortableList;
