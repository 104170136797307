import React from 'react';
import FinishedMessage from '../../Components/FinishedMessage';
import { StageConsumer } from '../../Context';

class FinishedMessagePage extends React.Component {
  render() {
    return (
      <StageConsumer>
        {({ state }) => <FinishedMessage {...this.props} {...state} />}
      </StageConsumer>
    );
  }
}

export default FinishedMessagePage;
