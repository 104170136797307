const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_Header',
  html: `
  <div class="">
    <span style="font-size:16px;">
      ¿Cuánto te gustaría realizar la siguiente actividad?
    </span>
    <br />
    <br />
  </div>
  `
};

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'PGIA_PAGE_01',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'PGIA_PAGE_01_HTML',
          html: `
  <div class="QuestionText BorderColor">
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        <h3>Instrucciones</h3>
      </span>
      <br />
      Muchas gracias por tu opinión sobre el test, ésta será muy útil. Para terminar, te presentaremos una serie de actividades que podrías realizar.
      <br />
      <br />
      Tu tarea será señalar cuánto te gustaría realizar cada actividad, en una escala de 1 <em>(No me gustaría para nada)</em> a 7 <em>(Me gustaría Mucho)</em>, como muestra la siguiente escala de ejemplo:
      <br />
      <br />
      <div class="qstn-likert-label top">
        <div>No me gustaría para nada</div>
      </div>
      <div class="btn-group">
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>1</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>2</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>3</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>4</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>5</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="6" value="6"><span class="quanta_q_rating_item_text"><span>6</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="DI_00" aria-required="true" aria-label="7" value="7"><span class="quanta_q_rating_item_text"><span>7</span></span></label>
      </div>
      <div class="qstn-likert-label bottom">
        <div>Me gustaría mucho</div>
      </div>
    </span>
  </div>
`
        }
      ]
    }
  ]
};

const items = [
  { code: 'PGI_B01', statement: 'Preparar informes financieros' },
  { code: 'PGI_B02', statement: 'Supervisar un grupo de análisis de datos' },
  { code: 'PGI_B03', statement: 'Instalar cableado eléctrico' },
  {
    code: 'PGI_B04',
    statement: 'Categorizar diferentes tipos de vida silvestre'
  },
  {
    code: 'PGI_B05',
    statement: 'Mantener los registros financieros de la oficina'
  },
  { code: 'PGI_B06', statement: 'Administrar una central eléctrica' },
  { code: 'PGI_B07', statement: 'Supervisar la construcción de edificios' },
  { code: 'PGI_B08', statement: 'Escribir un artículo científico' },
  {
    code: 'PGI_B09',
    statement: 'Mantener el registro de la venta de acciones'
  },
  {
    code: 'PGI_B10',
    statement: 'Escribir programas informáticos para empresas'
  },
  {
    code: 'PGI_B11',
    statement: 'Inspeccionar la seguridad de obras de construcción'
  },
  { code: 'PGI_B12', statement: 'Enseñar ciencia' },
  {
    code: 'PGI_B13',
    statement: 'Establecer un procedimiento de contabilidad de una empresa'
  },
  { code: 'PGI_B14', statement: 'Analizar mapas de encuestas' },
  { code: 'PGI_B15', statement: 'Montar instrumentos ópticos de precisión' },
  { code: 'PGI_B16', statement: 'Estudiar vida silvestre' }
];

const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: items.map(item => {
    const { code, statement } = item;
    return {
      name: code,
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: code,
          title: statement,
          // isRequired: true,
          minRateDescription: 'No me gustaría para nada',
          maxRateDescription: 'Me gustaría mucho',
          rateValues: ratingValues
        }
      ]
    };
  })
};

export default baseInfo;
