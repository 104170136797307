import React from 'react';
import Demographics from './Demographics';
import postData from '../API';

import getConfig from '../../../config';

class DemographicsPage extends React.Component {
  // state = {
  //   rut: localStorage.getItem(`currentCode-${this.props.BATTERY}`)
  // };

  componentDidMount = async () => {
    const { updateContext, BATTERY, rut } = this.props;
    // const { rut } = this.state;
    if (updateContext) {
      // console.log('on SSCC test');
      // console.log({ props: this.props });
      if (rut) {
        const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
          BATTERY,
          rut
        );
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE,
          rut: CURRENT_CODE
        });
      } else {
        const { USER_ID, USER_STAGE } = await getConfig(BATTERY);
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE
        });
      }
    }
  };

  onComplete = async data => {
    const { BATTERY, rut, user, school, stage = 0 } = this.props;
    // const { rut } = this.state;
    const userData = {
      school,
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      grade: user.grade
    };
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }

    const newData = { ...userData, ...data, ...finished, STAGE: stage + 1 };

    // set SEX value on local storage
    // console.log({ SEX: data.SEX });
    if (data.SEX) {
      localStorage.setItem(`current-${rut}-SEX`, data.SEX);
    }

    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }

    const { USER_ID } = await getConfig(BATTERY, rut);
    return postData(newData, USER_ID, rut);
  };

  render() {
    return <Demographics onComplete={this.onComplete} />;
  }
}

export default DemographicsPage;
