import React, { useState } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { Icon } from 'react-icons-kit';
import { warningOutline } from 'react-icons-kit/typicons/warningOutline';

const RutIdWrapper = styled.div`
  width: 100vw;
  background: ${({ theme }) => theme.colors.greyBackground};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  padding: 0.5rem 2rem;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  transform: translateY(${({ opened }) => (opened ? 0 : 50)}px);
  @media (max-width: 574px) {
    transform: translateY(${({ opened }) => (opened ? 0 : 75)}px);
  }
  .drawer-open-button {
    position: absolute;
    top: -25px;
    left: 0;
    background: ${({ theme }) => theme.colors.greyBackground};
    width: 35px;
    padding: 0.2rem;
    button {
      background: none;
      font-size: 0.8rem;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
    }
  }
  span {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .multi-options {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    button {
      border: none;
      -webkit-transition: background 0.2s ease-in-out;
      padding: 7px 15px;
      text-align: center;
      border-radius: 4px;
      margin-left: 0.5rem;
      background: #f1f1f1;
      color: ${({ theme }) => theme.colors.textColor};
      font-weight: 600;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
  @media (max-width: 564px) {
    display: initial;
    text-align: center;
    padding: 0.5rem 1rem;
    .multi-options {
      text-align: center;
      margin-top: 5px;
      ${'' /* display: inherit; */}
      .info-label {
        width: 30%;
      }
      .info-button {
        width: 70%;
      }
      button {
        font-weight: 600;
        padding: 6px 15px;
        margin: 0;
        margin-top: 3px;
        width: 100%;
      }
    }
  }
`;

const ChangeParticipantButton = ({ onClick }) => {
  const [opened, setOpened] = useState(false);
  const handleClick = () => {
    setOpened(false);
    onClick();
  };
  const ToolTipContent = () => {
    return (
      <ToolTipWrapper>
        <div className='content'>
          <Icon icon={warningOutline} size={22} /> Seguro deseas cambiar de
          participante?
        </div>
        <div className='footer'>
          <button className='danger' onClick={handleClick}>
            Si
          </button>
          <button className='cancel' onClick={() => setOpened(false)}>
            No
          </button>
        </div>
      </ToolTipWrapper>
    );
  };
  return (
    <TippyWrapper className='info-button'>
      <Tippy
        content={<ToolTipContent />}
        visible={opened}
        onClickOutside={() => setOpened(false)}
        interactive={true}
        interactiveBorder={20}
        delay={100}
        theme='light'
        placement='top-start'
      >
        <button onClick={() => setOpened(!opened)}>
          Cambiar de participante
        </button>
      </Tippy>
    </TippyWrapper>
  );
};

const AuthRutId = props => {
  const [opened, setOpened] = useState(true);

  const removeCurrentRut = async () => {
    const { updateContext, BATTERY } = props;
    const CURRENT_CODE = `currentCode-${BATTERY}`;
    localStorage.removeItem(CURRENT_CODE);
    await updateContext({
      rut: null
    });
  };

  const { user } = props;
  if (!user) {
    return null;
  }
  const { rut, name, lastname } = user;
  return (
    <RutIdWrapper opened={opened}>
      <div className='drawer-open-button'>
        <button onClick={() => setOpened(prev => !prev)}>
          {opened ? 'X' : `^`}
        </button>
      </div>
      <div>
        Respondiendo como: <span>{`${lastname}, ${name} – ${rut}`}</span>
      </div>
      <div className='multi-options'>
        <div className='info-label'>¿No eres tu?</div>
        <ChangeParticipantButton onClick={removeCurrentRut} />
        {/* <button onClick={this.removeCurrentRut}>
            Cambiar de participante
          </button> */}
      </div>
    </RutIdWrapper>
  );
};

export default AuthRutId;

const ToolTipWrapper = styled.div`
  .content {
    padding: 0.5rem;
    line-height: 1.8;
    color: ${({ theme }) => theme.colors.textColor};
    svg {
      color: ${({ theme }) => theme.colors.warning};
      margin-right: 3px;
    }
  }
  .footer {
    text-align: right;
    padding: 0.5rem;
    .cancel {
      min-width: 90px;
    }
    .danger {
      background-color: ${({ theme }) => theme.colors.danger};
      border: none;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const TippyWrapper = styled.div`
  .tippy-box[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }
  .tippy-iOS {
    cursor: pointer !important;
    -webkit-tap-highlight-color: transparent;
  }
  [data-tippy-root] {
    max-width: calc(100vw - 10px);
  }
  .tippy-box {
    position: relative;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity;
  }
  .tippy-box[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
  }
  .tippy-box[data-placement^='top'] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }
  .tippy-box[data-placement^='left'] > .tippy-arrow {
    right: 0;
  }
  .tippy-box[data-placement^='left'] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left;
  }
  .tippy-box[data-placement^='right'] > .tippy-arrow {
    left: 0;
  }
  .tippy-box[data-placement^='right'] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
  }
  .tippy-box[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
  .tippy-arrow {
    width: 16px;
    height: 16px;
    color: #333;
  }
  .tippy-arrow:before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
  .tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1;
  }
  .tippy-box[data-theme~='light'] {
    color: #26323d;
    box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
      0 4px 80px -8px rgba(36, 40, 47, 0.25),
      0 4px 4px -2px rgba(91, 94, 105, 0.15);
    background-color: #fff;
  }
  .tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow:before {
    border-top-color: #fff;
  }
  .tippy-box[data-theme~='light'][data-placement^='bottom']
    > .tippy-arrow:before {
    border-bottom-color: #fff;
  }
  .tippy-box[data-theme~='light'][data-placement^='left']
    > .tippy-arrow:before {
    border-left-color: #fff;
  }
  .tippy-box[data-theme~='light'][data-placement^='right']
    > .tippy-arrow:before {
    border-right-color: #fff;
  }
  .tippy-box[data-theme~='light'] > .tippy-backdrop {
    background-color: #fff;
  }
  .tippy-box[data-theme~='light'] > .tippy-svg-arrow {
    fill: #fff;
  }
`;
