const PriorityOptions = [
  {
    value: '1',
    text:
      'Motivar y desarrollar estudiantes en las dimensiones académicas, social, personal y de Fe'
  },
  {
    value: '2',
    text:
      'Desarrollar currículo que impacte las competencias de los estudiantes para su realización personal y profesional'
  },
  {
    value: '3',
    text:
      'Desarrollar el sentido de pertenencia e identificación con la espiritualidad SSCC en la comunidad (estudiantes, trabajadores, apoderados)'
  },
  {
    value: '4',
    text:
      'Asegurar el cumplimiento de los objetivos estratégicos, gestionando los recursos y mejorando las capacidades de gestión (procesos internos, administración)'
  },
  {
    value: '5',
    text: 'Desarrollar e implementar un plan de diversidad e inclusión'
  },
  {
    value: '6',
    text:
      'Alinear el Proyecto Educativo con una propuesta pedagógica basada en la Cultura del Pensamiento'
  },
  {
    value: '7',
    text:
      'Instalar Pensamiento Visible como nuestra metodología en todos los niveles'
  },
  {
    value: '8',
    text:
      'Fortalecer la espiritualidad y cultura SSCC mediante un plan actualizado de formación (inducción, capacitación, comunicación)'
  },
  {
    value: '9',
    text:
      'Implementar un plan maestro de infraestructura (remodelación del parvulario, mantención de infraestructura)'
  },
  {
    value: '10',
    text: 'Definir e implementar un Plan de Gestión de Personas integral'
  },
  {
    value: '11',
    text:
      'Implementar un plan de Liderazgo Sagrados Corazones de Manquehue, a todos los niveles orientado a la gestión del cambio'
  },
  {
    value: '12',
    text:
      'Diseñar un Modelo Organizacional, funcional y articulado, orientado al cumplimiento de los objetivos organizacionales'
  }
];

const baseInfo = {
  locale: 'es',
  title: 'Prioridades',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Prioridades</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  <br>
                  Las siguientes preguntas tienen como propósito entender cuáles son,
                  según su opinión, los objetivos y/o  acciones del plan estratégico
                  sobre las que el rector debiera enfocar sus esfuerzos en el corto y
                  mediano plazo.
                  Para ello le pedimos identificar las áreas prioritarias de gestión
                  para el <strong>Primer Semestre de 2020</strong> y luego para el período
                  comprendido entre el <strong>Segundo Semestre de 2020 y hacia el 2021.</strong>
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'PRIORIDAD_PAGE_01',
      elements: [
        {
          type: 'html',
          name: 'HTML_PLAN_TITLE',
          html: `
            <div>
              <legend>
                <span style="font-size:16px;">
                  En su opinión, ¿qué objetivos y/o acciones debiera priorizar el rector durante el <strong>PRIMER SEMESTRE DEL 2020</strong>? MARQUE LAS TRES MÁS IMPORTANTES.
                </span>
              </legend>
            </div>
          `
        },
        {
          type: 'checkbox',
          name: 'PRIORIDADES_1',
          title: 'Prioridades Primer Semestre de 2020',
          isRequired: true,
          colCount: 7,
          validators: [
            {
              type: 'expression',
              expression: 'checkboxEqualSelected({PRIORIDADES_1}, 3) = true',
              text: 'Por favor, selecciona 3 opciones'
            }
          ],
          choices: PriorityOptions
        }
      ]
    },
    {
      name: 'PRIORIDAD_PAGE_02',
      elements: [
        {
          type: 'html',
          name: 'HTML_PLAN_TITLE',
          html: `
            <div>
              <legend>
                <span style="font-size:16px;">
                  En su opinión, ¿qué objetivos y/o acciones debiera priorizar el rector para el período comprendido entre el <strong>Segundo Semestre de 2020 y hacia el 2021</strong>? MARQUE LAS TRES MÁS IMPORTANTES.
                </span>
              </legend>
            </div>
          `
        },
        {
          type: 'checkbox',
          name: 'PRIORIDADES_2',
          title: 'Prioridades Segundo Semestre de 2020 y hacia el 2021',
          isRequired: true,
          colCount: 7,
          validators: [
            {
              type: 'expression',
              expression: 'checkboxEqualSelected({PRIORIDADES_2}, 3) = true',
              text: 'Por favor, selecciona 3 opciones'
            }
          ],
          choices: PriorityOptions
        }
      ]
    }
  ]
};

export default baseInfo;
