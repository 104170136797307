import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import LearningAgilitySurvey from './learning_agility_rating';
import LearningAgilityWrapper from './LearningAgility.styles';
// import showdown from 'showdown';
// const converter = new showdown.Converter();

class LearningAgility extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    // console.log('Survey results: ');
    const { data } = survey;
    const { onComplete } = this.props;
    const parsed = { ...data };
    await onComplete(parsed);
  };

  render() {
    const LearningAgilityModel = new Survey.Model(LearningAgilitySurvey);
    // //Create showdown mardown converter
    // LearningAgilityModel.onTextMarkdown.add((survey, options) => {
    //   //convert the mardown text to html
    //   var str = converter.makeHtml(options.text);
    //   //remove root paragraphs <p></p>
    //   str = str.substring(3);
    //   str = str.substring(0, str.length - 4);
    //   //set html
    //   options.html = str;
    // });
    return (
      <LearningAgilityWrapper>
        {/* <div className="uai_header">
          <img
            className="uai-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png"
            alt="uai-logo"
          />
        </div> */}
        <Survey.Survey
          model={LearningAgilityModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </LearningAgilityWrapper>
    );
  }
}

export default LearningAgility;
