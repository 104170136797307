const rateValues = [
  {
    value: '1',
    text: 'Muy en desacuerdo'
  },
  {
    value: '2',
    text: 'En desacuerdo'
  },
  {
    value: '3',
    text: 'Ni de acuerdo ni en desacuerdo'
  },
  {
    value: '4',
    text: 'De acuerdo'
  },
  {
    value: '5',
    text: 'Muy de acuerdo'
  },
  {
    value: '0',
    text: 'No aplica'
  }
];

const CareerSuccess = {
  locale: 'es',
  title: 'Desarrollo de Carrera',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'CSAT_01',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div style="font-size:18px; margin-bottom: 30px;">
            <span>En relación con su desarrollo profesional,</span><br />
            <span>Indique su grado de acuerdo/desacuerdo con las siguientes frases:</span>
          </div>`
        },
        {
          type: 'rating',
          name: 'CSAT_01',
          title: 'Estoy satisfecho con el éxito que he alcanzado en general',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CSAT_02',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div style="font-size:18px; margin-bottom: 30px;">
            <span>En relación con su desarrollo profesional,</span><br />
            <span>Indique su grado de acuerdo/desacuerdo con las siguientes frases:</span>
          </div>`
        },
        {
          type: 'rating',
          name: 'CSAT_02',
          title:
            'Estoy satisfecho con lo que he logrado en relación a mis metas laborales',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CSAT_03',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div style="font-size:18px; margin-bottom: 30px;">
            <span>En relación con su desarrollo profesional,</span><br />
            <span>Indique su grado de acuerdo/desacuerdo con las siguientes frases:</span>
          </div>`
        },
        {
          type: 'rating',
          name: 'CSAT_03',
          title:
            'Estoy satisfecho con lo que he logrado en relación a mis metas económicas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CSAT_04',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div style="font-size:18px; margin-bottom: 30px;">
            <span>En relación con su desarrollo profesional,</span><br />
            <span>Indique su grado de acuerdo/desacuerdo con las siguientes frases:</span>
          </div>`
        },
        {
          type: 'rating',
          name: 'CSAT_04',
          title:
            'Estoy satisfecho con lo que he logrado en relación al avance de mi carrera',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CSAT_05',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div style="font-size:18px; margin-bottom: 30px;">
            <span>En relación con su desarrollo profesional,</span><br />
            <span>Indique su grado de acuerdo/desacuerdo con las siguientes frases:</span>
          </div>`
        },
        {
          type: 'rating',
          name: 'CSAT_05',
          title:
            'Estoy satisfecho con lo que he logrado en relación al desarrollo de nuevas competencias laborales',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    }
  ]
};

export default CareerSuccess;
