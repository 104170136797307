import styled from 'styled-components';

const BigFiveWrapper = styled.div`
  .uai_header {
    position: absolute;
    right: 5%;
    top: 2%;
    img {
      max-width: 150px;
    }
  }
  .${({ theme }) => theme.baseName}_main {
    .progress {
      position: fixed;
      top: 0;
      width: 100%;
      border-radius: 0;
      height: 5px;
      span {
        opacity: 0;
        width: 0;
      }
      .progress-bar {
        margin: 0px;
        background-color: ${({ theme }) => theme.colors.brandColor} !important;
      }
    }
    .panel-heading {
      padding: 1.5rem;
      border: none;
      background-color: ${({ theme }) => theme.colors.greyBackground};
      color: white;
      height: 200px;
      box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.5);
      border-radius: 0;
      h3 {
        font-weight: 300;
      }
    }
    .${({ theme }) => theme.baseName}_page {
      margin-top: -100px;
      @media (max-width: 564px) {
        margin-top: -125px;
      }
    }
  }
  .QuestionText {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.textColor};
    padding: 20px;
    line-height: 1.5em;
  }
  ${'' /* font-family: 'Helvetica Neue', Arial, sans-serif; */}
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -40px;
  ${
    '' /* @media (min-width: 770px) {
    .quanta_page {
      width: 770px;
      max-width: 95%;
    }
  } */
  }
  .${({ theme }) => theme.baseName}_main {
    height: 100%;
    min-height: 85vh;
  }
  .quanta_page, .panel-footer {
    max-width: 840px;
    padding: 2rem 3rem;
    margin: 0 auto;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
  }
  @media only screen and (max-width: 767px) {
    .quanta_page,
    .panel-footer {
      margin: 0 auto;
      width: 80%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }
  }
  @media (min-width: 980px) {
    .quanta_page,
    .panel-footer {
      display: table;
      min-width: 840px;
    }
  }
  @media (max-width: 979px) {
    .quanta_page,
    .panel-footer {
      display: block;
      min-width: 0;
    }
  }
  @media (max-width: 564px) {
    .quanta_page,
    .panel-footer {
      width: 100%;
    }
  }

  .quanta_qcbc.form-inline {
    width: 100%;
    display: block;
    justify-content: space-around;
    text-align: center;
    .sv-q-column-2 {
      width: 40%;
      margin: 0 1.5%;
      display: inline-flex;
      .radio {
        width: 100%;
        border-radius: 4px;
        &.checked {
          border-radius: 4px;
          background: ${({ theme }) => theme.colors.brandColor};
          border-color: ${({ theme }) => theme.colors.brandSolid};
          color: #fff;
        }
        label {
          font-weight: 700;
          padding-top: 20px;
          padding-bottom: 20px;
          cursor: pointer;
          background: ${({ theme }) => theme.colors.itemBackground};
          background: ${({ theme }) => theme.colors.itemBackground};
          border: none;
          transition: background 0.2s ease-in-out;
          padding: 20px;
          display: block;
          text-align: center;
          border-radius: 4px;
          input {
            display: none;
          }
          .circle {
            display: none;
          }
          &:hover {
            background: ${({ theme }) => theme.colors.itemBackgroundHover};
          }
        }
      }
    }

    @media (max-width: 767px) {
      .sv-q-column-2 {
        width: 100%;
        margin: 0;
      }
    }
  }

  .panel-footer.card-footer {
    border: none;
    margin-top: 50px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    input {
      background: ${({ theme }) => theme.colors.brandColor};
      color: #fff;
      border: none;
      -webkit-transition: background 0.2s ease-in-out;
      padding: 8px 20px;
      display: block;
      text-align: center;
      border-radius: 4px;
    }
  }

  .quanta_qstn {
    .quanta_qstn_title {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.textColor};
      padding: 20px 0;
      line-height: 1.5em;
      font-weight: 500;
    }
  }

  .quanta_qstn {
    .form-control {
      border: 2px solid #d2d2d6;
    }
  }

   {
    /** radio buttons Qualtrics style */
  }
  .quanta_qstn {
    .quanta_qcbc {
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      margin: 0;
      padding: 0;
      .radio.checked:focus {
        background-color: yellow;
      }
      .radio {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;
        border-radius: 4px;
        transition: all 0.15s ease-in;
        &.checked {
          border-radius: 4px;
          background: ${({ theme }) => theme.colors.brandColor};
          border-color: ${({ theme }) => theme.colors.brandSolid};
          color: #fff;
          &:hover {
            background: ${({ theme }) => theme.colors.brandColor};
          }
        }

        label {
          font-weight: 700;
          padding-top: 20px;
          padding-bottom: 20px;
          cursor: pointer;
          background: #f1f1f1;
          background: ${({ theme }) => theme.colors.itemBackground};
          border: none;
          transition: background 0.2s ease-in-out;
          padding: 20px;
          display: block;
          text-align: center;
          border-radius: 4px;
          input {
            display: none;
          }
          .circle {
            display: none;
          }
          &:hover {
            background: ${({ theme }) => theme.colors.itemBackgroundHover};
          }
        }
      }
    }
  }

  .Footer {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 14px;
    max-width: 810px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    img {
      max-width: 250px;
    }
    .btg-logo {
      max-width: 125px;
    }
  }
  .PushStickyFooter {
    height: 50px;
  }
  .Plug {
    text-align: center;
    // display: block;
    display: flex;
    justify-content: flex-end;
    a {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.textColor};
      text-decoration: none;
      padding: 12px 24px;
    }

    @media (min-width: 769px) {
      a {
        color: #fff;
        height: 40px;
        float: right;
        background: #e6e6e6;
        background: rgba(61, 61, 68, 0.3);
        transition: background 0.3s;
        text-align: center;
        border-radius: 5px 0 0 0;
      }
    }
  }

  .quanta_q_matrix {
    tbody {
      tr {
        transition: background 0.2s ease-in-out;
        &:hover {
          background: ${({ theme }) => theme.colors.itemBackground};
        }
      }
    }
    th {
      border: none;
    }
    td {
      border: none;
      label {
        margin-bottom: 0;
        cursor: pointer;
        border: 2px solid ${({ theme }) => theme.colors.brandSolid};
        width: 1.1em;
        height: 1.1em;
        display: inline-block;
        background: 0 0;
        border-radius: 100%;
        &.checked {
          background-color: ${({ theme }) => theme.colors.brandColor};
        }
        input {
          opacity: 0;
          width: 0;
        }
      }
    }
  }

  .title_img_table {
    padding: 1rem;
    img {
      width: 100%;
    }
    &.table_title {
      margin-bottom: -50px
    }
  }

  .${({ theme }) => theme.baseName}_page {
    @media (max-width: 564px) {
        h4 {
        font-size: 1.25rem;
        text-align: center;
      }
      .${({ theme }) => theme.baseName}_qstn_title {
        text-align: center;
      }
    }

    .btn-group {
      width: 100%;
      display: inline-grid;
      label {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        transition: all 0.15s ease-in;
        &.btn {
          background: ${({ theme }) => theme.colors.itemBackground};
          border: none;
          border-radius: 4px;
          color: #212529;
          &:hover {
            background: ${({ theme }) => theme.colors.brandColor};
            color: white;
          }
        }

        .${({ theme }) => theme.baseName}_q_rating_item_text {
          font-weight: 700;
          cursor: pointer;
          transition: background 0.2s ease-in-out;
          padding: 20px;
          display: block;
          text-align: center;
          border-radius: 4px;
        }
      }
    }
  }
`;

export default BigFiveWrapper;
