const baseInfo = {
  locale: 'es',
  title: 'Comentarios',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_05',
      elements: [
        {
          type: 'comment',
          name: 'FINAL_COMMENTS',
          title:
            '¿Tiene algún comentario adicional que le gustaría hacer antes de finalizar esta encuesta?'
        }
      ]
    }
  ]
};

export default baseInfo;
