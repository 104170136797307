import React from 'react';
import Presentation from './Presentation';
import SortableIntro from './Sortable_Intro';
import SortablePolarList from './SortablePolarList';
import SortableStudyArea from './SortableStudyArea';
import SortableStudyIntro from './SortableStudy_Intro';
// import PilotajeFeedback from './PilotajeFeedback';
// import PGIPiloto from './PGI_PILOTO';
import EnfoqueEstudio from './EnfoqueEstudio';
import FinishedMessage from './FinishedMessage/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../AppConfig';
import VocationalWrapper from './votacional.style';

const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

class Equis2Test extends React.Component {
  state = {
    error: '',
    feedbackForm: 'A', // default
    stage: getQuery('stage')
  };

  componentDidMount = async () => {
    // const { campaign, updateContext } = this.props;
    const { campaign, updateContext, user } = this.props;
    const rut = user ? user.rut : 'anon';
    // assign random Form logic
    const forms = ['A', 'B'];
    // select random
    const randomForm = forms[Math.floor(Math.random() * forms.length)];
    // get local form
    const localForm = localStorage.getItem(
      `feedbackForm-${campaign.id}-${rut}`
    );
    if (!localForm) {
      // if not local form, assign form
      localStorage.setItem(`feedbackForm-${campaign.id}-${rut}`, randomForm);
    }
    // use local form or new selected form as fallback
    const feedbackForm = localForm || randomForm;
    // set prev feedbackForm
    await this.setState({ feedbackForm });

    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        campaign.id,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: this.state.stage ? parseInt(this.state.stage) : USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        campaign.id,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: this.state.stage ? parseInt(this.state.stage) : USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    const { campaign, stage, user, setUserData } = this.props;
    const rut = user ? user.rut : 'anon';
    // console.log({ rut });
    const stageName = `userStage-${rut}-${campaign.id}`;
    // await postData(data, user, rut);
    try {
      const input = {
        values: { ...data, caseId, rut },
        campaignID: campaign.id,
        responseID: user && user.responseID,
        CODE: rut,
        finished: data.finished
      };
      const responseData = await postData(input);
      // console.log({ responseData });
      if (responseData) {
        await setUserData(prevData => ({
          ...prevData,
          responseID: responseData.id
        }));
      }
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    if (data.CONSENT === '2') {
      console.log('NO CONSENT');
      this.props.toNextStage(stageName);
    }
    if (stage < 6) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, campaign, user } = this.props;
    // const { campaign, user } = this.props;
    const rut = user ? user.rut : 'anon';
    // const stage = 5;
    const { error, feedbackForm } = this.state;
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (stage > 5) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='UAI METRICS'
          email='uaimetrics@uai.cl'
        />
      );
    }
    if (stage === 5) {
      return (
        <EnfoqueEstudio
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          isLast
        />
      );
    }
    if (stage === 4) {
      return (
        <SortableStudyArea
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          label='STUDY'
        />
      );
    }
    if (stage === 3) {
      return (
        <SortableStudyIntro
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 2) {
      return (
        <SortablePolarList
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 1) {
      return (
        <SortableIntro
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    return (
      <Presentation
        onComplete={this.onComplete}
        campaign={campaign}
        user={user}
        rut={rut}
        stage={stage}
      />
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <Equis2Test {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};

export default WithWrapper;
