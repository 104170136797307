import React from 'react';
import Survey, { CssNames } from '../../Survey';
import SortableSurvey from './sortable_list';
import SortableListWrapper from './sortable_list.styles';

function listMinSelected(params) {
  const [responses, n] = params;
  const minSelected = responses.length === n;
  if (minSelected) {
    return true;
  }
  return false;
}

class SortableListSurvey extends React.Component {
  _times = {};

  onComplete = async (survey, options) => {
    const { data } = survey;
    const { onComplete } = this.props;
    // console.log({ data, times: this._times });
    const parsed = {
      ...data,
      ...this._times
    };
    await onComplete(parsed);
  };

  onValidate = async survey => {
    const { currentPage } = survey;
    const { questions } = currentPage;
    // const currentQuestion = questions.length > 2 ? questions[2] : questions[1];
    const currentQuestion = questions[0];
    const { name } = currentQuestion;
    const qstName = `${name}_TIME`;
    const qstTime = currentPage.timeSpent;
    // console.log({ qstName, qstTime });

    this._times = { ...this._times, [qstName]: qstTime };
  };

  render() {
    const SortableListModel = new Survey.Model(SortableSurvey);
    Survey.FunctionFactory.Instance.register(
      'listMinSelected',
      listMinSelected,
      false // last param indicates if async callback or not
    );
    SortableListModel.startTimer();

    return (
      <SortableListWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={SortableListModel}
          css={CssNames}
          onComplete={this.onComplete}
          onValidatedErrorsOnCurrentPage={this.onValidate}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </SortableListWrapper>
    );
  }
}

export default SortableListSurvey;
