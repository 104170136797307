const demandsValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  }
];

const satisfactionValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];
const questionHeader = {
  type: 'html',
  name: 'HTML_LEARNING_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        Indique su grado de acuerdo con las siguientes frases <strong>utilizando una escala de 1 (Totalmente en Desacuerdo) a 4 (Completamente de Acuerdo).</strong>
      </span>
    </legend>
  </div>
  `
};
const satisfactionHeader = {
  type: 'html',
  name: 'HTML_LEARNING_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        ¿Qué tan satisfecho se encuentra actualmente con los siguientes aspectos? Por favor, responda <strong>utilizando una escala de 1 a 5, donde 1 es “Nada satisfecho” y 5 es “Muy satisfecho”.</strong>
      </span>
    </legend>
  </div>
  `
};

const WorkDemands = {
  locale: 'es',
  title: 'Exigencias en el Trabajo',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'WDEM_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_01',
          title: 'Mi trabajo exige ir muy deprisa',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_02',
          title: 'Mi trabajo exige trabajar con mucho esfuerzo mental',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_03',
          title: 'Se me pide hacer una cantidad excesiva de trabajo',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_04',
          title: 'No tengo suficiente tiempo para hacer mi trabajo',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_05',
          title: 'Recibo peticiones contradictorias de los demás',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_06',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_06',
          title:
            'Mi trabajo me obliga a concentrarme durante largos periodos de tiempo',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_07',
          title:
            'Mi tarea es a menudo interrumpida antes de haberla acabado y debo finalizarla más tarde',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WDEM_08',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'WDEM_08',
          title:
            'A menudo me retraso en mi trabajo porque debo esperar al trabajo de los demás',
          isRequired: true,
          rateValues: demandsValues
        }
      ]
    },
    {
      name: 'WSAT_01',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_01',
          title: 'La relación con los compañeros de trabajo',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_02',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_02',
          title: 'La estabilidad de su trabajo',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_03',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_03',
          title: 'El prestigio del colegio',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_04',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_04',
          title:
            'La compatibilidad del trabajo con la vida personal y familiar',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_05',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_05',
          title: 'El estilo de liderazgo de su jefe o supervisor directo',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_06',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_06',
          title: 'Las posibilidades de opinión y participación',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_07',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_07',
          title: 'El nivel de entretención que le produce su trabajo',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_08',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_08',
          title: 'El grado de cooperación que existe entre las distintas áreas',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_09',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_09',
          title: 'Su sueldo y beneficios económicos',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_10',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_10',
          title: 'Las posibilidades de capacitación y desarrollo profesional',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    },
    {
      name: 'WSAT_11',
      title: '',
      elements: [
        satisfactionHeader,
        {
          type: 'rating',
          name: 'WSAT_11',
          title: 'Las posibilidades para crear e innovar que se le otorgan',
          isRequired: true,
          rateValues: satisfactionValues
        }
      ]
    }
  ]
};

export default WorkDemands;
