const ratingValues = [
  {
    value: '1',
    text: 'Muy en desacuerdo'
  },
  {
    value: '2',
    text: 'En desacuerdo'
  },
  {
    value: '3',
    text: 'Ni de acuerdo ni en desacuerdo'
  },
  {
    value: '4',
    text: 'De acuerdo'
  },
  {
    value: '5',
    text: 'Muy de acuerdo'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_IMPACT_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        El propósito de esta sección conocer tu opinión sobre la evaluación que recibiste recientemente sobre tu desempeño en la certificación de competencias.
        <br />
        <br />
        Por favor lee cuidadosamente cada enunciado e indica tu grado de acuerdo con cada uno de ellos usando la siguiente escala:
        <br />
      </span>
    </legend>
  </div>
  `
};

const baseInfo = {
  locale: 'es',
  title: 'FAQ',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FAQ01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FAQ01',
          title:
            'La evaluación que recibí refleja adecuadamente mi desempeño en la certificación de competencias',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'FAQ02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FAQ02',
          title:
            'Estoy de acuerdo con las calificaciones que me pusieron en la certificación de competencias',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'FAQ03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FAQ03',
          title: 'Siento que el proceso de evaluación fue justo',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'FAQ04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'FAQ04',
          title:
            'Creo que los jueces fueron objetivos en la evaluación de mi desempeño',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    }
  ]
};

export default baseInfo;
