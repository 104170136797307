import React from 'react';
import BigFive from '../BigFive';
import CareerSuccess from '../CareerSuccess';
import Demographics from '../Demographics';
import GFNExample from '../GFNExample';
import GFNComplete from '../GFNComplete';
import LearningAgility from '../LearningAgility';
import WorkingInfo from '../WorkingInfo';
import FinishedMessage from '../FinishedMessage';
import postData from '../../API';
import getConfig from '../../config';

const BATTERY = 'INDEX';

class FullTest extends React.Component {
  componentDidMount = async () => {
    console.log('on dkms test');
    console.log({ props: this.props });
    const { updateContext } = this.props;
    const { USER_ID, USER_STAGE, STAGE_NAME } = await getConfig(BATTERY);
    console.log({ USER_ID, USER_STAGE, STAGE_NAME });
    await updateContext({
      user: USER_ID,
      stage: USER_STAGE
    });
  };

  onComplete = async data => {
    const { stage, user } = this.props;
    const stageName = `userStage-${BATTERY}`;
    await postData(data, user);
    if (stage < 7) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage } = this.props;

    if (stage > 6) {
      return <FinishedMessage />;
    }
    if (stage === 6) {
      return <WorkingInfo onComplete={this.onComplete} isLast />;
    }
    if (stage === 5) {
      return <CareerSuccess onComplete={this.onComplete} />;
    }
    if (stage === 4) {
      return <GFNComplete onComplete={this.onComplete} />;
    }
    if (stage === 3) {
      return <GFNExample onComplete={this.onComplete} />;
    }
    if (stage === 2) {
      return <BigFive onComplete={this.onComplete} />;
    }
    if (stage === 1) {
      return <LearningAgility onComplete={this.onComplete} />;
    }
    return <Demographics onComplete={this.onComplete} />;
  }
}

export default FullTest;
