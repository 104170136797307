const baseInfo = {
  locale: 'es',
  title: 'Costos económicos de la enfermedad',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    // {
    //   name: 'COSTS_PAGE_01',
    //   // title: 'Impacto familiar',
    //   elements: [
    //     {
    //       type: 'radiogroup',
    //       name: 'COSTS_01',
    //       title: '¿Con qué sistema previsional de salud cuenta {CHILD_NAME}?',
    //       isRequired: true,
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'Isapre'
    //         },
    //         {
    //           value: '2',
    //           text: 'Fonasa'
    //         },
    //         {
    //           value: '3',
    //           text: 'Capredena'
    //         },
    //         {
    //           value: '4',
    //           text: 'PRAIS'
    //         },
    //         {
    //           value: '5',
    //           text: 'Otro'
    //         }
    //       ]
    //     },
    //     {
    //       type: 'text',
    //       title: 'Especifique:',
    //       name: 'COSTS_01_OTHER',
    //       isRequired: true,
    //       visibleIf: '{COSTS_01} = "5"'
    //     },
    //     {
    //       type: 'radiogroup',
    //       name: 'COSTS_02',
    //       title:
    //         'Ya que su respuesta fue “Fonasa”, ¿En qué tramo se encuentra {CHILD_NAME}?',
    //       isRequired: true,
    //       visibleIf: '{COSTS_01} = "2"',
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'A'
    //         },
    //         {
    //           value: '2',
    //           text: 'B'
    //         },
    //         {
    //           value: '3',
    //           text: 'C'
    //         },
    //         {
    //           value: '4',
    //           text: 'D'
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   name: 'COSTS_PAGE_02',
    //   // title: 'Impacto familiar',
    //   elements: [
    //     {
    //       type: 'radiogroup',
    //       name: 'COSTS_03',
    //       title:
    //         '¿{CHILD_NAME} cuenta actualmente con seguro complementario de salud?',
    //       isRequired: true,
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'Si'
    //         },
    //         {
    //           value: '2',
    //           text: 'No'
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      name: 'COSTS_PAGE_01',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01',
          html: `
<div class="QuestionText BorderColor">
  <span style="font-size:16px;">
    <hr style="border: 2px dashed grey;">
    <span style="font-family:arial,helvetica,sans-serif;">
      <strong>**Las siguientes preguntas se respondes SOLO si el niño/a NO tiene isapre:</strong>
    </span>
    <hr style="border: 2px dashed grey;">
  </span>
</div>
`
        },
        {
          type: 'radiogroup',
          name: 'COSTS_01',
          title:
            '¿Utiliza o utilizó servicios de salud privados o remunerados con médicos y especialistas en relación con el diagnóstico o tratamiento de {CHILD_NAME}, por fuera de su plan médico usual?',
          // isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Si'
            },
            {
              value: '02',
              text: 'No'
            },
            {
              value: '03',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'radiogroup',
          name: 'COSTS_01_WHY',
          title: '¿Por qué?',
          // isRequired: true,
          visibleIf: '{COSTS_01} = "01"',
          choices: [
            {
              value: '01',
              text: 'Largo tiempo de espera en el sector público'
            },
            {
              value: '02',
              text: 'Servicio no estaba disponible en sector público'
            },
            {
              value: '03',
              text:
                'El servicio estaba disponible en el sector publico, pero lejos de nuestro lugar de residencia'
            },
            {
              value: '04',
              text:
                'Porque teníamos recomendación de un especialista del sector privado'
            },
            {
              value: '05',
              text: 'Otra'
            },
            {
              value: '06',
              text: 'No sabe / No responde'
            }
          ]
        },
        {
          type: 'text',
          title: 'Especifique:',
          name: 'COSTS_01_WHY_OTHER',
          // isRequired: true,
          visibleIf: '{COSTS_01_WHY} = "05"'
        }
      ]
    },
    {
      name: 'COSTS_PAGE_02',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'checkbox',
          name: 'COSTS_02',
          title:
            'Para afrontar el tratamiento económicamente, usted o alguien de su familia tuvo que: [MARCAR TODAS LAS QUE CORRESPONDAN]',
          // isRequired: true,
          choices: [
            {
              value: '01',
              text: 'Pedir prestado dinero a un familiar y/o amigos/as'
            },
            {
              value: '02',
              text: 'Pedir un préstamo bancario'
            },
            {
              value: '03',
              text: 'Vender pertenencias valiosas'
            },
            {
              value: '04',
              text: 'Vender una propiedad'
            },
            {
              value: '05',
              text: 'Realizar un bingo/rifa'
            },
            {
              value: '06',
              text: 'Pedir apoyo económico a una fundación u ONG'
            },
            {
              value: '07',
              text: 'Otro'
            }
          ]
        },
        {
          type: 'text',
          title: 'Si otra, especifique',
          name: 'COSTS_02_OTHER',
          // isRequired: true,
          visibleIf: '{COSTS_02} contains "07"'
        }
      ]
    },
    {
      name: 'COSTS_PAGE_03',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'multipletext',
          name: 'COSTS_03',
          title:
            'Si es posible, ¿podría indicar los montos aproximados asociados a lo que mencionó en su respuesta anterior?',
          items: [
            {
              name: 'COSTS_03_01',
              title: 'Pedir prestado dinero a un familiar y/o amigos/as ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_03_02',
              title: 'Pedir un préstamo bancario ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_03_03',
              title: 'Vender una propiedad ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_03_04',
              title: 'Vender pertenencias valiosas ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_03_05',
              title: 'Realizar un bingo/rifa ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_03_06',
              title: 'Pedir apoyo económico a una fundación u ONG ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_03_07',
              title: 'Otro'
            },
            {
              name: 'COSTS_03_08',
              title: 'No sabe / No responde'
            }
          ]
        }
      ]
    },
    {
      name: 'COSTS_PAGE_04',
      // title: 'Impacto familiar',
      elements: [
        {
          type: 'multipletext',
          name: 'COSTS_04',
          title:
            'Si es posible, ¿Nos podría indicar en qué otros costos financieros ha incurrido como consecuencia del diagnóstico o tratamiento de {CHILD_NAME} y cuáles son los montos asociados aproximadamente?',
          items: [
            {
              name: 'COSTS_04_01',
              title:
                'Adaptación necesaria de viviendas (condiciones sanitarias, barreras arquitectónicas) ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_04_02',
              title: 'Arriendo de vivienda adicional ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_04_03',
              title: 'Compra del equipo de rehabilitación ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_04_04',
              title:
                'Realizar exámenes como resonancia magnética, tomografía computarizada, ultrasonido, PET y otros que por alguna razón no pudieron ser reembolsadas total o parcialmente. ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_04_05',
              title:
                'Gastos adicionales en educación como tutorías, adquisición de materiales didácticos u otro ($)',
              inputType: 'number'
            },
            {
              name: 'COSTS_04_06',
              title: 'Otro'
            },
            {
              name: 'COSTS_04_07',
              title: 'No sabe / No responde'
            }
          ]
        }
      ]
    }

    // {
    //   name: 'COSTS_PAGE_04',
    //   // title: 'Impacto familiar',
    //   elements: [
    //     {
    //       type: 'radiogroup',
    //       name: 'COSTS_04',
    //       title:
    //         'Para afrontar el tratamiento económicamente, usted o alguien de su familia tuvo que:',
    //       isRequired: true,
    //       choices: [
    //         {
    //           value: '1',
    //           text: 'Pedir prestado dinero a un familiar y/o amigos/as'
    //         },
    //         {
    //           value: '2',
    //           text: 'Pedir un préstamo bancario'
    //         },
    //         {
    //           value: '3',
    //           text: 'Vender inmuebles del hogar'
    //         },
    //         {
    //           value: '4',
    //           text: 'Realizar un bingo/rifa'
    //         },
    //         {
    //           value: '5',
    //           text: 'Pedir apoyo a una fundacion u ONG'
    //         },
    //         {
    //           value: '6',
    //           text: 'Otro'
    //         }
    //       ]
    //     },
    //     {
    //       type: 'text',
    //       title: 'Especifique:',
    //       name: 'COSTS_04_OTHER',
    //       isRequired: true,
    //       visibleIf: '{COSTS_04} = "6"'
    //     },
    //     {
    //       type: 'multipletext',
    //       name: 'COSTS_05_GROUP',
    //       visibleIf: '{COSTS_04} = "2"',
    //       // isRequired: true,
    //       title:
    //         'Ya que su respuesta fue “Pedir un préstamo bancario”, ¿A qué porcentaje del ingreso familiar equivale ese préstamo?',
    //       items: [
    //         {
    //           name: 'COSTS_05',
    //           title: 'Porcentaje (%)',
    //           isRequired: true,
    //           inputType: 'number',
    //           validators: [
    //             {
    //               type: 'numeric',
    //               text: 'Porcentaje invalido, seleccione un numero de 0 a 100',
    //               minValue: 0,
    //               maxValue: 100
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // }
  ]
};

export default baseInfo;
