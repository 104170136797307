import React from 'react';
import LoginBox from './LoginBox';
import LogInWrapper from './login.styles';

const LogInForm = props => {
  return (
    <LogInWrapper>
      <LoginBox {...props} />
    </LogInWrapper>
  );
};

export default LogInForm;
