import React from 'react';
import Presentation from './Presentation';
import SCCI from './SCCI';
import SCCIINTRO from './SCCI_INTRO';
import DCareerBelief from './CreenciasErroneasCarrera';
import DificultadInfo from './DificultadInfo';
import IndecisionGen from './IndecisionGeneral';
import SortableIntro from './Sortable_Intro';
import SortablePolarList from './SortablePolarList';
import SortablePolarDimension from './SortablePolarDimension';
import PilotajeFeedback from './PilotajeFeedback';
import PGIPiloto from './PGI_PILOTO';
import FinishedMessage from './FinishedMessage/NabuFinish';
import ErrorMessage from './ErrorMessage';
import postData from './API';
import getConfig from '../AppConfig';
import VocationalWrapper from './votacional.style';
import getWinningDimensions from './helpers';

const getQuery = param => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};

class AppEquisOne extends React.Component {
  state = {
    // sortable second stage
    error: '',
    firstDimension: '',
    secondDimension: '',
    feedbackForm: 'A', // default
    stage: getQuery('stage')
    // firstDimension: 'LM',
    // secondDimension: 'IP'
  };

  componentDidMount = async () => {
    // const { campaign, updateContext } = this.props;
    const { campaign, updateContext, user } = this.props;
    const rut = user ? user.rut : 'anon';
    const firstDimFallBack = 'IP';
    const secondDimFallBack = 'LM';
    const firstDimension =
      localStorage.getItem(`FirstDim-${campaign.id}-${rut}`) ||
      firstDimFallBack;
    const secondDimension =
      localStorage.getItem(`SecondDim-${campaign.id}-${rut}`) ||
      secondDimFallBack;
    const thirdDimension = localStorage.getItem(
      `ThirdDim-${campaign.id}-${rut}`
    );
    const fourthDimension = localStorage.getItem(
      `FourthDim-${campaign.id}-${rut}`
    );

    // assign random Form logic
    const forms = ['A', 'B'];
    // select random
    const randomForm = forms[Math.floor(Math.random() * forms.length)];
    // get local form
    const localForm = localStorage.getItem(
      `feedbackForm-${campaign.id}-${rut}`
    );
    if (!localForm) {
      // if not local form, assign form
      localStorage.setItem(`feedbackForm-${campaign.id}-${rut}`, randomForm);
    }
    // use local form or new selected form as fallback
    const feedbackForm = localForm || randomForm;

    // set prev dimensions
    await this.setState({
      firstDimension,
      secondDimension,
      thirdDimension,
      fourthDimension,
      feedbackForm
    });
    // await this.setState({ firstDimension: 'IP', secondDimension: 'LM' });
    if (rut) {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        campaign.id,
        rut
      );
      await updateContext({
        user: USER_ID,
        stage: this.state.stage ? parseInt(this.state.stage) : USER_STAGE,
        rut: CURRENT_CODE
      });
    } else {
      const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
        campaign.id,
        'anon'
      );
      await updateContext({
        user: USER_ID,
        stage: this.state.stage ? parseInt(this.state.stage) : USER_STAGE,
        rut: CURRENT_CODE
      });
    }
  };

  onSortableComplete = async data => {
    const caseId = this.props.state.user;
    const { campaign, user, setUserData } = this.props;
    const rut = user ? user.rut : 'anon';
    const stageName = `userStage-${rut}-${campaign.id}`;
    // await postData(data, caseId, rut);
    try {
      const input = {
        values: { ...data, caseId, rut },
        campaignID: campaign.id,
        responseID: user && user.responseID,
        CODE: rut,
        finished: data.finished
      };
      const responseData = await postData(input);
      // console.log({ responseData });
      if (responseData) {
        await setUserData(prevData => ({
          ...prevData,
          responseID: responseData.id
        }));
      }
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }

    // set winning dimensions on storage stage
    const { first, second, third, fourth } = getWinningDimensions(data);
    localStorage.setItem(`FirstDim-${campaign.id}-${rut}`, first);
    localStorage.setItem(`SecondDim-${campaign.id}-${rut}`, second);
    localStorage.setItem(`ThirdDim-${campaign.id}-${rut}`, third);
    localStorage.setItem(`FourthDim-${campaign.id}-${rut}`, fourth);
    // set new conditional stages
    await this.setState({
      firstDimension: first,
      secondDimension: second,
      thirdDimension: third,
      fourthDimension: fourth
    });
    // await this.setState({ firstDimension: 'IP', secondDimension: 'LM' });
    return this.props.toNextStage(stageName);
  };

  onComplete = async data => {
    const caseId = this.props.state.user;
    const { campaign, stage, user, setUserData } = this.props;
    const rut = user ? user.rut : 'anon';
    // console.log({ rut });
    const stageName = `userStage-${rut}-${campaign.id}`;
    // await postData(data, user, rut);
    try {
      const input = {
        values: { ...data, caseId, rut },
        campaignID: campaign.id,
        responseID: user && user.responseID,
        CODE: rut,
        finished: data.finished
      };
      const responseData = await postData(input);
      // console.log({ responseData });
      if (responseData) {
        await setUserData(prevData => ({
          ...prevData,
          responseID: responseData.id
        }));
      }
    } catch (err) {
      console.log(err);
      return this.setState({
        error:
          'Tuvimos un error al momento de guardar esta etapa, por favor, recarga tu navegador para intentarlo nuevamente :('
      });
    }
    if (data.CONSENT === '2') {
      console.log('NO CONSENT');
      this.props.toNextStage(stageName);
    }
    if (stage < 14) {
      return this.props.toNextStage(stageName);
    }
  };

  render() {
    const { stage, campaign, user } = this.props;
    const rut = user ? user.rut : 'anon';
    // const stage = 9;
    const {
      firstDimension,
      secondDimension,
      thirdDimension,
      fourthDimension,
      error,
      feedbackForm
    } = this.state;
    // console.log({ stage, firstDimension, secondDimension });
    if (!!error) {
      return <ErrorMessage error={error} />;
    }

    if (stage > 13) {
      return (
        <FinishedMessage
          // onComplete={this.onComplete}
          name='UAI METRICS'
          email='uaimetrics@uai.cl'
        />
      );
    }

    if (stage === 13) {
      return (
        <PGIPiloto
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          form={feedbackForm}
          isLast
        />
      );
    }
    if (stage === 12) {
      return (
        <PilotajeFeedback
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 11) {
      return (
        <IndecisionGen
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 10) {
      return (
        <DificultadInfo
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 9) {
      return (
        <DCareerBelief
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          SEX={localStorage.getItem(`current-${rut}-SEX`) || '1'}
        />
      );
    }
    if (stage === 8) {
      return (
        <SCCI
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 7) {
      return (
        <SCCIINTRO
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 6) {
      return (
        <SortablePolarDimension
          dimension={fourthDimension}
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          label='FOURTH'
        />
      );
    }
    if (stage === 5) {
      return (
        <SortablePolarDimension
          dimension={thirdDimension}
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          label='THIRD'
        />
      );
    }
    if (stage === 4) {
      return (
        <SortablePolarDimension
          dimension={secondDimension}
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          label='SECOND'
        />
      );
    }
    if (stage === 3) {
      return (
        <SortablePolarDimension
          dimension={firstDimension}
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
          label='FIRST'
        />
      );
    }
    if (stage === 2) {
      return (
        <SortablePolarList
          onComplete={this.onSortableComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    if (stage === 1) {
      return (
        <SortableIntro
          onComplete={this.onComplete}
          campaign={campaign}
          user={user}
          rut={rut}
          stage={stage}
        />
      );
    }
    return (
      <Presentation
        onComplete={this.onComplete}
        campaign={campaign}
        user={user}
        rut={rut}
        stage={stage}
      />
    );
  }
}

const WithWrapper = props => {
  // withAuth for padding-bottom for auth details footer
  return (
    <VocationalWrapper withAuth={props.withAuth}>
      <div className='tl' />
      <div className='tr' />
      <AppEquisOne {...props} />
      <div className='bl' />
      <div className='br' />
    </VocationalWrapper>
  );
};
export default WithWrapper;
