import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

// all available props
const chatTheme = {
  background: '#f5f8fb',
  // fontFamily: 'Helvetica Neue',
  headerBgColor: '#009fe3',
  headerFontColor: '#fff',
  // headerFontSize: '15px',
  botBubbleColor: '#009fe3',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a'
};

export const ChatCustomComp = ({ children }) => {
  return <div>{children}</div>;
};

class ChatBotComponent extends React.Component {
  render() {
    return (
      <ThemeProvider theme={chatTheme}>
        <ChatBot {...this.props} />
      </ThemeProvider>
    );
  }
}

export default ChatBotComponent;
