import React from 'react';
import Survey, { CssNames } from '../../Survey';
import RemunerationSurvey from './remuneration';
import RemunerationWrapper from './Remuneration.styles';

class Remuneration extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    // console.log('Survey results: ');
    const { data } = survey;
    const { onComplete } = this.props;
    // console.log({ data });
    const parsed = {
      ...data
    };
    await onComplete(parsed);
  };

  render() {
    const RemunerationModel = new Survey.Model(RemunerationSurvey);
    return (
      <RemunerationWrapper>
        <Survey.Survey
          model={RemunerationModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className="Footer"></div>
        <div className="PushStickyFooter"></div>
        <div className="Plug">
          <a
            href="http://www.uaimetrics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UAIMetrics
          </a>
        </div>
      </RemunerationWrapper>
    );
  }
}

export default Remuneration;
