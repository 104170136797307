const ratingValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  },
  {
    value: '6',
    text: '6'
  },
  {
    value: '7',
    text: '7'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_QUESTION_Header',
  html: `
  <div class="">
    <span style="font-size:16px;">
      Por favor indica en qué medida estas afirmaciones te representan utilizando la siguiente escala:
    </span>
    <br />
    <br />
  </div>
  `
};

export const TestIntro = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'IG_PAGE_01_M',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_DIALOG_01_IGM',
          html: `
  <div class="QuestionText BorderColor">
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        <h3>Instrucciones</h3>
      </span>
      <br />
      A continuación encontrarás una lista de afirmaciones relacionadas con el tomar decisiones en general.
      <br />
      <br />
      Por favor indica en qué medida estas afirmaciones te representan usando una escala de <strong>1</strong> (No me representa para nada) a <strong>7</strong> (Me representa muy bien), tal como se presenta a continuación:
      <br />
      <br />
      <div class="qstn-likert-label top">
        <div>No me representa para nada</div>
      </div>
      <div class="btn-group">
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="1" value="1"><span class="quanta_q_rating_item_text"><span>1</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="2" value="2"><span class="quanta_q_rating_item_text"><span>2</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="3" value="3"><span class="quanta_q_rating_item_text"><span>3</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="4" value="4"><span class="quanta_q_rating_item_text"><span>4</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="5" value="5"><span class="quanta_q_rating_item_text"><span>5</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="6" value="6"><span class="quanta_q_rating_item_text"><span>6</span></span></label>
        <label class="btn btn-primary"><input type="radio" class="sv-visuallyhidden" name="IG_00" aria-required="true" aria-label="7" value="7"><span class="quanta_q_rating_item_text"><span>7</span></span></label>
      </div>
      <div class="qstn-likert-label bottom">
        <div>Me representa muy bien</div>
      </div>
    </span>
  </div>
`
        }
      ]
    }
  ]
};

const baseInfo = {
  locale: 'es',
  title: '',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  progressBarType: 'pages',
  goNextPageAutomatic: true,
  firstPageIsStarted: false,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'IG_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_01',
          title: 'Encuentro fácil tomar decisiones',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_02',
          title: 'Me caracterizaría como una persona indecisa',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_03',
          title: 'No sé como tomar decisiones',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_04',
          title: 'No dudo demasiado cuando tengo que tomar una decisión',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_05',
          title:
            'Cuando tomo una decisión, generalmente me siento muy inseguro',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_06',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_06',
          title: 'Puedo tomar decisiones rápidamente',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_07',
          title: 'Normalmente no pospongo tener que tomar una decisión',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_08',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_08',
          title: 'Trato de evitar tomar decisiones',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_09',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_09',
          title: 'Intento dejar la toma de decisiones a otras personas',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_10',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_10',
          title: 'Normalmente reconsidero las decisiones que tomo',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_11',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_11',
          title:
            'Después de tomar una decisión, no puedo dejar de pensar en ello',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_12',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_12',
          title:
            'Normalmente después de que tomo una decisión, creo que me he equivocado',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_13',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_13',
          title: 'Es difícil para mi tomar una decisión',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_14',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_14',
          title: 'Sé que pasos debo realizar para tomar una decisión',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_15',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_15',
          title: 'Cuando tomo una decisión, normalmente me siento seguro',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_16',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_16',
          title:
            'Me toma mucho tiempo analizar los pros y contras cuando tengo que tomar una decisión',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_17',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_17',
          title: 'Generalmente me demoro en tomar una decisión',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_18',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_18',
          title: 'No evito situaciones en que se deben tomar decisiones',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_19',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_19',
          title:
            'Prefiero tomar las decisiones por mi mismo que dejárselo a otras personas',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_20',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_20',
          title: 'Una vez que tomo una decisión, me apego a ella',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_21',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_21',
          title:
            'Una vez que tomo una decisión, dejo de preocuparme por el asunto',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'IG_22',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'IG_22',
          title:
            'Después de tomar una decisión, no me arrepiento de lo decidido',
          // isRequired: true,
          minRateDescription: 'No me representa para nada',
          maxRateDescription: 'Me representa muy bien',
          rateValues: ratingValues
        }
      ]
    }
  ]
};

export default baseInfo;
