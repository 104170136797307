import styled from 'styled-components';
import BottomLeftImg from './img/bottom-left.png';
import TopRightImg from './img/top-right.png';

const LogInWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.extraLight};
  ${'' /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cg fill='%23f1f1f1' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M5 3.59L1.46.05.05 1.46 3.59 5 .05 8.54l1.41 1.41L5 6.41l3.54 3.54 1.41-1.41L6.41 5l3.54-3.54L8.54.05 5 3.59zM17 2h24v2H17V2zm0 4h24v2H17V6zM2 17h2v24H2V17zm4 0h2v24H6V17z'/%3E%3C/g%3E%3C/svg%3E"); */}
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f1f1f1' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  height: 100vh;
  width: 100vw;
  padding: 3rem 5rem;
  display: flex;
  align-items: center;
  @media (max-width: 574px) {
    padding: 2rem 1rem;
  }
`;

export const Form = styled.form`
  text-align: center;
  label {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 0.9rem;
    font-weight: 700;
  }
  input {
    background: #fff;
    color: ${({ theme }) => theme.colors.textColor};
    -webkit-transition: background 0.2s ease-in-out;
    padding: 8px 20px;
    display: block;
    text-align: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.greyBackground};
    width: 100%;
    margin-bottom: 1rem;
  }
  button {
    background: ${({ theme }) => theme.colors.brandColor};
    color: #fff;
    border: none;
    -webkit-transition: background 0.2s ease-in-out;
    padding: 8px 20px;
    text-align: center;
    border-radius: 4px;
  }
`;

export const FormInput = styled.div`
  padding: 0.5rem 1rem;
  p {
    font-size: 0.85rem;
  }
  @media (max-width: 574px) {
    padding: 0rem 0.5rem;
  }
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  padding: 4rem 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.light};
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  ${'' /* border: 1px solid; */}
  @media (max-width: 724px) {
    padding: 2rem 1rem;
    overflow: auto;
    height: 100%;
  }
  .bl {
    background-image: url(${BottomLeftImg});
    position: absolute;
    opacity: 0.3;
    ${'' /* bottom: -10px; */}
    bottom: 0;
    left: 0;
    ${'' /* z-index: -1; */}
    height: 70%;
    width: 30%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    @media (max-width: 1440px) {
      height: 70%;
      width: 30%;
    }
    @media (max-width: 1224px) {
      height: 80%;
      width: 40%;
    }
    @media (max-width: 991px) {
      height: 100%;
      width: 50%;
    }
    @media (max-width: 574px) {
      background-image: none;
    }
  }
  .tr {
    background-image: url(${TopRightImg});
    position: absolute;
    opacity: 0.3;
    top: 0;
    right: 0;
    ${'' /* z-index: -1; */}
    height: 60%;
    width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    @media (max-width: 1440px) {
      height: 50%;
      width: 70%;
    }
    @media (max-width: 1224px) {
      height: 50%;
      width: 80%;
    }
    @media (max-width: 991px) {
      height: 40%;
      width: 80%;
    }
    @media (max-width: 774px) {
      height: 30%;
      width: 70%;
    }
    ${'' /* @media (max-width: 574px) {
      background-image: none;
    } */}
  }
`;

export const Col = styled.div`
  width: 50%;
  padding: 1rem;
  z-index: 1;
  .logo-wrapper {
    width: 100%;
    img {
      max-width: 250px;
    }
  }
  .equis-info {
    p {
      line-height: 1.8;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
  @media (min-width: 1440px) {
    padding: 1rem 6rem;
  }
  @media (max-width: 724px) {
    width: 100%;
    .logo-wrapper {
      text-align: center;
    }
    .equis-info {
      p {
        line-height: 1.2;
      }
    }
  }
`;

export const SchoolInfoWrapper = styled.div`
  ${'' /* display: flex; */}
  ${'' /* justify-content: center; */}
  ${'' /* align-items: center; */}
  ${'' /* align-items: baseline; */}
  color: ${({ theme }) => theme.colors.grey};
  font-weight: 500;
  button {
    opacity: 0.6;
    font-size: 0.85rem;
    margin-left: 5px;
    background: none;
    color: ${({ theme }) => theme.colors.greyBackground};
    padding: 0;
    text-decoration: underline;
    &:hover {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  button {
    width: 50%;
    background: ${({ theme }) => theme.colors.brandSolid};
    color: #fff;
    border: none;
    transition: all 0.2s;
    padding: 10px 25px;
    display: block;
    text-align: center;
    border-radius: 4px;
    font-weight: 600;
    &:hover {
      transform: translateY(-2px);
      background: ${({ theme }) => theme.colors.brandColor};
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    }
  }
  @media (max-width: 574px) {
    padding-bottom: 3rem;
    button {
      width: 100%;
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  background-color: ${({ theme }) => theme.colors.dangerLight};
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  margin: 5px 10px;
`;

export default LogInWrapper;
