import React from 'react';
import EquisJuniorLogo from './img/equis_junior_logo.png';
import EquisOneLogo from './img/equis_1_logo.png';
import {
  Form,
  FormInput,
  SchoolInfoWrapper,
  Row,
  Col,
  Footer,
  ErrorMessage
} from './finished.styles';

const MessageBox = props => {
  const { SKU, campaign, user } = props;
  const userLastname = user?.lastname;
  const userName = user?.name;
  const userComplete =
    [userLastname, userName].some(Boolean) &&
    `${userLastname ? `${userLastname}, ` : ''}${userName}`;

  return (
    <Row>
      {SKU === 'EQUIS_ONE' && <EquisOneIntro />}
      {SKU === 'EQUIS_ONE_PILOTO' && <EquisOneIntro />}
      {SKU === 'EQUIS_ONE_SORTABLE_ITEMS' && <EquisOneIntro />}
      {SKU === 'EQUIS_ONE_DIMENSION_ITEMS' && <EquisOneIntro />}
      {SKU === 'EQUIS_JUNIOR' && <EquisJuniorIntro />}
      {SKU === 'EQUIS_JUNIOR_PILOTO' && <EquisJuniorIntro />}
      <Col>
        <Form onSubmit={() => {}}>
          <FormInput>
            <SchoolInfoWrapper>
              <div>
                {userComplete || campaign.organization || campaign.title}
              </div>
            </SchoolInfoWrapper>
          </FormInput>
          <FormInput>
            <p>Tu respuesta ya fue registrada con éxito anteriormente.</p>
            <p>No es necesario que haga nada más.</p>
          </FormInput>
        </Form>
      </Col>
      <div>
        <div className='bl' />
        <div className='tr' />
      </div>
    </Row>
  );
};
export default MessageBox;

const EquisOneIntro = () => (
  <Col>
    <div className='logo-wrapper'>
      <img src={EquisOneLogo} alt='equis1 logo' />
    </div>
    <br />
    <div className='equis-info'>
      <p>
        Bienvenido a <strong>Equis One</strong>.
      </p>
      <p>
        Si tienes alguna pregunta o deseas ponerte en contacto con nosotros,
        puedes contactar a UAI METRICS al email{' '}
        <a href={`mailto:uaimetrics@uai.cl`}>uaimetrics@uai.cl</a>.
      </p>
    </div>
  </Col>
);

const EquisJuniorIntro = () => (
  <Col>
    <div className='logo-wrapper'>
      <img src={EquisJuniorLogo} alt='equis junior logo' />
    </div>
    <br />
    <div className='equis-info'>
      <p>
        Bienvenido a <strong>Equis Junior</strong>.
      </p>
      <p>
        Si tienes alguna pregunta o deseas ponerte en contacto con nosotros,
        puedes contactar a UAI METRICS al email{' '}
        <a href={`mailto:uaimetrics@uai.cl`}>uaimetrics@uai.cl</a>.
      </p>
    </div>
  </Col>
);
