const GFL_EXAMPLE = {
  locale: 'es',
  title: 'Serie de Letras (Test)',
  showCompletedPage: true,
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  // maxTimeToFinish: 600,
  // maxTimeToFinishPage: 0,
  // showTimerPanel: 'top',
  // showTimerPanelMode: 'survey',
  // completedHtml:
  //   '<h4>Respondiste <b>{correctedAnswers}</b> preguntas correctas de <b>{questionCount}</b>.</h4>',
  pages: [
    {
      name: 'page1',
      elements: [
        // {
        //   type: 'html',
        //   name: 'question1',
        //   html: `Este es un test para evaluar su capacidad para analizar patrones.<br />
        //     Cada problema en esta prueba tiene cinco sets de letras con cuatro letras en cada set. <br />
        //     Cuatro de estos sets de letras son similares de alguna manera. <br />
        //     TU TAREA consiste en inferir la regla que hace similares a estos sets. <br />
        //     Un set de letras será diferente a los otros y no encajará en esta regla. <br />
        //     <br />
        //     Haz click en el set de letras que tú crees NO SIGUE la regla.
        //     <br /><br /><br />
        //     Las reglas NO estarán basadas en el sonido del set de palabras, la forma de las letras, o si las combinaciones de letras forman palabras o partes de alguna.
        //     <br />
        //     En la siguiente página se encuentra el primer ejemplo de práctica.
        //     <br />
        //     Intenta encontrar la regla que aplica a cuatro sets de letras, luego haz click en el set de letras que NO sigue esa regla.
        //     <br />
        //     Si tienes alguna consulta, por favor pregunta al experimentador ahora.
        //     <br /><br />
        //     Haz click en el botón "Continuar" para comenzar.
        //     `
        // },
        {
          type: 'html',
          name: 'HTML_01',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Serie de letras (Ejemplo)</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                  A continuación, realizará un test para evaluar su capacidad para analizar patrones.
                  <br>
                  <br>
                  Cada problema en esta prueba tiene cinco sets de letras con cuatro letras en cada set.
                  <br>
                  Cuatro de estos sets de letras son similares de alguna manera.
                  <br>
                  <br>
                  SU TAREA consiste en inferir la regla que hace similares a estos sets.
                  <br>
                  Un set de letras será diferente a los otros y no encajará en esta regla.
                  <br>
                  Haga click en el set de letras que usted crea <strong>NO SIGA </strong>la regla.
                  <br>
                  <br>
                  Las reglas NO estarán basadas en el sonido del set de palabras, la forma de las letras, o si las combinaciones de letras forman palabras o partes de alguna.
                  <br>
                  <br>
                  En la siguiente página se encuentra el primer <u>ejemplo de práctica</u>.
                  <br>
                  Intente encontrar la regla que aplique a cuatro sets de letras, luego haga click en el set de letras que <strong>NO</strong> siga esa regla.
                  <br>
                  <br>
                  <br>
                  Presione el botón "Continuar" para comenzar
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'Q01_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'Q01_Test',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '2',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'NOPQ'
            },
            {
              value: '2',
              text: 'DEFL'
            },
            {
              value: '3',
              text: 'ABCD'
            },
            {
              value: '4',
              text: 'HIJK'
            },
            {
              value: '5',
              text: 'UVWX'
            }
          ]
        }
      ]
    },
    {
      name: 'Q01_Test_result',
      elements: [
        {
          type: 'html',
          name: 'feedback01',
          visibleIf: '{Q01_Test} = "2"',
          html: {
            es: `
            <div class="feedback-response">
              <div>
                <h3>¡Correcto! En este caso la respuesta correcta es DEFL ya que es el único set donde las letras no están ordenadas alfabéticamente.</h3>
                <div id="sq_108" class="quanta_qstn" data-rendered="r" style="width: 100%;"><div></div><div><fieldset class="quanta_qcbc form-inline"><legend aria-label="  *"></legend><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_0" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="NOPQ" value="1"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">NOPQ</span></span></label></div><div class="radio sv-q-col-1 checked"><label class=""><input class="" id="sq_108i_1" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="DEFL" value="2"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">DEFL</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_2" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="ABCD" value="3"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">ABCD</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_3" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="HIJK" value="4"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">HIJK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_4" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="UVWX" value="5"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">UVWX</span></span></label></div></fieldset></div></div>
              </div>
              <br>
              <div></div>
            </div>
            `
          }
        },
        {
          type: 'html',
          name: 'feedback02',
          visibleIf: '{Q01_Test} <> "2"',
          html: {
            es: `
            <div class="feedback-response">
              <div>
                <h3>En este caso la respuesta correcta es DEFL ya que es el único set donde las letras no están ordenadas alfabéticamente.</h3>
                <div id="sq_108" class="quanta_qstn" data-rendered="r" style="width: 100%;"><div></div><div><fieldset class="quanta_qcbc form-inline"><legend aria-label="  *"></legend><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_0" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="NOPQ" value="1"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">NOPQ</span></span></label></div><div class="radio sv-q-col-1 checked"><label class=""><input class="" id="sq_108i_1" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="DEFL" value="2"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">DEFL</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_2" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="ABCD" value="3"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">ABCD</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_3" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="HIJK" value="4"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">HIJK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_108i_4" type="radio" name="Q01_Test_sq_108" aria-required="true" aria-label="UVWX" value="5"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">UVWX</span></span></label></div></fieldset></div></div>
              </div>
              <br>
              <div></div>
            </div>
            `
          }
        }
      ]
    },
    {
      name: 'Q02_Test',
      elements: [
        {
          type: 'radiogroup',
          name: 'Q02_Test',
          title: ' ',
          isRequired: true,
          titleLocation: 'hidden',
          correctAnswer: '4',
          // choicesOrder: 'random',
          choices: [
            {
              value: '1',
              text: 'NLIK'
            },
            {
              value: '2',
              text: 'PLIK'
            },
            {
              value: '3',
              text: 'QLIK'
            },
            {
              value: '4',
              text: 'THIK'
            },
            {
              value: '5',
              text: 'VLIK'
            }
          ]
        }
      ]
    },
    {
      name: 'Q02_Test_result',
      elements: [
        {
          type: 'html',
          name: 'feedback03',
          visibleIf: '{Q02_Test} = "4"',
          html: {
            es: `
            <div class="feedback-response">
              <div>
                <h3>¡Correcto! En este caso el set THIK es el único donde las tres últimas letras no son LIK</h3>
                <div id="sq_111" class="quanta_qstn" data-rendered="r" style="width: 100%;"><div></div><div><fieldset class="quanta_qcbc form-inline"><legend aria-label="  *"></legend><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_0" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="NLIK" value="1"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">NLIK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_1" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="PLIK" value="2"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">PLIK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_2" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="QLIK" value="3"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">QLIK</span></span></label></div><div class="radio sv-q-col-1 checked"><label class=""><input class="" id="sq_111i_3" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="THIK" value="4"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">THIK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_4" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="VLIK" value="5"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">VLIK</span></span></label></div></fieldset></div></div>
              </div>
              <br>
              <div></div>
            </div>
            `
          }
        },
        {
          type: 'html',
          name: 'feedback04',
          visibleIf: '{Q02_Test} <> "4"',
          html: {
            es: `
            <div class="feedback-response">
              <div>
                <h3>En este caso el set THIK es el único donde las tres últimas letras no son LIK</h3>
                <div id="sq_111" class="quanta_qstn" data-rendered="r" style="width: 100%;"><div></div><div><fieldset class="quanta_qcbc form-inline"><legend aria-label="  *"></legend><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_0" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="NLIK" value="1"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">NLIK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_1" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="PLIK" value="2"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">PLIK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_2" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="QLIK" value="3"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">QLIK</span></span></label></div><div class="radio sv-q-col-1 checked"><label class=""><input class="" id="sq_111i_3" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="THIK" value="4"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">THIK</span></span></label></div><div class="radio sv-q-col-1"><label class=""><input class="" id="sq_111i_4" type="radio" name="Q02_Test_sq_111" aria-required="true" aria-label="VLIK" value="5"><span class="circle"></span><span class="check"></span><span class=""><span style="margin-left: 3px; display: inline; position: static;">VLIK</span></span></label></div></fieldset></div></div>
              </div>
              <br>
              <div></div>
            </div>
            `
          }
        }
      ]
    }
  ]
};

export default GFL_EXAMPLE;
