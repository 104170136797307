const rateValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

const attributeOptions = [
  {
    value: '01',
    text: 'Inspirador'
  },
  {
    value: '02',
    text: 'Transmite prioridades claras'
  },
  {
    value: '03',
    text: 'Promueve un alto desempeño'
  },
  {
    value: '04',
    text: 'Apoya nuestro desarrollo profesional'
  },
  {
    value: '05',
    text: 'Está presente y conoce lo que pasa en el aula'
  },
  {
    value: '06',
    text: 'Preocupado por el bienestar de las personas'
  },
  {
    value: '07',
    text: 'Genera y promueve relaciones de confianza'
  },
  {
    value: '08',
    text: 'Reconoce los logros de las personas'
  },
  {
    value: '09',
    text: 'Promueve la participación'
  },
  {
    value: '10',
    text: 'Valiente para tomar decisiones organizacionales'
  },
  {
    value: '11',
    text: 'Conectado con las familias y la comunidad'
  },
  {
    value: '12',
    text: 'Asigna estratégicamente los recursos'
  },
  {
    value: '13',
    text:
      'Ayuda a que concentremos nuestros esfuerzos en lo importante, no nos pide hacer cosas accesorias'
  }
];

const PersonalAttributes = {
  locale: 'es',
  title: 'La Persona del Rector',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'P_ATTRIBUTES_PAGE_01',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                Por favor indique en qué medida los siguientes atributos son característicos del rector <strong>usando una escala de 1 (Para Nada) a 5 (Completamente)</strong>
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'P_ATTRIBUTES_01',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: rateValues,
          rows: [
            {
              value: 'LEADER_01',
              text: 'Inspirador'
            },
            {
              value: 'LEADER_02',
              text: 'Transmite prioridades claras'
            },
            {
              value: 'LEADER_03',
              text: 'Promueve un alto desempeño'
            },
            {
              value: 'LEADER_04',
              text: 'Apoya nuestro desarrollo profesional'
            },
            {
              value: 'LEADER_05',
              text: 'Está presente y conoce lo que pasa en el aula'
            },
            {
              value: 'LEADER_06',
              text: 'Preocupado por el bienestar de las personas'
            },
            {
              value: 'LEADER_07',
              text: 'Genera y promueve relaciones de confianza'
            },
            {
              value: 'LEADER_08',
              text: 'Reconoce los logros de las personas'
            },
            {
              value: 'LEADER_09',
              text: 'Promueve la participación'
            },
            {
              value: 'LEADER_10',
              text: 'Valiente para tomar decisiones organizacionales'
            },
            {
              value: 'LEADER_11',
              text: 'Conectado con las familias y la comunidad'
            },
            {
              value: 'LEADER_12',
              text: 'Asigna estratégicamente los recursos'
            },
            {
              value: 'LEADER_13',
              text:
                'Ayuda a que concentremos nuestros esfuerzos en lo importante, no nos pide hacer cosas accesorias'
            }
          ]
        }
      ]
    },
    {
      name: 'P_ATTRIBUTES_PAGE_02',
      // title: {
      //   es:
      //     'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.'
      // },
      elements: [
        {
          type: 'html',
          name: 'HTML_LEARNING_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                Según su opinion, ¿En cúal de estos atributos <strong>tiene que mejorar más</strong> el rector? MARCAR HASTA 3 ATRIBUTOS.
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'checkbox',
          name: 'LEADER_WORK',
          title: `Atributos:`,
          isRequired: true,
          colCount: 7,
          validators: [
            {
              type: 'expression',
              expression: 'checkboxMaxSelected({LEADER_WORK}, 3) = true',
              text: 'Por favor, selecciona hasta 3 atributos máximo'
            }
          ],
          choices: attributeOptions
        }
        // {
        //   type: 'matrix',
        //   name: 'P_ATTRIBUTES_02',
        //   title: ` `,
        //   // title:
        //   //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
        //   // isRequired: true,
        //   isAllRowRequired: true,
        //   columns: rateValues,
        //   rows: [
        //     {
        //       value: 'LEADER2_01',
        //       text: 'Inspirador'
        //     },
        //     {
        //       value: 'LEADER2_02',
        //       text: 'Transmite prioridades claras'
        //     },
        //     {
        //       value: 'LEADER2_03',
        //       text: 'Promueve un alto desempeño'
        //     },
        //     {
        //       value: 'LEADER2_04',
        //       text: 'Apoya nuestro desarrollo profesional'
        //     },
        //     {
        //       value: 'LEADER2_05',
        //       text: 'Está presente y conoce lo que pasa en el aula'
        //     },
        //     {
        //       value: 'LEADER2_06',
        //       text: 'Preocupado por el bienestar de las personas'
        //     },
        //     {
        //       value: 'LEADER2_07',
        //       text: 'Genera y promueve relaciones de confianza'
        //     },
        //     {
        //       value: 'LEADER2_08',
        //       text: 'Reconoce los logros de las personas'
        //     },
        //     {
        //       value: 'LEADER2_09',
        //       text: 'Promueve la participación'
        //     },
        //     {
        //       value: 'LEADER2_10',
        //       text: 'Valiente para tomar decisiones organizacionales'
        //     },
        //     {
        //       value: 'LEADER2_11',
        //       text: 'Conectado con las familias y la comunidad'
        //     },
        //     {
        //       value: 'LEADER2_12',
        //       text: 'Asigna estratégicamente los recursos'
        //     },
        //     {
        //       value: 'LEADER2_13',
        //       text:
        //         'Ayuda a que concentremos nuestros esfuerzos en lo importante, no nos pide hacer cosas accesorias'
        //     }
        //   ]
        // }
      ]
    }
  ]
};

export default PersonalAttributes;
