const baseInfo = {
  locale: 'es',
  title: 'Growth Mindset',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'GROWTH_PAGE_01',
      // title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                Las siguientes preguntas son para indagar sobre lo que piensan las personas sobre la inteligencia.
                <br />
                No hay respuestas correctas o incorrectas. Sólo nos interesa conocer tu visión.
                <br /><br />
                Usando la escala de más abajo, por favor indica en qué medida estás de acuerdo o en desacuerdo con las siguientes afirmaciones:
                <br />
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'GROWTH_QUESTIONS',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Totalmente en desacuerdo'
            },
            {
              value: '2',
              text: 'En desacuerdo'
            },
            {
              value: '3',
              text: 'Algo en desacuerdo'
            },
            {
              value: '4',
              text: 'Algo de acuerdo'
            },
            {
              value: '5',
              text: 'De acuerdo'
            },
            {
              value: '6',
              text: 'Totalmente de acuerdo'
            }
          ],
          rows: [
            {
              value: 'GMQ01',
              text:
                'Uno tiene un cierto nivel de inteligencia y no hay mucho que hacer para aumentarla'
            },
            {
              value: 'GMQ02',
              text:
                'La inteligencia es una característica individual que no se puede mejorar mucho'
            },
            {
              value: 'GMQ03',
              text:
                'No importa quién seas, todos pueden aumentar significativamente su nivel de inteligencia'
            },
            {
              value: 'GMQ05',
              text: 'Siempre se puede hacer algo para aumentar la inteligencia'
            },
            {
              value: 'GMQ06',
              text:
                'Uno puede aprender nuevas cosas pero en realidad no puede cambiar su inteligencia de base'
            },
            {
              value: 'GMQ07',
              text:
                'No importa cuán inteligente sea una persona, siempre puedes mejorar considerablemente su inteligencia'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
