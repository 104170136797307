const WorkingInfo = {
  locale: 'es',
  title: 'Información laboral',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'WorkingInfo_Page_01',
      elements: [
        {
          type: 'radiogroup',
          name: 'TipoTrabajo',
          title: 'Usted trabaja principalmente como:',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Empleado de empresa privada '
            },
            {
              value: '2',
              text: 'Empleado en una ONG o fundación sin fines de lucro '
            },
            {
              value: '3',
              text:
                'Empleado en el sector público (ej. ministerios, municipalidades, consultorios, hospitales, etc.) '
            },
            {
              value: '4',
              text:
                'Empleado en empresa pública  (ej. CODELCO, Banco Estado, EFE, ENAP, etc.) '
            },
            {
              value: '5',
              text: 'Empleado por cuenta propia o independiente '
            },
            {
              value: '6',
              text: 'Patrón o empleador '
            }
          ]
        }
      ]
    },
    {
      name: 'WorkingInfo_Page_02',
      elements: [
        {
          type: 'multipletext',
          name: 'Time_Emp',
          title: '¿Cuántos tiempo lleva trabajando en su empresa?',
          description:
            'Ejemplo: Si lleva 1 año y 8 meses trabajando, en años ponga "1" y en meses "8"',
          items: [
            {
              name: 'AnosEmp',
              title: 'Años (escriba 0 si ha trabajado menos de un año)',
              isRequired: true,
              inputType: 'number',
              validators: [
                {
                  type: 'numeric',
                  minValue: 0,
                  maxValue: 65
                }
              ]
            },
            {
              name: 'MesesEmp',
              title: 'Meses (escriba 0 si ha trabajado menos de un mes)',
              isRequired: true,
              inputType: 'number',
              validators: [
                {
                  type: 'numeric',
                  minValue: 0,
                  maxValue: 12
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'WorkingInfo_Page_03',
      elements: [
        {
          type: 'dropdown',
          name: 'Tamano_Establecimiento_Actual',
          title:
            '¿Cuántas personas trabajan en el establecimiento, sucursal, o local en que usted se desempeña?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: '2 a 4 personas'
            },
            {
              value: '2',
              text: '5 a 9 personas'
            },
            {
              value: '3',
              text: '10 a 19 personas'
            },
            {
              value: '4',
              text: '20 a 49 personas'
            },
            {
              value: '5',
              text: '50 a 99 personas'
            },
            {
              value: '6',
              text: '100 a 199 personas'
            },
            {
              value: '7',
              text: '200 a 499 personas'
            },
            {
              value: '8',
              text: '500 o más personas'
            },
            {
              value: '0',
              text: 'No lo sé'
            }
          ]
        },
        {
          type: 'dropdown',
          name: 'Tamano_Organizacion_Actual',
          title:
            '¿Cuántas personas trabajan en total en la organización o institución donde usted se desempeña? (Tamaño de la Organización)',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: '2 a 4 personas'
            },
            {
              value: '2',
              text: '5 a 9 personas'
            },
            {
              value: '3',
              text: '10 a 19 personas'
            },
            {
              value: '4',
              text: '20 a 49 personas'
            },
            {
              value: '5',
              text: '50 a 99 personas'
            },
            {
              value: '6',
              text: '100 a 199 personas'
            },
            {
              value: '7',
              text: '200 a 499 personas'
            },
            {
              value: '8',
              text: '500 a 999 personas'
            },
            {
              value: '9',
              text: '1000 a 1999 personas'
            },
            {
              value: '10',
              text: '2000 a 4999 personas'
            },
            {
              value: '11',
              text: '5000 o más personas'
            },
            {
              value: '0',
              text: 'No lo sé'
            }
          ]
        }
      ]
    },
    {
      name: 'WorkingInfo_Page_04',
      elements: [
        // {
        //   type: 'dropdown',
        //   name: 'Nombre_Cargo_Actual',
        //   title: '¿Cuál es el nombre de su cargo actual?  ',
        //   isRequired: true,
        //   choices: [
        //     {
        //       value: '1',
        //       text: 'Assistant'
        //     },
        //     {
        //       value: '2',
        //       text: 'Analyst'
        //     },
        //     {
        //       value: '3',
        //       text: 'Associate Director'
        //     },
        //     {
        //       value: '4',
        //       text: 'Director'
        //     },
        //     {
        //       value: '5',
        //       text: 'Executive Director'
        //     },
        //     {
        //       value: '6',
        //       text: 'Associate Partner'
        //     },
        //     {
        //       value: '0',
        //       text: 'No lo sé o No aplica'
        //     }
        //   ]
        // },
        {
          type: 'text',
          name: 'Nombre_Cargo_Actual',
          title: '¿Cuál es el nombre de su cargo actual?  ',
          description:
            'Sea lo más específico posible, por ejemplo:\n a.- Jefe Finanzas\n b.- Junior Product Manager\n c.-Gerente de Investigación y Desarrollo  \n',
          isRequired: true
        },
        // {
        //   type: 'text',
        //   name: 'Descripcion_Cargo_Actual',
        //   title: '¿Qué hace usted en su cargo actual?  ',
        //   description:
        //     'Sea lo más específico posible, por ejemplo: \n a.- Lidero grupo de tesorería y cobranzas reportando al Gerente de Finanzas   \nb.- Gerenciamiento de una línea de productos con reporte a un Senior Product Manager \n c.- Lidero el equipo en innovación de productos de la empresa y asesoro al Gerente General ',
        //   isRequired: true
        // },
        {
          type: 'comment',
          name: 'Descripcion_Cargo_Actual',
          title: '¿Qué hace usted en su cargo actual?  ',
          description:
            'Sea lo más específico posible, por ejemplo: \n a.- Lidero grupo de tesorería y cobranzas reportando al Gerente de Finanzas   \nb.- Gerenciamiento de una línea de productos con reporte a un Senior Product Manager \n c.- Lidero el equipo en innovación de productos de la empresa y asesoro al Gerente General ',
          isRequired: true
        },
        {
          type: 'multipletext',
          name: 'Time_Cargo_Actual',
          title: '¿Cuánto tiempo lleva trabajando en su cargo actual?',
          description:
            'Ejemplo: Si lleva 1 año y 8 meses trabajando, en años ponga "1" y en meses "8"',
          items: [
            {
              name: 'AnosCargoActual',
              title: 'Años (escriba 0 si ha trabajado menos de un año)',
              isRequired: true,
              inputType: 'number',
              validators: [
                {
                  type: 'numeric',
                  minValue: 0,
                  maxValue: 65
                }
              ]
            },
            {
              name: 'MesesCargoActual',
              title: 'Meses (escriba 0 si ha trabajado menos de un mes)',
              isRequired: true,
              inputType: 'number',
              validators: [
                {
                  type: 'numeric',
                  minValue: 0,
                  maxValue: 12
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'WorkingInfo_Page_05',
      elements: [
        {
          type: 'radiogroup',
          name: 'Rol_Actual_Empresa',
          title:
            '¿Cuál de los siguientes describe mejor su rol actual en la empresa?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Administrativo sin rol de jefatura '
            },
            {
              value: '2',
              text: 'Administrativo con rol de jefatura '
            },
            {
              value: '3',
              text: 'Técnico sin rol de jefatura '
            },
            {
              value: '4',
              text: 'Técnico con rol de jefatura '
            },
            {
              value: '5',
              text: 'Profesional sin rol de jefatura '
            },
            {
              value: '6',
              text: 'Profesional con rol de jefatura '
            },
            {
              value: '7',
              text: 'Gerente/Directivo  '
            }
          ]
        }
      ]
    },
    {
      name: 'WorkingInfo_Page_06',
      elements: [
        {
          type: 'text',
          name: 'NivJerarTot',
          title:
            '¿Cuántos niveles funcionales diría usted que existen en su compañía? ',
          description:
            'Por ejemplo, si el nivel de entrada de su trabajo es "1" y el máximo puesto al que puede aspirar es un "5", su compañía tendría 5 niveles funcionales (favor ingrese un número). ',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 100
            }
          ]
        },
        {
          type: 'text',
          name: 'NJerarRelat',
          title:
            'En relación al número máximo de niveles funcionales de su compañía, ¿Cuál es su nivel actual? (favor ingrese un número)',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 100
            }
          ]
        },
        {
          type: 'text',
          name: 'NJerarEnt',
          title:
            'En relación al número máximo de niveles funcionales de su compañía, ¿En qué nivel partió trabajando en su empresa?  (favor ingrese un número)',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 100
            }
          ]
        }
      ]
    }
  ]
};

export default WorkingInfo;
