const rateValues = [
  {
    value: '1',
    text: 'Muy en desacuerdo'
  },
  {
    value: '2',
    text: 'Ligeramente en desacuerdo'
  },
  {
    value: '3',
    text: 'Ni de acuerdo ni en desacuerdo'
  },
  {
    value: '4',
    text: 'Ligeramente de acuerdo'
  },
  {
    value: '5',
    text: 'Muy de acuerdo'
  }
];

const surveyBig5 = {
  locale: 'es',
  title: 'Características de personalidad',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'BIG5_01',
      title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `
          <div style="margin-bottom: 30px;">
            <span style="font-size:16px;">
              A continuación se presentan una serie de características que pueden o no aplicar a usted.
              <br />
              Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.
            </span>
          </div>
        `
        },
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_01',
          title: 'Es sociable, le gusta conocer a otras personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_02',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_02',
          title: 'Es compasivo(a), bondadoso',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_03',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_03',
          title: 'Tiende a ser desorganizado(a)',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_04',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_04',
          title: 'Es relajado(a), maneja bien el estrés',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'BIG5_05',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_05',
          title: 'Tiene pocos intereses artísticos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_06',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_06',
          title: 'Tiene una personalidad dominante',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_07',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_07',
          title: 'Es considerado(a), trata a las demás personas con respeto',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_08',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_08',
          title: 'Con frecuencia siente flojera',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_09',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_09',
          title: 'Se mantiene optimista después de tener un contratiempo',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'BIG5_10',
      title: ' ',
      elements: [
        {
          type: 'html',
          name: 'HTML_BIG5_TITLE',
          html: `<div><h4>Yo soy una persona que:</h4></div>`
        },
        {
          type: 'rating',
          name: 'BIG5_10',
          title: 'Siente curiosidad por diferentes temas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    }
  ]
};

export default surveyBig5;
