import React from 'react';
import Demographics from '../../Components/Demographics';
import { StageConsumer } from '../../Context';

class DemographicsPage extends React.Component {
  render() {
    return (
      <StageConsumer>
        {({ state }) => <Demographics {...this.props} {...state} />}
      </StageConsumer>
    );
  }
}

export default DemographicsPage;
