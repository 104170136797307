const baseInfo = {
  locale: 'es',
  title: 'Coordinación Hora',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          visibleIf: '{CONSENT} = "1"',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h3>Coordinación Hora</h3>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                <br>
                A continuación le agradeceremos indicar qué día y en qué horarios lo podemos llamar para realizar la entrevista telefónica. <strong>Le recordamos que la entrevista dura entre 15 y 20 minutos aproximadamente</strong>
                </span>
              </span>
              <br>
              <span style="font-size:16px;">
              <span style="font-family:arial,helvetica,sans-serif;">
              <br>
              Los tiempos seleccionados serán utilizados como referencia por nuestro equipo.
              </span>
            </span>
            </div>
          </legend>
          `
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          visibleIf: '{CONSENT} = "1"',
          html: `
          <div class="QuestionText BorderColor">
            <div class="alert center alert-warning">
              Las encuestas telefónicas se reanudarán durante la primera semana de Marzo, 2020.
            </div>
            <span style="font-size:16px;">
              <span style="font-family:arial,helvetica,sans-serif;">
                A continuación le pedimos que escriba o indique algún o algunos días u horas en los cuales podríamos contactarlo.
                <br>
                Puede escribir algún mensaje, o puede seleccionar algún día y horas específicas.
              </span>
              <span style="font-size:16px;">
              <br>
              <br>
              <span style="font-family:arial,helvetica,sans-serif;">
                <strong>Importante: Las encuestas telefónicas se retomarán durante la primera semana de Marzo</strong>
              </span>
            </span>
          </div>
          `
        },
        {
          type: 'text',
          name: 'DATE_TEXT',
          title:
            'Por favor, escriba los días y horarios en los cuales podríamos contactarlo:',
          inputType: 'text',
          isRequired: false,
          visibleIf: '{CONSENT} = "1"'
        },
        {
          type: 'text',
          name: 'DATE',
          title:
            'O bien, si lo prefiere, seleccione alguna fecha *específica* en la que prefiere que lo contactemos:',
          inputType: 'date',
          isRequired: false,
          visibleIf: '{CONSENT} = "1"'
        },
        {
          type: 'dropdown',
          visibleIf: '{DATE} notempty',
          name: 'TIME_1',
          title: 'Por favor indique alguna hora para llamarlo',
          isRequired: true,
          hasOther: true,
          choices: [
            {
              value: '1',
              text: '10:00-11:30'
            },
            {
              value: '2',
              text: '11:30-13:00'
            },
            {
              value: '3',
              text: '13:00-14:30'
            },
            {
              value: '4',
              text: '14:30-16:00'
            },
            {
              value: '5',
              text: '16:00-17:30'
            },
            {
              value: '6',
              text: '17:30-19:00'
            },
            {
              value: '7',
              text: '19:00-21:00'
            }
          ],
          otherText: 'Otro (especifique)',
          optionsCaption: 'Opciones...'
        },
        {
          type: 'dropdown',
          visibleIf: '{TIME_1} notempty',
          name: 'TIME_2',
          title: 'Por favor, indique algún horario (adicional)',
          isRequired: true,
          hasOther: true,
          choices: [
            {
              value: '1',
              text: '10:00-11:30'
            },
            {
              value: '2',
              text: '11:30-13:00'
            },
            {
              value: '3',
              text: '13:00-14:30'
            },
            {
              value: '4',
              text: '14:30-16:00'
            },
            {
              value: '5',
              text: '16:00-17:30'
            },
            {
              value: '6',
              text: '17:30-19:00'
            },
            {
              value: '7',
              text: '19:00-21:00'
            }
          ],
          otherText: 'Otro (especifique)',
          optionsCaption: 'Opciones...'
        },
        {
          type: 'html',
          name: 'HTML_01',
          visibleIf: '{CONSENT} = "1"',
          html: `
          <legend>
            <div class="QuestionText BorderColor">
              <div>
              <br>
                <span style="font-size:16px;">
                  <span style="font-family:arial,helvetica,sans-serif;">
                    <h4>Información Importante</h4>
                  </span>
                </span>
              </div>
              <br>
              <span style="font-size:16px;">
                <span style="font-family:arial,helvetica,sans-serif;">
                Su número de telefono es importante para poder comunicarnos con usted.
                <br>
                <br>
                Para la encuesta los contactará <em>Amal Dababneh</em> desde el siguiente número de teléfono:
                <br>
                </span>
                <ul style="font-size:16px;">
                  <li>+56 9 4436 7167</li>
                </ul>
                <span style="font-family:arial,helvetica,sans-serif;">
                  Le recomendamos guardar este número para que sepa que somos nosotros quienes lo estamos llamando el día de la entrevista.
                  <br>
                </span>
                <span style="font-family:arial,helvetica,sans-serif;">
                  <br>
                  <strong>Importante: Le recordamos que las encuestas telefónicas se retomarán durante la primera semana de Marzo</strong>
                </span>
              </span>
            </div>
          </legend>
          `
        }
      ]
    }
  ]
};

export default baseInfo;
