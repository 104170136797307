const CareerSuccess = {
  locale: 'es',
  title: 'Desarrollo de Carrera',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'CAREER_SUCCESS_01',
      title: {
        es: 'En relación con su desarrollo profesional'
      },
      elements: [
        {
          type: 'matrix',
          name: 'CAREER_SUCCESS_01',
          title:
            'Indique su grado de acuerdo/desacuerdo con las siguientes frases',
          isRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'En desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'De acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            },
            {
              value: '0',
              text: 'No aplica'
            }
          ],
          rows: [
            {
              value: 'CSAT_01',
              text: 'Estoy satisfecho con el éxito que he alcanzado en general'
            },
            {
              value: 'CSAT_02',
              text:
                'Estoy satisfecho con lo que he logrado en relación a mis metas laborales'
            },
            {
              value: 'CSAT_03',
              text:
                'Estoy satisfecho con lo que he logrado en relación a mis metas económicas'
            },
            {
              value: 'CSAT_04',
              text:
                'Estoy satisfecho con lo que he logrado en relación al avance de mi carrera'
            },
            {
              value: 'CSAT_05',
              text:
                'Estoy satisfecho con lo que he logrado en relación al desarrollo de nuevas competencias laborales'
            }
          ]
        }
      ]
    }
  ]
};

export default CareerSuccess;
