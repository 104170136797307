import { normal } from 'jstat';

let dataInit = [
  {
    caseid: 'd81ec9a9-4662-4641-9e3d-14d942905b88',
    createdat: 'Thu 17 Dec 2020 15:11:55 GMT',
    updatedat: 'Thu 17 Dec 2020 15:37:23 GMT',
    finished: '1',
    school: 'The International School La Serena',
    grade: '',
    name: 'Margori',
    lastname: 'Goldberg',
    email: '',
    RUT: '1.111.111-1',
    BIRTH: '07-19-19',
    SEX: '2',
    CHAT_NAME: 'margori',
    TUTORIAL: '1',
    C1_1_CN: '1',
    C1_1_FM: '0',
    C1_1_CD: '-1',
    C1_1_AP: '0',
    C1_2_CN: '1',
    C1_2_FM: '0',
    C1_2_CD: '-1',
    C1_2_AP: '0',
    C1_3_CN: '1',
    C1_3_FM: '0',
    C1_3_CD: '-1',
    C1_3_AP: '0',
    C1_4_CN: '0',
    C1_4_FM: '1',
    C1_4_CD: '0',
    C1_4_AP: '-1',
    C2_1_AL: '1',
    C2_1_CN: '0',
    C2_1_FM: '0',
    C2_1_CD: '-1',
    C2_2_AL: '1',
    C2_2_CN: '0',
    C2_2_FM: '0',
    C2_2_CD: '-1',
    C2_3_AL: '1',
    C2_3_CN: '-1',
    C2_3_FM: '0',
    C2_3_CD: '0',
    C2_4_AL: '1',
    C2_4_CN: '0',
    C2_4_FM: '0',
    C2_4_CD: '-1',
    C3_1_SE: '1',
    C3_1_AL: '0',
    C3_1_CN: '0',
    C3_1_FM: '-1',
    C3_2_SE: '1',
    C3_2_AL: '0',
    C3_2_CN: '0',
    C3_2_FM: '-1',
    C3_3_SE: '1',
    C3_3_AL: '0',
    C3_3_CN: '0',
    C3_3_FM: '-1',
    C3_4_SE: '0',
    C3_4_AL: '1',
    C3_4_CN: '0',
    C3_4_FM: '-1',
    C4_1_IP: '0',
    C4_1_SE: '1',
    C4_1_AL: '0',
    C4_1_CN: '-1',
    C4_2_IP: '0',
    C4_2_SE: '1',
    C4_2_AL: '0',
    C4_2_CN: '-1',
    C4_3_IP: '1',
    C4_3_SE: '0',
    C4_3_AL: '0',
    C4_3_CN: '-1',
    C4_4_IP: '-1',
    C4_4_SE: '0',
    C4_4_AL: '1',
    C4_4_CN: '0',
    C5_1_LM: '-1',
    C5_1_IP: '0',
    C5_1_SE: '0',
    C5_1_AL: '1',
    C5_2_LM: '-1',
    C5_2_IP: '0',
    C5_2_SE: '1',
    C5_2_AL: '0',
    C5_3_LM: '-1',
    C5_3_IP: '0',
    C5_3_SE: '1',
    C5_3_AL: '0',
    C5_4_LM: '-1',
    C5_4_IP: '0',
    C5_4_SE: '0',
    C5_4_AL: '1',
    C6_1_AP: '-1',
    C6_1_LM: '0',
    C6_1_IP: '0',
    C6_1_SE: '1',
    C6_2_AP: '-1',
    C6_2_LM: '0',
    C6_2_IP: '0',
    C6_2_SE: '1',
    C6_3_AP: '-1',
    C6_3_LM: '0',
    C6_3_IP: '1',
    C6_3_SE: '0',
    C6_4_AP: '-1',
    C6_4_LM: '0',
    C6_4_IP: '0',
    C6_4_SE: '1',
    C7_1_CD: '-1',
    C7_1_AP: '0',
    C7_1_LM: '1',
    C7_1_IP: '0',
    C7_2_CD: '-1',
    C7_2_AP: '0',
    C7_2_LM: '0',
    C7_2_IP: '1',
    C7_3_CD: '-1',
    C7_3_AP: '0',
    C7_3_LM: '0',
    C7_3_IP: '1',
    C7_4_CD: '-1',
    C7_4_AP: '0',
    C7_4_LM: '0',
    C7_4_IP: '1',
    C8_1_FM: '0',
    C8_1_CD: '0',
    C8_1_AP: '-1',
    C8_1_LM: '1',
    C8_2_FM: '1',
    C8_2_CD: '0',
    C8_2_AP: '0',
    C8_2_LM: '-1',
    C8_3_FM: '1',
    C8_3_CD: '0',
    C8_3_AP: '-1',
    C8_3_LM: '0',
    C8_4_FM: '1',
    C8_4_CD: '-1',
    C8_4_AP: '0',
    C8_4_LM: '0',
    AL_SUM: '8',
    AP_SUM: '-7',
    CD_SUM: '-11',
    CN_SUM: '2',
    FM_SUM: '-3',
    IP_SUM: '4',
    LM_SUM: '-3',
    SE_SUM: '10',
    TOP_DIM_1: 'SE',
    TOP_DIM_2: 'AL',
    AC1_1_LM_AG: '',
    AC1_1_LM_CS: '',
    AC1_1_LM_T: '',
    AC1_1_LM_AC: '',
    AC1_2_LM_AG: '',
    AC1_2_LM_CS: '',
    AC1_2_LM_T: '',
    AC1_2_LM_AC: '',
    AC1_3_LM_AG: '',
    AC1_3_LM_CS: '',
    AC1_3_LM_T: '',
    AC1_3_LM_AC: '',
    AC1_4_LM_AG: '',
    AC1_4_LM_CS: '',
    AC1_4_LM_T: '',
    AC1_4_LM_AC: '',
    AC2_1_IP_CS: '',
    AC2_1_IP_D: '',
    AC2_1_IP_E: '',
    AC2_1_IP_AC: '',
    AC2_2_IP_CS: '',
    AC2_2_IP_D: '',
    AC2_2_IP_E: '',
    AC2_2_IP_AC: '',
    AC2_3_IP_CS: '',
    AC2_3_IP_D: '',
    AC2_3_IP_E: '',
    AC2_3_IP_AC: '',
    AC2_4_IP_CS: '',
    AC2_4_IP_D: '',
    AC2_4_IP_E: '',
    AC2_4_IP_AC: '',
    AC3_1_SE_CS: '1',
    AC3_1_SE_D: '0',
    AC3_1_SE_E: '0',
    AC3_1_SE_S: '-1',
    AC3_2_SE_CS: '1',
    AC3_2_SE_D: '0',
    AC3_2_SE_E: '0',
    AC3_2_SE_S: '-1',
    AC3_3_SE_CS: '1',
    AC3_3_SE_D: '0',
    AC3_3_SE_E: '-1',
    AC3_3_SE_S: '0',
    AC3_4_SE_CS: '0',
    AC3_4_SE_D: '-1',
    AC3_4_SE_E: '1',
    AC3_4_SE_S: '0',
    AC4_1_AL_AR: '0',
    AC4_1_AL_H: '1',
    AC4_1_AL_E: '0',
    AC4_1_AL_T: '-1',
    AC4_2_AL_AR: '1',
    AC4_2_AL_H: '-1',
    AC4_2_AL_E: '0',
    AC4_2_AL_T: '0',
    AC4_3_AL_AR: '1',
    AC4_3_AL_H: '-1',
    AC4_3_AL_E: '0',
    AC4_3_AL_T: '0',
    AC4_4_AL_AR: '1',
    AC4_4_AL_H: '0',
    AC4_4_AL_E: '0',
    AC4_4_AL_T: '-1',
    AC5_1_CN_AG: '',
    AC5_1_CN_CN: '',
    AC5_1_CN_T: '',
    AC5_1_CN_S: '',
    AC5_2_CN_AG: '',
    AC5_2_CN_CN: '',
    AC5_2_CN_T: '',
    AC5_2_CN_S: '',
    AC5_3_CN_AG: '',
    AC5_3_CN_CN: '',
    AC5_3_CN_T: '',
    AC5_3_CN_S: '',
    AC5_4_CN_AG: '',
    AC5_4_CN_CN: '',
    AC5_4_CN_T: '',
    AC5_4_CN_S: '',
    AC6_1_FM_AG: '',
    AC6_1_FM_CN: '',
    AC6_1_FM_T: '',
    AC6_1_FM_S: '',
    AC6_2_FM_AG: '',
    AC6_2_FM_CN: '',
    AC6_2_FM_T: '',
    AC6_2_FM_S: '',
    AC6_3_FM_AG: '',
    AC6_3_FM_CN: '',
    AC6_3_FM_T: '',
    AC6_3_FM_S: '',
    AC6_4_FM_AG: '',
    AC6_4_FM_CN: '',
    AC6_4_FM_T: '',
    AC6_4_FM_S: '',
    AC7_1_CD_CN: '',
    AC7_1_CD_CS: '',
    AC7_1_CD_T: '',
    AC7_1_CD_AC: '',
    AC7_2_CD_CN: '',
    AC7_2_CD_CS: '',
    AC7_2_CD_T: '',
    AC7_2_CD_AC: '',
    AC7_3_CD_CN: '',
    AC7_3_CD_CS: '',
    AC7_3_CD_T: '',
    AC7_3_CD_AC: '',
    AC7_4_CD_CN: '',
    AC7_4_CD_CS: '',
    AC7_4_CD_T: '',
    AC7_4_CD_AC: '',
    AC8_1_AP_AG: '',
    AC8_1_AP_CS: '',
    AC8_1_AP_T: '',
    AC8_1_AP_AC: '',
    AC8_2_AP_AG: '',
    AC8_2_AP_CS: '',
    AC8_2_AP_T: '',
    AC8_2_AP_AC: '',
    AC8_3_AP_AG: '',
    AC8_3_AP_CS: '',
    AC8_3_AP_T: '',
    AC8_3_AP_AC: '',
    AC8_4_AP_AG: '',
    AC8_4_AP_CS: '',
    AC8_4_AP_T: '',
    AC8_4_AP_AC: '',
    AC_FIRST_SUM: '',
    AG_FIRST_SUM: '',
    AR_FIRST_SUM: '',
    CN_FIRST_SUM: '',
    CS_FIRST_SUM: '3',
    D_FIRST_SUM: '-1',
    E_FIRST_SUM: '0',
    H_FIRST_SUM: '',
    S_FIRST_SUM: '-2',
    T_FIRST_SUM: '',
    TOP_FIRST_AREA_1: 'CS',
    TOP_FIRST_AREA_2: 'E',
    AC_SECOND_SUM: '',
    AG_SECOND_SUM: '',
    AR_SECOND_SUM: '3',
    CN_SECOND_SUM: '',
    CS_SECOND_SUM: '',
    D_SECOND_SUM: '',
    E_SECOND_SUM: '0',
    H_SECOND_SUM: '-1',
    S_SECOND_SUM: '',
    T_SECOND_SUM: '-2',
    TOP_SECOND_AREA_1: 'AR',
    TOP_SECOND_AREA_2: 'E',
    SCCI_TUTORIAL: '1',
    SCCI_01: '9',
    SCCI_02: '9',
    SCCI_03: '8',
    SCCI_04: '7',
    SCCI_05: '8',
    SCCI_06: '8',
    SCCI_07: '5',
    SCCI_08: '7',
    SCCI_09: '5',
    SCCI_10: '9',
    SCCI_11: '7',
    SCCI_12: '7',
    SCCI_13: '7',
    SCCI_14: '5',
    SCCI_15: '5',
    SCCI_16: '9',
    SCCI_17: '4',
    SCCI_18: '3',
    SCCI_19: '7',
    SCCI_20: '6',
    SCCI_21: '5',
    SCCI_22: '6',
    SCCI_23: '5',
    SCCI_24: '6',
    SCCI_25: '9',
    SCCI_26: '7',
    SCCI_27: '8',
    SCCI_28: '7',
    SCCI_29: '4',
    SCCI_30: '5',
    SCCI_31: '3',
    SCCI_32: '8',
    SCCI_33: '7',
    SCCI_34: '4',
    SCCI_35: '5',
    SCCI_36: '5',
    SCCI_37: '7',
    SCCI_38: '7',
    SCCI_39: '6',
    SCCI_40: '8',
    SCCI_41: '7',
    SCCI_42: '7',
    SCCI_43: '7',
    SCCI_44: '4',
    SCCI_45: '5',
    DCB_01: '9',
    DCB_02: '2',
    DCB_03: '5',
    DCB_04: '3',
    DCB_05: '8',
    DCB_06_M: '',
    DCB_06_F: '2',
    DCB_07: '2',
    DCB_08: '2',
    DCB_09: '2',
    DCB_10: '1',
    DCB_11_M: '',
    DCB_11_F: '2',
    DCB_12: '1',
    DCB_13: '4',
    DCB_14: '1',
    DCB_15: '1',
    DCB_16_M: '',
    DCB_16_F: '1',
    DI_01: '6',
    DI_02: '6',
    DI_03: '6',
    DI_04: '5',
    DI_05: '7',
    DI_06: '5',
    DI_07: '5',
    DI_08: '4',
    DI_09: '8',
    DI_10: '5',
    DI_11: '2',
    DI_12: '7',
    IG_01: '1',
    IG_02: '3',
    IG_03: '4',
    IG_04: '4',
    IG_05: '4',
    IG_06: '2',
    IG_07: '6',
    IG_08: '2',
    IG_09: '2',
    IG_10: '6',
    IG_11: '4',
    IG_12: '3',
    IG_13: '4',
    IG_14: '4',
    IG_15: '4',
    IG_16: '2',
    IG_17: '2',
    IG_18: '7',
    IG_19: '7',
    IG_20: '6',
    IG_21: '7',
    IG_22: '6'
  }
];

const dataResult = [
  {
    school: 'The International School La Serena',
    grade: '',
    name: 'Margori',
    lastname: 'Goldberg',
    email: '',
    RUT: '1.111.111-1',
    BIRTH: '2019/07/19 00:00:00',
    SEX: 'Femenino',
    age: '1',
    updatedat: 'Thu 17 Dec 2020 15:37:23 GMT',
    TOP_DIM_1: 'SE',
    TOP_DIM_2: 'AL',
    CONS_AL: '46.875',
    CONS_SE: '65.625',
    CONS_IP: '21.875',
    CONS_LM: '0',
    CONS_AP: '0',
    CONS_CD: '0',
    CONS_FM: '0',
    CONS_CN: '15.625',
    perc_AL_SUM: '75',
    perc_AP_SUM: '28.125',
    perc_CD_SUM: '15.625',
    perc_CN_SUM: '56.25',
    perc_FM_SUM: '40.625',
    perc_IP_SUM: '62.5',
    perc_LM_SUM: '40.625',
    perc_SE_SUM: '81.25',
    perc_AC_FIRST_SUM: '',
    perc_AG_FIRST_SUM: '',
    perc_AR_FIRST_SUM: '',
    perc_CN_FIRST_SUM: '',
    perc_CS_FIRST_SUM: '87.5',
    perc_D_FIRST_SUM: '37.5',
    perc_E_FIRST_SUM: '50',
    perc_H_FIRST_SUM: '',
    perc_S_FIRST_SUM: '25',
    perc_T_FIRST_SUM: '',
    perc_AC_SECOND_SUM: '',
    perc_AG_SECOND_SUM: '',
    perc_AR_SECOND_SUM: '87.5',
    perc_CN_SECOND_SUM: '',
    perc_CS_SECOND_SUM: '',
    perc_D_SECOND_SUM: '',
    perc_E_SECOND_SUM: '50',
    perc_H_SECOND_SUM: '37.5',
    perc_S_SECOND_SUM: '',
    perc_T_SECOND_SUM: '25',
    'Afrontamiento Productivo': '49',
    'Búsqueda de Apoyo': '81',
    'Afrontamiento No-Producivo': '85',
    'Rol de la casualidad': '4',
    'Implicancias de la Decisión': '43',
    'Rol de otros significativos': '14',
    'Rol de la ayuda profesional': '64',
    Género: '2',
    'Información sobre el proceso': '81',
    'Información sobre el Self': '92',
    'Información sobre Ocupaciones': '72',
    'Conflictos internos': '88',
    'Conflictos externos': '90',
    'Indecisión General': '29'
  }
];

let selectFieldsFromObject = (fields, object) => {
  return fields.reduce((acc, curr) => {
    return { ...acc, [curr]: object[curr] };
  }, {});
};

let arrayFromObject = object => {
  const fields = Object.keys(object);
  return fields.map(field => ({ [field]: object[field] }));
};

let getSumFomValues = (values = []) => {
  const sum = values.reduce((acc, curr) => acc + parseFloat(curr), 0);
  return sum;
};

let getMeanFromValues = (values = []) => {
  const sum = getSumFomValues(values);
  const mean = sum / values.length;
  return mean;
};

let getMeanFromFields = (fields, object) => {
  const values = fields.map(field => {
    return parseInt(object[field]);
  });
  const mean = getMeanFromValues(values);
  return mean;
};

let calculateAgeFromBirthday = birthDate => {
  // date comes as "2001-01-02" (year-month-day) from date input
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
  // https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
};

let greaterThanZero = val => {
  return val >= 0 ? val : 0;
};

const getResults = data => {
  //   # Reverse specified items from Indecision General scale
  // IG_reverse <-c("IG_01","IG_04","IG_06","IG_07","IG_14",
  //                "IG_18","IG_19","IG_20","IG_21","IG_22")
  // d1[ ,IG_reverse] = 8 - d1[ ,IG_reverse]
  // const IG_reverse = {
  //   IG_01_R: 8 - data.IG_01,
  //   IG_04_R: 8 - data.IG_04,
  //   IG_06_R: 8 - data.IG_06,
  //   IG_07_R: 8 - data.IG_07,
  //   IG_14_R: 8 - data.IG_14,
  //   IG_18_R: 8 - data.IG_18,
  //   IG_19_R: 8 - data.IG_19,
  //   IG_20_R: 8 - data.IG_20,
  //   IG_21_R: 8 - data.IG_21,
  //   IG_22_R: 8 - data.IG_22
  // };
  // const workingData = { ...data, ...IG_reverse };
  const workingData = { ...data };
  // const baseInfo = selectFieldsFromObject(
  //   [
  //     'school',
  //     'grade',
  //     'name',
  //     'lastname',
  //     'email',
  //     'RUT',
  //     'BIRTH',
  //     'SEX',
  //     'updatedat',
  //     'TOP_DIM_1',
  //     'TOP_DIM_2'
  //   ],
  //   workingData
  // );
  // const baseInfo = selectFieldsFromObject(
  //   [
  //     'updatedat',
  //     'TOP_DIM_1',
  //     'TOP_DIM_2',
  //     'TOP_DIM_3',
  //     'TOP_DIM_4',
  //   ],
  //   workingData
  // );
  // const age = calculateAgeFromBirthday(baseInfo.BIRTH); // "07-19-19"
  // ALL _ SE perc
  // const EquisDomains = selectFieldsFromObject(
  //   [
  //     'AL_SUM',
  //     'AP_SUM',
  //     'CD_SUM',
  //     'CN_SUM',
  //     'FM_SUM',
  //     'IP_SUM',
  //     'LM_SUM',
  //     'SE_SUM'
  //   ],
  //   workingData
  // );
  // const EQUIS_percentiles = arrayFromObject(EquisDomains).reduce(
  //   (acc, curr) => {
  //     const key = Object.keys(curr)[0];
  //     const value = Object.values(curr)[0];
  //     const percentile = ((parseInt(value) + 16) / 32) * 100;
  //     return { ...acc, [`perc_${key}`]: percentile };
  //   },
  //   {}
  // );

  // const Equis_Consistencies = {
  //   CONS_AL: greaterThanZero(
  //     EQUIS_percentiles.perc_AL_SUM - EQUIS_percentiles.perc_AP_SUM
  //   ),
  //   CONS_SE: greaterThanZero(
  //     EQUIS_percentiles.perc_SE_SUM - EQUIS_percentiles.perc_CD_SUM
  //   ),
  //   CONS_IP: greaterThanZero(
  //     EQUIS_percentiles.perc_IP_SUM - EQUIS_percentiles.perc_FM_SUM
  //   ),
  //   CONS_LM: greaterThanZero(
  //     EQUIS_percentiles.perc_LM_SUM - EQUIS_percentiles.perc_CN_SUM
  //   ),
  //   CONS_AP: greaterThanZero(
  //     EQUIS_percentiles.perc_AP_SUM - EQUIS_percentiles.perc_AL_SUM
  //   ),
  //   CONS_CD: greaterThanZero(
  //     EQUIS_percentiles.perc_CD_SUM - EQUIS_percentiles.perc_SE_SUM
  //   ),
  //   CONS_FM: greaterThanZero(
  //     EQUIS_percentiles.perc_FM_SUM - EQUIS_percentiles.perc_IP_SUM
  //   ),
  //   CONS_CN: greaterThanZero(
  //     EQUIS_percentiles.perc_CN_SUM - EQUIS_percentiles.perc_LM_SUM
  //   )
  // };

  const AreasFirst = selectFieldsFromObject(
    [
      'AC_FIRST_SUM',
      'AG_FIRST_SUM',
      'AR_FIRST_SUM',
      'CN_FIRST_SUM',
      'CS_FIRST_SUM',
      'D_FIRST_SUM',
      'E_FIRST_SUM',
      'H_FIRST_SUM',
      'S_FIRST_SUM',
      'T_FIRST_SUM'
    ],
    workingData
  );
  const AREAS_FIRST_percentiles = arrayFromObject(AreasFirst).reduce(
    (acc, curr) => {
      const key = Object.keys(curr)[0];
      const value = Object.values(curr)[0] || 0;
      const percentile = ((parseInt(value) + 4) / 8) * 100;
      return { ...acc, [`perc_${key}`]: percentile };
    },
    {}
  );

  const AreasSecond = selectFieldsFromObject(
    [
      'AC_SECOND_SUM',
      'AG_SECOND_SUM',
      'AR_SECOND_SUM',
      'CN_SECOND_SUM',
      'CS_SECOND_SUM',
      'D_SECOND_SUM',
      'E_SECOND_SUM',
      'H_SECOND_SUM',
      'S_SECOND_SUM',
      'T_SECOND_SUM'
    ],
    workingData
  );
  const AREAS_SECOND_percentiles = arrayFromObject(AreasSecond).reduce(
    (acc, curr) => {
      const key = Object.keys(curr)[0];
      const value = Object.values(curr)[0] || 0;
      const percentile = ((parseInt(value) + 4) / 8) * 100;
      return { ...acc, [`perc_${key}`]: percentile };
    },
    {}
  );

  const AreasThird = selectFieldsFromObject(
    [
      'AC_THIRD_SUM',
      'AG_THIRD_SUM',
      'AR_THIRD_SUM',
      'CN_THIRD_SUM',
      'CS_THIRD_SUM',
      'D_THIRD_SUM',
      'E_THIRD_SUM',
      'H_THIRD_SUM',
      'S_THIRD_SUM',
      'T_THIRD_SUM'
    ],
    workingData
  );
  const AREAS_THIRD_percentiles = arrayFromObject(AreasThird).reduce(
    (acc, curr) => {
      const key = Object.keys(curr)[0];
      const value = Object.values(curr)[0] || 0;
      const percentile = ((parseInt(value) + 4) / 8) * 100;
      return { ...acc, [`perc_${key}`]: percentile };
    },
    {}
  );
  const AreasFourth = selectFieldsFromObject(
    [
      'AC_FOURTH_SUM',
      'AG_FOURTH_SUM',
      'AR_FOURTH_SUM',
      'CN_FOURTH_SUM',
      'CS_FOURTH_SUM',
      'D_FOURTH_SUM',
      'E_FOURTH_SUM',
      'H_FOURTH_SUM',
      'S_FOURTH_SUM',
      'T_FOURTH_SUM'
    ],
    workingData
  );
  const AREAS_FOURTH_percentiles = arrayFromObject(AreasFourth).reduce(
    (acc, curr) => {
      const key = Object.keys(curr)[0];
      const value = Object.values(curr)[0] || 0;
      const percentile = ((parseInt(value) + 4) / 8) * 100;
      return { ...acc, [`perc_${key}`]: percentile };
    },
    {}
  );

  // const means = {
  //   'Afrontamiento Productivo': getMeanFromFields(
  //     [
  //       'SCCI_03',
  //       'SCCI_18',
  //       'SCCI_33',
  //       'SCCI_06',
  //       'SCCI_21',
  //       'SCCI_36',
  //       'SCCI_09',
  //       'SCCI_24',
  //       'SCCI_39',
  //       'SCCI_11',
  //       'SCCI_26',
  //       'SCCI_41',
  //       'SCCI_13',
  //       'SCCI_28',
  //       'SCCI_43',
  //       'SCCI_15',
  //       'SCCI_30',
  //       'SCCI_45'
  //     ],
  //     workingData
  //   ),
  //   'Búsqueda de Apoyo': getMeanFromFields(
  //     [
  //       'SCCI_02',
  //       'SCCI_17',
  //       'SCCI_32',
  //       'SCCI_05',
  //       'SCCI_20',
  //       'SCCI_35',
  //       'SCCI_08',
  //       'SCCI_23',
  //       'SCCI_38'
  //     ],
  //     workingData
  //   ),
  //   'Afrontamiento No-Producivo': getMeanFromFields(
  //     [
  //       'SCCI_04',
  //       'SCCI_19',
  //       'SCCI_34',
  //       'SCCI_07',
  //       'SCCI_22',
  //       'SCCI_37',
  //       'SCCI_10',
  //       'SCCI_25',
  //       'SCCI_40',
  //       'SCCI_12',
  //       'SCCI_27',
  //       'SCCI_42',
  //       'SCCI_14',
  //       'SCCI_29',
  //       'SCCI_44'
  //     ],
  //     workingData
  //   ),

  //   'Rol de la casualidad': getMeanFromFields(
  //     ['DCB_02', 'DCB_07'],
  //     workingData
  //   ),

  //   'Implicancias de la Decisión': getMeanFromFields(
  //     ['DCB_03', 'DCB_08'],
  //     workingData
  //   ),

  //   'Rol de otros significativos': getMeanFromFields(
  //     ['DCB_04', 'DCB_09'],
  //     workingData
  //   ),

  //   'Rol de la ayuda profesional': getMeanFromFields(
  //     ['DCB_05', 'DCB_15'],
  //     workingData
  //   ),

  //   Género:
  //     parseInt(workingData.SEX) === 1
  //       ? getMeanFromFields(['DCB_11_M', 'DCB_16_M'], workingData)
  //       : getMeanFromFields(['DCB_11_F', 'DCB_16_F'], workingData),

  //   'Información sobre el proceso': getMeanFromFields(['DI_01'], workingData),

  //   'Información sobre el Self': getMeanFromFields(
  //     ['DI_02', 'DI_03'],
  //     workingData
  //   ),

  //   'Información sobre Ocupaciones': getMeanFromFields(
  //     ['DI_04', 'DI_05'],
  //     workingData
  //   ),

  //   'Conflictos internos': getMeanFromFields(
  //     ['DI_06', 'DI_07', 'DI_08', 'DI_09', 'DI_10'],
  //     workingData
  //   ),

  //   'Conflictos externos': getMeanFromFields(['DI_11', 'DI_12'], workingData),

  //   'Indecisión General': getMeanFromFields(
  //     [
  //       'IG_01_R',
  //       'IG_02',
  //       'IG_03',
  //       'IG_04_R',
  //       'IG_05',
  //       'IG_06_R',
  //       'IG_07_R',
  //       'IG_08',
  //       'IG_09',
  //       'IG_10',
  //       'IG_11',
  //       'IG_12',
  //       'IG_13',
  //       'IG_14_R',
  //       'IG_15',
  //       'IG_16',
  //       'IG_17',
  //       'IG_18_R',
  //       'IG_19_R',
  //       'IG_20_R',
  //       'IG_21_R',
  //       'IG_22_R'
  //     ],
  //     workingData
  //   )
  // };

  // const percentiles = {
  //   'Afrontamiento Productivo': Math.round(
  //     normal.cdf((means['Afrontamiento Productivo'] - 6.15) / 1.3, 0, 1) * 100
  //   ),
  //   'Búsqueda de Apoyo': Math.round(
  //     normal.cdf((means['Búsqueda de Apoyo'] - 5.08) / 1.71, 0, 1) * 100
  //   ),
  //   'Afrontamiento No-Producivo': Math.round(
  //     normal.cdf((means['Afrontamiento No-Producivo'] - 4.7) / 1.69, 0, 1) * 100
  //   ),
  //   'Rol de la casualidad': Math.round(
  //     normal.cdf((means['Rol de la casualidad'] - 5.18) / 1.85, 0, 1) * 100
  //   ),
  //   'Implicancias de la Decisión': Math.round(
  //     normal.cdf((means['Implicancias de la Decisión'] - 3.83) / 1.89, 0, 1) *
  //       100
  //   ),
  //   'Rol de otros significativos': Math.round(
  //     normal.cdf((means['Rol de otros significativos'] - 4.36) / 1.72, 0, 1) *
  //       100
  //   ),
  //   'Rol de la ayuda profesional': Math.round(
  //     normal.cdf((means['Rol de la ayuda profesional'] - 3.69) / 2.18, 0, 1) *
  //       100
  //   ),
  //   Género: Math.round(normal.cdf((means['Género'] - 3.93) / 1.2, 0, 1) * 100),
  //   'Información sobre el proceso': Math.round(
  //     normal.cdf((means['Información sobre el proceso'] - 4.04) / 2.28, 0, 1) *
  //       100
  //   ),
  //   'Información sobre el Self': Math.round(
  //     normal.cdf((means['Información sobre el Self'] - 3.44) / 1.79, 0, 1) * 100
  //   ),
  //   'Información sobre Ocupaciones': Math.round(
  //     normal.cdf((means['Información sobre Ocupaciones'] - 4.62) / 2.37, 0, 1) *
  //       100
  //   ),
  //   'Conflictos internos': Math.round(
  //     normal.cdf((means['Conflictos internos'] - 3.42) / 1.69, 0, 1) * 100
  //   ),
  //   'Conflictos externos': Math.round(
  //     normal.cdf((means['Conflictos externos'] - 2.28) / 1.75, 0, 1) * 100
  //   ),
  //   'Indecisión General': Math.round(
  //     normal.cdf((means['Indecisión General'] - 3.65) / 0.84, 0, 1) * 100
  //   )
  // };

  return {
    // ...baseInfo,
    // SEX: parseInt(workingData.SEX) === 1 ? 'Masculino' : 'Femenino',
    // age,
    // ...EQUIS_percentiles,
    // ...Equis_Consistencies,
    ...AREAS_FIRST_percentiles,
    ...AREAS_SECOND_percentiles,
    ...AREAS_THIRD_percentiles,
    ...AREAS_FOURTH_percentiles,
    // ...percentiles
  };
};

export default getResults;

let res = {
  school: 'The International School La Serena',
  grade: '',
  name: 'Margori',
  lastname: 'Goldberg',
  email: '',
  RUT: '1.111.111-1',
  BIRTH: '07-19-19',
  SEX: 'Femenino',
  updatedat: 'Thu 17 Dec 2020 15:37:23 GMT',
  TOP_DIM_1: 'SE',
  TOP_DIM_2: 'AL',
  age: 1,
  perc_AL_SUM: 75,
  perc_AP_SUM: 28.125,
  perc_CD_SUM: 15.625,
  perc_CN_SUM: 56.25,
  perc_FM_SUM: 40.625,
  perc_IP_SUM: 62.5,
  perc_LM_SUM: 40.625,
  perc_SE_SUM: 81.25,
  CONS_AL: 46.875,
  CONS_SE: 65.625,
  CONS_IP: 21.875,
  CONS_LM: 0,
  CONS_AP: 0,
  CONS_CD: 0,
  CONS_FM: 0,
  CONS_CN: 15.625,
  perc_AC_FIRST_SUM: 50,
  perc_AG_FIRST_SUM: 50,
  perc_AR_FIRST_SUM: 50,
  perc_CN_FIRST_SUM: 50,
  perc_CS_FIRST_SUM: 87.5,
  perc_D_FIRST_SUM: 37.5,
  perc_E_FIRST_SUM: 50,
  perc_H_FIRST_SUM: 50,
  perc_S_FIRST_SUM: 25,
  perc_T_FIRST_SUM: 50,
  perc_AC_SECOND_SUM: 50,
  perc_AG_SECOND_SUM: 50,
  perc_AR_SECOND_SUM: 87.5,
  perc_CN_SECOND_SUM: 50,
  perc_CS_SECOND_SUM: 50,
  perc_D_SECOND_SUM: 50,
  perc_E_SECOND_SUM: 50,
  perc_H_SECOND_SUM: 37.5,
  perc_S_SECOND_SUM: 50,
  perc_T_SECOND_SUM: 25,
  'Afrontamiento Productivo': 6.111111111111111,
  'Búsqueda de Apoyo': 6.555555555555555,
  'Afrontamiento No-Producivo': 6.466666666666667,
  'Rol de la casualidad': 2,
  'Implicancias de la Decisión': 3.5,
  'Rol de otros significativos': 2.5,
  'Rol de la ayuda profesional': 4.5,
  Género: 1.5,
  'Información sobre el proceso': 6,
  'Información sobre el Self': 6,
  'Información sobre Ocupaciones': 6,
  'Conflictos internos': 5.4,
  'Conflictos externos': 4.5,
  'Indecisión General': 3.1818181818181817
};
