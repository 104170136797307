const baseInfo = {
  locale: 'es',
  title: 'FAQ',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'FAQ_PAGE_01',
      // title: '',
      elements: [
        {
          type: 'html',
          name: 'HTML_IMPACT_TITLE',
          html: `
          <div>
            <legend>
              <span style="font-size:16px;">
                El propósito de esta sección conocer tu opinión sobre la evaluación que recibiste recientemente sobre tu desempeño en la certificación de competencias.
                <br />
                <br />
                Por favor lee cuidadosamente cada enunciado e indica tu grado de acuerdo con cada uno de ellos usando la siguiente escala:
              </span>
            </legend>
          </div>
          `
        },
        {
          type: 'matrix',
          name: 'FAQ_QUESTIONS',
          title: ` `,
          // title:
          //   'A continuación encontrará una lista de comportamientos que describen cómo las personas se desempeñan en el trabajo. Pensando en los últimos 6 meses, por favor señale cuán frecuentemente Ud. ha mostrado cada uno de estos comportamientos en su vida laboral.',
          // isRequired: true,
          isAllRowRequired: true,
          columns: [
            {
              value: '1',
              text: 'Muy en desacuerdo'
            },
            {
              value: '2',
              text: 'En desacuerdo'
            },
            {
              value: '3',
              text: 'Ni de acuerdo ni en desacuerdo'
            },
            {
              value: '4',
              text: 'De acuerdo'
            },
            {
              value: '5',
              text: 'Muy de acuerdo'
            }
          ],
          rows: [
            {
              value: 'FAQ01',
              text:
                'La evaluación que recibí refleja adecuadamente mi desempeño en la certificación de competencias'
            },
            {
              value: 'FAQ02',
              text:
                'Estoy de acuerdo con las calificaciones que me pusieron en la certificación de competencias'
            },
            {
              value: 'FAQ03',
              text: 'Siento que el proceso de evaluación fue justo'
            },
            {
              value: 'FAQ04',
              text:
                'Creo que los jueces fueron objetivos en la evaluación de mi desempeño'
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
