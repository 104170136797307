import React, { useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal';

const StyledModal = Modal.styled`
  width: 50%;
  min-height: 20rem;
  border-radius: 5px;
  padding: 2rem 2.5rem;
  background-color: white;
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
  @media (max-width: 979px) {
    width: 70%;
  }
  @media (max-width: 564px) {
    padding: 1.5rem 1.5rem;
    width: 80%;
  }
`;
const Form = styled.form`
  text-align: center;
  label {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 0.9rem;
    font-weight: 700;
  }
  input {
    background: #fff;
    color: ${({ theme }) => theme.colors.textColor};
    -webkit-transition: background 0.2s ease-in-out;
    padding: 8px 20px;
    display: block;
    text-align: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.greyBackground};
    width: 100%;
    margin-bottom: 1rem;
  }
  button {
    background: ${({ theme }) => theme.colors.brandColor};
    color: #fff;
    border: none;
    -webkit-transition: background 0.2s ease-in-out;
    padding: 8px 20px;
    text-align: center;
    border-radius: 4px;
  }
`;

const FancyModalButton = ({
  active = false,
  updateContext,
  BATTERY,
  codeId
}) => {
  const [isOpen, setIsOpen] = useState(active);
  const [opacity, setOpacity] = useState(0);
  const [rut, setRut] = useState('');

  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });
  }

  const onRutSubmit = async e => {
    e.preventDefault();
    console.log('submitting', rut);
    const CURRENT_CODE = `currentCode-${BATTERY}`;
    localStorage.setItem(CURRENT_CODE, rut);
    await updateContext({
      rut: rut
    });
    window.location.reload();
  };

  const showTestButton = false;

  return (
    <React.Fragment>
      {showTestButton && <button onClick={toggleModal}>Open modal</button>}
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        // onBackgroundClick={{}}
        // onEscapeKeydown={{}}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <div>
          <div>
            <h5>Ingresa tu {codeId ? 'Código' : 'Rut'}</h5>
            <br />
            {!codeId && (
              <p>
                Este dato{' '}
                <strong>
                  <u>NO</u>
                </strong>{' '}
                será registrado en ninguna base ni tampoco será asociado a
                ninguna respuesta en especifico.
              </p>
            )}

            <p>
              Solo se utilizará para guardar los avances de esta encuesta
              {codeId ? '' : 'en este dispositivo o computadora'}, y es
              requisito para poder registrar cada respuesta por separado.
            </p>
            <p>
              En caso de necesitarlo, puedes retomar tus avances volviendo a
              introducir tu {codeId ? 'Código' : 'Rut'} en esta encuesta.
            </p>
            {!codeId && (
              <p>
                <strong>Importante: </strong>
                Por favor, ingresa tu RUT <u>sin puntos ni rayas</u>. En caso
                que termine en <em>K</em> reemplácelo por un <em>0</em>.
              </p>
            )}
          </div>
          <div>
            <Form onSubmit={onRutSubmit}>
              <label htmlFor='rutid'>{codeId ? 'Código' : 'Rut'}</label>
              <input
                name='rutid'
                value={rut}
                onChange={e => setRut(e.target.value)}
              />
              <button type='submit'>Guardar</button>
            </Form>
          </div>
          {/* <button onClick={toggleModal}>Close me</button> */}
        </div>
      </StyledModal>
    </React.Fragment>
  );
};

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
`;

const ModalSelector = props => {
  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <FancyModalButton active {...props} />
    </ModalProvider>
  );
};

export default ModalSelector;
