const C1 = [
  {
    combination: 'C1',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'CN',
            code: 'C1_1_CN',
            statement:
              'Participar en una expedición para categorizar la fauna marina de una zona'
          },
          {
            dimension: 'FM',
            code: 'C1_1_FM',
            statement:
              'Estar a cargo de la calibración de equipos de medición geológica'
          },
          {
            dimension: 'CD',
            code: 'C1_1_CD',
            statement: 'Analizar estadísticamente los datos de una encuesta'
          },
          {
            dimension: 'AP',
            code: 'C1_1_AP',
            statement:
              'Elaborar la planificación estratégica a cuatro años para una empresa'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'CN',
            code: 'C1_2_CN',
            statement: 'Trabajar como guardaparques en un Parque Nacional'
          },
          {
            dimension: 'FM',
            code: 'C1_2_FM',
            statement: 'Reparar la maquinaria de una fábrica de alimentos'
          },
          {
            dimension: 'CD',
            code: 'C1_2_CD',
            statement:
              'Desarrollar los algoritmos para un nuevo buscador de internet'
          },
          {
            dimension: 'AP',
            code: 'C1_2_AP',
            statement:
              'Elaborar el presupuesto requerido para el desarrollo de un nuevo emprendimiento'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'CN',
            code: 'C1_3_CN',
            statement:
              'Participar en una investigación para encontrar la cura a una nueva enfermedad'
          },
          {
            dimension: 'FM',
            code: 'C1_3_FM',
            statement:
              'Diseñar una nueva maquinaria agrícola para optimizar la cosecha'
          },
          {
            dimension: 'CD',
            code: 'C1_3_CD',
            statement:
              'Mantener actualizada la plataforma informática de una empresa'
          },
          {
            dimension: 'AP',
            code: 'C1_3_AP',
            statement:
              'Mantener los registros de ventas y gastos de una empresa'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'CN',
            code: 'C1_4_CN',
            statement:
              'Realizar una investigación científica sobre el derretimiento de los polos'
          },
          {
            dimension: 'FM',
            code: 'C1_4_FM',
            statement: 'Diseñar un motor que emita menos gases contaminantes'
          },
          {
            dimension: 'CD',
            code: 'C1_4_CD',
            statement:
              'Analizar el funcionamiento de las líneas de transmisión eléctrica del país'
          },
          {
            dimension: 'AP',
            code: 'C1_4_AP',
            statement:
              'Realizar un análisis financiero de los resultados anuales de una empresa'
          }
        ]
      }
    ]
  }
];

const C2 = [
  {
    combination: 'C2',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'AL',
            code: 'C2_1_AL',
            statement: 'Elaborar un proyecto de arte textil'
          },
          {
            dimension: 'CN',
            code: 'C2_1_CN',
            statement:
              'Extraer y clasificar minerales en la zona norte del país'
          },
          {
            dimension: 'FM',
            code: 'C2_1_FM',
            statement:
              'Supervisar el funcionamiento de los equipos de una estación meteorológica'
          },
          {
            dimension: 'CD',
            code: 'C2_1_CD',
            statement:
              'Trabajar como analista de datos estadísticos para el Instituto Nacional de Estadísticas'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'AL',
            code: 'C2_2_AL',
            statement: 'Pintar un mural en un edificio público'
          },
          {
            dimension: 'CN',
            code: 'C2_2_CN',
            statement: 'Estudiar el efecto del uso de energías sustentables'
          },
          {
            dimension: 'FM',
            code: 'C2_2_FM',
            statement:
              'Realizar las pruebas de seguridad del sistema de frenado de un nuevo automóvil'
          },
          {
            dimension: 'CD',
            code: 'C2_2_CD',
            statement:
              'Programar un nuevo software de apoyo apara trabajos de contabilidad'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'AL',
            code: 'C2_3_AL',
            statement: 'Componer una obra musical'
          },
          {
            dimension: 'CN',
            code: 'C2_3_CN',
            statement:
              'Desarrollar un experimento en un laboratorio farmacéutico'
          },
          {
            dimension: 'FM',
            code: 'C2_3_FM',
            statement:
              'Diseñar un tren de aterrizaje más eficiente para aviones comerciales'
          },
          {
            dimension: 'CD',
            code: 'C2_3_CD',
            statement:
              'Diseñar un sistema de transmisión de datos para zonas con bajo acceso a internet'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'AL',
            code: 'C2_4_AL',
            statement: 'Diseñar una prenda de vestuario'
          },
          {
            dimension: 'CN',
            code: 'C2_4_CN',
            statement: 'Participar en un estudio de oceanografía'
          },
          {
            dimension: 'FM',
            code: 'C2_4_FM',
            statement:
              'Trabajar en la fabricación de un nuevo sistema mecánico para buques de gran tamaño'
          },
          {
            dimension: 'CD',
            code: 'C2_4_CD',
            statement: 'Analizar los flujos de datos a través de redes sociales'
          }
        ]
      }
    ]
  }
];

const C3 = [
  {
    combination: 'C3',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'SE',
            code: 'C3_1_SE',
            statement: 'Orientar a personas para que puedan encontrar trabajo'
          },
          {
            dimension: 'AL',
            code: 'C3_1_AL',
            statement: 'Escribir el guión de una película'
          },
          {
            dimension: 'CN',
            code: 'C3_1_CN',
            statement:
              'Realizar una investigación acerca de las propiedades de la medicina natural mapuche'
          },
          {
            dimension: 'FM',
            code: 'C3_1_FM',
            statement:
              'Ser responsable de revisar las redes computacionales de una empresa '
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'SE',
            code: 'C3_2_SE',
            statement:
              'Ser monitor/a de un programa para facilitar la integración de niños y niñas extranjeros al colegio'
          },
          {
            dimension: 'AL',
            code: 'C3_2_AL',
            statement: 'Realizar un documental sobre un músico importante'
          },
          {
            dimension: 'CN',
            code: 'C3_2_CN',
            statement:
              'Hacer una investigación para entender el comportamiento de las abejas'
          },
          {
            dimension: 'FM',
            code: 'C3_2_FM',
            statement: 'Diseñar una solución tecnológica para la minería'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'SE',
            code: 'C3_3_SE',
            statement:
              'Diseñar un taller para fomentar la autoestima de personas de la tercera edad'
          },
          {
            dimension: 'AL',
            code: 'C3_3_AL',
            statement: 'Realizar las ilustraciones para un nuevo libro infantil'
          },
          {
            dimension: 'CN',
            code: 'C3_3_CN',
            statement:
              'Estudiar el comportamiento de microorganismos en condiciones de temperaturas extremas'
          },
          {
            dimension: 'FM',
            code: 'C3_3_FM',
            statement:
              'Desarrollar equipamiento industrial de bajo consumo eléctrico'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'SE',
            code: 'C3_4_SE',
            statement:
              'Colaborar en el consultorio de salud de un municipio de bajos recursos'
          },
          {
            dimension: 'AL',
            code: 'C3_4_AL',
            statement: 'Criticar películas del cine arte'
          },
          {
            dimension: 'CN',
            code: 'C3_4_CN',
            statement:
              'Realizar un estudio de los efectos del uso de plaguicidas en el campo'
          },
          {
            dimension: 'FM',
            code: 'C3_4_FM',
            statement: 'Reparar el motor de un automóvil'
          }
        ]
      }
    ]
  }
];

const C4 = [
  {
    combination: 'C4',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'IP',
            code: 'C4_1_IP',
            statement:
              'Ser asesor/a comunicacional de una organización'
          },
          {
            dimension: 'SE',
            code: 'C4_1_SE',
            statement: 'Ayudar a parejas que tienen dificultades en su relación'
          },
          {
            dimension: 'AL',
            code: 'C4_1_AL',
            statement: 'Analizar una obra teatral'
          },
          {
            dimension: 'CN',
            code: 'C4_1_CN',
            statement:
              'Investigar el impacto ambiental de la instalación de una nueva planta hidroeléctrica'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'IP',
            code: 'C4_2_IP',
            statement: 'Convencer a la prensa de que asista a la conferencia de un personaje público'
          },
          {
            dimension: 'SE',
            code: 'C4_2_SE',
            statement: 'Capacitar a jóvenes de escasos recursos en un oficio'
          },
          {
            dimension: 'AL',
            code: 'C4_2_AL',
            statement: 'Montar la exposición de arte de una galería'
          },
          {
            dimension: 'CN',
            code: 'C4_2_CN',
            statement:
              'Desarrollar una investigación sobre los agujeros negros en el universo'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'IP',
            code: 'C4_3_IP',
            statement:
              'Administrar las relaciones públicas de una organización a través de redes sociales'
          },
          {
            dimension: 'SE',
            code: 'C4_3_SE',
            statement:
              'Dirigir un taller para enseñar español a mujeres migrantes que no manejan el idioma'
          },
          {
            dimension: 'AL',
            code: 'C4_3_AL',
            statement: 'Escribir una novela para adolescentes'
          },
          {
            dimension: 'CN',
            code: 'C4_3_CN',
            statement:
              'Realizar un experimento para estudiar el comportamiento de las partículas a nivel atómico'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'IP',
            code: 'C4_4_IP',
            statement:
              'Estar a cargo de la recepción de clientes en un restaurante exclusivo'
          },
          {
            dimension: 'SE',
            code: 'C4_4_SE',
            statement:
              'Realizar actividades recreativas con personas de una residencia para el adulto mayor'
          },
          {
            dimension: 'AL',
            code: 'C4_4_AL',
            statement: 'Crear la portada para una novela'
          },
          {
            dimension: 'CN',
            code: 'C4_4_CN',
            statement:
              'Estudiar la composición de los minerales de la zona norte del país'
          }
        ]
      }
    ]
  }
];

const C5 = [
  {
    combination: 'C5',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'LM',
            code: 'C5_1_LM',
            statement: 'Ser gerente/a comercial de una empresa'
          },
          {
            dimension: 'IP',
            code: 'C5_1_IP',
            statement:
              'Guiar a un grupo de personas en una visita a un sitio patrimonial'
          },
          {
            dimension: 'SE',
            code: 'C5_1_SE',
            statement:
              'Entregar orientación a jóvenes a través de una línea telefónica gratuita'
          },
          {
            dimension: 'AL',
            code: 'C5_1_AL',
            statement: 'Escribir cuentos infantiles'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'LM',
            code: 'C5_2_LM',
            statement: 'Ser parte del directorio de fundación importante'
          },
          {
            dimension: 'IP',
            code: 'C5_2_IP',
            statement:
              'Motivar a un equipo deportivo antes de un partido importante'
          },
          {
            dimension: 'SE',
            code: 'C5_2_SE',
            statement: 'Hacer clases a niños y niñas de educación básica'
          },
          {
            dimension: 'AL',
            code: 'C5_2_AL',
            statement: 'Restaurar una pintura del siglo pasado'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'LM',
            code: 'C5_3_LM',
            statement: 'Ser director de operaciones en una empresa logística'
          },
          {
            dimension: 'IP',
            code: 'C5_3_IP',
            statement: 'Preocuparse de que los pasajeros de un hotel de lujo tengan una experiencia de primer nivel'
          },
          {
            dimension: 'SE',
            code: 'C5_3_SE',
            statement: 'Dirigir un taller para apoyar a madres adolescentes'
          },
          {
            dimension: 'AL',
            code: 'C5_3_AL',
            statement: 'Crear una escultura para el edificio de una fundación'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'LM',
            code: 'C5_4_LM',
            statement: 'Crear una empresa'
          },
          {
            dimension: 'IP',
            code: 'C5_4_IP',
            statement:
              'Motivar a un equipo de trabajo a alcanzar una meta difícil de alcanzar'
          },
          {
            dimension: 'SE',
            code: 'C5_4_SE',
            statement:
              'Enseñar lenguaje de señas a personas con problemas de audición'
          },
          {
            dimension: 'AL',
            code: 'C5_4_AL',
            statement: 'Dirigir una película'
          }
        ]
      }
    ]
  }
];

const C6 = [
  {
    combination: 'C6',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'AP',
            code: 'C6_1_AP',
            statement: 'Ser ejecutivo/a de cuentas de un banco'
          },
          {
            dimension: 'LM',
            code: 'C6_1_LM',
            statement: 'Dirigir una fundación para impulsar el emprendimiento'
          },
          {
            dimension: 'IP',
            code: 'C6_1_IP',
            statement:
              'Dirigir la visita guiada a asistentes a la biblioteca nacional'
          },
          {
            dimension: 'SE',
            code: 'C6_1_SE',
            statement:
              'Ayudar a personas de escasos recursos a obtener sus beneficios sociales'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'AP',
            code: 'C6_2_AP',
            statement:
              'Elaborar los reportes financieros mensuales de una empresa'
          },
          {
            dimension: 'LM',
            code: 'C6_2_LM',
            statement: 'Ser gerente de personas en un banco'
          },
          {
            dimension: 'IP',
            code: 'C6_2_IP',
            statement:
              'Estar a cargo del servicio de atención a clientes de una tienda exclusiva'
          },
          {
            dimension: 'SE',
            code: 'C6_2_SE',
            statement: 'Orientar vocacionalmente a jóvenes de educación media'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'AP',
            code: 'C6_3_AP',
            statement: 'Realizar el inventario de una fábrica de productos'
          },
          {
            dimension: 'LM',
            code: 'C6_3_LM',
            statement: 'Administrar una empresa de mayor tamaño'
          },
          {
            dimension: 'IP',
            code: 'C6_3_IP',
            statement:
              'Asesorar y ayudar a organizar el viaje de personas que vacacionarán fuera del país'
          },
          {
            dimension: 'SE',
            code: 'C6_3_SE',
            statement: 'Ser profesor jefe de un curso de enseñanza media'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'AP',
            code: 'C6_4_AP',
            statement: 'Hacer una auditoría contable'
          },
          {
            dimension: 'LM',
            code: 'C6_4_LM',
            statement:
              'Estar a cargo de la gerencia de innovación de una compañía'
          },
          {
            dimension: 'IP',
            code: 'C6_4_IP',
            statement: 'Llevar las relaciones públicas de una fundación'
          },
          {
            dimension: 'SE',
            code: 'C6_4_SE',
            statement:
              'Enseñar hábitos alimenticios saludables a madres y padres de familias vulnerables'
          }
        ]
      }
    ]
  }
];

const C7 = [
  {
    combination: 'C7',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'CD',
            code: 'C7_1_CD',
            statement:
              'Desarrollar un software para la estimación de cálculos matemáticos complejos'
          },
          {
            dimension: 'AP',
            code: 'C7_1_AP',
            statement:
              'Administrar la planilla de recursos humanos de una empresa'
          },
          {
            dimension: 'LM',
            code: 'C7_1_LM',
            statement: 'Dirigir una empresa de selección de personas'
          },
          {
            dimension: 'IP',
            code: 'C7_1_IP',
            statement: 'Representar a una empresa en un congreso internacional'
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'CD',
            code: 'C7_2_CD',
            statement:
              'Producir las estadísticas comparativas sobre el nivel de desarrollo de países de Latinoamérica'
          },
          {
            dimension: 'AP',
            code: 'C7_2_AP',
            statement:
              'Estimar los costos de desarrollar un nuevo producto en una fábrica'
          },
          {
            dimension: 'LM',
            code: 'C7_2_LM',
            statement: 'Ser gerente general en una empresa'
          },
          {
            dimension: 'IP',
            code: 'C7_2_IP',
            statement:
              'Organizar el evento social para reunir fondos de una fundación'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'CD',
            code: 'C7_3_CD',
            statement:
              'Elaborar una base de datos que organice información  para analizar los resultados financieros de una empresa'
          },
          {
            dimension: 'AP',
            code: 'C7_3_AP',
            statement:
              'Evaluar los costos que tendrá el desarrollo de un proyecto industrial'
          },
          {
            dimension: 'LM',
            code: 'C7_3_LM',
            statement: 'Dirigir una nueva empresa'
          },
          {
            dimension: 'IP',
            code: 'C7_3_IP',
            statement: 'Ser asesor/a comunicacional para un Ministerio'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'CD',
            code: 'C7_4_CD',
            statement: 'Programar un software de inteligencia artificial'
          },
          {
            dimension: 'AP',
            code: 'C7_4_AP',
            statement:
              'Estar a cargo de el pago de sueldos dentro de una empresa'
          },
          {
            dimension: 'LM',
            code: 'C7_4_LM',
            statement:
              'Trabajar como director de negocios en una compañía importante'
          },
          {
            dimension: 'IP',
            code: 'C7_4_IP',
            statement: 'Ser anfitrión/a en un evento'
          }
        ]
      }
    ]
  }
];

const C8 = [
  {
    combination: 'C8',
    items: [
      {
        item: '1',
        options: [
          {
            dimension: 'FM',
            code: 'C8_1_FM',
            statement: 'Reparar los telescopios de un observatorio astronómico'
          },
          {
            dimension: 'CD',
            code: 'C8_1_CD',
            statement:
              'Desarrollar códigos de programación de un software para detectar virus informáticos'
          },
          {
            dimension: 'AP',
            code: 'C8_1_AP',
            statement:
              'Entregar servicios de contabilidad a empresas medianas y pequeñas'
          },
          {
            dimension: 'LM',
            code: 'C8_1_LM',
            statement: 'Ser director de un instituto de investigación '
          }
        ]
      },
      {
        item: '2',
        options: [
          {
            dimension: 'FM',
            code: 'C8_2_FM',
            statement: 'Construir un automóvil que utilice energías limpias'
          },
          {
            dimension: 'CD',
            code: 'C8_2_CD',
            statement:
              'Diseñar los circuitos eléctricos para una nueva tecnología'
          },
          {
            dimension: 'AP',
            code: 'C8_2_AP',
            statement:
              'Elaborar los informes contables para el directorio de una empresa'
          },
          {
            dimension: 'LM',
            code: 'C8_2_LM',
            statement: 'Ser gerente de administración y finanzas en una empresa'
          }
        ]
      },
      {
        item: '3',
        options: [
          {
            dimension: 'FM',
            code: 'C8_3_FM',
            statement:
              'Participar en la construcción del próximo satélite chileno'
          },
          {
            dimension: 'CD',
            code: 'C8_3_CD',
            statement:
              'Analizar y comparar las estadísticas vitales de distintos países el mundo'
          },
          {
            dimension: 'AP',
            code: 'C8_3_AP',
            statement:
              'Estar a cargo del control de gastos en un proyecto de ingeniería'
          },
          {
            dimension: 'LM',
            code: 'C8_3_LM',
            statement: 'Ser parte del directorio de una empresa de mayor tamaño'
          }
        ]
      },
      {
        item: '4',
        options: [
          {
            dimension: 'FM',
            code: 'C8_4_FM',
            statement: 'Diseñar un prototipo de auto para fórmula 1'
          },
          {
            dimension: 'CD',
            code: 'C8_4_CD',
            statement:
              'Desarrollar una aplicación para entregar información sobre transporte público'
          },
          {
            dimension: 'AP',
            code: 'C8_4_AP',
            statement:
              'Determinar los riesgos económicos de desarrollar un nuevo negocio'
          },
          {
            dimension: 'LM',
            code: 'C8_4_LM',
            statement: 'Ser director financiero de una institución de salud'
          }
        ]
      }
    ]
  }
];

const PGIBIP = [...C1, ...C2, ...C3, ...C4, ...C5, ...C6, ...C7, ...C8];

export default PGIBIP;
