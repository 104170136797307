import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const INITIAL_VALUES = {};

const DataContext = createContext(INITIAL_VALUES);

export const DataProvider = props => {
  const { children, id, initial } = props;
  const [state, setState] = useState(initial || INITIAL_VALUES);
  const [userData, setUserData] = useState(null);
  // const [responses, setResponses] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);

  const checkForCurrentUser = () => {
    const localUser = localStorage.getItem(`localUserData_${id}`);
    // console.log({ localUser });
    if (localUser) {
      try {
        const parsedLocalUser = JSON.parse(localUser);
        setUserData(parsedLocalUser);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    checkForCurrentUser();
  }, []);

  const saveLocalCurrentUser = newUserData => {
    localStorage.setItem(`localUserData_${id}`, JSON.stringify(newUserData));
  };
  useEffect(() => {
    saveLocalCurrentUser(userData);
  }, [userData]);
  // useEffect(() => {
  //   // console.log('updating initial response');
  //   // console.log({ list });
  //   setState(list || INITIAL_VALUES);
  // }, [list]);

  useEffect(() => {
    // console.log('on data context results');
    const getRespData = async () => {
      const POST_API = `/api/campaign`;
      const body = {
        params: {
          id
        }
      };
      // console.log('prev calling');
      // const options = { ...opts, headers };
      // console.log({ body, options });
      const { data } = await axios.get(POST_API, body);
      // console.log({ data });
      if (data) {
        try {
          const parsed = JSON.parse(data);
          await setCampaign(parsed.data);
        } catch (err) {
          await setCampaign(null);
        }
      }
      await setLoading(false);
    };
    getRespData();
  }, [id]);

  const handleLoginUser = async ({ field, value }) => {
    await setLoadingUser(true);
    const POST_API = `/api/campaign/participant`;
    const body = {
      params: {
        id: campaign.id,
        field,
        value
      }
    };
    // console.log('prev calling');
    // const options = { ...opts, headers };
    // console.log({ body, options });
    const { data } = await axios.get(POST_API, body);
    // console.log({ data });
    if (data) {
      try {
        const { participant } = JSON.parse(data);
        // console.log('data of loggin user');
        // console.log({ parsed });
        // await setCampaign(parsed.data);
        await setLoadingUser(false);
        return participant;
      } catch (err) {
        console.log(err);
        await setLoadingUser(false);
        return null;
      }
    }
  };

  return (
    <DataContext.Provider
      value={{
        ...state,
        updateContext: setState,
        campaign,
        isLoading: loading,
        userData,
        setUserData,
        loadingUser,
        logInUser: handleLoginUser
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DataConsumer = DataContext.Consumer;
export const useDataContext = () => useContext(DataContext);
