import uuidv4 from 'uuid/v4';

export default async (BATTERY, rut = 'anon') => {
  let user;
  let stage;
  let currentRut;
  const USER_ID = `userId-${rut}-${BATTERY}`;
  const USER_STAGE = `userStage-${rut}-${BATTERY}`;
  const CURRENT_CODE = `currentCode-${BATTERY}`;
  const existingId = localStorage.getItem(USER_ID);
  const prevStage = localStorage.getItem(USER_STAGE);
  const prevCode = localStorage.getItem(CURRENT_CODE);
  // check if user on localStorage
  if (existingId) {
    user = existingId;
  } else {
    const tempUser = uuidv4();
    user = tempUser;
    localStorage.setItem(USER_ID, tempUser);
  }
  // check if stage on localStorage
  if (prevStage) {
    stage = parseInt(prevStage, 10);
  } else {
    stage = 0;
    localStorage.setItem(USER_STAGE, 0);
  }
  // check if code on localStorage
  if (prevCode) {
    currentRut = prevCode;
  }
  return {
    USER_ID: user,
    USER_STAGE: stage,
    STAGE_NAME: USER_STAGE,
    CURRENT_CODE: currentRut
  };
};
