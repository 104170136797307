const PolarSelectObject = {
  locale: 'es',
  title: 'Polar Selection',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: false,
  showNavigationButtons: false,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'POLAR_SELECT_PAGE_01',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'radiogroup',
          name: 'FOOD_01',
          title: 'Que comida prefieres?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Pizza'
            },
            {
              value: '2',
              text: 'Sandwich'
            }
          ]
        }
      ]
    },
    {
      name: 'POLAR_SELECT_PAGE_02',
      // title:
      //   'A continuación se presentan una serie de características que pueden o no aplicar a usted. Por favor, señale su grado de acuerdo o desacuerdo para cada una de ellas marcando una de las opciones de respuesta.',
      elements: [
        {
          type: 'radiogroup',
          name: 'FOOD_02',
          title: 'Que Snack prefieres?',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Papas fritas'
            },
            {
              value: '2',
              text: 'Aros de cebolla'
            }
          ]
        }
      ]
    }
  ]
};

export default PolarSelectObject;
