const ratingValues = [
  {
    value: '1',
    text: 'Totalmente en desacuerdo'
  },
  {
    value: '2',
    text: 'En desacuerdo'
  },
  {
    value: '3',
    text: 'Algo en desacuerdo'
  },
  {
    value: '4',
    text: 'Algo de acuerdo'
  },
  {
    value: '5',
    text: 'De acuerdo'
  },
  {
    value: '6',
    text: 'Totalmente de acuerdo'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_IMPACT_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        Las siguientes preguntas son para indagar sobre lo que piensan las personas sobre la inteligencia.
        <br />
        No hay respuestas correctas o incorrectas. Sólo nos interesa conocer tu visión.
        <br /><br />
        Usando la escala de más abajo, por favor indica en qué medida estás de acuerdo o en desacuerdo con las siguientes afirmaciones:
        <br />
      </span>
    </legend>
  </div>
  `
};

const baseInfo = {
  locale: 'es',
  title: 'Growth Mindset',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'GMQ01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'GMQ01',
          title:
            'Uno tiene un cierto nivel de inteligencia y no hay mucho que hacer para aumentarla',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'GMQ02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'GMQ02',
          title:
            'La inteligencia es una característica individual que no se puede mejorar mucho',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'GMQ03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'GMQ03',
          title:
            'No importa quién seas, todos pueden aumentar significativamente su nivel de inteligencia',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'GMQ05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'GMQ05',
          title: 'Siempre se puede hacer algo para aumentar la inteligencia',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'GMQ06',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'GMQ06',
          title:
            'Uno puede aprender nuevas cosas pero en realidad no puede cambiar su inteligencia de base',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    },
    {
      name: 'GMQ07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'GMQ07',
          title:
            'No importa cuán inteligente sea una persona, siempre puedes mejorar considerablemente su inteligencia',
          isRequired: true,
          rateValues: ratingValues
        }
      ]
    }
  ]
};

export default baseInfo;
