import React from 'react';
import Survey, { CssNames } from '../../../Survey';
import GeneralSelfEfficacySurvey from './gen_seff';
import GeneralSelfEffWrapper from './GeneralSelfEff.styles';

class GenSelfEfficacy extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete

  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { data } = survey;
    const { onComplete } = this.props;
    const { GENERAL_AUTOEFF_01 } = data;
    const parsed = {
      ...GENERAL_AUTOEFF_01
    };
    await onComplete(parsed);
  };

  render() {
    const GenSelfEffModel = new Survey.Model(GeneralSelfEfficacySurvey);
    return (
      <GeneralSelfEffWrapper>
        <div className='uai_header'>
          <img
            className='uai-logo'
            src='https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png'
            alt='uai-logo'
          />
        </div>
        <Survey.Survey
          model={GenSelfEffModel}
          css={CssNames}
          onComplete={this.onComplete}
        />
        <div className='Footer'>
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className='PushStickyFooter'></div>
        <div className='Plug'>
          <a
            href='http://www.uaimetrics.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UAI Metrics
          </a>
        </div>
      </GeneralSelfEffWrapper>
    );
  }
}

export default GenSelfEfficacy;
