import React from 'react';
import Prioridades from './Prioridades';
import postData from '../API';

import getConfig from '../../../config';

class PrioridadesPage extends React.Component {
  state = {
    rut: localStorage.getItem(`currentCode-${this.props.BATTERY}`)
  };

  componentDidMount = async () => {
    const { updateContext, BATTERY } = this.props;
    const { rut } = this.state;
    if (updateContext) {
      // console.log('on SSCC test');
      // console.log({ props: this.props });
      if (rut) {
        const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
          BATTERY,
          rut
        );
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE,
          rut: CURRENT_CODE
        });
      } else {
        const { USER_ID, USER_STAGE } = await getConfig(BATTERY);
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE
        });
      }
    }
  };

  onComplete = async data => {
    const { BATTERY } = this.props;
    const { rut } = this.state;
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished };
    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }

    const { USER_ID } = await getConfig(BATTERY, rut);
    return postData(newData, USER_ID);
  };

  render() {
    return <Prioridades onComplete={this.onComplete} />;
  }
}

export default PrioridadesPage;
