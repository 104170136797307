import React, { createContext, Component } from 'react';

const { Provider, Consumer } = createContext({
  stage: 0,
  user: null,
  rut: null,
  school: null
});

export class StageProvider extends Component {
  state = {
    stage: 0,
    user: null,
    rut: null,
    school: null
  };

  toNextStage = async (userStage = 'userStage-anon') => {
    const { stage } = this.state;
    const newStage = stage + 1;
    localStorage.setItem(userStage, newStage);
    await this.setState(state => ({
      ...state,
      stage: state.stage + 1
    }));
  };

  toPrevStage = async (userStage = 'userStage-anon') => {
    const { stage } = this.state;
    const newStage = stage - 1;
    localStorage.setItem(userStage, newStage < 0 ? 0 : newStage);
    await this.setState(state => ({
      ...state,
      stage: state.stage - 1 < 0 ? 0 : state.stage - 1
    }));
  };

  updateContext = async newContext => {
    await this.setState(state => ({
      ...state,
      ...newContext
    }));
  };

  render = () => (
    <Provider
      value={{
        state: this.state,
        updateContext: this.updateContext,
        nextStage: this.toNextStage,
        prevStage: this.toPrevStage
      }}
    >
      {this.props.children}
    </Provider>
  );
}

export const StageConsumer = Consumer;
