import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  DroppableListWrapper,
  ListItemWrapper,
  Container,
  DropZone,
  ScrollContainer
} from './sortableList.style';

const Item = ({ provided, snapshot, content }) => {
  return (
    <ListItemWrapper
      ref={ref => provided.innerRef(ref)}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style }}
      isDragging={snapshot.isDragging}
    >
      {content}
    </ListItemWrapper>
  );
};

class DraggableItemList extends React.Component {
  renderBoard = provided => {
    const { items, enterState } = this.props;
    // console.log({ enterState });
    return (
      <Container state={enterState}>
        <DropZone ref={provided.innerRef}>
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <Item
                  content={item.statement}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </DropZone>
      </Container>
    );
  };

  render() {
    const { id, listType, internalScroll, isCombineEnabled } = this.props;
    return (
      <Droppable
        droppableId={id}
        type={listType}
        isCombineEnabled={isCombineEnabled}
      >
        {(provided, snapshot) => (
          <DroppableListWrapper
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {internalScroll ? (
              <ScrollContainer>{this.renderBoard(provided)}</ScrollContainer>
            ) : (
              this.renderBoard(provided)
            )}
          </DroppableListWrapper>
        )}
      </Droppable>
    );
  }
}

export default DraggableItemList;
