import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
// application
import RedirectPage from './Pages/RedirectPage';
import SwitchPage from './Pages/SwitchPage';
import SingleSwitchPage from './Pages/SingleSwitchPage';
// complete pages
import TestPage from './Pages/Test';
import IndexPage from './Pages/Index';
// SIOP Project
import SIOPPage from './Pages/SIOP';
// DKMS Project
import DKMSInscriptionPage from './Pages/DKMS_INS';
import DKMSPage from './Pages/DKMS';
import DKMSPresentation from './Pages/DKMS/Presentation';
import DKMSAntecedentesEncuestado from './Pages/DKMS/AntecedentesEncuestado';
import DKMSAntecedentesNino from './Pages/DKMS/AntecedentesNino';
import DKMSChildTreatment from './Pages/DKMS/ChildTreatment';
import DKMSChildCare from './Pages/DKMS/ChildCare';
import DKMSRoleIdentification from './Pages/DKMS/RoleIdentification';
// import DKMSOccupation from './Pages/DKMS/Occupation';
// import DKMSWorkImpact from './Pages/DKMS/WorkImpact';
import DKMSChildImpact from './Pages/DKMS/ChildImpact';
import DKMSFamilyImpact from './Pages/DKMS/FamilyImpact';

import DKMSPartnerImpact from './Pages/DKMS/PartnerImpact';
import DKMSEconomicCosts from './Pages/DKMS/EconomicCosts';
import DKMSIllnessPositivity from './Pages/DKMS/IllnessPositivity';
import DKMSReflectionComments from './Pages/DKMS/ReflectionComments';
// vocational alpha - Equis1
import VocationalPage from './Pages/Vocational';
import Equis1PilotoPage from './Pages/Equis1Piloto';
// Equis2
import EquisJuniorPage from './Pages/EquisJunior';
// SSCC project
import SSCCPage from './Pages/SSCC';
import SSCCCompromisoCambio from './Pages/SSCC/CompromisoCambio';
import SSCCDemographics from './Pages/SSCC/Demographics';
import SSCCEjesPlanEstrategico from './Pages/SSCC/EjesPlanEstrategico';
import SSCCPersonalAttributes from './Pages/SSCC/PersonalAttributes';
import SSCCPrioridades from './Pages/SSCC/Prioridades';
import SSCCWorkDemandsPage from './Pages/SSCC/WorkDemands';
import SSCCFinaleCommentsPage from './Pages/SSCC/FinalComments';
// MBA
import MBAPage from './Pages/MBA';

// component pages
import BigFivePage from './Pages/BigFive';
import CareerSuccessPage from './Pages/CareerSuccess';
import DemographicsPage from './Pages/Demographics';
import LearningAgilityPage from './Pages/LearningAgility';
// import GFLExamplePage from './Pages/GFLExample';
// import GFLCompletePage from './Pages/GFLComplete';
import GFNExamplePage from './Pages/GFNExample';
import GFNCompletePage from './Pages/GFNComplete';
// import GoalOrientationPage from './Pages/GoalOrientation';
// import SociodemographicsPage from './Pages/Sociodemographics';
import WorkingInfoPage from './Pages/WorkingInfo';
import FinishedPage from './Pages/Finished';
import { StageProvider, StageConsumer } from './Context';
import './index.css';

class App extends React.Component {
  render() {
    return (
      <StageProvider>
        <StageConsumer>
          {({ state, nextStage }) => {
            return (
              <ThemeProvider theme={theme}>
                <Router>
                  <>
                    <Route path='/' exact component={RedirectPage} />
                    <Route path='/c/:id' exact component={SwitchPage} />
                    <Route path='/r/:id' exact component={SingleSwitchPage} />

                    <Route path='/repo' exact component={TestPage} />
                    <Route path='/testing' exact component={IndexPage} />
                    {/* MBA 2.0 */}
                    <Route path='/mba' exact component={MBAPage} />
                    {/* Vocational alpha */}
                    <Route
                      path='/vocational'
                      exact
                      component={VocationalPage}
                    />
                    <Route path='/equis1' exact component={VocationalPage} />
                    <Route
                      path='/equis1-piloto'
                      exact
                      component={Equis1PilotoPage}
                    />
                    <Route path='/abproject' exact component={VocationalPage} />

                    {/* Equis junior alpha */}
                    <Route path='/equis2' exact component={EquisJuniorPage} />
                    <Route
                      path='/equis-junior'
                      exact
                      component={EquisJuniorPage}
                    />

                    {/* SIOP questionnaire project */}
                    <Route path='/siop' exact component={SIOPPage} />

                    {/* DKMS project */}
                    <Route path='/DKMS' exact component={DKMSInscriptionPage} />
                    <Route path='/dkmsdata' exact component={DKMSPage} />
                    <Route
                      path='/dkmsdata/presentation'
                      exact
                      component={DKMSPresentation}
                    />
                    <Route
                      path='/dkmsdata/antecedentes-encuestado'
                      exact
                      component={DKMSAntecedentesEncuestado}
                    />
                    <Route
                      path='/dkmsdata/antecedentes-nino'
                      exact
                      component={DKMSAntecedentesNino}
                    />
                    <Route
                      path='/dkmsdata/child-treatment'
                      exact
                      component={DKMSChildTreatment}
                    />
                    <Route
                      path='/dkmsdata/role-identification'
                      exact
                      component={DKMSRoleIdentification}
                    />
                    <Route
                      path='/dkmsdata/child-care'
                      exact
                      component={DKMSChildCare}
                    />
                    {/* <Route
                      path='/dkmsdata/occupation'
                      exact
                      component={DKMSOccupation}
                    /> */}
                    {/* <Route
                      path='/dkmsdata/work-impact'
                      exact
                      component={DKMSWorkImpact}
                    /> */}
                    <Route
                      path='/dkmsdata/child-impact'
                      exact
                      component={DKMSChildImpact}
                    />
                    <Route
                      path='/dkmsdata/family-impact'
                      exact
                      component={DKMSFamilyImpact}
                    />
                    <Route
                      path='/dkmsdata/partner-impact'
                      exact
                      component={DKMSPartnerImpact}
                    />

                    <Route
                      path='/dkmsdata/economics'
                      exact
                      component={DKMSEconomicCosts}
                    />
                    <Route
                      path='/dkmsdata/illness-positivity'
                      exact
                      component={DKMSIllnessPositivity}
                    />
                    <Route
                      path='/dkmsdata/reflection-comments'
                      exact
                      component={DKMSReflectionComments}
                    />
                    {/* SSCC project */}
                    <Route path='/SSCC' exact component={SSCCPage} />
                    <Route
                      path='/SSCC/compromiso-cambio'
                      exact
                      component={SSCCCompromisoCambio}
                    />
                    <Route
                      path='/SSCC/demographics'
                      exact
                      component={SSCCDemographics}
                    />
                    <Route
                      path='/SSCC/ejes-plan'
                      exact
                      component={SSCCEjesPlanEstrategico}
                    />
                    <Route
                      path='/SSCC/personal-attributes'
                      exact
                      component={SSCCPersonalAttributes}
                    />
                    <Route
                      path='/SSCC/priorities'
                      exact
                      component={SSCCPrioridades}
                    />
                    <Route
                      path='/SSCC/work-demands'
                      exact
                      component={SSCCWorkDemandsPage}
                    />
                    <Route
                      path='/SSCC/final-comments'
                      exact
                      component={SSCCFinaleCommentsPage}
                    />

                    <Route
                      path='/demographics'
                      exact
                      component={DemographicsPage}
                    />
                    <Route
                      path='/learning-agility'
                      component={LearningAgilityPage}
                    />
                    <Route path='/big-five' exact component={BigFivePage} />
                    {/* <Route path="/gfl-example" component={GFLExamplePage} /> */}
                    {/* <Route path="/gfl-complete" component={GFLCompletePage} /> */}
                    <Route path='/gfn-example' component={GFNExamplePage} />
                    <Route path='/gfn-complete' component={GFNCompletePage} />
                    <Route
                      path='/career-success'
                      exact
                      component={CareerSuccessPage}
                    />
                    <Route path='/working-info' component={WorkingInfoPage} />
                    {/* <Route
                      path="/goal-orientation"
                      component={GoalOrientationPage}
                    /> */}

                    {/* <Route
                      path="/socio-demographics"
                      component={SociodemographicsPage}
                    /> */}
                    <Route path='/finished' component={FinishedPage} />
                  </>
                </Router>
              </ThemeProvider>
            );
          }}
        </StageConsumer>
      </StageProvider>
    );
  }
}
export default App;
