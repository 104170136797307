import React from 'react';
import MediaQuery from 'react-responsive';
import PGIA from './PGI_A';
import PGIAMobile from './PGI_A_Mobile';
import postData from '../../API';

import getConfig from '../../../../config';

class DemographicsPage extends React.Component {
  componentDidMount = async () => {
    const { updateContext, campaign, rut } = this.props;
    // const { rut } = this.state;
    if (updateContext) {
      // console.log('on DI test');
      // console.log({ props: this.props });
      if (rut) {
        const { USER_ID, USER_STAGE, CURRENT_CODE } = await getConfig(
          campaign.id,
          rut
        );
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE,
          rut: CURRENT_CODE
        });
      } else {
        const { USER_ID, USER_STAGE } = await getConfig(campaign.id);
        await updateContext({
          user: USER_ID,
          stage: USER_STAGE
        });
      }
    }
  };

  onComplete = async data => {
    const { campaign, rut, stage = 0, form = '' } = this.props;
    // const { rut } = this.state;
    let finished = { finished: 0 };
    if (this.props.isLast) {
      finished.finished = 1;
    }
    const newData = { ...data, ...finished, STAGE: stage + 1, FORM: form };

    // if parent has onComplete
    if (this.props.onComplete) {
      return this.props.onComplete(newData);
    }
    const { USER_ID } = await getConfig(campaign.id, rut);
    return postData(newData, USER_ID, rut);
  };

  render() {
    return (
      <MediaQuery maxWidth={700}>
        {matches => {
          if (matches) {
            return <PGIAMobile onComplete={this.onComplete} />;
          } else {
            return <PGIA onComplete={this.onComplete} />;
          }
        }}
      </MediaQuery>
    );
  }
}

export default DemographicsPage;
