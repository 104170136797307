import React from 'react';
import PGIA from './FORMA_A';
import PGIB from './FORMA_B';

class PGIFORM extends React.Component {
  render() {
    const { form } = this.props;
    if (form === 'B') {
      return <PGIB {...this.props} />;
    }
    return <PGIA {...this.props} />;
  }
}

export default PGIFORM;
