import React, { Component } from 'react';
import SortableListGroup from './SortableListGroup';

class SortablePolar extends Component {
  state = {
    active: true
  };
  onComplete = async items => {
    const firstItem = { ...items[0], value: 2 };
    const lastItem = { ...items[items.length - 1], value: 0 };
    const clone = items.slice();
    clone[0] = firstItem;
    clone[items.length - 1] = lastItem;
    const parsed = [{ ...items, ...clone }];
    // console.log({ parsed });
    const { onComplete } = this.props;
    await this.setState(state => ({ active: !state.active }));

    await onComplete(parsed);
  };

  render() {
    const { exampleItems } = this.props;
    const { active } = this.state;
    return (
      <SortableListGroup
        {...this.props}
        items={exampleItems}
        toNextItem={items => this.onComplete(items)}
        disabled={!active}
      />
    );
  }
}

export default SortablePolar;
