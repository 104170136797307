const baseInfo = {
  locale: 'es',
  title: 'Demográficos',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  // showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: false,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'DEMO_PAGE_01',
      // title: 'Bienvenido',
      elements: [
        {
          type: 'html',
          name: 'HTML_01',
          html: `
<legend>
  <div class="QuestionText BorderColor">
    <div>
      <span style="font-size:16px;">
        <span style="font-family:arial,helvetica,sans-serif;">
          <h3>Consentimiento Informado</h3>
        </span>
      </span>
    </div>
    <br>
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        Queremos invitarlo a participar en el proceso de validación de un conjunto de medidas psicológicas relacionadas con el desempeño y capacidades de gestión de personas como Usted.
        Este estudio es llevado a cabo por un equipo de investigación de la Universidad Adolfo Ibáñez y la Universidad de Chile.<br>
      </span>
    </span>

    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">
        <br>
        <strong>Si accede a participar en este estudio, le pediremos responder una encuesta que le tomará aproximadamente 25 minutos</strong>.
        <br>
        <br>
        Su participación en este estudio <strong>es voluntaria</strong>. Esto quiere decir que usted puede optar por no participar o incluso abandonar el estudio en cualquier momento. Si acepta ser parte de este estudio, se le pedirá que complete un cuestionario que contiene un conjunto de preguntas que indagan sobre comportamientos y actitudes que las personas demuestran en su trabajo.
        <br>
        <br>
        <strong>Sus respuestas a estos cuestionarios son confidenciales</strong>. Sus respuestas individuales NO serán publicadas ni reveladas a personas ajenas a este estudio. En el caso que se publiquen los resultados de este estudio, los datos se reportaran de manera agregada de modo que no será posible en ningún caso individualizar a los participantes.
        <br>
        <br>
        Si tiene preguntas en relación a este estudio, puede contactar a Diego Bravo al email <a href="mailto:diego.bravo@uai.cl">diego.bravo@uai.cl</a>
      </span>
    </span>
    <span style="font-size:16px;">
      <span style="font-family:arial,helvetica,sans-serif;">.
        Por favor asegúrese de haber leído la información anterior, haber hecho preguntas en caso de tener dudas y haber recibido respuestas satisfactorias frente a cada una de ellas. Al chequear el casillero de más abajo, usted acepta participar en este estudio.
        <br>
        <br>
        De antemano, gracias por su participación
      </span>
    </span>
    <br>
  </div>
  <hr />
</legend>
`
        },
        {
          type: 'radiogroup',
          name: 'DEMO_Q01',
          title:
            'Seleccionando "Acepto participar en el estudio" declaro haber comprendido los alcances del estudio y mis derechos como participante del mismo.',
          isRequired: true,
          colCount: 2,
          choices: [
            {
              value: '1',
              text: 'Acepto participar en el estudio'
            },
            {
              value: '2',
              text: 'No acepto participar en el estudio'
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_02',
      elements: [
        {
          type: 'multipletext',
          name: 'NAME_BASE',
          // isRequired: true,
          title: 'Ingrese su Nombre y Apellido',
          items: [
            {
              name: 'NAME',
              title: 'Nombre',
              isRequired: true
            },
            {
              name: 'LASTNAME',
              title: 'Apellido',
              isRequired: true
            }
          ]
        }
        // {
        //   type: 'text',
        //   name: 'G_CODE',
        //   title: 'Escriba el código de su grupo'
        // }
      ]
    },
    {
      name: 'DEMO_PAGE_03',
      title: 'Información general',
      elements: [
        {
          type: 'radiogroup',
          name: 'Sexo',
          title: 'Sexo',
          isRequired: true,
          choices: [
            {
              value: '1',
              text: 'Hombre'
            },
            {
              value: '0',
              text: 'Mujer'
            }
          ]
        },
        {
          type: 'text',
          name: 'AGE',
          title: 'Edad (años)',
          isRequired: true,
          inputType: 'number',
          validators: [
            {
              type: 'numeric',
              minValue: 0,
              maxValue: 99
            }
          ]
        }
      ]
    },
    {
      name: 'DEMO_PAGE_04',
      title: ' ',
      // description: 'Para poder hacerle una devolución de sus resultados.',
      elements: [
        {
          type: 'multipletext',
          name: 'DEMO_Q02',
          title: 'Ingrese su dirección de correo',
          items: [
            // {
            //   name: 'RUT',
            //   title: 'Rut'
            // },
            {
              name: 'EMAIL',
              title: 'Email',
              isRequired: true,
              inputType: 'email',
              validators: [
                {
                  type: 'email'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default baseInfo;
