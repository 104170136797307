import React from 'react';
import ChatBot, { ChatCustomComp } from '../ChatBot';
import ChatWrapper from './chat.style';
import NabuPic from './img/nabu-full.png';

class ScciIntro extends React.Component {
  state = { loading: false };

  handleEnd = ({ steps, values }) => {
    const { stage = 0 } = this.props;
    setTimeout(() => {
      this.setState({ loading: true });
      // console.log({ steps });
      // console.log({ values });
      const data = { SCCI_TUTORIAL: '1', STAGE: stage + 1 };
      this.props.onComplete(data);
    }, 2000);
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <ChatWrapper>
          <h4>Guardando respuestas para avanzar...</h4>
        </ChatWrapper>
      );
    }
    return (
      <ChatWrapper>
        <div className='nabu-chat-person'>
          <img className='nabu-pic' src={NabuPic} alt='nabu-pic' />
        </div>
        <ChatBot
          className='nabu-chat-wrapper'
          headerTitle='NABU'
          placeholder='Escribe un mensaje'
          botDelay={3500}
          handleEnd={this.handleEnd}
          steps={[
            {
              id: '1',
              message: 'Muy bien!',
              delay: 1000,
              trigger: '2'
            },
            {
              id: '2',
              message: 'Pudiste reflejarte en alguno de los items anteriores?',
              delay: 2000,
              trigger: '3'
            },
            {
              id: '3',
              message: 'Yo inicié teniendo claro todo lo que debía hacer...',
              trigger: '4'
            },
            {
              id: '4',
              message: 'Pero claro, me programaron de esta forma...',
              trigger: '5'
            },
            {
              id: '5',
              options: [
                { value: 1, label: '[Silencio Incomodo]', trigger: '6' },
                { value: 2, label: 'Ok...', trigger: '6' },
                { value: 3, label: '...', trigger: '6' }
              ]
            },

            {
              id: '6',
              message: '...',
              delay: 1000,
              trigger: '7'
            },
            {
              id: '7',
              message: 'Continuemos!',
              delay: 2000,
              trigger: '8'
            },
            {
              id: '8',
              message: 'Ahora deberás responder otra actividad',
              delay: 3000,
              trigger: '9'
            },
            {
              id: '9',
              component: (
                <ChatCustomComp>
                  Te presentaré una serie de afirmaciones y deberás responder
                  Qué tanto te representan, utilizando una escala de a{' '}
                  <strong>1</strong> a <strong>9</strong>.
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 6000,
              trigger: '10'
            },
            {
              id: '10',
              component: (
                <ChatCustomComp>
                  Donde <strong>1</strong> es: <em>"No me representa nada"</em>
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 8000,
              trigger: '11'
            },
            {
              id: '11',
              component: (
                <ChatCustomComp>
                  Y donde <strong>9</strong> es:{' '}
                  <em>"Me representa muy bien"</em>
                </ChatCustomComp>
              ),
              asMessage: true,
              delay: 5000,
              trigger: '12'
            },
            {
              id: '12',
              message: 'Estás listo para comenzar?',
              delay: 6000,
              trigger: '13'
            },
            {
              id: '13',
              options: [
                {
                  value: 1,
                  label: 'Si, Comenzar test!',
                  trigger: 'end_last'
                }
              ]
            },
            {
              id: 'end_last',
              message: 'Aquí vamos!',
              end: true
            }
          ]}
        />
      </ChatWrapper>
    );
  }
}

export default ScciIntro;
