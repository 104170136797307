const rateValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

const attributeOptions = [
  {
    value: '01',
    text: 'Inspirador'
  },
  {
    value: '02',
    text: 'Transmite prioridades claras'
  },
  {
    value: '03',
    text: 'Promueve un alto desempeño'
  },
  {
    value: '04',
    text: 'Apoya nuestro desarrollo profesional'
  },
  {
    value: '05',
    text: 'Está presente y conoce lo que pasa en el aula'
  },
  {
    value: '06',
    text: 'Preocupado por el bienestar de las personas'
  },
  {
    value: '07',
    text: 'Genera y promueve relaciones de confianza'
  },
  {
    value: '08',
    text: 'Reconoce los logros de las personas'
  },
  {
    value: '09',
    text: 'Promueve la participación'
  },
  {
    value: '10',
    text: 'Valiente para tomar decisiones organizacionales'
  },
  {
    value: '11',
    text: 'Conectado con las familias y la comunidad'
  },
  {
    value: '12',
    text: 'Asigna estratégicamente los recursos'
  },
  {
    value: '13',
    text:
      'Ayuda a que concentremos nuestros esfuerzos en lo importante, no nos pide hacer cosas accesorias'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_LEARNING_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        Por favor indique en qué medida los siguientes atributos son característicos del rector <strong>usando una escala de 1 (Para Nada) a 5 (Completamente)</strong>
      </span>
    </legend>
  </div>
  `
};

const questionHeaderPreference = {
  type: 'html',
  name: 'HTML_LEARNING_TITLE_02',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        Según su opinion, ¿En cúal de estos atributos <strong>tiene que mejorar más</strong> el rector? MARCAR HASTA 3 ATRIBUTOS.
      </span>
    </legend>
  </div>
  `
};

const PersonalAttributes = {
  locale: 'es',
  title: 'La Persona del Rector',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'LEADER_01',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_01',
          title: 'Inspirador',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_02',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_02',
          title: 'Transmite prioridades claras',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_03',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_03',
          title: 'Promueve un alto desempeño',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'LEADER_04',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_04',
          title: 'Apoya nuestro desarrollo profesional',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_05',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_05',
          title: 'Está presente y conoce lo que pasa en el aula',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_06',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_06',
          title: 'Preocupado por el bienestar de las personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_07',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_07',
          title: 'Genera y promueve relaciones de confianza',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_08',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_08',
          title: 'Reconoce los logros de las personas',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_09',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_09',
          title: 'Promueve la participación',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_10',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_10',
          title: 'Valiente para tomar decisiones organizacionales',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_11',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_11',
          title: 'Conectado con las familias y la comunidad',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_12',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_12',
          title: 'Asigna estratégicamente los recursos',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'LEADER_13',
      title: '',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'LEADER_13',
          title:
            'Ayuda a que concentremos nuestros esfuerzos en lo importante, no nos pide hacer cosas accesorias',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },

    {
      name: 'LEADER2_01',
      title: '',
      elements: [
        questionHeaderPreference,
        {
          type: 'checkbox',
          name: 'LEADER_WORK',
          title: ` `,
          isRequired: true,
          colCount: 7,
          validators: [
            {
              type: 'expression',
              expression: 'checkboxMaxSelected({LEADER_WORK}, 3) = true',
              text: 'Por favor, selecciona hasta 3 atributos máximo'
            }
          ],
          choices: attributeOptions
        }
        // {
        //   type: 'rating',
        //   name: 'LEADER2_01',
        //   title: 'Inspirador',
        //   isRequired: true,
        //   rateValues: rateValues
        // }
      ]
    }
    // {
    //   name: 'LEADER2_02',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_02',
    //       title: 'Transmite prioridades claras',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_03',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_03',
    //       title: 'Promueve un alto desempeño',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_04',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_04',
    //       title: 'Apoya nuestro desarrollo profesional',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_05',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_05',
    //       title: 'Está presente y conoce lo que pasa en el aula',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_06',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_06',
    //       title: 'Preocupado por el bienestar de las personas',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_07',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_07',
    //       title: 'Genera y promueve relaciones de confianza',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_08',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_08',
    //       title: 'Reconoce los logros de las personas',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_09',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_09',
    //       title: 'Promueve la participación',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_10',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_10',
    //       title: 'Valiente para tomar decisiones organizacionales',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_11',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_11',
    //       title: 'Conectado con las familias y la comunidad',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_12',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_12',
    //       title: 'Asigna estratégicamente los recursos',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // },
    // {
    //   name: 'LEADER2_13',
    //   title: '',
    //   elements: [
    //     questionHeaderPreference,
    //     {
    //       type: 'rating',
    //       name: 'LEADER2_13',
    //       title:
    //         'Ayuda a que concentremos nuestros esfuerzos en lo importante, no nos pide hacer cosas accesorias',
    //       isRequired: true,
    //       rateValues: rateValues
    //     }
    //   ]
    // }
  ]
};

export default PersonalAttributes;
