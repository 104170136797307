const rateValues = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '2',
    text: '2'
  },
  {
    value: '3',
    text: '3'
  },
  {
    value: '4',
    text: '4'
  },
  {
    value: '5',
    text: '5'
  }
];

const questionHeader = {
  type: 'html',
  name: 'HTML_LEARNING_TITLE',
  html: `
  <div>
    <legend>
      <span style="font-size:16px;">
        Por favor evalúe las siguientes afirmaciones pensando en la cultura de pensamiento o metodología VESS como una estrategia de cambio adoptada por el colegio al cual usted pertenece. Frente a cada una de ellas evalúe su grado de acuerdo o desacuerdo <strong>usando una escala de 1 (Muy en desacuerdo) a 5 (Muy de acuerdo).</strong>
      </span>
    </legend>
  </div>
  `
};

const LearningAgility = {
  locale: 'es',
  title: 'Cultura de Pensamiento / Modelo VESS',
  completedHtml:
    '<div style="display: flex; justify-content: center; align-items: center; height: 40vh;"><h4>Guardando respuestas para avanzar...</h4></div>',
  startSurveyText: 'Continuar',
  showProgressBar: 'top',
  // progressBarType: 'questions',
  goNextPageAutomatic: true,
  // firstPageIsStarted: true,
  showPrevButton: true,
  showNavigationButtons: true,
  pageNextText: '→',
  pagePrevText: '←',
  showQuestionNumbers: 'off',
  requiredText: `*`,
  pages: [
    {
      name: 'CHANGE_01',
      title: ' ',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'CHANGE_01',
          title:
            'Creo en el valor del VESS para producir aprendizajes significativos en mis estudiantes',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CHANGE_02',
      title: ' ',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'CHANGE_02',
          title:
            'El VESS contribuye a los buenos resultados en pruebas como el SIMCE y la PSU ',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CHANGE_03',
      title: ' ',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'CHANGE_03',
          title: 'Tengo la preparación adecuada para implementar el VESS',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CHANGE_04',
      title: ' ',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'CHANGE_04',
          title:
            'Tengo tiempo suficiente para planificar las actividades asociadas a la metodología VESS',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CHANGE_05',
      title: ' ',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'CHANGE_05',
          title:
            'Cuento con los recursos materiales adecuados para implementar la metodología VESS',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'CHANGE_06',
      title: ' ',
      elements: [
        questionHeader,
        {
          type: 'rating',
          name: 'CHANGE_06',
          title:
            'Creo que aprender la metodología VESS aporta a mi desarrollo profesional y empleabilidad',
          isRequired: true,
          rateValues: rateValues
        }
      ]
    },
    {
      name: 'C_CHANGE_PAGE_02',
      elements: [
        {
          type: 'comment',
          name: 'CHANGE_COMMENT',
          title: '¿Qué sugeriría Ud. para mejorar la implementación del VESS?'
        }
      ]
    }
  ]
};

export default LearningAgility;
