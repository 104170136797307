import axios from 'axios';
// import { USER_ID } from '../config';

const postData = async (values, USER_ID, CODE) => {
  const POST_API =
    'https://iathnyvk8h.execute-api.us-west-2.amazonaws.com/dev/submit';
  const config = { SHEET_ID: '1gkyL2rHYOqi41VPRuJgXOhTggkUPQF60OveyR6_RzOI' };
  const caseid = USER_ID;
  const data = { ...values, caseid, code: CODE, config };
  // console.log({ data });
  await axios.post(`${POST_API}`, data);
  return Promise.resolve('200');
};

export default postData;
