import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Survey, { CssNames } from '../../Survey';
import AudioTestIntro from './audioTest';
import AudioTestSurvey from './audioTestQuestions';
import AudioTestWrapper, { Button } from './audioTest.styles';
import mp3File from './Driving-English-Conversation-Sample.mp3';

class AudioTest extends React.Component {
  // state = { stage: 0, big5: null, matrix: null };
  //Define a callback methods on survey complete
  state = { innerState: 0, audioFinished: false };
  onComplete = async (survey, options) => {
    // Write survey results into database
    // console.log('Survey results: ');
    // const { data } = survey;
    // console.log(data);
    // await this.setState(prev => ({ big5: data, stage: prev.stage + 1 }));
    const { data } = survey;
    console.log({ data });
    const { onComplete } = this.props;
    const { AUD_Q01, AUD_Q02, AUD_Q03, AUD_Q04 } = data;
    const parsed = { AUD_Q01, AUD_Q02, AUD_Q03, AUD_Q04 };
    await onComplete(parsed);
  };

  toNextPage = async () => {
    await this.setState(prevState => ({
      ...prevState,
      innerState: prevState.innerState + 1
    }));
  };

  onFinishAudio = async () => {
    await this.setState(prevState => ({
      ...prevState,
      audioFinished: true
    }));
  };

  renderFirstPage = () => {
    const AudioIntroModel = new Survey.Model(AudioTestIntro);
    return (
      <Survey.Survey
        model={AudioIntroModel}
        css={CssNames}
        onComplete={this.toNextPage}
      />
    );
  };

  renderAudioPage = () => {
    const { audioFinished } = this.state;
    return (
      <div className="quanta_main">
        <div className="panel-heading">
          <h3>Prueba Audio</h3>
        </div>
        <div className="card-block mt-4">
          <div className="quanta_page">
            <div>
              Escuche el siguiente audio presionado el icono de{' '}
              <strong>"Play"</strong>
            </div>
            <div>
              Una vez termine de escuchar la pista de audio, presione el botón
              de "Continuar" para avanzar.
            </div>
            <div className="audio-player-wrapper">
              <ReactAudioPlayer
                src={mp3File}
                controls
                controlsList="nodownload"
                onEnded={this.onFinishAudio}
              />
            </div>
            <div className="panel-footer card-footer">
              <Button
                type="button"
                disabled={!audioFinished}
                onClick={this.toNextPage}
              >
                Continuar →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTestPage = () => {
    const AudioTestModel = new Survey.Model(AudioTestSurvey);
    return (
      <Survey.Survey
        model={AudioTestModel}
        css={CssNames}
        onComplete={this.onComplete}
      />
    );
  };

  renderInnerPage = () => {
    const { innerState } = this.state;
    if (innerState === 1) {
      return this.renderAudioPage();
    }
    if (innerState === 2) {
      return this.renderTestPage();
    }
    // if not
    return this.renderFirstPage();
  };

  render() {
    return (
      <AudioTestWrapper>
        <div className="uai_header">
          <img
            className="uai-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564630271/uaimetrics/uai_white.png"
            alt="uai-logo"
          />
        </div>
        {this.renderInnerPage()}
        <div className="Footer">
          {/* <img
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629089/uaimetrics/Logo_UAI-CORPORATE-2.png"
            alt="uai-corporate"
          /> */}
          {/* <img
            className="btg-logo"
            src="https://res.cloudinary.com/dpveipnvx/image/upload/v1564629090/uaimetrics/BTG-Pactual-HP_Logo.jpg"
            alt="btg-pactual"
          /> */}
        </div>
        <div className="PushStickyFooter"></div>
        <div className="Plug">
          <a
            href="http://www.uaimetrics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UAI Metrics
          </a>
        </div>
      </AudioTestWrapper>
    );
  }
}

export default AudioTest;
